import type { DashboardDueTodayResponseDto } from 'dtos/dashboard.dtos';
import type { DueTodayFilter } from 'services/dashboard/types';
import useSWR from 'swr';
import { getUrlSearchParams } from 'utils/client/fetch';
import type { PaginatedData, SortModel } from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseDashboardDueTodayProps = {
  page: number;
  pageSize: number;
  sortModel: SortModel;
  filter?: DueTodayFilter;
};

export default function useDashboardDueToday({
  page = 1,
  pageSize = 10,
  sortModel: { sort, sortDirection },
  filter,
}: UseDashboardDueTodayProps) {
  const params = getUrlSearchParams({
    page,
    pageSize,
    sort,
    sortDirection,
    today: new Date().toLocaleDateString('en-CA'),
    filter,
  });

  const {
    data: response,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<PaginatedData<DashboardDueTodayResponseDto>, Error>(
    `dashboard/today?${params}`,
    fetcher,
  );

  const { data, meta } = response || {};

  return { data, meta, isLoading, error, refresh: mutate };
}
