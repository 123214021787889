import type { AirSegment } from '@prisma/client';
import { iataAirportCountryMap } from 'data/iata-airport-country-map';
import { ItineraryType } from 'dtos';

export const getItineraryTypeFromSegments = (
  segments: AirSegment[] = [],
): ItineraryType | undefined => {
  if (segments.length === 0) return undefined;

  const uniqueCountries = new Set<string>();

  for (const segment of segments) {
    const departCountry = getCountryFromIataCode(segment.departCityCode);
    const arrivalCountry = getCountryFromIataCode(segment.arrivalCityCode);

    if (!departCountry || !arrivalCountry) return undefined;

    uniqueCountries.add(departCountry).add(arrivalCountry);

    if (uniqueCountries.size > 1) return ItineraryType.INTERNATIONAL;
  }

  return ItineraryType.DOMESTIC;
};

export const getItineraryTypeFromItinerary = (
  itinerary: string | null | undefined,
): ItineraryType | undefined => {
  if (!itinerary) return undefined;

  const iataCodes = itinerary.split('/');
  const firstCountry = getCountryFromIataCode(iataCodes[0]);

  for (let i = 1; i < iataCodes.length; i++) {
    const currentCountry = getCountryFromIataCode(iataCodes[i]);
    if (currentCountry !== firstCountry) return ItineraryType.INTERNATIONAL;
  }

  return ItineraryType.DOMESTIC;
};

export const getCountryFromIataCode = (
  iataCode: string | null | undefined,
): string | undefined =>
  iataCode ? iataAirportCountryMap[iataCode] : undefined;

export const mapAirSegmentsToItinerary = (
  airSegments: AirSegment[] = [],
): string | undefined =>
  airSegments.length
    ? airSegments.reduce(
        (acc, as, index) =>
          index === 0 // First segment
            ? `${as.departCityCode}/${as.arrivalCityCode}`
            : airSegments[index - 1].arrivalCityCode === as.departCityCode // Subsequent segments
              ? `${acc}/${as.arrivalCityCode}`
              : `${acc}/${as.departCityCode}/${as.arrivalCityCode}`,
        '',
      )
    : undefined;
