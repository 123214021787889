import type {
  DashboardDatesResponseDto,
  DashboardDatesSortKeys,
  DateTypeValue,
} from 'dtos/dashboard.dtos';
import { DashboardDateRange } from 'dtos/dashboard.dtos';
import useSWR from 'swr';
import { getUrlSearchParams } from 'utils/client/fetch';
import type { PaginatedData } from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

export type UseDashboardDatesProps = {
  page: number;
  pageSize: number;
  sort: DashboardDatesSortKeys;
  sortDirection: 'asc' | 'desc';
  dateFilter?: DashboardDateRange;
  typeFilter?: DateTypeValue;
};

export default function useDashboardDates({
  page = 0,
  pageSize = 20,
  sort,
  sortDirection,
  dateFilter = DashboardDateRange.ALL,
  typeFilter = 'ALL',
}: UseDashboardDatesProps) {
  const params = getUrlSearchParams({
    page,
    pageSize,
    sort,
    sortDirection,
    dateRange: dateFilter,
    type: typeFilter,
    today: new Date().toLocaleDateString('en-CA'),
  });

  const {
    data: response,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<PaginatedData<DashboardDatesResponseDto>, Error>(
    `dashboard/dates?${params}`,
    fetcher,
  );

  const { data, meta } =
    (response as PaginatedData<DashboardDatesResponseDto>) || {};

  return { data, meta, isLoading, error, refresh: mutate };
}
