import type { DashboardTasksResponseDto } from 'dtos/dashboard.dtos';
import {
  type TaskBasis,
  TaskFilter,
  type TaskFilterType,
} from 'services/dashboard/types';
import useSWR from 'swr';
import { getUrlSearchParams } from 'utils/client/fetch';
import type { PaginatedData, SortModel } from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseDashboardTasksProps = {
  page: number;
  pageSize: number;
  sortModel: SortModel;
  basis?: TaskBasis;
  filter?: TaskFilterType;
};

export default function useDashboardTasks({
  page = 1,
  pageSize = 20,
  sortModel: { sort, sortDirection },
  basis,
  filter = TaskFilter.NEXT_7_DAYS,
}: UseDashboardTasksProps) {
  const params = getUrlSearchParams({
    page,
    pageSize,
    sort,
    sortDirection,
    basis,
    filter,
    today: new Date().toLocaleDateString('en-CA'),
  });

  const {
    data: response,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<PaginatedData<DashboardTasksResponseDto>, Error>(
    `dashboard/tasks?${params}`,
    fetcher,
  );

  const { data, meta } = response || {};

  return { data, meta, isLoading, error, refresh: mutate };
}
