import {
  queryTypes as qt,
  useQueryState,
  useQueryStates,
} from 'next-usequerystate';
import type { SortModel } from 'types/paginatedData';

const getQueryParam = (prefix: string, param: string) =>
  `${prefix}${prefix ? param.slice(0, 1).toUpperCase() + param.slice(1) : param}`;

const usePagination = <T = { [key: string]: unknown }>(
  defaultSortColumn: keyof T,
  defaultSortDirection: 'asc' | 'desc' = 'asc',
  prefix = '',
  defaultPageSize = 100,
) => {
  const [sortModel, setSortModel] = useQueryStates(
    {
      [getQueryParam(prefix, 'sort')]: qt.string.withDefault(
        defaultSortColumn as string,
      ),
      [getQueryParam(prefix, 'sortDirection')]: qt
        .stringEnum(['asc', 'desc'])
        .withDefault(defaultSortDirection),
    },
    {
      history: 'replace',
    },
  );

  const [page, setPage] = useQueryState(
    getQueryParam(prefix, 'page'),
    qt.integer.withDefault(0),
  );
  const [pageSize, setPageSize] = useQueryState(
    getQueryParam(prefix, 'pageSize'),
    qt.integer.withDefault(defaultPageSize),
  );

  return {
    sortModel: {
      sort: sortModel[getQueryParam(prefix, 'sort')],
      sortDirection: sortModel[getQueryParam(prefix, 'sortDirection')] as
        | 'asc'
        | 'desc',
    } as SortModel,
    setSortModel: (sortModel: SortModel) =>
      setSortModel({
        [getQueryParam(prefix, 'sort')]: sortModel.sort,
        [getQueryParam(prefix, 'sortDirection')]: sortModel.sortDirection,
      }),
    page,
    setPage,
    pageSize,
    setPageSize,
  };
};

export default usePagination;
