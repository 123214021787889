import type { Booking } from '@prisma/client';
import { iataAirlineCodes } from 'data/iata-airline-codes';
import type Decimal from 'decimal.js';
import { PayingEntity, PaymentMethod } from 'dtos';
import moment from 'moment';
import { ReturnedCouponUsage } from 'utils/server/arc/types';
import { TULSA_TIME, ZERO } from 'utils/shared/constants';
import { ArcTicketType, InterfaceType } from 'utils/shared/types';
import type { getArcBookingsForWhereClause } from './arc.service';
import type {
  ArcReportRow,
  ArcReportRowForSnapshot,
  BosRow,
  SnapshotRecord,
  TicketChange,
} from './types';
export function getSalesTotalFromBosRow(
  row: BosRow,
  bosData: BosRow[],
): Decimal {
  if (row.dateVoided) {
    return ZERO;
  }

  if (
    row.primaryDocumentNumber &&
    row.primaryDocumentNumber !== row.documentNumber &&
    !['MC', 'MX'].includes(row.transactionCode)
  ) {
    return ZERO;
  }

  const ticketAmount = row.ticketDocumentAmount ?? ZERO;

  const cancellationPenaltyAmount = bosData
    .filter(
      (b) =>
        b.primaryDocumentNumber === row.documentNumber &&
        b.returnedCouponUsage !== ReturnedCouponUsage.FULL_REFUND,
    )
    .reduce(
      (acc, row) => acc.plus(row.cancellationPenaltyAmount ?? ZERO),
      ZERO,
    );

  const originalTicketRows = bosData.filter(
    (b) =>
      b.primaryDocumentNumber === row.documentNumber &&
      row.returnedCouponUsage ===
        ReturnedCouponUsage.NEW_DOCUMENT_IN_AN_EXCHANGE &&
      b.returnedCouponUsage === ReturnedCouponUsage.OLD_DOCUMENT_IN_AN_EXCHANGE,
  );

  const originalTicketAmount = originalTicketRows.reduce(
    (acc, row) => acc.plus(row.ticketDocumentAmount ?? ZERO),
    ZERO,
  );

  const total = ticketAmount
    .minus(originalTicketAmount)
    .plus(cancellationPenaltyAmount);

  return total;
}

export function getNetRemitFromBosRow(row: BosRow): Decimal {
  if (row.dateVoided) {
    return ZERO;
  }

  return row.netRemit ?? ZERO;
}

export function getArcDatesForDueDate(dueDate: Date) {
  const dueMoment = moment.utc(dueDate).startOf('day');

  // Ensure the due date is a Friday.
  if (dueMoment.day() !== 5) {
    throw new Error(
      `Invalid due date: Due date must be a Friday. Given: ${dueMoment.format(
        'dddd',
      )}, ${dueMoment.format('YYYY-MM-DD')}`,
    );
  }

  // Calculate the end date as the Sunday before the due date.
  const endDate = dueMoment.clone().subtract(5, 'days').endOf('day');

  // Calculate the start date as seven days before the end date.
  const startDate = endDate.clone().subtract(6, 'days').startOf('day');

  return {
    startDate,
    endDate,
    dueDate: dueMoment, // Keep the due date as is, assuming it's valid and passed the check.
  };
}
export function getFormattedPassengerName(
  firstName?: string,
  lastName?: string,
): string {
  const first = firstName ?? '';
  const last = lastName ?? '';
  return first ? `${last}, ${first}` : last;
}

export function searchIataAirlineByNameForCode(
  startsWith: string,
): string | undefined {
  return iataAirlineCodes.find((airline) =>
    airline.name.toLowerCase().startsWith(startsWith.toLowerCase()),
  )?.alpha;
}

export function getPaymentMethod(paymentMethod?: string): string {
  if (!paymentMethod) {
    return '';
  }
  if (paymentMethod === PaymentMethod.CREDIT_CARD) {
    return 'CC';
  }

  return paymentMethod;
}

export function groupByTicketNo(
  rows: ArcReportRowForSnapshot[],
): SnapshotRecord {
  return rows.reduce(
    (acc, row) => {
      if (!acc[row.ticket_no]) {
        acc[row.ticket_no] = [];
      }
      acc[row.ticket_no].push(mapToSnapshotRow(row));
      return acc;
    },
    {} as Record<string, ArcReportRowForSnapshot[]>,
  );
}

export function diffRecords(
  record1: SnapshotRecord,
  record2: SnapshotRecord,
): { ticket_no: string; changes: TicketChange[] }[] {
  const changes: { ticket_no: string; changes: TicketChange[] }[] = [];

  const allKeys = new Set([...Object.keys(record1), ...Object.keys(record2)]);

  for (const key of allKeys) {
    const rows1 = (record1[key] || []).map(mapToSnapshotRow);
    const rows2 = (record2[key] || []).map(mapToSnapshotRow);

    // use JSON.stringify to do a quick compare of the arrays
    if (JSON.stringify(rows1) !== JSON.stringify(rows2)) {
      const changesForKey: TicketChange[] = [];

      // Find differences
      const maxLength = Math.max(rows1.length, rows2.length);
      for (let i = 0; i < maxLength; i++) {
        const row1 = rows1[i];
        const row2 = rows2[i];

        if (row1 && row2) {
          const allKeys = Object.keys(row1);
          const rowChanges: string[] = [];
          for (const prop of allKeys) {
            if (
              row1[prop as keyof ArcReportRowForSnapshot] !==
              row2[prop as keyof ArcReportRowForSnapshot]
            ) {
              rowChanges.push(
                `Changed ${prop} from ${
                  row1[prop as keyof ArcReportRowForSnapshot]
                } to ${row2[prop as keyof ArcReportRowForSnapshot]}`,
              );
            }
          }
          changesForKey.push({
            changeType: 'changed',
            changes: rowChanges.join('. '),
          } satisfies TicketChange);
        } else if (row1 && !row2) {
          changesForKey.push({
            changeType: 'removed',
            addedOrRemovedTicket: row1,
          });
        } else if (!row1 && row2) {
          changesForKey.push({
            changeType: 'added',
            addedOrRemovedTicket: row2,
          });
        }
      }

      changes.push({ ticket_no: key, changes: changesForKey });
    }
  }

  return changes;
}

export function mapToSnapshotRow(
  row: ArcReportRow | ArcReportRowForSnapshot,
): ArcReportRowForSnapshot {
  return {
    related_id: row.related_id,
    ticket_no: row.ticket_no,
    pnr: row.pnr,
    issue_date: moment(row.issue_date).toISOString(),
    gross_fare: row.gross_fare,
    base_fare: row.base_fare,
    tax: row.tax,
    commission: row.commission,
    commission_percent: row.commission_percent,
    net_remit: row.net_remit,
    original_document_no: row.original_document_no,
    payment_method: row.payment_method,
    last_four: row.last_four,
    rate: row.rate,
    is_void: row.is_void,
    ticket_type: row.ticket_type,
  };
}

// TODO: we may want to visually present this in the UI at some point. If not, remove
export function generateHtmlDiff(
  changes: { ticket_no: string; changes: string[] }[],
): string {
  let html = '<html><body><h1>Differences</h1><ul>';

  for (const change of changes) {
    html += `<li><h2>Ticket No: ${change.ticket_no}</h2><ul>`;
    for (const diff of change.changes) {
      html += `<li>${diff}</li>`;
    }
    html += '</ul></li>';
  }

  html += '</ul></body></html>';
  return html;
}

/**** Helpers below ****/
//RF = refund, MC = service fee, ET = straight sale, EX = exchange, MX = fare difference
//CM = credit memo, DM = debit memo,
//CN = not known, maybe cancelled

export function getTicketTypeCode(ticketType: ArcTicketType): string {
  switch (ticketType) {
    case ArcTicketType.TICKET:
      return 'ET';
    case ArcTicketType.EXCHANGE:
      return 'EX';
    case ArcTicketType.FARE_DIFFERENCE:
      return 'MX';
    case ArcTicketType.BOOKING_REFUND:
    case ArcTicketType.FEE_REFUND:
      return 'RF';
    case ArcTicketType.FEE:
      return 'MC';
    case ArcTicketType.CREDIT_MEMO:
      return 'CM';
    case ArcTicketType.DEBIT_MEMO:
      return 'DM';
    default:
      return 'UN';
  }
}

// Sabre uses Tulsa time, aka America/Chicago
export function convertDateToCentralTimezone(date: Date): string {
  // Convert the date to America/Chicago timezone
  const chicagoDate = moment(date).tz(TULSA_TIME);

  // Format the date as a string to send to the UI (e.g., YYYY-MM-DD HH:mm:ss)
  const formattedDate = chicagoDate.format('YYYY-MM-DD HH:mm:ss');

  return formattedDate;
}

export function ticketTypeIsBookingEntity(ticket_type: string): boolean {
  switch (ticket_type) {
    case ArcTicketType.TICKET:
    case ArcTicketType.EXCHANGE:
    case ArcTicketType.FARE_DIFFERENCE:
    case ArcTicketType.DEBIT_MEMO:
    case ArcTicketType.CREDIT_MEMO:
      return true;
    default:
      return false;
  }
}
export function calculateBookingNetRemit(
  booking:
    | Awaited<ReturnType<typeof getArcBookingsForWhereClause>>[0]
    | Booking,
  commission: Decimal,
) {
  if (booking.netRemit) {
    return booking.netRemit;
  }

  if (booking.bookingType === InterfaceType.FARE_DIFFERENCE) {
    return ZERO;
  }

  // always treat exchanges as CREDIT_CARD FOP
  if (
    booking.bookingType === InterfaceType.EXCHANGE ||
    (!booking.bookingType && booking.originalBookingId)
  ) {
    return commission;
  }

  if (booking.payingEntity === PayingEntity.AGENCY) {
    return (booking.baseEstCommissionHome ?? ZERO).minus(
      booking.baseTotalHome ?? ZERO,
    );
  }

  return commission;
}

const bosFilenameRegex = /(\d+)\.(\d+)$/;
type BosFilename = {
  createdDateTime: Date;
  counter: number;
};
export function parseBosFilename(filename: string): BosFilename | undefined {
  const match = filename.match(bosFilenameRegex);
  if (!match) {
    return undefined;
  }

  const [, createdDateTime, counter] = match;
  const date = moment(createdDateTime, 'MMDDhhmm');
  if (!date.isValid()) {
    return undefined;
  }

  return {
    createdDateTime: date.toDate(),
    counter: Number.parseInt(counter),
  };
}
