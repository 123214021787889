export function toTitleCase(input: string): string {
  return input.replace(
    /(\w)(\w*)/g,
    (_, firstLetter: string, remainingLetters: string) => {
      return firstLetter.toUpperCase() + remainingLetters.toLowerCase();
    },
  );
}

export function truncate(
  input: string,
  length: number,
  truncationSuffix = '…',
): string {
  if (input.length <= length) {
    return input;
  }

  // Ensure the truncation suffix is not longer than the specified length
  const safeSuffix = truncationSuffix.length > length ? '' : truncationSuffix;
  return input.substring(0, length - safeSuffix.length) + safeSuffix;
}

export function parseConnectionString(connectionString: string): {
  host: string;
  port: string;
  user: string;
  password: string;
  database: string;
} {
  // Removing 'postgres://' or any other protocol to ensure compatibility
  const withoutProtocol = connectionString.substring(
    connectionString.indexOf('://') + 3,
  );
  const lastAtSignIndex = withoutProtocol.lastIndexOf('@');

  // Extract the user and password part, which is before the last '@'
  const userAndPassword = withoutProtocol.substring(0, lastAtSignIndex);
  // Extract the host, port, and database part, which is after the last '@'
  const hostPortAndDatabase = withoutProtocol.substring(lastAtSignIndex + 1);

  // Split the user from the password
  const firstColonIndex = userAndPassword.indexOf(':');
  const user = userAndPassword.substring(0, firstColonIndex);
  const password = userAndPassword.substring(firstColonIndex + 1);

  // Split the host and port from the database
  const [hostPort, database] = hostPortAndDatabase.split('/');
  const [host, port] = hostPort.split(':');

  return { host, port, user, password, database };
}

export function extractPlainTextFromRTF(rtfStr: string): string {
  if (!rtfStr.includes('\\rtf')) {
    return rtfStr;
  }

  // Match both text and empty lines (\\plain followed directly by \\par)
  const pattern = /\\plain[^\s]*\s([^\r\n\\]*?)(?=\\par|\\)/g;
  const matches = [...rtfStr.matchAll(pattern)];

  // Transform matches into text lines or newlines for empty matches
  const lines = matches.map((m) => {
    const text = m[1].trim();
    return text === '' ? '\n' : text;
  });

  return lines.join('\n').replace(/\n{3,}/g, '\n\n');
}

export function leftPad(
  str: string,
  targetLength: number,
  padChar = ' ',
): string {
  return str.length >= targetLength
    ? str
    : padChar.repeat(targetLength - str.length) + str;
}

export function parseFromString<T extends Record<string, RegExp>>(
  config: T,
  notesStr: string,
): Record<keyof T, string | undefined> {
  const parsed: Record<keyof T, string | undefined> = {} as Record<
    keyof T,
    string | undefined
  >;

  for (const [key, regex] of Object.entries(config)) {
    const match = notesStr.match(regex);
    parsed[key as keyof T] = match ? match[1] : undefined;
  }
  return parsed;
}

export function isUUID(uuid: string): boolean {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}

export function toASCII(str: string): string {
  // Normalize the string to NFD (Canonical Decomposition)
  const normalized = str.normalize('NFD');

  // Remove diacritical marks
  const withoutDiacritics = normalized.replace(/[\u0300-\u036f]/g, '');

  // Remove any remaining non-ASCII characters
  const asciiOnly = withoutDiacritics.replace(/[^\x00-\x7F]/g, '');

  return asciiOnly;
}

export function supplierNameAlreadyHasOrgName({
  supplierName,
  orgName,
}: { supplierName: string; orgName: string }): boolean {
  return supplierName.toLowerCase().includes(orgName.toLowerCase());
}

export const pluralize = (value: string, count: number, pluralForm = '') =>
  count !== 1
    ? pluralForm ||
      (value.endsWith('y') ? `${value.slice(0, -1)}ies` : `${value}s`)
    : value;

export function getDomainFromEmail(email: string): string | null {
  const parts = email.split('@');
  return parts.length === 2 ? parts[1] : null;
}

const domainExceptTLDRegex = /(?<=@)([a-z0-9-.]+)(?=\.[a-z]{2,})/i;
export function getDomainExceptTLDFromEmail(email: string): string | null {
  const match = email.match(domainExceptTLDRegex);
  return match?.[0] ?? null;
}

export function parseLocaleNumber(
  numStr: string,
  locale = 'en-US',
): number | null {
  // Get the number format for the specified locale
  const formatter = new Intl.NumberFormat(locale);

  // Format a known number to get the grouping, decimal symbols
  const parts = formatter.formatToParts(12345.6);
  const groupSymbol = parts.find((part) => part.type === 'group')?.value || ',';
  const decimalSymbol =
    parts.find((part) => part.type === 'decimal')?.value || '.';

  // Create a regex to replace grouping symbols and set decimal point
  const cleanedStr = numStr
    .replace(new RegExp(`\\${groupSymbol}`, 'g'), '') // Remove grouping
    .replace(new RegExp(`\\${decimalSymbol}`), '.'); // Standardize decimal

  const parsedNumber = Number.parseFloat(cleanedStr);
  return Number.isNaN(parsedNumber) ? null : parsedNumber;
}

export function capitalize(word: string): string {
  if (!word) return word; // Handle empty strings
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}
