export const iataAirportCountryMap: { [key: string]: string } = {
  UTK: 'MH',
  OCA: 'US',
  PQS: 'US',
  CSE: 'US',
  JCY: 'US',
  WLR: 'US',
  NUP: 'US',
  PTC: 'US',
  ICY: 'US',
  PPV: 'US',
  KKK: 'US',
  MHS: 'US',
  NIR: 'US',
  GCT: 'US',
  ELW: 'US',
  LVD: 'US',
  HGZ: 'US',
  OTN: 'US',
  TLF: 'US',
  BZT: 'US',
  HBH: 'US',
  FAK: 'US',
  BYW: 'US',
  DRF: 'US',
  BDF: 'US',
  VRS: 'US',
  GDH: 'US',
  ATT: 'US',
  LIV: 'US',
  PDB: 'US',
  KOZ: 'US',
  TNK: 'US',
  WHD: 'US',
  MNT: 'US',
  TKI: 'US',
  WKK: 'US',
  WFB: 'US',
  NNK: 'US',
  BKF: 'US',
  BCS: 'US',
  SPQ: 'US',
  TSS: 'US',
  NYS: 'US',
  BWL: 'US',
  WYB: 'US',
  CWS: 'US',
  TEK: 'US',
  DUF: 'US',
  SSW: 'US',
  FOB: 'US',
  PUL: 'US',
  CCD: 'US',
  WMK: 'US',
  AXB: 'US',
  REE: 'US',
  WDN: 'US',
  WWP: 'US',
  CHU: 'US',
  UGS: 'US',
  PSQ: 'US',
  KLL: 'US',
  SGW: 'US',
  WTL: 'US',
  TWA: 'US',
  KCQ: 'US',
  AAD: 'SO',
  ABP: 'PG',
  AFC: 'PH',
  ACM: 'CO',
  ADC: 'PG',
  ADV: 'SD',
  DJH: 'AE',
  AEE: 'SS',
  AEI: 'ES',
  AEK: 'PG',
  AEQ: 'CN',
  KHT: 'AF',
  URZ: 'AF',
  OLR: 'AF',
  AFK: 'LK',
  AFR: 'PG',
  AFT: 'SB',
  RNA: 'SB',
  ATD: 'SB',
  VEV: 'SB',
  GEF: 'SB',
  AGG: 'PG',
  AKS: 'SB',
  BNY: 'SB',
  CHY: 'SB',
  BAS: 'SB',
  FRE: 'SB',
  HIR: 'SB',
  MBU: 'SB',
  AVU: 'SB',
  IRA: 'SB',
  SCZ: 'SB',
  MUA: 'SB',
  GZO: 'SB',
  MNY: 'SB',
  PRS: 'SB',
  RNL: 'SB',
  EGM: 'SB',
  NNB: 'SB',
  RUS: 'SB',
  VAO: 'SB',
  XYA: 'SB',
  AGK: 'PG',
  KGE: 'SB',
  KUE: 'SB',
  KWS: 'SB',
  AGL: 'PG',
  NAZ: 'SB',
  MHM: 'SB',
  GTA: 'SB',
  RIN: 'SB',
  RBV: 'SB',
  TLG: 'SB',
  AGY: 'AU',
  AHJ: 'CN',
  AHY: 'MG',
  AIE: 'PG',
  AIH: 'PG',
  AIC: 'MH',
  CEX: 'US',
  SOL: 'US',
  HED: 'US',
  TWE: 'US',
  KTH: 'US',
  NKI: 'US',
  LNI: 'US',
  CDL: 'US',
  AOS: 'US',
  BSW: 'US',
  AKM: 'TD',
  ALZ: 'US',
  PPE: 'MX',
  AME: 'MZ',
  AMF: 'PG',
  AMU: 'PG',
  AMY: 'MG',
  INU: 'NR',
  ANZ: 'AU',
  CTV: 'AO',
  ANL: 'AO',
  CNZ: 'AO',
  DRC: 'AO',
  KNP: 'AO',
  NDF: 'AO',
  AOB: 'PG',
  AOD: 'TD',
  APP: 'PG',
  APR: 'PG',
  QET: 'AQ',
  AQY: 'US',
  QRF: 'AR',
  CVI: 'AR',
  CNT: 'AR',
  VGS: 'AR',
  LMD: 'AR',
  VCF: 'AR',
  NCJ: 'AR',
  CPG: 'AR',
  PRQ: 'AR',
  OLN: 'AR',
  ARP: 'PG',
  ASZ: 'PG',
  ATN: 'PG',
  ATP: 'PG',
  LYT: 'AU',
  GYZ: 'AU',
  KQR: 'AU',
  AGW: 'AU',
  AYD: 'AU',
  BYX: 'AU',
  COB: 'AU',
  CRJ: 'AU',
  CRY: 'AU',
  CSD: 'AU',
  DYM: 'AU',
  HIS: 'AU',
  HLV: 'AU',
  KBD: 'AU',
  KGR: 'AU',
  MYO: 'AU',
  OKB: 'AU',
  PEP: 'AU',
  RDA: 'AU',
  SSK: 'AU',
  SWB: 'AU',
  TPR: 'AU',
  TWP: 'AU',
  ZVG: 'AU',
  AUI: 'PG',
  AUJ: 'PG',
  AUP: 'PG',
  AUV: 'PG',
  AWE: 'GA',
  AXF: 'CN',
  AXO: 'ID',
  KPM: 'PG',
  BUA: 'PG',
  BRP: 'PG',
  CMU: 'PG',
  MDM: 'PG',
  KPF: 'PG',
  DNU: 'PG',
  DOI: 'PG',
  DAU: 'PG',
  EMS: 'PG',
  XYR: 'PG',
  EPT: 'PG',
  EGA: 'PG',
  EMO: 'PG',
  ERU: 'PG',
  MFZ: 'PG',
  FRQ: 'PG',
  FAQ: 'PG',
  FUM: 'PG',
  GKA: 'PG',
  GUG: 'PG',
  GRL: 'PG',
  GUR: 'PG',
  GAP: 'PG',
  PNP: 'PG',
  GBC: 'PG',
  HBD: 'PG',
  HNI: 'PG',
  HNN: 'PG',
  HKN: 'PG',
  HIT: 'PG',
  IMN: 'PG',
  KGM: 'PG',
  IMD: 'PG',
  KIE: 'PG',
  IAL: 'PG',
  WIU: 'PG',
  KGH: 'PG',
  LSA: 'PG',
  KPA: 'PG',
  UNG: 'PG',
  KNE: 'PG',
  KRI: 'PG',
  KMA: 'PG',
  KRX: 'PG',
  KUQ: 'PG',
  KVG: 'PG',
  LNV: 'PG',
  LAB: 'PG',
  LWI: 'PG',
  LPN: 'PG',
  LNG: 'PG',
  LSJ: 'PG',
  AYM: 'AE',
  MRM: 'PG',
  OBM: 'PG',
  MAG: 'PG',
  HGU: 'PG',
  GUV: 'PG',
  MDU: 'PG',
  MAS: 'PG',
  MXH: 'PG',
  MIS: 'PG',
  MWG: 'PG',
  NKN: 'PG',
  GBF: 'PG',
  MFO: 'PG',
  KSB: 'PG',
  NMN: 'PG',
  NBA: 'PG',
  LAE: 'PG',
  KGB: 'PG',
  OKP: 'PG',
  HOC: 'PG',
  KCJ: 'PG',
  KDE: 'PG',
  OPB: 'PG',
  PGB: 'PG',
  PGN: 'PG',
  MPF: 'PG',
  PMN: 'PG',
  POM: 'PG',
  SPH: 'PG',
  SXA: 'PG',
  RMN: 'PG',
  KMR: 'PG',
  MWI: 'PG',
  MRH: 'PG',
  SBE: 'PG',
  NIS: 'PG',
  SIL: 'PG',
  SBV: 'PG',
  SIM: 'PG',
  SBC: 'PG',
  SPV: 'PG',
  SXW: 'PG',
  MBV: 'PG',
  TIZ: 'PG',
  TBG: 'PG',
  TPI: 'PG',
  RAB: 'PG',
  TKW: 'PG',
  TEP: 'PG',
  TSW: 'PG',
  TRJ: 'PG',
  TWY: 'PG',
  TKB: 'PG',
  AYU: 'PG',
  UMC: 'PG',
  URU: 'PG',
  UPR: 'PG',
  UVO: 'PG',
  TLW: 'PG',
  TCJ: 'PG',
  VAI: 'PG',
  TON: 'PG',
  WAO: 'PG',
  WBM: 'PG',
  WAJ: 'PG',
  WWK: 'PG',
  WOA: 'PG',
  WKN: 'PG',
  WSU: 'PG',
  WTP: 'PG',
  WUG: 'PG',
  YVD: 'PG',
  SMJ: 'PG',
  WEP: 'PG',
  AYY: 'LK',
  KYX: 'PG',
  KSX: 'PG',
  WUM: 'PG',
  ZXT: 'AZ',
  FZL: 'AZ',
  HAE: 'US',
  AZB: 'PG',
  AZJ: 'CN',
  BAJ: 'PG',
  BCP: 'PG',
  BCW: 'MZ',
  BCZ: 'AU',
  ILL: 'US',
  BDZ: 'PG',
  HKV: 'BG',
  JAM: 'BG',
  JEG: 'GL',
  AOQ: 'GL',
  AGM: 'GL',
  QGQ: 'GL',
  UAK: 'GL',
  JCH: 'GL',
  CNP: 'GL',
  QFG: 'GL',
  GOH: 'GL',
  JGO: 'GL',
  IKE: 'GL',
  QFI: 'GL',
  QRY: 'GL',
  XIQ: 'GL',
  IUI: 'GL',
  IOQ: 'GL',
  QJI: 'GL',
  JJU: 'GL',
  JAV: 'GL',
  QPW: 'GL',
  KUS: 'GL',
  KUZ: 'GL',
  KHQ: 'GL',
  KGQ: 'GL',
  QJE: 'GL',
  IOT: 'GL',
  JSU: 'GL',
  JNN: 'GL',
  JUU: 'GL',
  JNS: 'GL',
  NIQ: 'GL',
  NSQ: 'GL',
  JFR: 'GL',
  PQT: 'GL',
  NAQ: 'GL',
  OBY: 'GL',
  SFJ: 'GL',
  SRK: 'GL',
  QOQ: 'GL',
  JHS: 'GL',
  SAE: 'GL',
  SVR: 'GL',
  TQA: 'GL',
  THU: 'GL',
  TQI: 'GL',
  JUV: 'GL',
  UMD: 'GL',
  JQA: 'GL',
  JUK: 'GL',
  BHL: 'MX',
  BHT: 'AU',
  AEY: 'IS',
  BIU: 'IS',
  BGJ: 'IS',
  BJD: 'IS',
  BLO: 'IS',
  BQD: 'IS',
  BXV: 'IS',
  DJU: 'IS',
  EGS: 'IS',
  FAS: 'IS',
  FAG: 'IS',
  GUU: 'IS',
  GJR: 'IS',
  GRY: 'IS',
  HVK: 'IS',
  HFN: 'IS',
  FLI: 'IS',
  HZK: 'IS',
  HVM: 'IS',
  HLO: 'IS',
  IFJ: 'IS',
  KEF: 'IS',
  OPA: 'IS',
  SAK: 'IS',
  NOR: 'IS',
  OFJ: 'IS',
  PFJ: 'IS',
  RHA: 'IS',
  OLI: 'IS',
  RFN: 'IS',
  RKV: 'IS',
  MVA: 'IS',
  SIJ: 'IS',
  SYK: 'IS',
  TEY: 'IS',
  THO: 'IS',
  VEY: 'IS',
  VPN: 'IS',
  BJE: 'SD',
  BJQ: 'OM',
  BJT: 'LK',
  PRN: 'XK',
  BMH: 'PG',
  BMQ: 'KE',
  BMZ: 'PG',
  BNF: 'US',
  BNM: 'PG',
  BNT: 'PG',
  RBQ: 'BO',
  BVL: 'BO',
  BOF: 'US',
  BOK: 'US',
  BOT: 'PG',
  BOV: 'PG',
  BPF: 'SB',
  BQV: 'US',
  CJZ: 'BR',
  VDC: 'BR',
  ALT: 'BR',
  NSB: 'BS',
  BSP: 'PG',
  BSV: 'MG',
  BTZ: 'TH',
  BUL: 'PG',
  BVR: 'CV',
  HUK: 'BW',
  BWJ: 'PG',
  BWP: 'PG',
  BXL: 'FJ',
  BXZ: 'PG',
  TXC: 'BY',
  BYA: 'US',
  BYL: 'LR',
  BYV: 'LK',
  BCV: 'BZ',
  BGK: 'BZ',
  CUK: 'BZ',
  CYC: 'BZ',
  CZH: 'BZ',
  DGA: 'BZ',
  INB: 'BZ',
  MDB: 'BZ',
  ORZ: 'BZ',
  PLJ: 'BZ',
  PND: 'BZ',
  SJX: 'BZ',
  SPR: 'BZ',
  SQS: 'BZ',
  STU: 'BZ',
  SVK: 'BZ',
  TZA: 'BZ',
  BZB: 'MZ',
  BZM: 'MG',
  YSV: 'CA',
  YRR: 'CA',
  YMV: 'CA',
  YHC: 'CA',
  PYS: 'US',
  YBW: 'CA',
  YTP: 'CA',
  ZNA: 'CA',
  YZZ: 'CA',
  YMB: 'CA',
  YHH: 'CA',
  YWS: 'CA',
  YBF: 'CA',
  YIG: 'CA',
  CJH: 'CA',
  YPT: 'CA',
  ZOF: 'CA',
  YCA: 'CA',
  CFQ: 'CA',
  YAA: 'CA',
  DGF: 'CA',
  JHL: 'CA',
  ZTS: 'CA',
  DUQ: 'CA',
  YHS: 'CA',
  YKK: 'CA',
  ZQS: 'CA',
  WPL: 'CA',
  XQU: 'CA',
  YMP: 'CA',
  YRN: 'CA',
  YTG: 'CA',
  YMU: 'CA',
  YSX: 'CA',
  YGG: 'CA',
  YTB: 'CA',
  YZA: 'CA',
  ZEL: 'CA',
  CBC: 'AU',
  YPB: 'CA',
  YBO: 'CA',
  TNS: 'CA',
  TUX: 'CA',
  YWM: 'CA',
  YSO: 'CA',
  YBI: 'CA',
  YHG: 'CA',
  YFX: 'CA',
  YHA: 'CA',
  YRG: 'CA',
  DVK: 'CA',
  JOJ: 'CA',
  ZFW: 'CA',
  YJP: 'CA',
  YLE: 'CA',
  YDC: 'CA',
  NML: 'CA',
  CFM: 'CA',
  ZSP: 'CA',
  KWD: 'CF',
  GSL: 'CA',
  XMP: 'CA',
  DAS: 'CA',
  YFI: 'CA',
  YFJ: 'CA',
  YOE: 'CA',
  TIL: 'CA',
  OKG: 'CG',
  CGA: 'US',
  CGC: 'PG',
  CGG: 'PH',
  GHK: 'CA',
  CHP: 'US',
  CIV: 'US',
  LRQ: 'CA',
  YDJ: 'CA',
  YDU: 'CA',
  YFL: 'CA',
  XCL: 'CA',
  YKE: 'CA',
  SUR: 'CA',
  CKD: 'US',
  YTT: 'CA',
  YAX: 'CA',
  WNN: 'CA',
  YBS: 'CA',
  YNO: 'CA',
  CKR: 'US',
  CKU: 'US',
  YDW: 'CA',
  CKX: 'US',
  WCH: 'CL',
  CMT: 'BR',
  CMZ: 'MZ',
  TVS: 'CN',
  YUA: 'CN',
  ZQZ: 'CN',
  BSD: 'CN',
  DZU: 'CN',
  LNJ: 'CN',
  RKZ: 'CN',
  PZI: 'CN',
  ZKL: 'CN',
  FUO: 'CN',
  BZX: 'CN',
  XAI: 'CN',
  HUZ: 'CN',
  DFA: 'CN',
  HSC: 'CN',
  JGS: 'CN',
  AEB: 'CN',
  LYG: 'CN',
  DOY: 'CN',
  XEN: 'CN',
  AAT: 'CN',
  THQ: 'CN',
  YZY: 'CN',
  DWS: 'CN',
  LCT: 'CN',
  WHN: 'CN',
  NAY: 'CN',
  DDG: 'CN',
  PNJ: 'CN',
  CBZ: 'CN',
  DDR: 'CN',
  HQL: 'CN',
  JDE: 'CN',
  HLJ: 'CN',
  TYC: 'CN',
  ZHA: 'CN',
  ZFL: 'CN',
  YEH: 'CN',
  LFH: 'CN',
  NTG: 'CN',
  XBE: 'CA',
  YNP: 'CA',
  YWR: 'CA',
  YPD: 'CA',
  XBR: 'CA',
  KIF: 'CA',
  YOG: 'CA',
  LCR: 'CO',
  SNT: 'CO',
  TCD: 'CO',
  YEB: 'CA',
  CPI: 'PG',
  YSI: 'CA',
  YHP: 'CA',
  KEW: 'CA',
  XVQ: 'CA',
  KFM: 'CA',
  YSA: 'CA',
  YLS: 'CA',
  YNX: 'CA',
  SSQ: 'CA',
  YKU: 'CA',
  EHT: 'US',
  ZTB: 'CA',
  ZKG: 'CA',
  YWQ: 'CA',
  CTP: 'BR',
  YAU: 'CA',
  TR7: 'CA',
  ZGS: 'CA',
  YFG: 'CA',
  ZLT: 'CA',
  CUJ: 'PH',
  HLI: 'US',
  CVL: 'PG',
  CXC: 'US',
  GEC: 'CY',
  YAB: 'CA',
  YAC: 'CA',
  YAR: 'CA',
  YAG: 'CA',
  YAH: 'CA',
  YAL: 'CA',
  YAM: 'CA',
  XKS: 'CA',
  YKG: 'CA',
  YAT: 'CA',
  YAY: 'CA',
  YAZ: 'CA',
  YBA: 'CA',
  YBB: 'CA',
  YBC: 'CA',
  QBC: 'CA',
  YBE: 'CA',
  YBY: 'CA',
  YBG: 'CA',
  YBK: 'CA',
  YBL: 'CA',
  XTL: 'CA',
  YBR: 'CA',
  YBT: 'CA',
  YBV: 'CA',
  YBX: 'CA',
  YRF: 'CA',
  YCB: 'CA',
  YCC: 'CA',
  YCD: 'CA',
  YCE: 'CA',
  YCG: 'CA',
  YCH: 'CA',
  XCM: 'CA',
  YCL: 'CA',
  YCN: 'CA',
  YCO: 'CA',
  YCQ: 'CA',
  YCR: 'CA',
  YCS: 'CA',
  YCT: 'CA',
  YCW: 'CA',
  YCY: 'CA',
  YCZ: 'CA',
  YDA: 'CA',
  YDB: 'CA',
  YDF: 'CA',
  YDL: 'CA',
  XRR: 'CA',
  YDN: 'CA',
  YDO: 'CA',
  YDP: 'CA',
  YDQ: 'CA',
  YEG: 'CA',
  YEK: 'CA',
  YEL: 'CA',
  YEM: 'CA',
  YEN: 'CA',
  YER: 'CA',
  YET: 'CA',
  YEU: 'CA',
  YEV: 'CA',
  YEY: 'CA',
  YFA: 'CA',
  YFB: 'CA',
  YFC: 'CA',
  YFE: 'CA',
  YFH: 'CA',
  YTM: 'CA',
  YFO: 'CA',
  YFR: 'CA',
  YFS: 'CA',
  YMN: 'CA',
  YGB: 'CA',
  YGH: 'CA',
  YGK: 'CA',
  YGL: 'CA',
  YGM: 'CA',
  YGO: 'CA',
  YGP: 'CA',
  YGQ: 'CA',
  YGR: 'CA',
  YGT: 'CA',
  YGV: 'CA',
  YGW: 'CA',
  YGX: 'CA',
  YGZ: 'CA',
  YQC: 'CA',
  YHB: 'CA',
  CXH: 'CA',
  YHD: 'CA',
  YHE: 'CA',
  YHF: 'CA',
  YNS: 'CA',
  YHI: 'CA',
  YHK: 'CA',
  YHM: 'CA',
  YHN: 'CA',
  YHO: 'CA',
  YHR: 'CA',
  YHT: 'CA',
  YHU: 'CA',
  YHY: 'CA',
  YHZ: 'CA',
  YIB: 'CA',
  YDG: 'CA',
  YIF: 'CA',
  YIK: 'CA',
  YIO: 'CA',
  YIV: 'CA',
  YJA: 'CA',
  YJF: 'CA',
  YJN: 'CA',
  YJT: 'CA',
  YKA: 'CA',
  YKC: 'CA',
  LAK: 'CA',
  YKF: 'CA',
  YWB: 'CA',
  YKJ: 'CA',
  YKL: 'CA',
  YKD: 'CA',
  AKV: 'CA',
  YKQ: 'CA',
  YKX: 'CA',
  YKY: 'CA',
  YKZ: 'CA',
  YPJ: 'CA',
  YLB: 'CA',
  YLC: 'CA',
  YLD: 'CA',
  YLH: 'CA',
  YLJ: 'CA',
  YSG: 'CA',
  YLL: 'CA',
  YLQ: 'CA',
  YLR: 'CA',
  YLK: 'CA',
  YLT: 'CA',
  XGR: 'CA',
  YLW: 'CA',
  CYM: 'US',
  YMA: 'CA',
  YME: 'CA',
  YMG: 'CA',
  YMH: 'CA',
  YMJ: 'CA',
  YML: 'CA',
  YMM: 'CA',
  YMO: 'CA',
  YMT: 'CA',
  YUD: 'CA',
  YMW: 'CA',
  YMX: 'CA',
  YNA: 'CA',
  YNC: 'CA',
  YND: 'CA',
  YNE: 'CA',
  YNH: 'CA',
  YLY: 'CA',
  YNL: 'CA',
  YNM: 'CA',
  HZP: 'CA',
  YOA: 'CA',
  YOC: 'CA',
  YOD: 'CA',
  YOH: 'CA',
  YOJ: 'CA',
  YOO: 'CA',
  YOP: 'CA',
  YOS: 'CA',
  YOW: 'CA',
  YPA: 'CA',
  YPC: 'CA',
  YPS: 'CA',
  YPE: 'CA',
  YPG: 'CA',
  YPH: 'CA',
  YPL: 'CA',
  YPM: 'CA',
  YPN: 'CA',
  YPO: 'CA',
  YPQ: 'CA',
  YPR: 'CA',
  YPW: 'CA',
  YPX: 'CA',
  YPY: 'CA',
  YPZ: 'CA',
  YQA: 'CA',
  YQB: 'CA',
  YQD: 'CA',
  YQF: 'CA',
  YQG: 'CA',
  YQH: 'CA',
  YQI: 'CA',
  YQK: 'CA',
  YQL: 'CA',
  YQM: 'CA',
  YQN: 'CA',
  YQQ: 'CA',
  YQR: 'CA',
  YQS: 'CA',
  YQT: 'CA',
  YQU: 'CA',
  YQV: 'CA',
  YQW: 'CA',
  YQX: 'CA',
  YQY: 'CA',
  YQZ: 'CA',
  YRA: 'CA',
  YRB: 'CA',
  YRI: 'CA',
  YRJ: 'CA',
  YRL: 'CA',
  YRM: 'CA',
  YRO: 'CA',
  YRQ: 'CA',
  YRS: 'CA',
  YRT: 'CA',
  YRV: 'CA',
  YSB: 'CA',
  YSC: 'CA',
  YSD: 'CA',
  YSE: 'CA',
  YSF: 'CA',
  YSH: 'CA',
  YSJ: 'CA',
  YSK: 'CA',
  YSL: 'CA',
  YSM: 'CA',
  YCM: 'CA',
  YSP: 'CA',
  YST: 'CA',
  YSU: 'CA',
  YSY: 'CA',
  YTA: 'CA',
  YTE: 'CA',
  YTF: 'CA',
  YTH: 'CA',
  YTL: 'CA',
  YTQ: 'CA',
  YTR: 'CA',
  YTS: 'CA',
  YTZ: 'CA',
  YUB: 'CA',
  YUL: 'CA',
  YUT: 'CA',
  YUX: 'CA',
  YUY: 'CA',
  YVB: 'CA',
  YVC: 'CA',
  YVG: 'CA',
  YVE: 'CA',
  YCK: 'CA',
  YVM: 'CA',
  YVO: 'CA',
  YVP: 'CA',
  YVQ: 'CA',
  YVR: 'CA',
  YVT: 'CA',
  YVV: 'CA',
  YVZ: 'CA',
  YWA: 'CA',
  YWG: 'CA',
  YWH: 'CA',
  YWJ: 'CA',
  YWK: 'CA',
  YWL: 'CA',
  YWP: 'CA',
  YWY: 'CA',
  YXC: 'CA',
  YXE: 'CA',
  YXH: 'CA',
  YXJ: 'CA',
  YXK: 'CA',
  YXL: 'CA',
  YXN: 'CA',
  YXP: 'CA',
  YXQ: 'CA',
  YXR: 'CA',
  YXS: 'CA',
  YXT: 'CA',
  YXU: 'CA',
  YXX: 'CA',
  YXY: 'CA',
  YXZ: 'CA',
  YYB: 'CA',
  YYC: 'CA',
  YYD: 'CA',
  YYE: 'CA',
  YYF: 'CA',
  YYG: 'CA',
  YYH: 'CA',
  YYJ: 'CA',
  YYL: 'CA',
  YYM: 'CA',
  YYN: 'CA',
  YYQ: 'CA',
  YYR: 'CA',
  YYT: 'CA',
  YYU: 'CA',
  YYW: 'CA',
  YYY: 'CA',
  YYZ: 'CA',
  YZE: 'CA',
  YZF: 'CA',
  YZG: 'CA',
  YZH: 'CA',
  YZP: 'CA',
  YZR: 'CA',
  YZS: 'CA',
  YZT: 'CA',
  YZU: 'CA',
  YZV: 'CA',
  YZW: 'CA',
  YZX: 'CA',
  ZAC: 'CA',
  YSN: 'CA',
  YDT: 'CA',
  ILF: 'CA',
  ZBF: 'CA',
  ZBM: 'CA',
  KES: 'CA',
  ZEM: 'CA',
  ZFA: 'CA',
  ZFD: 'CA',
  XPK: 'CA',
  ZFM: 'CA',
  ZFN: 'CA',
  ZGF: 'CA',
  ZGI: 'CA',
  ZGR: 'CA',
  ZHP: 'CA',
  CZJ: 'PA',
  ZJG: 'CA',
  ZJN: 'CA',
  CZK: 'US',
  ZKE: 'CA',
  YTD: 'CA',
  MSA: 'CA',
  ZMH: 'CA',
  PIW: 'CA',
  ZMT: 'CA',
  CZN: 'US',
  XPP: 'CA',
  CZO: 'US',
  ZPB: 'CA',
  WPC: 'CA',
  ZPO: 'CA',
  ZRJ: 'CA',
  ZSJ: 'CA',
  XSI: 'CA',
  ZST: 'CA',
  ZSW: 'CA',
  YDV: 'CA',
  ZTM: 'CA',
  ZUC: 'CA',
  ZUM: 'CA',
  XLB: 'CA',
  ZWL: 'CA',
  IUA: 'US',
  DJN: 'US',
  MQV: 'DZ',
  QLD: 'DZ',
  BUJ: 'DZ',
  BJA: 'DZ',
  ALG: 'DZ',
  DJG: 'DZ',
  VVZ: 'DZ',
  QSF: 'DZ',
  TMR: 'DZ',
  GJL: 'DZ',
  MZW: 'DZ',
  AAE: 'DZ',
  CZL: 'DZ',
  QMH: 'DZ',
  TEE: 'DZ',
  BLJ: 'DZ',
  DAF: 'PG',
  HRM: 'DZ',
  QDJ: 'DZ',
  DAH: 'YE',
  DAO: 'PG',
  TID: 'DZ',
  TIN: 'DZ',
  CFK: 'DZ',
  TAF: 'DZ',
  TLM: 'DZ',
  ORN: 'DZ',
  CBH: 'DZ',
  BFW: 'DZ',
  MUW: 'DZ',
  EBH: 'DZ',
  INF: 'DZ',
  BMW: 'DZ',
  AZR: 'DZ',
  BSK: 'DZ',
  ELG: 'DZ',
  GHA: 'DZ',
  HME: 'DZ',
  INZ: 'DZ',
  TGR: 'DZ',
  LOO: 'DZ',
  ELU: 'DZ',
  TMX: 'DZ',
  OGX: 'DZ',
  IAM: 'DZ',
  COO: 'BJ',
  DJA: 'BJ',
  KDC: 'BJ',
  NAE: 'BJ',
  PKO: 'BJ',
  SVF: 'BJ',
  DBC: 'CN',
  DBK: 'LK',
  DBP: 'PG',
  DBU: 'LK',
  DCG: 'AE',
  DCK: 'US',
  DDM: 'PG',
  DEQ: 'CN',
  DER: 'PG',
  DEX: 'ID',
  XKY: 'BF',
  OUG: 'BF',
  XDJ: 'BF',
  XLU: 'BF',
  PUP: 'BF',
  XBO: 'BF',
  XBG: 'BF',
  DIP: 'BF',
  DOR: 'BF',
  FNG: 'BF',
  XGG: 'BF',
  XKA: 'BF',
  TMQ: 'BF',
  XPA: 'BF',
  ARL: 'BF',
  XSE: 'BF',
  TEG: 'BF',
  XZA: 'BF',
  OUA: 'BF',
  BNR: 'BF',
  DGU: 'BF',
  XGA: 'BF',
  XNU: 'BF',
  BOY: 'BF',
  TUQ: 'BF',
  XDE: 'BF',
  XAR: 'BF',
  ACC: 'GH',
  TML: 'GH',
  DGM: 'LK',
  KMS: 'GH',
  NYI: 'GH',
  TKD: 'GH',
  DHB: 'US',
  DHG: 'RU',
  DHL: 'YE',
  ABO: 'CI',
  ABJ: 'CI',
  OGO: 'CI',
  BXI: 'CI',
  BYK: 'CI',
  BQO: 'CI',
  BDK: 'CI',
  DIM: 'CI',
  DJO: 'CI',
  GGN: 'CI',
  GGO: 'CI',
  BBV: 'CI',
  HGO: 'CI',
  MJC: 'CI',
  KEO: 'CI',
  OFI: 'CI',
  SEO: 'CI',
  SPY: 'CI',
  ZSS: 'CI',
  TXU: 'CI',
  TOZ: 'CI',
  DIW: 'LK',
  ASK: 'CI',
  DLR: 'RU',
  DKA: 'NG',
  ABV: 'NG',
  QUO: 'NG',
  AKR: 'NG',
  ABB: 'NG',
  BCU: 'NG',
  BNI: 'NG',
  CBQ: 'NG',
  ENU: 'NG',
  IBA: 'NG',
  ILR: 'NG',
  QOW: 'NG',
  JOS: 'NG',
  KAD: 'NG',
  KAN: 'NG',
  MIU: 'NG',
  MDI: 'NG',
  LOS: 'NG',
  MXJ: 'NG',
  PHC: 'NG',
  SKO: 'NG',
  YOL: 'NG',
  ZAR: 'NG',
  DOO: 'PG',
  DPT: 'RU',
  DQA: 'CN',
  MFQ: 'NE',
  NIM: 'NE',
  THZ: 'NE',
  AJY: 'NE',
  RLT: 'NE',
  ZND: 'NE',
  DSG: 'PH',
  TBJ: 'TN',
  MIR: 'TN',
  NBE: 'TN',
  TUN: 'TN',
  GAF: 'TN',
  GAE: 'TN',
  DJE: 'TN',
  EBM: 'TN',
  SFA: 'TN',
  TOE: 'TN',
  DVD: 'MG',
  DWO: 'LK',
  DWR: 'AF',
  LRL: 'TG',
  LFW: 'TG',
  DZH: 'CN',
  EAL: 'MH',
  ANR: 'BE',
  BRU: 'BE',
  CRL: 'BE',
  KJK: 'BE',
  LGG: 'BE',
  EBO: 'MH',
  OST: 'BE',
  MZD: 'EC',
  EDA: 'US',
  AOC: 'DE',
  HDF: 'DE',
  IES: 'DE',
  REB: 'DE',
  CSO: 'DE',
  BBH: 'DE',
  FNB: 'DE',
  GTI: 'DE',
  KOQ: 'DE',
  PEF: 'DE',
  BER: 'DE',
  DRS: 'DE',
  ERF: 'DE',
  FRA: 'DE',
  FMO: 'DE',
  HAM: 'DE',
  CGN: 'DE',
  DUS: 'DE',
  MUC: 'DE',
  NUE: 'DE',
  LEJ: 'DE',
  SCN: 'DE',
  STR: 'DE',
  HAJ: 'DE',
  BRE: 'DE',
  HHN: 'DE',
  MHG: 'DE',
  EIB: 'DE',
  SGE: 'DE',
  IZE: 'DE',
  XFW: 'DE',
  KEL: 'DE',
  LBC: 'DE',
  EUM: 'DE',
  FMM: 'DE',
  AAH: 'DE',
  ESS: 'DE',
  BFE: 'DE',
  MGL: 'DE',
  PAD: 'DE',
  NRN: 'DE',
  DTM: 'DE',
  AGB: 'DE',
  OBF: 'DE',
  RBM: 'DE',
  FDH: 'DE',
  SZW: 'DE',
  BYU: 'DE',
  URD: 'DE',
  GHF: 'DE',
  HOQ: 'DE',
  BBJ: 'DE',
  ZQW: 'DE',
  FKB: 'DE',
  LHA: 'DE',
  BWE: 'DE',
  KSF: 'DE',
  EME: 'DE',
  AGE: 'DE',
  WVN: 'DE',
  JUI: 'DE',
  LGO: 'DE',
  BMK: 'DE',
  NOD: 'DE',
  VAC: 'DE',
  NRD: 'DE',
  BMR: 'DE',
  HEI: 'DE',
  FLF: 'DE',
  HGL: 'DE',
  QHU: 'DE',
  PSH: 'DE',
  GWT: 'DE',
  OHR: 'DE',
  KDL: 'EE',
  URE: 'EE',
  EPU: 'EE',
  EES: 'EG',
  TLL: 'EE',
  TAY: 'EE',
  ENF: 'FI',
  EFG: 'PG',
  KEV: 'FI',
  HEL: 'FI',
  HYV: 'FI',
  KTQ: 'FI',
  IVL: 'FI',
  JOE: 'FI',
  JYV: 'FI',
  KAU: 'FI',
  KEM: 'FI',
  KAJ: 'FI',
  KHJ: 'FI',
  KOK: 'FI',
  KAO: 'FI',
  KTT: 'FI',
  KUO: 'FI',
  LPP: 'FI',
  MHQ: 'FI',
  MIK: 'FI',
  OUL: 'FI',
  POR: 'FI',
  RVN: 'FI',
  SVL: 'FI',
  SJY: 'FI',
  SOT: 'FI',
  TMP: 'FI',
  TKU: 'FI',
  UTI: 'FI',
  VAA: 'FI',
  VRK: 'FI',
  YLI: 'FI',
  AUE: 'EG',
  BFS: 'GB',
  ENK: 'GB',
  BHD: 'GB',
  LDY: 'GB',
  BHX: 'GB',
  CVT: 'GB',
  GLO: 'GB',
  ORM: 'GB',
  NQT: 'GB',
  GBA: 'GB',
  MAN: 'GB',
  UPV: 'GB',
  YEO: 'GB',
  CAL: 'GB',
  EOI: 'GB',
  FIE: 'GB',
  WHS: 'GB',
  COL: 'GB',
  NRL: 'GB',
  OBN: 'GB',
  PPW: 'GB',
  SOY: 'GB',
  NDY: 'GB',
  LWK: 'GB',
  WRY: 'GB',
  CSA: 'GB',
  HAW: 'GB',
  CWL: 'GB',
  SWS: 'GB',
  BRS: 'GB',
  LPL: 'GB',
  LTN: 'GB',
  LEQ: 'GB',
  ISC: 'GB',
  BOH: 'GB',
  SOU: 'GB',
  BBP: 'GB',
  NQY: 'GB',
  QUG: 'GB',
  ACI: 'GG',
  GCI: 'GG',
  JER: 'JE',
  ESH: 'GB',
  BQH: 'GB',
  LGW: 'GB',
  LCY: 'GB',
  FAB: 'GB',
  BBS: 'GB',
  LHR: 'GB',
  SEN: 'GB',
  LYX: 'GB',
  CAX: 'GB',
  BLK: 'GB',
  HUY: 'GB',
  BWF: 'GB',
  LBA: 'GB',
  WRT: 'GB',
  CEG: 'GB',
  IOM: 'IM',
  NCL: 'GB',
  MME: 'GB',
  EMA: 'GB',
  VLY: 'GB',
  KOI: 'GB',
  LSI: 'GB',
  WIC: 'GB',
  ABZ: 'GB',
  INV: 'GB',
  GLA: 'GB',
  EDI: 'GB',
  ILY: 'GB',
  PIK: 'GB',
  BEB: 'GB',
  DND: 'GB',
  SYY: 'GB',
  BRR: 'GB',
  PSL: 'GB',
  TRE: 'GB',
  UNT: 'GB',
  ADX: 'GB',
  LMO: 'GB',
  CBG: 'GB',
  NWI: 'GB',
  STN: 'GB',
  HYC: 'GB',
  EXT: 'GB',
  OXF: 'GB',
  RCS: 'GB',
  BEX: 'GB',
  LKZ: 'GB',
  MHZ: 'GB',
  QUY: 'GB',
  FFD: 'GB',
  BZZ: 'GB',
  ODH: 'GB',
  NHT: 'GB',
  QCY: 'GB',
  BEQ: 'GB',
  SQZ: 'GB',
  WTN: 'GB',
  KNF: 'GB',
  MPN: 'FK',
  AMS: 'NL',
  MST: 'NL',
  EIN: 'NL',
  GRQ: 'NL',
  GLZ: 'NL',
  DHR: 'NL',
  LEY: 'NL',
  LWR: 'NL',
  RTM: 'NL',
  ENS: 'NL',
  EHU: 'CN',
  UDE: 'NL',
  WOE: 'NL',
  BYT: 'IE',
  BLY: 'IE',
  NNR: 'IE',
  ORK: 'IE',
  GWY: 'IE',
  CFN: 'IE',
  DUB: 'IE',
  IOR: 'IE',
  INQ: 'IE',
  KKY: 'IE',
  NOC: 'IE',
  KIR: 'IE',
  LTR: 'IE',
  IIA: 'IE',
  SNN: 'IE',
  SXL: 'IE',
  WAT: 'IE',
  EJN: 'CN',
  EJT: 'MH',
  AAR: 'DK',
  BLL: 'DK',
  CPH: 'DK',
  EBJ: 'DK',
  KRP: 'DK',
  BYR: 'DK',
  MRW: 'DK',
  ODE: 'DK',
  RKE: 'DK',
  RNN: 'DK',
  SGD: 'DK',
  CNL: 'DK',
  SKS: 'DK',
  SQW: 'DK',
  TED: 'DK',
  FAE: 'FO',
  STA: 'DK',
  AAL: 'DK',
  LUX: 'LU',
  EMR: 'GT',
  AES: 'NO',
  ANX: 'NO',
  ALF: 'NO',
  FDE: 'NO',
  BNN: 'NO',
  BOO: 'NO',
  BGO: 'NO',
  BJF: 'NO',
  BVG: 'NO',
  KRS: 'NO',
  BDU: 'NO',
  EVE: 'NO',
  VDB: 'NO',
  FRO: 'NO',
  OSL: 'NO',
  HMR: 'NO',
  HAU: 'NO',
  HFT: 'NO',
  HAA: 'NO',
  HVG: 'NO',
  KSU: 'NO',
  GLL: 'NO',
  KKN: 'NO',
  LKN: 'NO',
  MEH: 'NO',
  MOL: 'NO',
  MJF: 'NO',
  LKL: 'NO',
  OSY: 'NO',
  NTB: 'NO',
  OLA: 'NO',
  HOV: 'NO',
  MQN: 'NO',
  RVK: 'NO',
  RRS: 'NO',
  RET: 'NO',
  RYG: 'NO',
  LYR: 'NO',
  SDN: 'NO',
  SOG: 'NO',
  SVJ: 'NO',
  SKN: 'NO',
  SKE: 'NO',
  SRP: 'NO',
  SOJ: 'NO',
  VAW: 'NO',
  SSJ: 'NO',
  TOS: 'NO',
  TRF: 'NO',
  TRD: 'NO',
  VDS: 'NO',
  ENY: 'CN',
  SVG: 'NO',
  BZG: 'PL',
  CZW: 'PL',
  GDN: 'PL',
  KRK: 'PL',
  KTW: 'PL',
  LUZ: 'PL',
  LCJ: 'PL',
  WMI: 'PL',
  POZ: 'PL',
  RDO: 'PL',
  RZE: 'PL',
  SZZ: 'PL',
  SZY: 'PL',
  WAW: 'PL',
  WRO: 'PL',
  IEG: 'PL',
  ERT: 'MN',
  RNB: 'SE',
  GOT: 'SE',
  JKG: 'SE',
  LDK: 'SE',
  GSE: 'SE',
  KVB: 'SE',
  THN: 'SE',
  KSK: 'SE',
  MXX: 'SE',
  NYO: 'SE',
  SCR: 'SE',
  KID: 'SE',
  OSK: 'SE',
  KLR: 'SE',
  MMX: 'SE',
  HAD: 'SE',
  VXO: 'SE',
  EVG: 'SE',
  GEV: 'SE',
  KRF: 'SE',
  LYC: 'SE',
  SDL: 'SE',
  OER: 'SE',
  KRN: 'SE',
  SFT: 'SE',
  UME: 'SE',
  VHM: 'SE',
  AJR: 'SE',
  SOO: 'SE',
  OSD: 'SE',
  ORB: 'SE',
  HFS: 'SE',
  KSD: 'SE',
  VST: 'SE',
  LLA: 'SE',
  ARN: 'SE',
  BMA: 'SE',
  BLE: 'SE',
  HLF: 'SE',
  GVX: 'SE',
  LPI: 'SE',
  NRK: 'SE',
  TYF: 'SE',
  EKT: 'SE',
  VBY: 'SE',
  VVK: 'SE',
  AGH: 'SE',
  SQO: 'SE',
  IDB: 'SE',
  PJA: 'SE',
  HMV: 'SE',
  DSE: 'ET',
  GLC: 'ET',
  SHC: 'ET',
  SPM: 'DE',
  RMS: 'DE',
  FRZ: 'DE',
  ILH: 'DE',
  KZG: 'DE',
  FCN: 'DE',
  GKE: 'DE',
  RLG: 'DE',
  WBG: 'DE',
  WIE: 'DE',
  IGS: 'DE',
  DGP: 'LV',
  LPX: 'LV',
  RIX: 'LV',
  VNT: 'LV',
  EXI: 'US',
  KUN: 'LT',
  PLQ: 'LT',
  PNV: 'LT',
  SQQ: 'LT',
  VNO: 'LT',
  RGR: 'US',
  ALJ: 'ZA',
  AGZ: 'ZA',
  ADY: 'ZA',
  BIY: 'ZA',
  BFN: 'ZA',
  UTE: 'ZA',
  ASS: 'ZA',
  CDO: 'ZA',
  CPT: 'ZA',
  DUK: 'ZA',
  PZL: 'ZA',
  ELS: 'ZA',
  EMG: 'ZA',
  ELL: 'ZA',
  FCB: 'ZA',
  GCJ: 'ZA',
  GRJ: 'ZA',
  GIY: 'ZA',
  QRA: 'ZA',
  HLW: 'ZA',
  HRS: 'ZA',
  HDS: 'ZA',
  KXE: 'ZA',
  KIM: 'ZA',
  MQP: 'ZA',
  KOF: 'ZA',
  KMH: 'ZA',
  KLZ: 'ZA',
  HLA: 'ZA',
  LMR: 'ZA',
  LDZ: 'ZA',
  DUR: 'ZA',
  LUJ: 'ZA',
  LCD: 'ZA',
  SDB: 'ZA',
  LAY: 'ZA',
  AAM: 'ZA',
  MGH: 'ZA',
  MEZ: 'ZA',
  MBD: 'ZA',
  LLE: 'ZA',
  MZY: 'ZA',
  MZQ: 'ZA',
  NCS: 'ZA',
  NGL: 'ZA',
  NLP: 'ZA',
  OVG: 'ZA',
  OUH: 'ZA',
  JNB: 'ZA',
  AFD: 'ZA',
  PLZ: 'ZA',
  PBZ: 'ZA',
  PHW: 'ZA',
  JOH: 'ZA',
  PRK: 'ZA',
  PZB: 'ZA',
  NTY: 'ZA',
  PTG: 'ZA',
  PCF: 'ZA',
  UTW: 'ZA',
  RCB: 'ZA',
  RVO: 'ZA',
  ROD: 'ZA',
  SBU: 'ZA',
  ZEC: 'ZA',
  GSS: 'ZA',
  SIS: 'ZA',
  SZK: 'ZA',
  THY: 'ZA',
  TCU: 'ZA',
  LTA: 'ZA',
  ULD: 'ZA',
  UTN: 'ZA',
  UTT: 'ZA',
  VRU: 'ZA',
  VIR: 'ZA',
  VRE: 'ZA',
  VYD: 'ZA',
  PRY: 'ZA',
  WKF: 'ZA',
  FRW: 'BW',
  GNZ: 'BW',
  JWA: 'BW',
  BBK: 'BW',
  KHW: 'BW',
  LOQ: 'BW',
  MUB: 'BW',
  ORP: 'BW',
  QPH: 'BW',
  GBE: 'BW',
  SXN: 'BW',
  PKW: 'BW',
  SVT: 'BW',
  SWX: 'BW',
  TLD: 'BW',
  TBY: 'BW',
  BZV: 'CG',
  DJM: 'CG',
  KNJ: 'CG',
  LCO: 'CG',
  MUY: 'CG',
  SIB: 'CG',
  NKY: 'CG',
  ANJ: 'CG',
  MSX: 'CG',
  BOE: 'CG',
  OLL: 'CG',
  EWO: 'CG',
  GMM: 'CG',
  ION: 'CG',
  KEE: 'CG',
  MKJ: 'CG',
  FTX: 'CG',
  SOE: 'CG',
  BTB: 'CG',
  OUE: 'CG',
  KMK: 'CG',
  DIS: 'CG',
  PNR: 'CG',
  MTS: 'SZ',
  SHO: 'SZ',
  FEA: 'GB',
  CRF: 'CF',
  BGF: 'CF',
  BGU: 'CF',
  IRO: 'CF',
  BBY: 'CF',
  NDL: 'CF',
  BOP: 'CF',
  BIV: 'CF',
  BSN: 'CF',
  BBT: 'CF',
  ODA: 'CF',
  AIG: 'CF',
  IMO: 'CF',
  MKI: 'CF',
  BTG: 'CF',
  GDI: 'CF',
  BMF: 'CF',
  ODJ: 'CF',
  RFA: 'CF',
  BCF: 'CF',
  BOZ: 'CF',
  NBN: 'GQ',
  BSG: 'GQ',
  GEM: 'GQ',
  SSG: 'GQ',
  ASI: 'SH',
  HLE: 'SH',
  MRU: 'MU',
  RRG: 'MU',
  FIN: 'PG',
  KVU: 'FJ',
  NKW: 'IO',
  KBI: 'CM',
  TKC: 'CM',
  DLA: 'CM',
  MMF: 'CM',
  BLC: 'CM',
  KLE: 'CM',
  OUR: 'CM',
  GXX: 'CM',
  MVR: 'CM',
  FOM: 'CM',
  NGE: 'CM',
  BTA: 'CM',
  GOU: 'CM',
  DSC: 'CM',
  BFX: 'CM',
  BPC: 'CM',
  EBW: 'CM',
  YAO: 'CM',
  NSI: 'CM',
  MMQ: 'ZM',
  CIP: 'ZM',
  JEK: 'ZM',
  CGJ: 'ZM',
  KLB: 'ZM',
  KMZ: 'ZM',
  KAA: 'ZM',
  ZKB: 'ZM',
  LVI: 'ZM',
  LXU: 'ZM',
  LUN: 'ZM',
  MNS: 'ZM',
  MFU: 'ZM',
  MNR: 'ZM',
  ZGM: 'ZM',
  NLA: 'ZM',
  RYL: 'ZM',
  SXG: 'ZM',
  KIW: 'ZM',
  SJQ: 'ZM',
  SLI: 'ZM',
  FLT: 'US',
  BBZ: 'ZM',
  ULI: 'FM',
  HAH: 'KM',
  NWA: 'KM',
  AJN: 'KM',
  DZA: 'YT',
  RUN: 'RE',
  ZSE: 'RE',
  WML: 'MG',
  ATJ: 'MG',
  WAQ: 'MG',
  VVB: 'MG',
  TNR: 'MG',
  JVA: 'MG',
  BMD: 'MG',
  ZVA: 'MG',
  MXT: 'MG',
  ILK: 'MG',
  TVA: 'MG',
  SMS: 'MG',
  TMM: 'MG',
  WTA: 'MG',
  MOQ: 'MG',
  WTS: 'MG',
  VAT: 'MG',
  WAM: 'MG',
  DIE: 'MG',
  WBE: 'MG',
  WMR: 'MG',
  ZWA: 'MG',
  AMB: 'MG',
  WBD: 'MG',
  WPB: 'MG',
  ANM: 'MG',
  HVA: 'MG',
  MJN: 'MG',
  NOS: 'MG',
  DWB: 'MG',
  WMP: 'MG',
  BPY: 'MG',
  WMN: 'MG',
  SVB: 'MG',
  TTS: 'MG',
  VOH: 'MG',
  WAI: 'MG',
  WMA: 'MG',
  IVA: 'MG',
  WBO: 'MG',
  WMD: 'MG',
  FTU: 'MG',
  WFI: 'MG',
  RVA: 'MG',
  IHO: 'MG',
  MJA: 'MG',
  WVK: 'MG',
  OVA: 'MG',
  MNJ: 'MG',
  TDV: 'MG',
  MXM: 'MG',
  TLE: 'MG',
  VND: 'MG',
  BKU: 'MG',
  AMP: 'MG',
  WAK: 'MG',
  AZZ: 'AO',
  SSY: 'AO',
  BUG: 'AO',
  GGC: 'AO',
  CAB: 'AO',
  CFF: 'AO',
  PGI: 'AO',
  CBT: 'AO',
  CTI: 'AO',
  CXM: 'AO',
  CAV: 'AO',
  FND: 'MV',
  DUE: 'AO',
  FNE: 'PG',
  VPE: 'AO',
  NOV: 'AO',
  SVP: 'AO',
  LLT: 'AO',
  LBZ: 'AO',
  LAD: 'AO',
  LZM: 'AO',
  MEG: 'AO',
  SPP: 'AO',
  MSZ: 'AO',
  GXG: 'AO',
  PBN: 'AO',
  VHC: 'AO',
  SZA: 'AO',
  NDD: 'AO',
  UAL: 'AO',
  SDD: 'AO',
  LUO: 'AO',
  UGO: 'AO',
  CEO: 'AO',
  XGN: 'AO',
  ARZ: 'AO',
  NZA: 'AO',
  BGB: 'GA',
  KDN: 'GA',
  FOU: 'GA',
  MBC: 'GA',
  MGX: 'GA',
  KDJ: 'GA',
  KOU: 'GA',
  MJL: 'GA',
  OYE: 'GA',
  OKN: 'GA',
  LBQ: 'GA',
  MVX: 'GA',
  BMM: 'GA',
  MFF: 'GA',
  MKB: 'GA',
  POG: 'GA',
  OMB: 'GA',
  IGE: 'GA',
  MKU: 'GA',
  LBV: 'GA',
  MZC: 'GA',
  MVB: 'GA',
  LTL: 'GA',
  TCH: 'GA',
  MYB: 'GA',
  FOY: 'LR',
  PCP: 'ST',
  TMS: 'ST',
  ANO: 'MZ',
  BEW: 'MZ',
  FXO: 'MZ',
  VPY: 'MZ',
  IHC: 'MZ',
  INH: 'MZ',
  VXC: 'MZ',
  LFB: 'MZ',
  MPM: 'MZ',
  MUD: 'MZ',
  MZB: 'MZ',
  MNC: 'MZ',
  APL: 'MZ',
  POL: 'MZ',
  PDD: 'MZ',
  UEL: 'MZ',
  TET: 'MZ',
  VNX: 'MZ',
  VJB: 'MZ',
  DES: 'SC',
  SEZ: 'SC',
  FSL: 'AU',
  PRI: 'SC',
  BDI: 'SC',
  DEI: 'SC',
  FRK: 'SC',
  SRH: 'TD',
  OGR: 'TD',
  AEH: 'TD',
  MQQ: 'TD',
  LTC: 'TD',
  ATV: 'TD',
  NDJ: 'TD',
  BKR: 'TD',
  OTC: 'TD',
  MVO: 'TD',
  AMC: 'TD',
  PLF: 'TD',
  OUT: 'TD',
  AMO: 'TD',
  FYT: 'TD',
  FUB: 'PG',
  BZH: 'ZW',
  BUQ: 'ZW',
  CHJ: 'ZW',
  BFO: 'ZW',
  VFA: 'ZW',
  HRE: 'ZW',
  KAB: 'ZW',
  MJW: 'ZW',
  UTA: 'ZW',
  MVZ: 'ZW',
  GWE: 'ZW',
  HWN: 'ZW',
  WKI: 'ZW',
  CEH: 'MW',
  BLZ: 'MW',
  CMK: 'MW',
  DWA: 'MW',
  KGJ: 'MW',
  KBQ: 'MW',
  LLW: 'MW',
  LIX: 'MW',
  MAI: 'MW',
  MYZ: 'MW',
  LMB: 'MW',
  ZZU: 'MW',
  LEF: 'LS',
  LRB: 'LS',
  LES: 'LS',
  MFC: 'LS',
  MKH: 'LS',
  MSU: 'LS',
  NKU: 'LS',
  PEL: 'LS',
  UTG: 'LS',
  UNE: 'LS',
  SHK: 'LS',
  SKQ: 'LS',
  SOK: 'LS',
  SHZ: 'LS',
  THB: 'LS',
  TKO: 'LS',
  AIW: 'NA',
  ADI: 'NA',
  GOG: 'NA',
  GFY: 'NA',
  HAL: 'NA',
  KAS: 'NA',
  MPA: 'NA',
  KMP: 'NA',
  LHU: 'NA',
  LUD: 'NA',
  MJO: 'NA',
  MQG: 'NA',
  OKU: 'NA',
  NNI: 'NA',
  OND: 'NA',
  OMG: 'NA',
  OMD: 'NA',
  OKF: 'NA',
  OPW: 'NA',
  OHI: 'NA',
  OTJ: 'NA',
  NDU: 'NA',
  RHN: 'NA',
  SWP: 'NA',
  SZM: 'NA',
  TCY: 'NA',
  TSB: 'NA',
  WVB: 'NA',
  ERS: 'NA',
  WDH: 'NA',
  FIH: 'CD',
  NLO: 'CD',
  MNB: 'CD',
  BOA: 'CD',
  LZI: 'CD',
  MAT: 'CD',
  NKL: 'CD',
  INO: 'CD',
  NIO: 'CD',
  FDU: 'CD',
  KRZ: 'CD',
  KKW: 'CD',
  IDF: 'CD',
  LUS: 'CD',
  MSM: 'CD',
  MDK: 'CD',
  BSU: 'CD',
  LIE: 'CD',
  BDT: 'CD',
  GMA: 'CD',
  KLI: 'CD',
  BMB: 'CD',
  LIQ: 'CD',
  BNB: 'CD',
  IKL: 'CD',
  FKI: 'CD',
  YAN: 'CD',
  IRP: 'CD',
  BUX: 'CD',
  BZU: 'CD',
  BKY: 'CD',
  RUE: 'CD',
  GOM: 'CD',
  BNC: 'CD',
  KND: 'CD',
  KLY: 'CD',
  PUN: 'CD',
  FBM: 'CD',
  PWO: 'CD',
  KEC: 'CD',
  KWZ: 'CD',
  MNO: 'CD',
  BDV: 'CD',
  FMI: 'CD',
  KBO: 'CD',
  KOO: 'CD',
  KMN: 'CD',
  KAP: 'CD',
  KNM: 'CD',
  KGA: 'CD',
  LZA: 'CD',
  TSH: 'CD',
  LJA: 'CD',
  LBO: 'CD',
  MEW: 'CD',
  BAN: 'CD',
  PFR: 'CD',
  MJM: 'CD',
  KBN: 'CD',
  AKE: 'GA',
  GAX: 'GA',
  GAB: 'US',
  BKO: 'ML',
  GUD: 'ML',
  GAQ: 'ML',
  KNZ: 'ML',
  KTX: 'ML',
  KYS: 'ML',
  MZI: 'ML',
  NRM: 'ML',
  NIX: 'ML',
  KSS: 'ML',
  TOM: 'ML',
  EYL: 'ML',
  GAZ: 'PG',
  DOC: 'GB',
  FLH: 'GB',
  FOA: 'GB',
  OUK: 'GB',
  PSV: 'GB',
  ULL: 'GB',
  GBM: 'SO',
  BJL: 'GM',
  FUE: 'ES',
  GMZ: 'ES',
  VDE: 'ES',
  SPC: 'ES',
  LPA: 'ES',
  ACE: 'ES',
  TFS: 'ES',
  GCV: 'BR',
  TFN: 'ES',
  JCU: 'ES',
  MLN: 'ES',
  GEW: 'PG',
  OYC: 'GF',
  KBS: 'SL',
  GFD: 'US',
  GBK: 'SL',
  HGS: 'SL',
  KBA: 'SL',
  KEN: 'SL',
  FNA: 'SL',
  WYE: 'SL',
  BQE: 'GW',
  OXB: 'GW',
  GHE: 'PA',
  QCU: 'GL',
  UCN: 'LR',
  CPA: 'LR',
  SNI: 'LR',
  MLW: 'LR',
  NIA: 'LR',
  GLP: 'PG',
  ROB: 'LR',
  SAZ: 'LR',
  THC: 'LR',
  VOI: 'LR',
  AGA: 'MA',
  TTA: 'MA',
  UAR: 'MA',
  FEZ: 'MA',
  ERH: 'MA',
  MEK: 'MA',
  OUD: 'MA',
  SMW: 'EH',
  GMD: 'MA',
  BEM: 'MA',
  RBA: 'MA',
  VIL: 'EH',
  ESU: 'MA',
  EUN: 'EH',
  CMN: 'MA',
  NDR: 'MA',
  RAK: 'MA',
  NNA: 'MA',
  OZZ: 'MA',
  GMQ: 'CN',
  AHU: 'MA',
  TTU: 'MA',
  TNG: 'MA',
  GNU: 'US',
  DSS: 'SN',
  GOC: 'PG',
  KDA: 'SN',
  ZIG: 'SN',
  CSK: 'SN',
  KLC: 'SN',
  DKR: 'SN',
  MAX: 'SN',
  POD: 'SN',
  RDT: 'SN',
  XLS: 'SN',
  BXE: 'SN',
  KGG: 'SN',
  SMY: 'SN',
  TUD: 'SN',
  AEO: 'MR',
  OTL: 'MR',
  THI: 'MR',
  TIY: 'MR',
  BGH: 'MR',
  KFA: 'MR',
  TMD: 'MR',
  EMN: 'MR',
  AJJ: 'MR',
  KED: 'MR',
  MOM: 'MR',
  NKC: 'MR',
  SEY: 'MR',
  THT: 'MR',
  ATR: 'MR',
  FGD: 'MR',
  NDB: 'MR',
  OUZ: 'MR',
  GRC: 'LR',
  GMT: 'US',
  CIQ: 'GT',
  DON: 'GT',
  ENJ: 'GT',
  PCG: 'GT',
  TKM: 'GT',
  UAX: 'GT',
  PKJ: 'GT',
  GTZ: 'TZ',
  CKY: 'GN',
  GUE: 'PG',
  FIG: 'GN',
  FAA: 'GN',
  KSI: 'GN',
  LEK: 'GN',
  MCA: 'GN',
  NZE: 'GN',
  BKJ: 'GN',
  SBI: 'GN',
  GII: 'GN',
  KNN: 'GN',
  SID: 'CV',
  NTO: 'CV',
  BVC: 'CV',
  GVE: 'US',
  MMO: 'CV',
  MTI: 'CV',
  RAI: 'CV',
  SFL: 'CV',
  SNE: 'CV',
  VXE: 'CV',
  BCG: 'GY',
  BTO: 'SR',
  DOE: 'SR',
  LDO: 'SR',
  WSO: 'SR',
  GZI: 'AF',
  ADD: 'ET',
  AMH: 'ET',
  AXU: 'ET',
  BCO: 'ET',
  BJR: 'ET',
  BEI: 'ET',
  DEM: 'ET',
  DBM: 'ET',
  DIR: 'ET',
  DBT: 'ET',
  FNH: 'ET',
  GOB: 'ET',
  GNN: 'ET',
  GMB: 'ET',
  GDQ: 'ET',
  GDE: 'ET',
  GOR: 'ET',
  QHR: 'ET',
  HUE: 'ET',
  JIJ: 'ET',
  JIM: 'ET',
  ABK: 'ET',
  LFO: 'ET',
  AWA: 'ET',
  LLI: 'ET',
  MKS: 'ET',
  MQX: 'ET',
  ETE: 'ET',
  NDM: 'ET',
  MUJ: 'ET',
  MTF: 'ET',
  EGL: 'ET',
  NEJ: 'ET',
  NEK: 'ET',
  PWI: 'ET',
  SXU: 'ET',
  SKR: 'ET',
  SZE: 'ET',
  ASO: 'ET',
  TIE: 'ET',
  WRA: 'ET',
  HAY: 'US',
  HAZ: 'PG',
  BJM: 'BI',
  GID: 'BI',
  KRE: 'BI',
  HBQ: 'CN',
  HBT: 'LK',
  ALU: 'SO',
  BIB: 'SO',
  CXN: 'SO',
  BSY: 'SO',
  HCM: 'SO',
  BSA: 'SO',
  GSR: 'SO',
  HGA: 'SO',
  BBO: 'SO',
  LGX: 'SO',
  KMU: 'SO',
  MGQ: 'SO',
  BLW: 'SO',
  CMO: 'SO',
  GLK: 'SO',
  CMS: 'SO',
  ERA: 'SO',
  BUO: 'SO',
  GGR: 'SO',
  JIB: 'DJ',
  AII: 'DJ',
  MHI: 'DJ',
  OBC: 'DJ',
  TDJ: 'DJ',
  EMY: 'EG',
  SQK: 'EG',
  DBB: 'EG',
  AAC: 'EG',
  ATZ: 'EG',
  HBE: 'EG',
  ABS: 'EG',
  CAI: 'EG',
  CCE: 'EG',
  DAK: 'EG',
  HRG: 'EG',
  EGH: 'EG',
  UVL: 'EG',
  LXR: 'EG',
  RMF: 'EG',
  MUH: 'EG',
  HEO: 'PG',
  GSQ: 'EG',
  PSD: 'EG',
  SKV: 'EG',
  HMB: 'EG',
  SSH: 'EG',
  ASW: 'EG',
  SEW: 'EG',
  SPX: 'EG',
  TCP: 'EG',
  ELT: 'EG',
  HEY: 'US',
  HGT: 'US',
  ASM: 'ER',
  MSW: 'ER',
  ASA: 'ER',
  TES: 'ER',
  HPV: 'US',
  WKL: 'US',
  HIA: 'CN',
  HIL: 'ET',
  JUB: 'SS',
  ASV: 'KE',
  HKB: 'US',
  EDL: 'KE',
  EYS: 'KE',
  KLK: 'KE',
  GAS: 'KE',
  HOA: 'KE',
  NBO: 'KE',
  KEU: 'KE',
  GGM: 'KE',
  KIS: 'KE',
  ILU: 'KE',
  KEY: 'KE',
  KTL: 'KE',
  LKG: 'KE',
  LOK: 'KE',
  LAU: 'KE',
  LOY: 'KE',
  NDE: 'KE',
  RBT: 'KE',
  JJM: 'KE',
  MYD: 'KE',
  MBA: 'KE',
  MRE: 'KE',
  OYL: 'KE',
  NYE: 'KE',
  NUU: 'KE',
  WIL: 'KE',
  NYK: 'KE',
  UAS: 'KE',
  UKA: 'KE',
  WJR: 'KE',
  SRX: 'LY',
  GHT: 'LY',
  AKF: 'LY',
  BEN: 'LY',
  MJI: 'LY',
  LAQ: 'LY',
  SEB: 'LY',
  LMQ: 'LY',
  NFR: 'LY',
  HUQ: 'LY',
  LTD: 'LY',
  TOB: 'LY',
  ZIS: 'LY',
  WAX: 'LY',
  EDQ: 'HN',
  HOO: 'VN',
  HRC: 'KZ',
  GYI: 'RW',
  BTQ: 'RW',
  KGL: 'RW',
  RHG: 'RW',
  KME: 'RW',
  ATB: 'SD',
  EDB: 'SD',
  DOG: 'SD',
  RSS: 'SD',
  ELF: 'SD',
  DNX: 'SD',
  EGN: 'SD',
  HEG: 'SD',
  HSJ: 'CN',
  KSL: 'SD',
  GBU: 'SD',
  KDX: 'SD',
  RBX: 'SS',
  MWE: 'SD',
  NUD: 'SD',
  UYL: 'SD',
  NHF: 'SD',
  EBD: 'SD',
  PZU: 'SD',
  KRT: 'SD',
  MAK: 'SS',
  WHF: 'SD',
  WUU: 'SS',
  ZLX: 'SD',
  ARK: 'TZ',
  BKZ: 'TZ',
  DAR: 'TZ',
  DOD: 'TZ',
  MBI: 'TZ',
  IRI: 'TZ',
  TKQ: 'TZ',
  KIY: 'TZ',
  JRO: 'TZ',
  LDI: 'TZ',
  LKY: 'TZ',
  HTM: 'MN',
  MFA: 'TZ',
  MWN: 'TZ',
  XMI: 'TZ',
  NPY: 'TZ',
  QSI: 'TZ',
  MYW: 'TZ',
  MUZ: 'TZ',
  MWZ: 'TZ',
  NCH: 'TZ',
  JOM: 'TZ',
  PMA: 'TZ',
  SEU: 'TZ',
  SGX: 'TZ',
  SUT: 'TZ',
  SHY: 'TZ',
  TBO: 'TZ',
  TGT: 'TZ',
  ZNZ: 'TZ',
  RUA: 'UG',
  EBB: 'UG',
  ULU: 'UG',
  JIN: 'UG',
  PAF: 'UG',
  KSE: 'UG',
  MBQ: 'UG',
  KCU: 'UG',
  SRT: 'UG',
  TRY: 'UG',
  HWA: 'PG',
  HYL: 'US',
  HZU: 'CN',
  IBI: 'PG',
  IBL: 'MZ',
  ICO: 'PH',
  PPJ: 'ID',
  JBB: 'ID',
  BWX: 'ID',
  BXW: 'ID',
  GYO: 'ID',
  KXB: 'ID',
  AAS: 'ID',
  AGD: 'ID',
  AKQ: 'ID',
  AYW: 'ID',
  BJG: 'ID',
  BXM: 'ID',
  DRH: 'ID',
  ELR: 'ID',
  EWE: 'ID',
  GAV: 'ID',
  IUL: 'ID',
  KBF: 'ID',
  KBX: 'ID',
  KCD: 'ID',
  KOD: 'ID',
  KRC: 'ID',
  LLN: 'ID',
  LWE: 'ID',
  LYK: 'ID',
  MSI: 'ID',
  NAF: 'ID',
  OBD: 'ID',
  PWL: 'ID',
  RAQ: 'ID',
  RKI: 'ID',
  TBM: 'ID',
  TMY: 'ID',
  ZEG: 'ID',
  UGU: 'ID',
  IDN: 'PG',
  IMA: 'PG',
  IMG: 'MZ',
  PYG: 'IN',
  VDY: 'IN',
  DGH: 'IN',
  GOX: 'IN',
  HGI: 'IN',
  JGB: 'IN',
  NVY: 'IN',
  RJI: 'IN',
  TEI: 'IN',
  INE: 'MZ',
  IOK: 'PG',
  IOP: 'PG',
  KHA: 'IR',
  IRU: 'LK',
  ISG: 'JP',
  ITK: 'PG',
  IVI: 'PA',
  JGD: 'CN',
  JIC: 'CN',
  JIO: 'ID',
  JIQ: 'CN',
  JJD: 'BR',
  JLA: 'US',
  JMB: 'AO',
  JNZ: 'CN',
  JOP: 'PG',
  JPN: 'US',
  JRA: 'US',
  JRB: 'US',
  JUH: 'CN',
  AMK: 'US',
  BDX: 'US',
  EUE: 'US',
  KPT: 'US',
  RLA: 'US',
  FID: 'US',
  HUD: 'US',
  TWD: 'US',
  MVM: 'US',
  HCC: 'US',
  AHD: 'US',
  GCW: 'US',
  CKE: 'US',
  ROF: 'US',
  CNE: 'US',
  RSX: 'US',
  COP: 'US',
  CIL: 'US',
  IRB: 'US',
  GNF: 'US',
  CHZ: 'US',
  LTW: 'US',
  AHF: 'US',
  PCT: 'US',
  CTO: 'US',
  NRI: 'US',
  GTP: 'US',
  NLE: 'US',
  GCD: 'US',
  VLE: 'US',
  FPY: 'US',
  NTJ: 'US',
  SBO: 'US',
  JVI: 'US',
  UCE: 'US',
  GOL: 'US',
  KKT: 'US',
  PRW: 'US',
  EGP: 'US',
  BLD: 'US',
  MFH: 'US',
  ECA: 'US',
  FMU: 'US',
  OTS: 'US',
  ROL: 'US',
  CTK: 'US',
  WPO: 'US',
  ATE: 'US',
  ASQ: 'US',
  AAF: 'US',
  ABE: 'US',
  ABI: 'US',
  ABQ: 'US',
  ABR: 'US',
  ABY: 'US',
  ACB: 'US',
  ACK: 'US',
  ACT: 'US',
  ACV: 'US',
  ACY: 'US',
  ADG: 'US',
  ADT: 'US',
  ADM: 'US',
  ADS: 'US',
  ADW: 'US',
  KAE: 'US',
  AEL: 'US',
  AEX: 'US',
  KAF: 'PG',
  AFF: 'US',
  WSG: 'US',
  AFN: 'US',
  AFO: 'US',
  AFW: 'US',
  AGC: 'US',
  AGO: 'US',
  AGS: 'US',
  AHC: 'US',
  AHH: 'US',
  AHN: 'US',
  AIA: 'US',
  AID: 'US',
  AIK: 'US',
  AIO: 'US',
  AIV: 'US',
  AIZ: 'US',
  KAK: 'PG',
  AKO: 'US',
  AKC: 'US',
  ALB: 'US',
  ALI: 'US',
  ALM: 'US',
  ALN: 'US',
  ALO: 'US',
  ALS: 'US',
  ALW: 'US',
  ALX: 'US',
  AMA: 'US',
  AMN: 'US',
  AMW: 'US',
  ANB: 'US',
  AND: 'US',
  SLT: 'US',
  ANP: 'US',
  ANQ: 'US',
  ANW: 'US',
  ANY: 'US',
  AOH: 'US',
  AOO: 'US',
  APA: 'US',
  APC: 'US',
  APF: 'US',
  APG: 'US',
  APH: 'US',
  APN: 'US',
  APT: 'US',
  APV: 'US',
  KAQ: 'PG',
  ARA: 'US',
  ARB: 'US',
  ARG: 'US',
  WHT: 'US',
  AUZ: 'US',
  ART: 'US',
  ARV: 'US',
  BFT: 'US',
  ASE: 'US',
  SPZ: 'US',
  ASH: 'US',
  ASL: 'US',
  ASN: 'US',
  AST: 'US',
  ASX: 'US',
  ASY: 'US',
  ATL: 'US',
  ATS: 'US',
  ATW: 'US',
  ATY: 'US',
  AUG: 'US',
  AUM: 'US',
  AUN: 'US',
  AUO: 'US',
  AUS: 'US',
  AUW: 'US',
  AVL: 'US',
  AVO: 'US',
  AVP: 'US',
  AVW: 'US',
  AVX: 'US',
  AWM: 'US',
  AXG: 'US',
  AXN: 'US',
  AXS: 'US',
  AXV: 'US',
  AXX: 'US',
  AYS: 'US',
  TUH: 'US',
  AZO: 'US',
  BAB: 'US',
  BAD: 'US',
  BAF: 'US',
  CLU: 'US',
  BAM: 'US',
  BBB: 'US',
  BBD: 'US',
  BKG: 'US',
  BTN: 'US',
  BBW: 'US',
  BCB: 'US',
  BCE: 'US',
  BCT: 'US',
  BDE: 'US',
  BDG: 'US',
  BDL: 'US',
  BDR: 'US',
  WBU: 'US',
  KBE: 'US',
  BEC: 'US',
  BED: 'US',
  BEH: 'US',
  BFD: 'US',
  BFF: 'US',
  BFI: 'US',
  BFL: 'US',
  BFM: 'US',
  BFR: 'US',
  BGD: 'US',
  BGE: 'US',
  BGM: 'US',
  BGR: 'US',
  KBH: 'TZ',
  BHB: 'US',
  BHM: 'US',
  BID: 'US',
  BIE: 'US',
  BIF: 'US',
  BIH: 'US',
  BIL: 'US',
  BIS: 'US',
  BIX: 'US',
  BJC: 'US',
  BJI: 'US',
  BJJ: 'US',
  BKD: 'US',
  BKE: 'US',
  BFK: 'US',
  BKL: 'US',
  BKT: 'US',
  BKW: 'US',
  BKX: 'US',
  BLF: 'US',
  BLH: 'US',
  BLI: 'US',
  BLM: 'US',
  BLU: 'US',
  BLV: 'US',
  KBM: 'PG',
  BMC: 'US',
  BMG: 'US',
  BMI: 'US',
  BML: 'US',
  BMT: 'US',
  BNA: 'US',
  BNG: 'US',
  BNL: 'US',
  BNO: 'US',
  BNW: 'US',
  BOI: 'US',
  BOS: 'US',
  BOW: 'US',
  HCA: 'US',
  BPI: 'US',
  WMH: 'US',
  BPT: 'US',
  BQK: 'US',
  BRD: 'US',
  BRL: 'US',
  BRO: 'US',
  BRY: 'US',
  KBT: 'MH',
  BTF: 'US',
  BTL: 'US',
  BTM: 'US',
  TTO: 'US',
  BTP: 'US',
  BTR: 'US',
  BTV: 'US',
  BTY: 'US',
  BUB: 'US',
  BUF: 'US',
  BUM: 'US',
  BUR: 'US',
  BFP: 'US',
  BVO: 'US',
  MVW: 'US',
  BVX: 'US',
  BVY: 'US',
  BWC: 'US',
  BWD: 'US',
  BWG: 'US',
  BWI: 'US',
  WAH: 'US',
  BWM: 'US',
  BXA: 'US',
  BXK: 'US',
  BYG: 'US',
  BYH: 'US',
  BYI: 'US',
  BYS: 'US',
  BBC: 'US',
  BZN: 'US',
  XES: 'US',
  PLY: 'US',
  CLG: 'US',
  CAD: 'US',
  CAE: 'US',
  CIG: 'US',
  CAK: 'US',
  CAO: 'US',
  CAR: 'US',
  CBE: 'US',
  CBF: 'US',
  CBK: 'US',
  CBM: 'US',
  KCC: 'US',
  CCB: 'US',
  CCR: 'US',
  CCY: 'US',
  LLX: 'US',
  CDC: 'US',
  CDH: 'US',
  CDN: 'US',
  CDR: 'US',
  CDS: 'US',
  CDW: 'US',
  CEA: 'US',
  CEC: 'US',
  CEF: 'US',
  CEU: 'US',
  CEV: 'US',
  CEW: 'US',
  CEY: 'US',
  CEZ: 'US',
  CFD: 'US',
  TZC: 'US',
  CFT: 'US',
  CFV: 'US',
  CGE: 'US',
  CGF: 'US',
  CGI: 'US',
  CGS: 'US',
  CGZ: 'US',
  CHA: 'US',
  CHK: 'US',
  CHO: 'US',
  CHS: 'US',
  CIC: 'US',
  CID: 'US',
  CIN: 'US',
  CIR: 'US',
  CIU: 'US',
  CKA: 'US',
  CKB: 'US',
  GRM: 'US',
  CKM: 'US',
  CKN: 'US',
  CKV: 'US',
  KCL: 'US',
  CLE: 'US',
  CLI: 'US',
  CLK: 'US',
  CLL: 'US',
  CLM: 'US',
  CLR: 'US',
  CLS: 'US',
  CLT: 'US',
  CLW: 'US',
  CMH: 'US',
  CMI: 'US',
  CMX: 'US',
  CMY: 'US',
  KCN: 'US',
  CNH: 'US',
  CNK: 'US',
  CNM: 'US',
  CNO: 'US',
  CNU: 'US',
  CNW: 'US',
  CNY: 'US',
  COD: 'US',
  COE: 'US',
  COF: 'US',
  COI: 'US',
  COM: 'US',
  CON: 'US',
  COS: 'US',
  COT: 'US',
  COU: 'US',
  CPM: 'US',
  CPR: 'US',
  CPS: 'US',
  HCW: 'US',
  KCR: 'US',
  CRE: 'US',
  CRG: 'US',
  CRO: 'US',
  CRP: 'US',
  CLD: 'US',
  CRS: 'US',
  CRT: 'US',
  CRW: 'US',
  CRX: 'US',
  CSG: 'US',
  CSM: 'US',
  CSQ: 'US',
  CSV: 'US',
  CTB: 'US',
  CTY: 'US',
  CTZ: 'US',
  CUB: 'US',
  CUH: 'US',
  CVG: 'US',
  CKK: 'US',
  CVN: 'US',
  CVO: 'US',
  CVS: 'US',
  CWA: 'US',
  KIP: 'US',
  CWF: 'US',
  CWI: 'US',
  CXL: 'US',
  CXO: 'US',
  CSN: 'US',
  HAR: 'US',
  CYS: 'US',
  CZT: 'US',
  VEX: 'US',
  DAA: 'US',
  DAB: 'US',
  DAG: 'US',
  DAL: 'US',
  DAN: 'US',
  DAY: 'US',
  DBN: 'US',
  DBQ: 'US',
  DCA: 'US',
  DCU: 'US',
  DDC: 'US',
  DEC: 'US',
  DEH: 'US',
  DEN: 'US',
  DET: 'US',
  DFI: 'US',
  DFW: 'US',
  DGL: 'US',
  DGW: 'US',
  DHN: 'US',
  DHT: 'US',
  DIK: 'US',
  DKK: 'US',
  DLL: 'US',
  DLF: 'US',
  DLH: 'US',
  DLN: 'US',
  DLS: 'US',
  DMA: 'US',
  DMN: 'US',
  DMO: 'US',
  DNL: 'US',
  DNN: 'US',
  DNS: 'US',
  DNV: 'US',
  DOV: 'US',
  KDP: 'PG',
  DPA: 'US',
  DPG: 'US',
  KDQ: 'PG',
  DRA: 'US',
  DRI: 'US',
  DRE: 'US',
  DRO: 'US',
  DRT: 'US',
  KDS: 'AU',
  DSM: 'US',
  DSV: 'US',
  DTA: 'US',
  DTL: 'US',
  DTN: 'US',
  DSI: 'US',
  DTW: 'US',
  DUA: 'US',
  DUC: 'US',
  DUG: 'US',
  DUJ: 'US',
  DVL: 'US',
  DVN: 'US',
  NOT: 'US',
  NSL: 'US',
  DVT: 'US',
  KDW: 'LK',
  DWH: 'US',
  DXR: 'US',
  DYL: 'US',
  DYS: 'US',
  KDZ: 'LK',
  VPG: 'KE',
  KTJ: 'KE',
  OSJ: 'KE',
  OLG: 'KE',
  HKR: 'KE',
  ANA: 'KE',
  MDR: 'KE',
  OLX: 'KE',
  KRV: 'KE',
  KIU: 'KE',
  LBK: 'KE',
  LBN: 'KE',
  LKU: 'KE',
  MUM: 'KE',
  MIF: 'US',
  CCG: 'US',
  ESO: 'US',
  WTR: 'US',
  ALE: 'US',
  BGT: 'US',
  EAN: 'US',
  EAR: 'US',
  EAT: 'US',
  EAU: 'US',
  KEB: 'US',
  EBS: 'US',
  ECG: 'US',
  ECP: 'US',
  ECS: 'US',
  EDE: 'US',
  ETS: 'US',
  EDW: 'US',
  EED: 'US',
  EEN: 'US',
  EFD: 'US',
  EFK: 'US',
  EFW: 'US',
  KEG: 'PG',
  EGE: 'US',
  EGI: 'US',
  EGV: 'US',
  KEK: 'US',
  EKA: 'US',
  EKI: 'US',
  EKN: 'US',
  EKO: 'US',
  EKX: 'US',
  ELA: 'US',
  ELD: 'US',
  ELK: 'US',
  ELM: 'US',
  ELN: 'US',
  LYU: 'US',
  ELP: 'US',
  ELY: 'US',
  ELZ: 'US',
  EMM: 'US',
  EMP: 'US',
  EMT: 'US',
  END: 'US',
  ENL: 'US',
  ENV: 'US',
  ENW: 'US',
  EOK: 'US',
  EPH: 'US',
  EDK: 'US',
  ERI: 'US',
  ERR: 'US',
  ERV: 'US',
  ESC: 'US',
  ESF: 'US',
  ESN: 'US',
  EST: 'US',
  ESW: 'US',
  ETB: 'US',
  ETN: 'US',
  EUF: 'US',
  EUG: 'US',
  EVM: 'US',
  EVV: 'US',
  EVW: 'US',
  EWB: 'US',
  EWK: 'US',
  EWN: 'US',
  EWR: 'US',
  KEX: 'PG',
  EYW: 'US',
  KEZ: 'LK',
  WIB: 'US',
  RBK: 'US',
  FAF: 'US',
  FAM: 'US',
  FAR: 'US',
  FAT: 'US',
  FAY: 'US',
  FBG: 'US',
  FBL: 'US',
  FBR: 'US',
  FBY: 'US',
  FCH: 'US',
  FCM: 'US',
  FCS: 'US',
  FCY: 'US',
  FDK: 'US',
  FDR: 'US',
  FDY: 'US',
  FEP: 'US',
  FET: 'US',
  FFA: 'US',
  FFL: 'US',
  FFM: 'US',
  FFO: 'US',
  FFT: 'US',
  MSC: 'US',
  FRD: 'US',
  FHU: 'US',
  FKL: 'US',
  FKN: 'US',
  FLD: 'US',
  FLG: 'US',
  FLL: 'US',
  FLO: 'US',
  FLP: 'US',
  FLV: 'US',
  FLX: 'US',
  FME: 'US',
  FMH: 'US',
  FMN: 'US',
  FMY: 'US',
  FNL: 'US',
  FNT: 'US',
  FOD: 'US',
  FOE: 'US',
  FOK: 'US',
  FIL: 'US',
  FPR: 'US',
  FRG: 'US',
  FRH: 'US',
  FRI: 'US',
  FRM: 'US',
  FRR: 'US',
  FSD: 'US',
  FSI: 'US',
  FSK: 'US',
  FSM: 'US',
  FST: 'US',
  FSU: 'US',
  FMS: 'US',
  FTK: 'US',
  FTW: 'US',
  FTY: 'US',
  FUL: 'US',
  WFK: 'US',
  FWA: 'US',
  FXE: 'US',
  FXY: 'US',
  FYM: 'US',
  FYV: 'US',
  GAD: 'US',
  GAG: 'US',
  GAI: 'US',
  GBD: 'US',
  GBG: 'US',
  GBR: 'US',
  GCC: 'US',
  JDA: 'US',
  GCK: 'US',
  GCN: 'US',
  GCY: 'US',
  GDM: 'US',
  GDV: 'US',
  GDW: 'US',
  GED: 'US',
  GEG: 'US',
  GEY: 'US',
  GFA: 'US',
  GFK: 'US',
  GFL: 'US',
  GGE: 'US',
  GGG: 'US',
  GGW: 'US',
  GHM: 'US',
  GIF: 'US',
  GJT: 'US',
  MEJ: 'US',
  GKT: 'US',
  GLD: 'US',
  GLE: 'US',
  GLH: 'US',
  GLR: 'US',
  GLS: 'US',
  GLW: 'US',
  GMU: 'US',
  GNG: 'US',
  GNT: 'US',
  GNV: 'US',
  GOK: 'US',
  GON: 'US',
  FCA: 'US',
  GPT: 'US',
  GPZ: 'US',
  GQQ: 'US',
  GRB: 'US',
  GRD: 'US',
  GRE: 'US',
  GRF: 'US',
  GRI: 'US',
  GRK: 'US',
  GRN: 'US',
  GRR: 'US',
  GSB: 'US',
  GSH: 'US',
  GSO: 'US',
  GSP: 'US',
  GTF: 'US',
  GTG: 'US',
  GTR: 'US',
  GUC: 'US',
  GUP: 'US',
  GUS: 'US',
  GUY: 'US',
  GVL: 'US',
  GVT: 'US',
  KGW: 'PG',
  GWO: 'US',
  GWS: 'US',
  KGX: 'US',
  GXY: 'US',
  GDC: 'US',
  PNX: 'US',
  GYR: 'US',
  GYY: 'US',
  KGZ: 'US',
  DSY: 'KH',
  HAB: 'US',
  HAF: 'US',
  HAI: 'US',
  HAO: 'US',
  HBG: 'US',
  HBR: 'US',
  HDE: 'US',
  HDN: 'US',
  HEE: 'US',
  MNZ: 'US',
  HEZ: 'US',
  HFD: 'US',
  HFF: 'US',
  HGR: 'US',
  HHR: 'US',
  HUJ: 'US',
  HIB: 'US',
  HIE: 'US',
  HIF: 'US',
  HII: 'US',
  HIO: 'US',
  HKA: 'US',
  HKS: 'US',
  HKY: 'US',
  HLB: 'US',
  HLC: 'US',
  HLG: 'US',
  HLM: 'US',
  HLN: 'US',
  HLR: 'US',
  HMN: 'US',
  HMT: 'US',
  HNB: 'US',
  HSH: 'US',
  HOB: 'US',
  HON: 'US',
  HOP: 'US',
  HOT: 'US',
  HOU: 'US',
  HPN: 'US',
  HPT: 'US',
  HPY: 'US',
  HQM: 'US',
  HES: 'US',
  HRL: 'US',
  HRO: 'US',
  HSB: 'US',
  HNC: 'US',
  THP: 'US',
  HSI: 'US',
  HSP: 'US',
  HST: 'US',
  HSV: 'US',
  HTH: 'US',
  HTL: 'US',
  HTO: 'US',
  HTS: 'US',
  HTW: 'US',
  HUA: 'US',
  HUF: 'US',
  HUL: 'US',
  HUM: 'US',
  HUT: 'US',
  HVE: 'US',
  HVN: 'US',
  HVR: 'US',
  HVS: 'US',
  HWD: 'US',
  HWO: 'US',
  WSH: 'US',
  HHH: 'US',
  HYA: 'US',
  HYR: 'US',
  HYS: 'US',
  HZL: 'US',
  JFN: 'US',
  IAB: 'US',
  IAD: 'US',
  IAG: 'US',
  IAH: 'US',
  KIB: 'US',
  ICL: 'US',
  ICT: 'US',
  IDA: 'US',
  IDI: 'US',
  IDP: 'US',
  XPR: 'US',
  IFA: 'US',
  IFP: 'US',
  IGM: 'US',
  IKK: 'US',
  KIL: 'CD',
  ILE: 'US',
  ILG: 'US',
  ILM: 'US',
  ILN: 'US',
  IML: 'US',
  IMM: 'US',
  MDN: 'US',
  IMT: 'US',
  IND: 'US',
  INK: 'US',
  INL: 'US',
  INS: 'US',
  INT: 'US',
  INW: 'US',
  IOW: 'US',
  IPL: 'US',
  IPT: 'US',
  KIQ: 'PG',
  IRK: 'US',
  IRS: 'US',
  ISM: 'US',
  ISO: 'US',
  ISP: 'US',
  ISQ: 'US',
  ISW: 'US',
  ITH: 'US',
  AZA: 'US',
  IWD: 'US',
  ISS: 'US',
  IWS: 'US',
  JCI: 'US',
  IYK: 'US',
  SQA: 'US',
  FRY: 'US',
  JAC: 'US',
  JAN: 'US',
  JAS: 'US',
  JAX: 'US',
  JBR: 'US',
  JCT: 'US',
  JDN: 'US',
  JEF: 'US',
  JFK: 'US',
  JHW: 'US',
  GUF: 'US',
  JLN: 'US',
  JMS: 'US',
  JOT: 'US',
  USA: 'US',
  JKV: 'US',
  JST: 'US',
  JVL: 'US',
  KJX: 'ID',
  JXN: 'US',
  KKB: 'US',
  KIC: 'US',
  KKL: 'US',
  KLS: 'US',
  KKU: 'US',
  DTH: 'US',
  BXS: 'US',
  RBF: 'US',
  TRH: 'US',
  LAA: 'US',
  LAF: 'US',
  LAL: 'US',
  LAM: 'US',
  LAN: 'US',
  LAR: 'US',
  LAS: 'US',
  LAW: 'US',
  LAX: 'US',
  LBB: 'US',
  LBE: 'US',
  LBF: 'US',
  LBL: 'US',
  LBT: 'US',
  LJN: 'US',
  LCH: 'US',
  LCI: 'US',
  LCK: 'US',
  LCQ: 'US',
  LDJ: 'US',
  LDM: 'US',
  LEB: 'US',
  LEE: 'US',
  LEM: 'US',
  LEW: 'US',
  LEX: 'US',
  LFI: 'US',
  LFK: 'US',
  LFT: 'US',
  LGA: 'US',
  LGB: 'US',
  LGC: 'US',
  LGD: 'US',
  LGF: 'US',
  LGU: 'US',
  LHV: 'US',
  LIY: 'US',
  LFN: 'US',
  LIC: 'US',
  LIT: 'US',
  LKP: 'US',
  LOW: 'US',
  LKV: 'US',
  CHL: 'US',
  LMS: 'US',
  LMT: 'US',
  LNA: 'US',
  LND: 'US',
  LNK: 'US',
  LNN: 'US',
  LNP: 'US',
  LNR: 'US',
  LNS: 'US',
  LOL: 'US',
  BBX: 'US',
  LOT: 'US',
  LOU: 'US',
  LOZ: 'US',
  LPC: 'US',
  LQK: 'US',
  LRD: 'US',
  LRF: 'US',
  LRJ: 'US',
  LRU: 'US',
  LSB: 'US',
  LSE: 'US',
  LSF: 'US',
  LSK: 'US',
  LSN: 'US',
  LSV: 'US',
  LTS: 'US',
  LUF: 'US',
  LUK: 'US',
  LUL: 'US',
  LVK: 'US',
  LVL: 'US',
  LVM: 'US',
  LVS: 'US',
  LWB: 'US',
  LWC: 'US',
  LWL: 'US',
  LWM: 'US',
  LWS: 'US',
  LWT: 'US',
  LWV: 'US',
  LXN: 'US',
  LXV: 'US',
  LYH: 'US',
  LYO: 'US',
  LZU: 'US',
  PCU: 'US',
  MLK: 'US',
  MAC: 'US',
  MAE: 'US',
  MAF: 'US',
  MAW: 'US',
  KMB: 'PG',
  MBG: 'US',
  MBL: 'US',
  DXE: 'US',
  MBS: 'US',
  MBY: 'US',
  MCB: 'US',
  MCC: 'US',
  MCD: 'US',
  MCE: 'US',
  MCF: 'US',
  MCI: 'US',
  MCK: 'US',
  MCN: 'US',
  MCO: 'US',
  MCW: 'US',
  MDA: 'US',
  MDD: 'US',
  MDH: 'US',
  XMD: 'US',
  MDT: 'US',
  MDW: 'US',
  MDF: 'US',
  MXE: 'US',
  MEI: 'US',
  MEM: 'US',
  MER: 'US',
  MEV: 'US',
  KMF: 'PG',
  MFD: 'US',
  MFE: 'US',
  MFI: 'US',
  MFR: 'US',
  MFV: 'US',
  MGC: 'US',
  MGE: 'US',
  MGJ: 'US',
  MGM: 'US',
  MGR: 'US',
  MGW: 'US',
  MGY: 'US',
  MHE: 'US',
  MHK: 'US',
  MHL: 'US',
  MHR: 'US',
  MHT: 'US',
  MHV: 'US',
  MIA: 'US',
  MIB: 'US',
  MIE: 'US',
  MIT: 'US',
  MIV: 'US',
  MJX: 'US',
  MKC: 'US',
  MKE: 'US',
  MKG: 'US',
  MKL: 'US',
  MRK: 'US',
  MLB: 'US',
  MLC: 'US',
  MLI: 'US',
  MLS: 'US',
  MLU: 'US',
  KMM: 'ID',
  MMH: 'US',
  MMI: 'US',
  MML: 'US',
  MMS: 'US',
  MMT: 'US',
  MMU: 'US',
  MNM: 'US',
  MNN: 'US',
  MOB: 'US',
  MOD: 'US',
  MOT: 'US',
  RMY: 'US',
  MPJ: 'US',
  MPO: 'US',
  MPV: 'US',
  MPZ: 'US',
  MQB: 'US',
  MEO: 'US',
  CTH: 'US',
  MQY: 'US',
  MRB: 'US',
  MRC: 'US',
  MRF: 'US',
  MRN: 'US',
  MRY: 'US',
  MSL: 'US',
  MSN: 'US',
  MSO: 'US',
  MSP: 'US',
  MSS: 'US',
  MSV: 'US',
  MSY: 'US',
  MTC: 'US',
  MTH: 'US',
  MTJ: 'US',
  MTN: 'US',
  MTO: 'US',
  MTP: 'US',
  MTW: 'US',
  MUI: 'US',
  MUO: 'US',
  MUT: 'US',
  MVC: 'US',
  MVE: 'US',
  MVL: 'US',
  MVY: 'US',
  MWA: 'US',
  MWC: 'US',
  MWH: 'US',
  MWL: 'US',
  MWO: 'US',
  MXA: 'US',
  MXF: 'US',
  KMY: 'US',
  MYF: 'US',
  MYL: 'US',
  MYR: 'US',
  MYV: 'US',
  MZJ: 'US',
  MZZ: 'US',
  CTX: 'US',
  SXY: 'US',
  NBG: 'US',
  NHX: 'US',
  DGN: 'US',
  NEL: 'US',
  NEN: 'US',
  NEW: 'US',
  NFL: 'US',
  FWH: 'US',
  NGU: 'US',
  NHK: 'US',
  NHZ: 'US',
  NIP: 'US',
  NJK: 'US',
  NKX: 'US',
  NLC: 'US',
  NPA: 'US',
  NQA: 'US',
  NQI: 'US',
  NQX: 'US',
  NRB: 'US',
  NRS: 'US',
  NSE: 'US',
  NTD: 'US',
  NTU: 'US',
  NUQ: 'US',
  NUW: 'US',
  YUM: 'US',
  NZY: 'US',
  NVN: 'US',
  COA: 'US',
  ODC: 'US',
  EYR: 'US',
  OAJ: 'US',
  OAK: 'US',
  OAR: 'US',
  OBE: 'US',
  OCF: 'US',
  OCH: 'US',
  OCW: 'US',
  OEA: 'US',
  OEO: 'US',
  OFK: 'US',
  OGA: 'US',
  OGB: 'US',
  OGD: 'US',
  OGS: 'US',
  OIC: 'US',
  OJC: 'US',
  OCN: 'US',
  OKC: 'US',
  ODW: 'US',
  OKK: 'US',
  OKM: 'US',
  OKS: 'US',
  WGO: 'US',
  OLD: 'US',
  OLF: 'US',
  OLM: 'US',
  OLS: 'US',
  OLV: 'US',
  KOM: 'PG',
  OMA: 'US',
  OMK: 'US',
  ONL: 'US',
  ONO: 'US',
  ONP: 'US',
  ONT: 'US',
  OPF: 'US',
  NCO: 'US',
  KOR: 'PG',
  ORD: 'US',
  ORF: 'US',
  ORH: 'US',
  ORL: 'US',
  ESD: 'US',
  OSC: 'US',
  OSH: 'US',
  OSU: 'US',
  OTH: 'US',
  OTM: 'US',
  OUN: 'US',
  OVE: 'US',
  OWA: 'US',
  OWB: 'US',
  OWD: 'US',
  OWK: 'US',
  OCE: 'US',
  OXC: 'US',
  OXD: 'US',
  OXR: 'US',
  KOY: 'US',
  STQ: 'US',
  OZA: 'US',
  OZR: 'US',
  YJS: 'KP',
  RGO: 'KP',
  BSQ: 'US',
  PXL: 'US',
  GLB: 'US',
  HBK: 'US',
  CWX: 'US',
  PAE: 'US',
  PAH: 'US',
  PAM: 'US',
  PJB: 'US',
  PAO: 'US',
  KPB: 'US',
  PBF: 'US',
  PBG: 'US',
  PBI: 'US',
  PVL: 'US',
  PCD: 'US',
  PDK: 'US',
  PDT: 'US',
  PDX: 'US',
  KPE: 'PG',
  PEQ: 'US',
  PGA: 'US',
  PGD: 'US',
  PGR: 'US',
  PGV: 'US',
  PHD: 'US',
  PHF: 'US',
  ADR: 'US',
  PHK: 'US',
  PHL: 'US',
  PHN: 'US',
  PHP: 'US',
  PHT: 'US',
  PHX: 'US',
  PIA: 'US',
  PIB: 'US',
  PIE: 'US',
  PIH: 'US',
  PIM: 'US',
  PIR: 'US',
  PIT: 'US',
  PKB: 'US',
  PKD: 'US',
  PKF: 'US',
  KPL: 'PG',
  PLK: 'US',
  PLN: 'US',
  PLR: 'US',
  PMB: 'US',
  PMD: 'US',
  PMH: 'US',
  PPM: 'US',
  PWY: 'US',
  PNC: 'US',
  PNE: 'US',
  PNN: 'US',
  PNS: 'US',
  POB: 'US',
  POC: 'US',
  POE: 'US',
  POF: 'US',
  POU: 'US',
  POY: 'US',
  PPA: 'US',
  PPF: 'US',
  LPO: 'US',
  PQI: 'US',
  PGL: 'US',
  KPR: 'US',
  PRB: 'US',
  PRC: 'US',
  PRO: 'US',
  PRX: 'US',
  PSC: 'US',
  PSF: 'US',
  PSK: 'US',
  PSM: 'US',
  PSN: 'US',
  PGO: 'US',
  PSP: 'US',
  PSX: 'US',
  PTB: 'US',
  PTK: 'US',
  PTN: 'US',
  PTT: 'US',
  PTV: 'US',
  PTW: 'US',
  PUB: 'US',
  PUC: 'US',
  PUW: 'US',
  PVC: 'US',
  PVD: 'US',
  PVF: 'US',
  PVU: 'US',
  PVW: 'US',
  PWA: 'US',
  PWD: 'US',
  PWK: 'US',
  PWM: 'US',
  PWT: 'US',
  KPY: 'US',
  PYM: 'US',
  KQL: 'PG',
  JCJ: 'KR',
  RAC: 'US',
  RAL: 'US',
  RAP: 'US',
  RBD: 'US',
  RBG: 'US',
  RBL: 'US',
  RBW: 'US',
  RCA: 'US',
  RCK: 'US',
  RCR: 'US',
  RCT: 'US',
  RDD: 'US',
  RDG: 'US',
  RDM: 'US',
  RDR: 'US',
  RDU: 'US',
  REO: 'US',
  RFD: 'US',
  RFG: 'US',
  RHI: 'US',
  RHV: 'US',
  RIC: 'US',
  RIL: 'US',
  RIV: 'US',
  RIW: 'US',
  KRJ: 'PG',
  RKD: 'US',
  RKP: 'US',
  RKS: 'US',
  RKW: 'US',
  RME: 'US',
  RMG: 'US',
  RNC: 'US',
  RND: 'US',
  RNO: 'US',
  RNT: 'US',
  ROA: 'US',
  ROC: 'US',
  ROG: 'US',
  ROW: 'US',
  ROX: 'US',
  RIE: 'US',
  RPX: 'US',
  WBR: 'US',
  RQO: 'US',
  RRL: 'US',
  RRT: 'US',
  RSL: 'US',
  RSN: 'US',
  RST: 'US',
  RSW: 'US',
  RTN: 'US',
  KRU: 'PG',
  SRW: 'US',
  RUT: 'US',
  RED: 'US',
  RVS: 'US',
  RWF: 'US',
  RWI: 'US',
  RWL: 'US',
  RXE: 'US',
  RNZ: 'US',
  AHM: 'US',
  BDY: 'US',
  SUO: 'US',
  MDJ: 'US',
  PRZ: 'US',
  IDH: 'US',
  SAA: 'US',
  SAC: 'US',
  SAD: 'US',
  SAF: 'US',
  SAN: 'US',
  SAR: 'US',
  SAT: 'US',
  SAV: 'US',
  MQT: 'US',
  SBA: 'US',
  SBD: 'US',
  SBM: 'US',
  SBN: 'US',
  SBP: 'US',
  SBS: 'US',
  SBX: 'US',
  SBY: 'US',
  SCB: 'US',
  SCH: 'US',
  SCK: 'US',
  SDF: 'US',
  SCF: 'US',
  SDM: 'US',
  SDY: 'US',
  SEA: 'US',
  SEE: 'US',
  SEF: 'US',
  SEG: 'US',
  SEM: 'US',
  SEP: 'US',
  SER: 'US',
  SDX: 'US',
  SFB: 'US',
  SFF: 'US',
  SFM: 'US',
  SFO: 'US',
  SFZ: 'US',
  SGF: 'US',
  SGH: 'US',
  UST: 'US',
  SGR: 'US',
  SGT: 'US',
  SGU: 'US',
  SHD: 'US',
  SHN: 'US',
  SHR: 'US',
  SHV: 'US',
  SIK: 'US',
  SIV: 'US',
  SJC: 'US',
  SJN: 'US',
  SJT: 'US',
  SKA: 'US',
  SKF: 'US',
  TSM: 'US',
  SLB: 'US',
  SLC: 'US',
  SLE: 'US',
  SLG: 'US',
  SLK: 'US',
  SLN: 'US',
  SLO: 'US',
  SLR: 'US',
  SMD: 'US',
  SME: 'US',
  SMF: 'US',
  SMN: 'US',
  SMO: 'US',
  SUM: 'US',
  SMX: 'US',
  SNA: 'US',
  SNK: 'US',
  SNL: 'US',
  SNS: 'US',
  SNY: 'US',
  SOP: 'US',
  SOW: 'US',
  KSP: 'PG',
  SPA: 'US',
  SPF: 'US',
  SPG: 'US',
  SPI: 'US',
  SPS: 'US',
  SPW: 'US',
  SQI: 'US',
  SQL: 'US',
  SRQ: 'US',
  RUI: 'US',
  SSC: 'US',
  SSF: 'US',
  SSI: 'US',
  STC: 'US',
  STE: 'US',
  STJ: 'US',
  STK: 'US',
  STL: 'US',
  STP: 'US',
  STS: 'US',
  SUA: 'US',
  SUD: 'US',
  SUE: 'US',
  SUN: 'US',
  SUS: 'US',
  SUU: 'US',
  SUW: 'US',
  SUX: 'US',
  SVC: 'US',
  SVE: 'US',
  SVH: 'US',
  SVN: 'US',
  SWF: 'US',
  SWO: 'US',
  SWW: 'US',
  SYI: 'US',
  SYR: 'US',
  SYV: 'US',
  SZL: 'US',
  TBC: 'US',
  TAD: 'US',
  KTB: 'US',
  TBN: 'US',
  TBR: 'US',
  KTC: 'CI',
  TCC: 'US',
  TCL: 'US',
  TCM: 'US',
  TCS: 'US',
  TDO: 'US',
  TDW: 'US',
  TDZ: 'US',
  TEB: 'US',
  TEX: 'US',
  THA: 'US',
  THM: 'US',
  THV: 'US',
  TIK: 'US',
  TIW: 'US',
  TIX: 'US',
  KNT: 'US',
  TLH: 'US',
  TLR: 'US',
  TMA: 'US',
  TMB: 'US',
  OTK: 'US',
  TNP: 'US',
  TNT: 'US',
  TNU: 'US',
  XSD: 'US',
  TOA: 'US',
  TOC: 'US',
  TOI: 'US',
  TOL: 'US',
  TOP: 'US',
  TOR: 'US',
  TPA: 'US',
  TPF: 'US',
  TPH: 'US',
  TPL: 'US',
  TRI: 'US',
  TKF: 'US',
  TRL: 'US',
  TRM: 'US',
  TSP: 'US',
  TTD: 'US',
  TTN: 'US',
  TUL: 'US',
  TUP: 'US',
  TUS: 'US',
  TVC: 'US',
  TVF: 'US',
  TVI: 'US',
  TVL: 'US',
  TWF: 'US',
  TXK: 'US',
  TYZ: 'US',
  TYR: 'US',
  TYS: 'US',
  BFG: 'US',
  NPH: 'US',
  RVR: 'US',
  PNU: 'US',
  ICS: 'US',
  UBS: 'US',
  UCY: 'US',
  UDD: 'US',
  UES: 'US',
  UGN: 'US',
  UIL: 'US',
  UIN: 'US',
  IKB: 'US',
  UKI: 'US',
  UKT: 'US',
  ULM: 'US',
  ATO: 'US',
  UNU: 'US',
  SCE: 'US',
  UOS: 'US',
  UOX: 'US',
  KUP: 'PG',
  UTM: 'US',
  HTV: 'US',
  NPT: 'US',
  UVA: 'US',
  KUX: 'PG',
  RKH: 'US',
  VAD: 'US',
  LLY: 'US',
  VBG: 'US',
  VCT: 'US',
  VCV: 'US',
  VDI: 'US',
  KVE: 'PG',
  VEL: 'US',
  VGT: 'US',
  VHN: 'US',
  VIH: 'US',
  VIS: 'US',
  VJI: 'US',
  VKS: 'US',
  VLA: 'US',
  VLD: 'US',
  VNC: 'US',
  VNY: 'US',
  VOK: 'US',
  VPS: 'US',
  VPZ: 'US',
  VQQ: 'US',
  VRB: 'US',
  VSF: 'US',
  VTN: 'US',
  VYS: 'US',
  GTY: 'US',
  SQV: 'US',
  PGC: 'US',
  WAL: 'US',
  WAY: 'US',
  WBW: 'US',
  WDG: 'US',
  WDR: 'US',
  KWF: 'US',
  WHP: 'US',
  WJF: 'US',
  WLD: 'US',
  WLW: 'US',
  WMC: 'US',
  KWP: 'US',
  WRB: 'US',
  WRI: 'US',
  WRL: 'US',
  WST: 'US',
  WVI: 'US',
  WVL: 'US',
  WWD: 'US',
  WWR: 'US',
  KWY: 'KE',
  WYS: 'US',
  KYO: 'US',
  KXA: 'US',
  XNA: 'US',
  WBK: 'US',
  YIP: 'US',
  YKM: 'US',
  YKN: 'US',
  YNG: 'US',
  BXY: 'KZ',
  USJ: 'KZ',
  ATX: 'KZ',
  KZB: 'US',
  KZF: 'PG',
  ZPH: 'US',
  ZZV: 'US',
  LAC: 'MY',
  KFZ: 'AL',
  TIA: 'AL',
  BOJ: 'BG',
  GOZ: 'BG',
  LBH: 'AU',
  LBM: 'MZ',
  PDV: 'BG',
  ROU: 'BG',
  SOF: 'BG',
  SZR: 'BG',
  VAR: 'BG',
  ECN: 'CY',
  LCA: 'CY',
  LCP: 'AR',
  PFO: 'CY',
  AKT: 'CY',
  DBV: 'HR',
  LSZ: 'HR',
  OSI: 'HR',
  PUY: 'HR',
  RJK: 'HR',
  BWK: 'HR',
  SPU: 'HR',
  LDW: 'AU',
  ZAG: 'HR',
  ZAD: 'HR',
  ABC: 'ES',
  ALC: 'ES',
  LEI: 'ES',
  OVD: 'ES',
  ODB: 'ES',
  BIO: 'ES',
  RGS: 'ES',
  BCN: 'ES',
  BJZ: 'ES',
  CDT: 'ES',
  LCG: 'ES',
  ILD: 'ES',
  GRO: 'ES',
  GRX: 'ES',
  HSK: 'ES',
  IBZ: 'ES',
  XRY: 'ES',
  MJV: 'ES',
  LEN: 'ES',
  RJL: 'ES',
  MAD: 'ES',
  AGP: 'ES',
  MAH: 'ES',
  RMU: 'ES',
  OZP: 'ES',
  LEO: 'GA',
  PMI: 'ES',
  PNA: 'ES',
  CQM: 'ES',
  REU: 'ES',
  ROZ: 'ES',
  SLM: 'ES',
  EAS: 'ES',
  SCQ: 'ES',
  LEU: 'ES',
  TEV: 'ES',
  TOJ: 'ES',
  VLC: 'ES',
  VLL: 'ES',
  VIT: 'ES',
  VGO: 'ES',
  SDR: 'ES',
  ZAZ: 'ES',
  SVQ: 'ES',
  DPE: 'FR',
  CQF: 'FR',
  BYF: 'FR',
  LTQ: 'FR',
  AGF: 'FR',
  BOD: 'FR',
  EGC: 'FR',
  CNG: 'FR',
  LRH: 'FR',
  PIS: 'FR',
  MCU: 'FR',
  LIG: 'FR',
  NIT: 'FR',
  TLS: 'FR',
  PUF: 'FR',
  LDE: 'FR',
  ANG: 'FR',
  PGX: 'FR',
  BIQ: 'FR',
  ZAO: 'FR',
  LBI: 'FR',
  DCM: 'FR',
  RDZ: 'FR',
  RYN: 'FR',
  RCO: 'FR',
  BIC: 'FR',
  OUI: 'FR',
  IDY: 'FR',
  CMR: 'FR',
  DLE: 'FR',
  MVV: 'FR',
  OBS: 'FR',
  LPY: 'FR',
  AHZ: 'FR',
  ETZ: 'FR',
  ANE: 'FR',
  BIA: 'FR',
  CLY: 'FR',
  FSC: 'FR',
  AJA: 'FR',
  PRP: 'FR',
  SOZ: 'FR',
  MFX: 'FR',
  AUF: 'FR',
  CMF: 'FR',
  CFE: 'FR',
  BOU: 'FR',
  CVF: 'FR',
  LYS: 'FR',
  SYT: 'FR',
  RNE: 'FR',
  NCY: 'FR',
  GNB: 'FR',
  VAF: 'FR',
  VHY: 'FR',
  AUR: 'FR',
  CHR: 'FR',
  LYN: 'FR',
  CEQ: 'FR',
  EBU: 'FR',
  CCF: 'FR',
  MRS: 'FR',
  NCE: 'FR',
  PGF: 'FR',
  CTT: 'FR',
  BAE: 'FR',
  MPL: 'FR',
  BZR: 'FR',
  AVN: 'FR',
  GAT: 'FR',
  MEN: 'FR',
  SCP: 'FR',
  BVA: 'FR',
  EVX: 'FR',
  LEH: 'FR',
  XCR: 'FR',
  LSO: 'FR',
  URO: 'FR',
  TUF: 'FR',
  CET: 'FR',
  LVA: 'FR',
  ORE: 'FR',
  LBG: 'FR',
  CSF: 'FR',
  CDG: 'FR',
  TNF: 'FR',
  ORY: 'FR',
  POX: 'FR',
  VIY: 'FR',
  NVS: 'FR',
  LIL: 'FR',
  HZB: 'FR',
  BES: 'FR',
  CER: 'FR',
  DNR: 'FR',
  LBY: 'FR',
  GFR: 'FR',
  DOL: 'FR',
  LRT: 'FR',
  EDM: 'FR',
  LDV: 'FR',
  CFR: 'FR',
  LME: 'FR',
  RNS: 'FR',
  LAI: 'FR',
  UIP: 'FR',
  NTE: 'FR',
  SBK: 'FR',
  MXN: 'FR',
  VNE: 'FR',
  SNR: 'FR',
  BSL: 'FR',
  DIJ: 'FR',
  EPL: 'FR',
  BVE: 'FR',
  ENC: 'FR',
  SXB: 'FR',
  TLN: 'FR',
  RZV: 'TR',
  FNI: 'FR',
  LTT: 'FR',
  MQC: 'PM',
  FSP: 'PM',
  PYR: 'GR',
  AXD: 'GR',
  ATH: 'GR',
  VOL: 'GR',
  LGE: 'AU',
  JKH: 'GR',
  JIK: 'GR',
  IOA: 'GR',
  HER: 'GR',
  KSO: 'GR',
  KIT: 'GR',
  EFL: 'GR',
  KZS: 'GR',
  KLX: 'GR',
  KGS: 'GR',
  AOK: 'GR',
  CFU: 'GR',
  KSJ: 'GR',
  KVA: 'GR',
  JKL: 'GR',
  KZI: 'GR',
  LRS: 'GR',
  LXS: 'GR',
  LRA: 'GR',
  JMK: 'GR',
  MLO: 'GR',
  MJT: 'GR',
  LGN: 'PG',
  JNX: 'GR',
  DRP: 'PH',
  PAS: 'GR',
  JTY: 'GR',
  PVK: 'GR',
  RHO: 'GR',
  GPA: 'GR',
  CHQ: 'GR',
  JSI: 'GR',
  SMI: 'GR',
  JSY: 'GR',
  SPJ: 'GR',
  JTR: 'GR',
  JSH: 'GR',
  SKU: 'GR',
  SKG: 'GR',
  ZTH: 'GR',
  BUD: 'HU',
  DEB: 'HU',
  PEV: 'HU',
  SOB: 'HU',
  QAQ: 'IT',
  CRV: 'IT',
  BRI: 'IT',
  FOG: 'IT',
  TAR: 'IT',
  LCC: 'IT',
  PSR: 'IT',
  BDS: 'IT',
  SUF: 'IT',
  CIY: 'IT',
  CTA: 'IT',
  LMP: 'IT',
  PNL: 'IT',
  PMO: 'IT',
  REG: 'IT',
  TPS: 'IT',
  NSY: 'IT',
  BLX: 'IT',
  RAN: 'IT',
  AHO: 'IT',
  DCI: 'IT',
  CAG: 'IT',
  OLB: 'IT',
  FNU: 'IT',
  TTB: 'IT',
  MXP: 'IT',
  BGY: 'IT',
  TRN: 'IT',
  ALL: 'IT',
  GOA: 'IT',
  LIN: 'IT',
  PMF: 'IT',
  AOT: 'IT',
  CUF: 'IT',
  AVB: 'IT',
  BZO: 'IT',
  UDN: 'IT',
  BLQ: 'IT',
  TSF: 'IT',
  FRL: 'IT',
  VBS: 'IT',
  TRS: 'IT',
  RMI: 'IT',
  VRN: 'IT',
  AOI: 'IT',
  VCE: 'IT',
  LCV: 'IT',
  SAY: 'IT',
  CIA: 'IT',
  FCO: 'IT',
  QSR: 'IT',
  EBA: 'IT',
  NAP: 'IT',
  PSA: 'IT',
  FLR: 'IT',
  GRS: 'IT',
  PEG: 'IT',
  LJU: 'SI',
  MBX: 'SI',
  POW: 'SI',
  LKC: 'CG',
  UHE: 'CZ',
  KLV: 'CZ',
  OSR: 'CZ',
  OLO: 'CZ',
  PED: 'CZ',
  PRV: 'CZ',
  PRG: 'CZ',
  BRQ: 'CZ',
  VOD: 'CZ',
  ZBE: 'CZ',
  TLV: 'IL',
  BEV: 'IL',
  ETM: 'IL',
  EIY: 'IL',
  LLH: 'HN',
  HFA: 'IL',
  RPN: 'IL',
  KSW: 'IL',
  LLL: 'AU',
  MTZ: 'IL',
  VTM: 'IL',
  VDA: 'IL',
  MIP: 'IL',
  YOT: 'IL',
  MLA: 'MT',
  LMV: 'MV',
  LMZ: 'MZ',
  LNC: 'PG',
  LNF: 'PG',
  LNM: 'PG',
  HOH: 'AT',
  LOM: 'MX',
  LOR: 'US',
  GRZ: 'AT',
  INN: 'AT',
  KLU: 'AT',
  LNZ: 'AT',
  SZG: 'AT',
  VIE: 'AT',
  AVR: 'PT',
  SMA: 'PT',
  BGC: 'PT',
  BYJ: 'PT',
  BGZ: 'PT',
  CHV: 'PT',
  CBP: 'PT',
  CVU: 'PT',
  CAT: 'PT',
  FLW: 'PT',
  FAO: 'PT',
  GRW: 'PT',
  HOR: 'PT',
  TER: 'PT',
  FNC: 'PT',
  QLR: 'PT',
  PDL: 'PT',
  PIX: 'PT',
  PRM: 'PT',
  OPO: 'PT',
  PXO: 'PT',
  LIS: 'PT',
  SJZ: 'PT',
  VRL: 'PT',
  VSE: 'PT',
  BNX: 'BA',
  OMO: 'BA',
  SJJ: 'BA',
  TZL: 'BA',
  ARW: 'RO',
  BCM: 'RO',
  BAY: 'RO',
  BBU: 'RO',
  CND: 'RO',
  CLJ: 'RO',
  CSB: 'RO',
  CRA: 'RO',
  IAS: 'RO',
  OMR: 'RO',
  OTP: 'RO',
  GHV: 'RO',
  SBZ: 'RO',
  SUJ: 'RO',
  SCV: 'RO',
  TCE: 'RO',
  TGM: 'RO',
  TSR: 'RO',
  GVA: 'CH',
  SIR: 'CH',
  EML: 'CH',
  LUG: 'CH',
  BRN: 'CH',
  BXO: 'CH',
  ZRH: 'CH',
  ACH: 'CH',
  SMV: 'CH',
  GKD: 'TR',
  ESB: 'TR',
  ANK: 'TR',
  ADA: 'TR',
  UAB: 'TR',
  AFY: 'TR',
  AYT: 'TR',
  GZT: 'TR',
  KFS: 'TR',
  KYA: 'TR',
  MZH: 'TR',
  VAS: 'TR',
  ONQ: 'TR',
  MLX: 'TR',
  ASR: 'TR',
  TJK: 'TR',
  DNZ: 'TR',
  NAV: 'TR',
  ISL: 'TR',
  CII: 'TR',
  BZI: 'TR',
  BDM: 'TR',
  CKZ: 'TR',
  ESK: 'TR',
  ADB: 'TR',
  IGL: 'TR',
  USQ: 'TR',
  KCO: 'TR',
  YEI: 'TR',
  DLM: 'TR',
  TEQ: 'TR',
  BXN: 'TR',
  AOE: 'TR',
  KZR: 'TR',
  EZS: 'TR',
  OGU: 'TR',
  DIY: 'TR',
  ERC: 'TR',
  ERZ: 'TR',
  KSY: 'TR',
  TZX: 'TR',
  VAN: 'TR',
  BAL: 'TR',
  MSR: 'TR',
  SXZ: 'TR',
  NOP: 'TR',
  KCM: 'TR',
  AJI: 'TR',
  ADF: 'TR',
  MQM: 'TR',
  GNY: 'TR',
  IGD: 'TR',
  NKT: 'TR',
  YKO: 'TR',
  HTY: 'TR',
  LTF: 'PG',
  ISE: 'TR',
  EDO: 'TR',
  BJV: 'TR',
  GZP: 'TR',
  SZF: 'TR',
  SAW: 'TR',
  IST: 'TR',
  BZY: 'MD',
  KIV: 'MD',
  LWA: 'PH',
  OHD: 'MK',
  SKP: 'MK',
  GIB: 'GI',
  BCQ: 'LY',
  DNF: 'LY',
  MRA: 'LY',
  QUB: 'LY',
  UZC: 'RS',
  BEG: 'RS',
  IVG: 'ME',
  BJY: 'RS',
  KVO: 'RS',
  INI: 'RS',
  TGD: 'ME',
  TIV: 'ME',
  LZG: 'CN',
  BTS: 'SK',
  KSC: 'SK',
  LUE: 'SK',
  PZY: 'SK',
  POV: 'SK',
  SLD: 'SK',
  TAT: 'SK',
  ILZ: 'SK',
  DRU: 'US',
  GLN: 'MA',
  UWA: 'US',
  MAP: 'PG',
  GDT: 'TC',
  MDS: 'TC',
  NCA: 'TC',
  PIC: 'TC',
  PLS: 'TC',
  XSC: 'TC',
  SLX: 'TC',
  BRX: 'DO',
  CBJ: 'DO',
  AZS: 'DO',
  COZ: 'DO',
  JBQ: 'DO',
  LRM: 'DO',
  PUJ: 'DO',
  POP: 'DO',
  SDQ: 'DO',
  STI: 'DO',
  MDV: 'GQ',
  LIZ: 'US',
  MEF: 'TD',
  OHB: 'MG',
  NKO: 'MG',
  DOA: 'MG',
  CBV: 'GT',
  CMM: 'GT',
  CTF: 'GT',
  GUA: 'GT',
  HUG: 'GT',
  MGP: 'PG',
  PBR: 'GT',
  PON: 'GT',
  AQB: 'GT',
  AAZ: 'GT',
  RUV: 'GT',
  LCF: 'GT',
  RER: 'GT',
  GSJ: 'GT',
  FRS: 'GT',
  AIM: 'MH',
  AUL: 'MH',
  BII: 'MH',
  EBN: 'MH',
  JAT: 'MH',
  JEJ: 'MH',
  LIK: 'MH',
  LML: 'MH',
  MAV: 'MH',
  MJB: 'MH',
  MJE: 'MH',
  NDK: 'MH',
  RNP: 'MH',
  TIC: 'MH',
  UIT: 'MH',
  WJA: 'MH',
  WTE: 'MH',
  WTO: 'MH',
  AHS: 'HN',
  BHG: 'HN',
  CAA: 'HN',
  LUI: 'HN',
  CYL: 'HN',
  CDD: 'HN',
  OAN: 'HN',
  GAC: 'HN',
  IRN: 'HN',
  GUO: 'HN',
  JUT: 'HN',
  LCE: 'HN',
  LEZ: 'HN',
  SAP: 'HN',
  MHN: 'US',
  GJA: 'HN',
  PCH: 'HN',
  PEU: 'HN',
  RTB: 'HN',
  RUY: 'HN',
  XPL: 'HN',
  TEA: 'HN',
  TGU: 'HN',
  TJI: 'HN',
  TCF: 'HN',
  SCD: 'HN',
  UII: 'HN',
  MHY: 'PG',
  ORO: 'HN',
  MIZ: 'AU',
  MJJ: 'PG',
  MJS: 'MZ',
  OCJ: 'JM',
  KIN: 'JM',
  MBJ: 'JM',
  POT: 'JM',
  MKN: 'PG',
  NEG: 'JM',
  KTP: 'JM',
  MIJ: 'MH',
  MLQ: 'PG',
  HEB: 'MM',
  HEH: 'MM',
  TZM: 'MX',
  ACA: 'MX',
  NTR: 'MX',
  AGU: 'MX',
  HUX: 'MX',
  CNA: 'MX',
  CVJ: 'MX',
  ACN: 'MX',
  CME: 'MX',
  NCG: 'MX',
  CUL: 'MX',
  CTM: 'MX',
  CEN: 'MX',
  CJT: 'MX',
  CPE: 'MX',
  CJS: 'MX',
  CZA: 'MX',
  CUU: 'MX',
  CVM: 'MX',
  CYW: 'MX',
  CZM: 'MX',
  CUA: 'MX',
  MMC: 'MX',
  DGO: 'MX',
  TPQ: 'MX',
  ESE: 'MX',
  GDL: 'MX',
  GYM: 'MX',
  GUB: 'MX',
  TCN: 'MX',
  HMO: 'MX',
  CLQ: 'MX',
  ISJ: 'MX',
  SLW: 'MX',
  IZT: 'MX',
  JAL: 'MX',
  AZP: 'MX',
  LZC: 'MX',
  LMM: 'MX',
  BJX: 'MX',
  LAP: 'MX',
  LTO: 'MX',
  MAM: 'MX',
  MID: 'MX',
  MUG: 'MX',
  MXL: 'MX',
  MLM: 'MX',
  MTT: 'MX',
  LOV: 'MX',
  MEX: 'MX',
  MTY: 'MX',
  MZT: 'MX',
  NOG: 'MX',
  NLD: 'MX',
  OAX: 'MX',
  PAZ: 'MX',
  PBC: 'MX',
  PDS: 'MX',
  PCO: 'MX',
  UPN: 'MX',
  PQM: 'MX',
  PVR: 'MX',
  PXM: 'MX',
  QRO: 'MX',
  REX: 'MX',
  SJD: 'MX',
  SFH: 'MX',
  CSL: 'MX',
  NLU: 'MX',
  SLP: 'MX',
  TRC: 'MX',
  TGZ: 'MX',
  TIJ: 'MX',
  TAM: 'MX',
  TSL: 'MX',
  TLC: 'MX',
  TAP: 'MX',
  CUN: 'MX',
  MMV: 'PG',
  VSA: 'MX',
  VER: 'MX',
  ZCL: 'MX',
  ZIH: 'MX',
  ZMM: 'MX',
  ZLO: 'MX',
  MXW: 'MN',
  BEF: 'NI',
  BZA: 'NI',
  ECI: 'NI',
  RNI: 'NI',
  MGA: 'NI',
  PUZ: 'NI',
  RFS: 'NI',
  NCR: 'NI',
  SIU: 'NI',
  WSP: 'NI',
  MOH: 'ID',
  MOS: 'US',
  BOC: 'PA',
  CTD: 'PA',
  CHX: 'PA',
  DAV: 'PA',
  ONX: 'PA',
  MPG: 'PG',
  BLB: 'PA',
  MPI: 'PA',
  JQE: 'PA',
  PAC: 'PA',
  PUE: 'PA',
  PDM: 'PA',
  RIH: 'PA',
  SYP: 'PA',
  PTY: 'PA',
  MPU: 'PG',
  PVE: 'PA',
  NBL: 'PA',
  MPX: 'PG',
  MQO: 'PG',
  FON: 'CR',
  TTQ: 'CR',
  BAI: 'CR',
  BCL: 'CR',
  OTR: 'CR',
  JAP: 'CR',
  PLD: 'CR',
  DRK: 'CR',
  FMG: 'CR',
  GLF: 'CR',
  GPL: 'CR',
  PBP: 'CR',
  LIR: 'CR',
  LSL: 'CR',
  LIO: 'CR',
  CSC: 'CR',
  NCT: 'CR',
  NOB: 'CR',
  SJO: 'CR',
  PJM: 'CR',
  PMZ: 'CR',
  SYQ: 'CR',
  XQP: 'CR',
  RFR: 'CR',
  IPZ: 'CR',
  TOO: 'CR',
  MRT: 'AU',
  TNO: 'CR',
  TMU: 'CR',
  UPL: 'CR',
  MSB: 'MF',
  SAL: 'SV',
  ILS: 'SV',
  CYA: 'HT',
  CAP: 'HT',
  MTX: 'US',
  JAK: 'HT',
  JEE: 'HT',
  PAP: 'HT',
  PAX: 'HT',
  MTU: 'MZ',
  BCA: 'CU',
  BWW: 'CU',
  BYM: 'CU',
  AVI: 'CU',
  CCC: 'CU',
  CFG: 'CU',
  CYO: 'CU',
  CMW: 'CU',
  SCU: 'CU',
  NBW: 'CU',
  GAO: 'CU',
  HAV: 'CU',
  HOG: 'CU',
  VRO: 'CU',
  LCL: 'CU',
  UMA: 'CU',
  MJG: 'CU',
  MOA: 'CU',
  MZO: 'CU',
  ICR: 'CU',
  GER: 'CU',
  UPB: 'CU',
  SNU: 'CU',
  SNJ: 'CU',
  SZJ: 'CU',
  USS: 'CU',
  TND: 'CU',
  VRA: 'CU',
  VTU: 'CU',
  FMT: 'MV',
  CYB: 'KY',
  LYB: 'KY',
  GCM: 'KY',
  MWR: 'ZA',
  MWU: 'PG',
  AJS: 'MX',
  AZG: 'MX',
  PCM: 'MX',
  PCV: 'MX',
  SCX: 'MX',
  SGM: 'MX',
  TUY: 'MX',
  UAC: 'MX',
  XAL: 'MX',
  MXC: 'US',
  MXK: 'PG',
  GTK: 'MY',
  LBP: 'MY',
  LLM: 'MY',
  MZS: 'MY',
  SPT: 'MY',
  MAY: 'BS',
  ASD: 'BS',
  TZN: 'BS',
  MHH: 'BS',
  SAQ: 'BS',
  AXP: 'BS',
  TCB: 'BS',
  WKR: 'BS',
  CCZ: 'BS',
  GHC: 'BS',
  BIM: 'BS',
  ATC: 'BS',
  TBI: 'BS',
  CXY: 'BS',
  CRI: 'BS',
  PWN: 'BS',
  GGT: 'BS',
  ELH: 'BS',
  GHB: 'BS',
  NMC: 'BS',
  RSD: 'BS',
  TYM: 'BS',
  FPO: 'BS',
  WTD: 'BS',
  IGA: 'BS',
  MYK: 'US',
  LGI: 'BS',
  SML: 'BS',
  MYG: 'BS',
  NAS: 'BS',
  DCT: 'BS',
  RCY: 'BS',
  MYS: 'ET',
  ZSA: 'BS',
  MYX: 'PG',
  NTC: 'MZ',
  IBO: 'MZ',
  TGS: 'MZ',
  BZE: 'BZ',
  MZE: 'BZ',
  CYD: 'BZ',
  IMI: 'MH',
  BQI: 'NA',
  NBS: 'CN',
  AIT: 'CK',
  AIU: 'CK',
  MGS: 'CK',
  MHX: 'CK',
  MUK: 'CK',
  MOI: 'CK',
  PZK: 'CK',
  PYE: 'CK',
  RAR: 'CK',
  NDI: 'PG',
  NDN: 'PG',
  EPG: 'US',
  ICI: 'FJ',
  CST: 'FJ',
  NAN: 'FJ',
  PTF: 'FJ',
  RBI: 'FJ',
  KDV: 'FJ',
  MNF: 'FJ',
  MFJ: 'FJ',
  SUV: 'FJ',
  LEV: 'FJ',
  NGI: 'FJ',
  LUC: 'FJ',
  LKB: 'FJ',
  LBS: 'FJ',
  TVU: 'FJ',
  KXF: 'FJ',
  RTA: 'FJ',
  SVU: 'FJ',
  KAY: 'FJ',
  ONU: 'FJ',
  YAS: 'FJ',
  EUA: 'TO',
  TBU: 'TO',
  HPA: 'TO',
  NFO: 'TO',
  NTT: 'TO',
  VAV: 'TO',
  TTL: 'FJ',
  VBV: 'FJ',
  VTF: 'FJ',
  GMO: 'NG',
  PHG: 'NG',
  QRW: 'NG',
  ABF: 'KI',
  BEZ: 'KI',
  FUN: 'TV',
  KUC: 'KI',
  MNK: 'KI',
  MZK: 'KI',
  MTK: 'KI',
  NIG: 'KI',
  OOT: 'KI',
  TRW: 'KI',
  AEA: 'KI',
  TBF: 'KI',
  TMN: 'KI',
  NON: 'KI',
  AIS: 'KI',
  TSU: 'KI',
  BBG: 'KI',
  AAK: 'KI',
  NIK: 'SN',
  IUE: 'NU',
  NJJ: 'CN',
  NKD: 'ID',
  NLH: 'CN',
  FUT: 'WF',
  WLS: 'WF',
  HBB: 'US',
  NND: 'MZ',
  NOM: 'PG',
  NOO: 'PG',
  NPG: 'PG',
  NRY: 'AU',
  OFU: 'AS',
  AAU: 'WS',
  APW: 'WS',
  FTI: 'AS',
  MXS: 'WS',
  PPG: 'AS',
  PPT: 'PF',
  RMT: 'PF',
  RUR: 'PF',
  TUB: 'PF',
  RVV: 'PF',
  AAA: 'PF',
  FGU: 'PF',
  TIH: 'PF',
  APK: 'PF',
  REA: 'PF',
  FAV: 'PF',
  HHZ: 'PF',
  XMH: 'PF',
  GMR: 'PF',
  KKR: 'PF',
  MKP: 'PF',
  NAU: 'PF',
  TKV: 'PF',
  PKP: 'PF',
  PUK: 'PF',
  TKP: 'PF',
  AXR: 'PF',
  MVT: 'PF',
  NUK: 'PF',
  ZTA: 'PF',
  AHE: 'PF',
  KHZ: 'PF',
  FAC: 'PF',
  FHZ: 'PF',
  RKA: 'PF',
  TJN: 'PF',
  NIU: 'PF',
  RRR: 'PF',
  TKX: 'PF',
  KXU: 'PF',
  NKP: 'PF',
  NHV: 'PF',
  AUQ: 'PF',
  UAP: 'PF',
  UAH: 'PF',
  BOB: 'PF',
  TTI: 'PF',
  RGI: 'PF',
  HUH: 'PF',
  MOZ: 'PF',
  HOI: 'PF',
  MAU: 'PF',
  RFP: 'PF',
  TPX: 'PF',
  UOA: 'PF',
  VHZ: 'PF',
  NUF: 'LK',
  NUG: 'PG',
  UCC: 'US',
  MTV: 'VU',
  SLH: 'VU',
  TOH: 'VU',
  EAE: 'VU',
  CCV: 'VU',
  LOD: 'VU',
  SSR: 'VU',
  PBJ: 'VU',
  LPM: 'VU',
  LNB: 'VU',
  MWF: 'VU',
  LNE: 'VU',
  NUS: 'VU',
  ZGU: 'VU',
  RCL: 'VU',
  SON: 'VU',
  TGH: 'VU',
  ULB: 'VU',
  VLS: 'VU',
  WLH: 'VU',
  SWJ: 'VU',
  OLJ: 'VU',
  AUY: 'VU',
  AWD: 'VU',
  DLY: 'VU',
  FTA: 'VU',
  IPA: 'VU',
  VLI: 'VU',
  TAH: 'VU',
  NWT: 'PG',
  TGJ: 'NC',
  BMY: 'NC',
  KNQ: 'NC',
  ILP: 'NC',
  HLU: 'NC',
  KOC: 'NC',
  LIF: 'NC',
  GEA: 'NC',
  PUV: 'NC',
  PDC: 'NC',
  MEE: 'NC',
  TOU: 'NC',
  UVE: 'NC',
  NOU: 'NC',
  FGL: 'NZ',
  AKL: 'NZ',
  TUO: 'NZ',
  AMZ: 'NZ',
  ASG: 'NZ',
  CHC: 'NZ',
  CHT: 'NZ',
  CMV: 'NZ',
  DGR: 'NZ',
  DUD: 'NZ',
  WHO: 'NZ',
  GBZ: 'NZ',
  GMN: 'NZ',
  GIS: 'NZ',
  GTN: 'NZ',
  HKK: 'NZ',
  HLZ: 'NZ',
  WIK: 'NZ',
  KBZ: 'NZ',
  KKE: 'NZ',
  KKO: 'NZ',
  KAT: 'NZ',
  ALR: 'NZ',
  MTA: 'NZ',
  MHB: 'NZ',
  MON: 'NZ',
  MFN: 'NZ',
  MZP: 'NZ',
  TEU: 'NZ',
  MRO: 'NZ',
  NPL: 'NZ',
  NPE: 'NZ',
  NSN: 'NZ',
  IVC: 'NZ',
  OHA: 'NZ',
  OAM: 'NZ',
  PMR: 'NZ',
  PCN: 'NZ',
  PPQ: 'NZ',
  ZQN: 'NZ',
  RAG: 'NZ',
  SZS: 'NZ',
  ROT: 'NZ',
  TRG: 'NZ',
  TMZ: 'NZ',
  KTF: 'NZ',
  TKZ: 'NZ',
  THH: 'NZ',
  TIU: 'NZ',
  TWZ: 'NZ',
  BHE: 'NZ',
  WKA: 'NZ',
  WHK: 'NZ',
  WLG: 'NZ',
  WIR: 'NZ',
  WRE: 'NZ',
  WSZ: 'NZ',
  WTZ: 'NZ',
  WAG: 'NZ',
  NLN: 'US',
  BZF: 'US',
  OAA: 'AF',
  BIN: 'AF',
  BST: 'AF',
  CCN: 'AF',
  SBF: 'AF',
  DAZ: 'AF',
  FAH: 'AF',
  FBD: 'AF',
  GRG: 'AF',
  KWH: 'AF',
  HEA: 'AF',
  OAI: 'AF',
  JAA: 'AF',
  KBL: 'AF',
  KDH: 'AF',
  MMZ: 'AF',
  MZR: 'AF',
  URN: 'AF',
  LQN: 'AF',
  OAS: 'AF',
  OAH: 'AF',
  SGA: 'AF',
  TII: 'AF',
  TQN: 'AF',
  UND: 'AF',
  OAZ: 'AF',
  ZAJ: 'AF',
  BAH: 'BH',
  OCS: 'GQ',
  AHB: 'SA',
  HOF: 'SA',
  ABT: 'SA',
  BHH: 'SA',
  DMM: 'SA',
  DWD: 'SA',
  DHA: 'SA',
  GIZ: 'SA',
  ELQ: 'SA',
  URY: 'SA',
  HAS: 'SA',
  QJB: 'SA',
  JED: 'SA',
  KMC: 'SA',
  KMX: 'SA',
  MED: 'SA',
  EAM: 'SA',
  NUM: 'SA',
  AQI: 'SA',
  AKH: 'SA',
  RAH: 'SA',
  RUH: 'SA',
  RAE: 'SA',
  SHW: 'SA',
  AJF: 'SA',
  SLF: 'SA',
  TUU: 'SA',
  TIF: 'SA',
  TUI: 'SA',
  WAE: 'SA',
  EJH: 'SA',
  YNB: 'SA',
  ZUL: 'SA',
  OGE: 'PG',
  OGM: 'PA',
  IAQ: 'IR',
  ABD: 'IR',
  DEF: 'IR',
  AKW: 'IR',
  GCH: 'IR',
  QMJ: 'IR',
  OMI: 'IR',
  MRX: 'IR',
  AWZ: 'IR',
  AEU: 'IR',
  BUZ: 'IR',
  KNR: 'IR',
  KIH: 'IR',
  BDH: 'IR',
  PGU: 'IR',
  KHK: 'IR',
  SXI: 'IR',
  LVP: 'IR',
  KSH: 'IR',
  IIL: 'IR',
  KHD: 'IR',
  SDG: 'IR',
  IFH: 'IR',
  KKS: 'IR',
  IFN: 'IR',
  CQD: 'IR',
  RAS: 'IR',
  HDM: 'IR',
  AJK: 'IR',
  NUJ: 'IR',
  IKA: 'IR',
  THR: 'IR',
  GZW: 'IR',
  PYK: 'IR',
  SNX: 'IR',
  BND: 'IR',
  JYR: 'IR',
  KER: 'IR',
  BXR: 'IR',
  HDR: 'IR',
  GSM: 'IR',
  RJN: 'IR',
  SYJ: 'IR',
  XBJ: 'IR',
  CKT: 'IR',
  RUD: 'IR',
  MHD: 'IR',
  BJB: 'IR',
  AFZ: 'IR',
  TCX: 'IR',
  KLM: 'IR',
  GBT: 'IR',
  BSM: 'IR',
  NSH: 'IR',
  RZR: 'IR',
  SRY: 'IR',
  FAZ: 'IR',
  JAR: 'IR',
  LRR: 'IR',
  LFM: 'IR',
  SYZ: 'IR',
  YES: 'IR',
  KHY: 'IR',
  ADU: 'IR',
  ACP: 'IR',
  PFQ: 'IR',
  OMH: 'IR',
  TBZ: 'IR',
  IMQ: 'IR',
  JWN: 'IR',
  AZD: 'IR',
  ACZ: 'IR',
  ZBR: 'IR',
  ZAH: 'IR',
  IHR: 'IR',
  JSK: 'IR',
  AMM: 'JO',
  ADJ: 'JO',
  AQJ: 'JO',
  OMF: 'JO',
  XIJ: 'KW',
  KWI: 'KW',
  OKV: 'PG',
  BEY: 'LB',
  OLH: 'US',
  KYE: 'LB',
  OLQ: 'PG',
  BYB: 'OM',
  AOM: 'OM',
  DQM: 'OM',
  MNH: 'OM',
  AUH: 'AE',
  AZI: 'AE',
  AAN: 'AE',
  DHF: 'AE',
  XSB: 'AE',
  DXB: 'AE',
  NHD: 'AE',
  DWC: 'AE',
  FJR: 'AE',
  OMN: 'IN',
  RKT: 'AE',
  SHJ: 'AE',
  OMY: 'KH',
  ONB: 'PG',
  RMB: 'OM',
  FAU: 'OM',
  RNM: 'OM',
  JNJ: 'OM',
  KHS: 'OM',
  LKW: 'OM',
  MSH: 'OM',
  MCT: 'OM',
  OMM: 'OM',
  SLL: 'OM',
  OHS: 'OM',
  TTH: 'OM',
  DDU: 'PK',
  BHW: 'PK',
  BNP: 'PK',
  WGB: 'PK',
  BHV: 'PK',
  CJL: 'PK',
  CHB: 'PK',
  DBA: 'PK',
  DEA: 'PK',
  DSK: 'PK',
  LYP: 'PK',
  GWD: 'PK',
  GIL: 'PK',
  ISB: 'PK',
  JAG: 'PK',
  JIW: 'PK',
  KHI: 'PK',
  HDD: 'PK',
  KDD: 'PK',
  OHT: 'PK',
  LHE: 'PK',
  LRG: 'PK',
  XJM: 'PK',
  MFG: 'PK',
  MWD: 'PK',
  MJD: 'PK',
  MPD: 'PK',
  ATG: 'PK',
  MUX: 'PK',
  WNS: 'PK',
  ORW: 'PK',
  PAJ: 'PK',
  PJG: 'PK',
  PSI: 'PK',
  PEW: 'PK',
  UET: 'PK',
  RYK: 'PK',
  RAZ: 'PK',
  SBQ: 'PK',
  KDU: 'PK',
  SKZ: 'PK',
  SYW: 'PK',
  SGI: 'PK',
  SDT: 'PK',
  SKT: 'PK',
  SUL: 'PK',
  RZS: 'PK',
  TLB: 'PK',
  BDN: 'PK',
  TFT: 'PK',
  TUK: 'PK',
  WAF: 'PK',
  PZH: 'PK',
  IQA: 'IQ',
  TQD: 'IQ',
  BMN: 'IQ',
  BGW: 'IQ',
  OSM: 'IQ',
  EBL: 'IQ',
  ORI: 'US',
  KIK: 'IQ',
  BSR: 'IQ',
  NJF: 'IQ',
  RQW: 'IQ',
  ISU: 'IQ',
  XNH: 'IQ',
  ALP: 'SY',
  DAM: 'SY',
  DEZ: 'SY',
  OSE: 'PG',
  OSG: 'PG',
  KAC: 'SY',
  LTK: 'SY',
  PMS: 'SY',
  DIA: 'QA',
  XJD: 'QA',
  DOH: 'QA',
  OTT: 'BR',
  OUM: 'TD',
  OXO: 'AU',
  ADE: 'YE',
  EAB: 'YE',
  AXK: 'YE',
  BYD: 'YE',
  BHN: 'YE',
  BUK: 'YE',
  AAY: 'YE',
  HOD: 'YE',
  KAM: 'YE',
  MYN: 'YE',
  UKR: 'YE',
  IHN: 'YE',
  RIY: 'YE',
  SYE: 'YE',
  SAH: 'YE',
  SCT: 'YE',
  GXF: 'YE',
  TAI: 'YE',
  OZG: 'MA',
  ACU: 'PA',
  AIL: 'PA',
  CTE: 'PA',
  MPP: 'PA',
  PYC: 'PA',
  NMG: 'PA',
  PYV: 'PA',
  AML: 'PA',
  BFQ: 'PA',
  ELE: 'PA',
  OTD: 'PA',
  SAX: 'PA',
  AKB: 'US',
  PML: 'US',
  PTD: 'US',
  PAQ: 'US',
  BTI: 'US',
  BET: 'US',
  BVU: 'US',
  BIG: 'US',
  BKC: 'US',
  BMX: 'US',
  BRW: 'US',
  BTT: 'US',
  CDB: 'US',
  CEM: 'US',
  CIK: 'US',
  CYF: 'US',
  SCM: 'US',
  IRC: 'US',
  CDV: 'US',
  CXF: 'US',
  CYT: 'US',
  CZF: 'US',
  DRG: 'US',
  RDB: 'US',
  ADK: 'US',
  DLG: 'US',
  MLL: 'US',
  ADQ: 'US',
  DUT: 'US',
  KKH: 'US',
  EDF: 'US',
  EEK: 'US',
  EAA: 'US',
  EHM: 'US',
  EIL: 'US',
  ELV: 'US',
  EMK: 'US',
  ENA: 'US',
  FAI: 'US',
  FBK: 'US',
  ABL: 'US',
  FRN: 'US',
  NIB: 'US',
  FWL: 'US',
  GAL: 'US',
  GBH: 'US',
  KWK: 'US',
  SHG: 'US',
  GKN: 'US',
  GLV: 'US',
  GAM: 'US',
  AGN: 'US',
  BGQ: 'US',
  GST: 'US',
  NME: 'US',
  SGY: 'US',
  HCR: 'US',
  HSL: 'US',
  HNS: 'US',
  HOM: 'US',
  HPB: 'US',
  HUS: 'US',
  SHX: 'US',
  HYG: 'US',
  IGG: 'US',
  EGX: 'US',
  IAN: 'US',
  ILI: 'US',
  UTO: 'US',
  MCL: 'US',
  WAA: 'US',
  JNU: 'US',
  KGK: 'US',
  KDK: 'US',
  KFP: 'US',
  AKK: 'US',
  KPN: 'US',
  KKA: 'US',
  LKK: 'US',
  AKN: 'US',
  IKO: 'US',
  AKP: 'US',
  KTN: 'US',
  UUK: 'US',
  KAL: 'US',
  KLW: 'US',
  KYK: 'US',
  KLN: 'US',
  KLG: 'US',
  WCR: 'US',
  LUR: 'US',
  KMO: 'US',
  MCG: 'US',
  MDO: 'US',
  LMA: 'US',
  SMK: 'US',
  MLY: 'US',
  MTM: 'US',
  MOU: 'US',
  MRI: 'US',
  MXY: 'US',
  MYU: 'US',
  WNA: 'US',
  ANC: 'US',
  ANI: 'US',
  ENN: 'US',
  NNL: 'US',
  FNR: 'US',
  ANN: 'US',
  NUL: 'US',
  ANV: 'US',
  KNW: 'US',
  OBU: 'US',
  PCA: 'US',
  HNH: 'US',
  OME: 'US',
  OOK: 'US',
  ORT: 'US',
  OTZ: 'US',
  NLG: 'US',
  STG: 'US',
  KPC: 'US',
  KPV: 'US',
  PSG: 'US',
  PTH: 'US',
  PKA: 'US',
  PTU: 'US',
  PIP: 'US',
  PHO: 'US',
  PPC: 'US',
  KWN: 'US',
  NUI: 'US',
  ARC: 'US',
  RSH: 'US',
  RBY: 'US',
  SVA: 'US',
  SCC: 'US',
  SDP: 'US',
  SHH: 'US',
  SIT: 'US',
  WLK: 'US',
  SLQ: 'US',
  KSM: 'US',
  SNP: 'US',
  SOV: 'US',
  SMU: 'US',
  UMM: 'US',
  SVW: 'US',
  SKW: 'US',
  SXQ: 'US',
  SYA: 'US',
  TAL: 'US',
  TNC: 'US',
  TLA: 'US',
  TOG: 'US',
  TKA: 'US',
  TLJ: 'US',
  ATK: 'US',
  AUK: 'US',
  UMT: 'US',
  UNK: 'US',
  WOW: 'US',
  KQA: 'US',
  VAK: 'US',
  KVC: 'US',
  VDZ: 'US',
  VEE: 'US',
  KVL: 'US',
  WBQ: 'US',
  SWD: 'US',
  WRG: 'US',
  AIN: 'US',
  WMO: 'US',
  WTK: 'US',
  WWA: 'US',
  YAK: 'US',
  CIS: 'KI',
  PCQ: 'LA',
  PDI: 'PG',
  PDR: 'BR',
  PEB: 'MZ',
  PEC: 'US',
  AKI: 'US',
  AET: 'US',
  PFC: 'US',
  NCN: 'US',
  CLP: 'US',
  ELI: 'US',
  KUK: 'US',
  KNK: 'US',
  KOT: 'US',
  KTS: 'US',
  KYU: 'US',
  KWT: 'US',
  ORV: 'US',
  SKK: 'US',
  TKJ: 'US',
  WSN: 'US',
  FYU: 'US',
  CPN: 'PG',
  EMI: 'PG',
  ERE: 'PG',
  ESA: 'PG',
  GAR: 'PG',
  GOE: 'PG',
  BPD: 'PG',
  BPK: 'PG',
  MZN: 'PG',
  SGK: 'PG',
  KII: 'PG',
  SMP: 'PG',
  AKG: 'PG',
  TAJ: 'PG',
  AWB: 'PG',
  BAA: 'PG',
  CVB: 'PG',
  GMI: 'PG',
  GVI: 'PG',
  HYF: 'PG',
  IHU: 'PG',
  IIS: 'PG',
  JAQ: 'PG',
  KDR: 'PG',
  KKD: 'PG',
  KUY: 'PG',
  KWO: 'PG',
  LMI: 'PG',
  LMY: 'PG',
  OBX: 'PG',
  OPU: 'PG',
  SKC: 'PG',
  TFI: 'PG',
  TFM: 'PG',
  TLO: 'PG',
  UKU: 'PG',
  ULE: 'PG',
  VMU: 'PG',
  WPM: 'PG',
  PGE: 'PG',
  PGM: 'US',
  ROP: 'MP',
  SPN: 'MP',
  UAM: 'GU',
  GUM: 'GU',
  TIQ: 'MP',
  CGY: 'PH',
  LLC: 'PH',
  ENI: 'PH',
  BKH: 'US',
  HDH: 'US',
  HHI: 'US',
  HNM: 'US',
  HIK: 'US',
  JHM: 'US',
  JRF: 'US',
  KOA: 'US',
  LIH: 'US',
  LUP: 'US',
  MKK: 'US',
  MUE: 'US',
  NGF: 'US',
  HNL: 'US',
  LNY: 'US',
  OGG: 'US',
  PAK: 'US',
  BSF: 'US',
  ITO: 'US',
  UPP: 'US',
  BHC: 'PK',
  CWP: 'PK',
  GRT: 'PK',
  KCF: 'PK',
  REQ: 'PK',
  ENT: 'MH',
  MAJ: 'MH',
  KAI: 'GY',
  KWA: 'MH',
  CXI: 'KI',
  PLE: 'PG',
  TNV: 'KI',
  TNQ: 'KI',
  MDY: 'UM',
  PMP: 'PG',
  PIZ: 'US',
  PPX: 'PG',
  PQD: 'LK',
  HUC: 'PR',
  XSO: 'PH',
  TKK: 'FM',
  PNI: 'FM',
  ROR: 'PW',
  KSA: 'FM',
  YAP: 'FM',
  PUA: 'PG',
  AWK: 'UM',
  PWR: 'US',
  BFA: 'PY',
  OLK: 'PY',
  PBT: 'PY',
  PCJ: 'PY',
  PYL: 'US',
  KIO: 'MH',
  QFX: 'GL',
  QJP: 'KR',
  RAA: 'PG',
  RAW: 'PG',
  RAX: 'PG',
  RBP: 'PG',
  KNH: 'TW',
  LZN: 'TW',
  TTT: 'TW',
  GNI: 'TW',
  KHH: 'TW',
  CYI: 'TW',
  HCN: 'TW',
  KYD: 'TW',
  RMQ: 'TW',
  MFK: 'TW',
  TNN: 'TW',
  HSZ: 'TW',
  MZG: 'TW',
  PIF: 'TW',
  TSA: 'TW',
  TPE: 'TW',
  WOT: 'TW',
  HUN: 'TW',
  RDV: 'US',
  RHR: 'AE',
  RHT: 'CN',
  NRT: 'JP',
  MMJ: 'JP',
  IBR: 'JP',
  MUS: 'JP',
  IWO: 'JP',
  KIX: 'JP',
  SHM: 'JP',
  UKB: 'JP',
  HIW: 'JP',
  TJH: 'JP',
  OBO: 'JP',
  CTS: 'JP',
  HKD: 'JP',
  KUH: 'JP',
  MMB: 'JP',
  SHB: 'JP',
  OKD: 'JP',
  RBJ: 'JP',
  WKJ: 'JP',
  AXJ: 'JP',
  IKI: 'JP',
  UBJ: 'JP',
  TSJ: 'JP',
  OMJ: 'JP',
  MBE: 'JP',
  AKJ: 'JP',
  OIR: 'JP',
  RIS: 'JP',
  KUM: 'JP',
  FUJ: 'JP',
  FUK: 'JP',
  TNE: 'JP',
  KOJ: 'JP',
  KMI: 'JP',
  OIT: 'JP',
  KKJ: 'JP',
  HSG: 'JP',
  KMJ: 'JP',
  NGS: 'JP',
  NGO: 'JP',
  ASJ: 'JP',
  OKE: 'JP',
  KKX: 'JP',
  TKN: 'JP',
  NKM: 'JP',
  FKJ: 'JP',
  QGU: 'JP',
  KMQ: 'JP',
  OKI: 'JP',
  FSZ: 'JP',
  TOY: 'JP',
  NTQ: 'JP',
  HIJ: 'JP',
  OKJ: 'JP',
  IZO: 'JP',
  YGJ: 'JP',
  IWK: 'JP',
  KCZ: 'JP',
  MYJ: 'JP',
  ITM: 'JP',
  TTJ: 'JP',
  TKS: 'JP',
  TAK: 'JP',
  IWJ: 'JP',
  AOJ: 'JP',
  GAJ: 'JP',
  SDS: 'JP',
  FKS: 'JP',
  HHE: 'JP',
  HNA: 'JP',
  AXT: 'JP',
  MSJ: 'JP',
  KIJ: 'JP',
  ONJ: 'JP',
  SDJ: 'JP',
  SYO: 'JP',
  NJA: 'JP',
  HAC: 'JP',
  OIM: 'JP',
  MYE: 'JP',
  HND: 'JP',
  OKO: 'JP',
  MWX: 'KR',
  KWJ: 'KR',
  KUV: 'KR',
  CHN: 'KR',
  RSU: 'KR',
  KAG: 'KR',
  WJU: 'KR',
  YNY: 'KR',
  CJU: 'KR',
  JDG: 'KR',
  CHF: 'KR',
  PUS: 'KR',
  HIN: 'KR',
  USN: 'KR',
  ICN: 'KR',
  SSN: 'KR',
  OSN: 'KR',
  GMP: 'KR',
  SWU: 'KR',
  QDY: 'KR',
  KPO: 'KR',
  TAE: 'KR',
  HMY: 'KR',
  CJJ: 'KR',
  YEC: 'KR',
  RKU: 'PG',
  RKY: 'AU',
  RLP: 'AU',
  RMP: 'US',
  OKA: 'JP',
  DNA: 'JP',
  UEO: 'JP',
  KJP: 'JP',
  MMD: 'JP',
  MMY: 'JP',
  AGJ: 'JP',
  IEJ: 'JP',
  HTR: 'JP',
  KTD: 'JP',
  SHI: 'JP',
  TRA: 'JP',
  RNJ: 'JP',
  OGN: 'JP',
  BSI: 'PH',
  SFS: 'PH',
  CRK: 'PH',
  LAO: 'PH',
  MNL: 'PH',
  CYU: 'PH',
  SGL: 'PH',
  LBX: 'PH',
  AAV: 'PH',
  CBO: 'PH',
  DVO: 'PH',
  BXU: 'PH',
  BPH: 'PH',
  DPL: 'PH',
  CGM: 'PH',
  IGN: 'PH',
  JOL: 'PH',
  MLP: 'PH',
  TWT: 'PH',
  OZC: 'PH',
  PAG: 'PH',
  MXI: 'PH',
  GES: 'PH',
  SUG: 'PH',
  CDY: 'PH',
  IPE: 'PH',
  TDG: 'PH',
  ZAM: 'PH',
  IAO: 'PH',
  RZP: 'PH',
  TAG: 'PH',
  SWL: 'PH',
  BAG: 'PH',
  DTE: 'PH',
  SJI: 'PH',
  MBO: 'PH',
  WNP: 'PH',
  BSO: 'PH',
  BQA: 'PH',
  SFE: 'PH',
  TUG: 'PH',
  VRC: 'PH',
  MRQ: 'PH',
  CYZ: 'PH',
  RPV: 'AU',
  TAC: 'PH',
  BCD: 'PH',
  CYP: 'PH',
  DGT: 'PH',
  MPH: 'PH',
  CRM: 'PH',
  ILO: 'PH',
  MBT: 'PH',
  KLO: 'PH',
  CEB: 'PH',
  OMC: 'PH',
  PPS: 'PH',
  RXS: 'PH',
  EUQ: 'PH',
  TBH: 'PH',
  USU: 'PH',
  BPA: 'PH',
  RRM: 'MZ',
  RSE: 'AU',
  NGK: 'RU',
  GRV: 'RU',
  NOI: 'RU',
  KDY: 'RU',
  UHS: 'RU',
  VUS: 'RU',
  RUU: 'PG',
  RVC: 'LR',
  LPS: 'US',
  KEH: 'US',
  MJR: 'AR',
  COC: 'AR',
  GHU: 'AR',
  PRA: 'AR',
  ROS: 'AR',
  SFN: 'AR',
  AEP: 'AR',
  LCM: 'AR',
  COR: 'AR',
  LPG: 'AR',
  EPA: 'AR',
  EZE: 'AR',
  RAF: 'AR',
  HOS: 'AR',
  CVH: 'AR',
  GNR: 'AR',
  RDS: 'AR',
  APZ: 'AR',
  SAM: 'PG',
  MDZ: 'AR',
  LGS: 'AR',
  AFA: 'AR',
  CTC: 'AR',
  SDE: 'AR',
  IRJ: 'AR',
  RHD: 'AR',
  TUC: 'AR',
  UAQ: 'AR',
  CRR: 'AR',
  RCU: 'AR',
  VDR: 'AR',
  VME: 'AR',
  RLO: 'AR',
  LUQ: 'AR',
  CNQ: 'AR',
  RES: 'AR',
  FMA: 'AR',
  IGR: 'AR',
  AOL: 'AR',
  MCS: 'AR',
  PSS: 'AR',
  SAS: 'US',
  SLA: 'AR',
  JUJ: 'AR',
  ORA: 'AR',
  TTG: 'AR',
  CLX: 'AR',
  ELO: 'AR',
  OYA: 'AR',
  LLS: 'AR',
  MDX: 'AR',
  RCQ: 'AR',
  UZU: 'AR',
  EHL: 'AR',
  CRD: 'AR',
  EMX: 'AR',
  EQS: 'AR',
  LHS: 'AR',
  IGB: 'AR',
  OES: 'AR',
  MQD: 'AR',
  ARR: 'AR',
  SGV: 'AR',
  REL: 'AR',
  VDM: 'AR',
  PMY: 'AR',
  FTE: 'AR',
  PUD: 'AR',
  RGA: 'AR',
  RGL: 'AR',
  USH: 'AR',
  ULA: 'AR',
  ROY: 'AR',
  PMQ: 'AR',
  GGS: 'AR',
  JSM: 'AR',
  RYO: 'AR',
  RZA: 'AR',
  BHI: 'AR',
  CSZ: 'AR',
  OVR: 'AR',
  GPO: 'AR',
  OYO: 'AR',
  SST: 'AR',
  MDQ: 'AR',
  NQN: 'AR',
  NEC: 'AR',
  PEH: 'AR',
  RSA: 'AR',
  BRC: 'AR',
  TDL: 'AR',
  VLG: 'AR',
  CUT: 'AR',
  CPC: 'AR',
  VIU: 'SB',
  CDJ: 'BR',
  ARX: 'BR',
  AQA: 'BR',
  AJU: 'BR',
  AFL: 'BR',
  ARU: 'BR',
  AAX: 'BR',
  BEL: 'BR',
  BGX: 'BR',
  PLU: 'BR',
  BFH: 'BR',
  BJP: 'BR',
  BSB: 'BR',
  BAT: 'BR',
  BAU: 'BR',
  BVB: 'BR',
  BPG: 'BR',
  BZC: 'BR',
  CAC: 'BR',
  CFB: 'BR',
  CFC: 'BR',
  CNF: 'BR',
  CGR: 'BR',
  XAP: 'BR',
  CLN: 'BR',
  CKS: 'BR',
  CCM: 'BR',
  CLV: 'BR',
  CAW: 'BR',
  CMG: 'BR',
  CWB: 'BR',
  CRQ: 'BR',
  CXJ: 'BR',
  CGB: 'BR',
  CZS: 'BR',
  BYO: 'BR',
  PPB: 'BR',
  MAO: 'BR',
  JCR: 'BR',
  IGU: 'BR',
  FLN: 'BR',
  FEN: 'BR',
  FOR: 'BR',
  GIG: 'BR',
  GJM: 'BR',
  GYN: 'BR',
  GRU: 'BR',
  GPB: 'BR',
  GVR: 'BR',
  GUJ: 'BR',
  ATM: 'BR',
  ITA: 'BR',
  ITB: 'BR',
  IOS: 'BR',
  IPN: 'BR',
  ITR: 'BR',
  IMP: 'BR',
  JJG: 'BR',
  QDV: 'BR',
  JDF: 'BR',
  JHF: 'BR',
  JPA: 'BR',
  RRJ: 'BR',
  JDO: 'BR',
  JOI: 'BR',
  CPV: 'BR',
  VCP: 'BR',
  LEC: 'BR',
  LAJ: 'BR',
  LIP: 'BR',
  LDB: 'BR',
  LAZ: 'BR',
  MAB: 'BR',
  MQH: 'BR',
  MEU: 'BR',
  MEA: 'BR',
  MGF: 'BR',
  MOC: 'BR',
  MII: 'BR',
  PLL: 'BR',
  MCZ: 'BR',
  MCP: 'BR',
  MVF: 'BR',
  RTE: 'BR',
  MNX: 'BR',
  NVT: 'BR',
  GEL: 'BR',
  OYK: 'BR',
  POA: 'BR',
  PHB: 'BR',
  POO: 'BR',
  PFB: 'BR',
  PMW: 'BR',
  PET: 'BR',
  PNZ: 'BR',
  PNB: 'BR',
  PMG: 'BR',
  BPS: 'BR',
  PVH: 'BR',
  RBR: 'BR',
  REC: 'BR',
  SDU: 'BR',
  RAO: 'BR',
  BRB: 'BR',
  SNZ: 'BR',
  NAT: 'BR',
  SJK: 'BR',
  SLZ: 'BR',
  RIA: 'BR',
  STM: 'BR',
  SMT: 'BR',
  CGH: 'BR',
  SJP: 'BR',
  SSZ: 'BR',
  SSA: 'BR',
  TMT: 'BR',
  UNA: 'BR',
  TOW: 'BR',
  THE: 'BR',
  TFF: 'BR',
  TRQ: 'BR',
  TEC: 'BR',
  TSQ: 'BR',
  TBT: 'BR',
  TUR: 'BR',
  SJL: 'BR',
  PAV: 'BR',
  URG: 'BR',
  UDI: 'BR',
  UBA: 'BR',
  RPU: 'BR',
  VAG: 'BR',
  BVH: 'BR',
  VIX: 'BR',
  QPS: 'BR',
  IZA: 'BR',
  ZUD: 'CL',
  LOB: 'CL',
  WAP: 'CL',
  ARI: 'CL',
  WPA: 'CL',
  CPO: 'CL',
  BBA: 'CL',
  TOQ: 'CL',
  DPB: 'CL',
  CCH: 'CL',
  CJC: 'CL',
  YAI: 'CL',
  PUQ: 'CL',
  COW: 'CL',
  GXQ: 'CL',
  IQQ: 'CL',
  SCL: 'CL',
  ESR: 'CL',
  FRT: 'CL',
  ANF: 'CL',
  WPR: 'CL',
  FFU: 'CL',
  LSQ: 'CL',
  WPU: 'CL',
  LGR: 'CL',
  CCP: 'CL',
  IPC: 'CL',
  ZOS: 'CL',
  CPP: 'CL',
  VLR: 'CL',
  ZLR: 'CL',
  PNT: 'CL',
  OVL: 'CL',
  ZPC: 'CL',
  MHC: 'CL',
  PUX: 'CL',
  ZCO: 'CL',
  CNR: 'CL',
  VAP: 'CL',
  TNM: 'AQ',
  SMB: 'CL',
  LSC: 'CL',
  WCA: 'CL',
  PZS: 'CL',
  PMC: 'CL',
  TLX: 'CL',
  ZIC: 'CL',
  TTC: 'CL',
  ZAL: 'CL',
  KNA: 'CL',
  OLC: 'BR',
  SOD: 'BR',
  SDI: 'PG',
  JTN: 'BR',
  JLS: 'BR',
  QOA: 'BR',
  QGC: 'BR',
  NVM: 'BR',
  OUS: 'BR',
  OIA: 'BR',
  QHB: 'BR',
  REZ: 'BR',
  QSC: 'BR',
  JPY: 'BR',
  UBT: 'BR',
  ITP: 'BR',
  VOT: 'BR',
  JTA: 'BR',
  ATF: 'EC',
  OCC: 'EC',
  CUE: 'EC',
  GPS: 'EC',
  GYE: 'EC',
  IBB: 'EC',
  JIP: 'EC',
  LTX: 'EC',
  MRR: 'EC',
  XMS: 'EC',
  MEC: 'EC',
  LGQ: 'EC',
  PYO: 'EC',
  PVO: 'EC',
  UIO: 'EC',
  ETR: 'EC',
  SNC: 'EC',
  SUQ: 'EC',
  PTZ: 'EC',
  SCY: 'EC',
  BHA: 'EC',
  TSC: 'EC',
  TPN: 'EC',
  LOH: 'EC',
  ESM: 'EC',
  TPC: 'EC',
  TUA: 'EC',
  PSY: 'FK',
  SFU: 'PG',
  ASU: 'PY',
  AYO: 'PY',
  CIO: 'PY',
  ENO: 'PY',
  AGT: 'PY',
  FLM: 'PY',
  ESG: 'PY',
  PIL: 'PY',
  PJC: 'PY',
  SIC: 'PA',
  LVR: 'BR',
  FRC: 'BR',
  SIZ: 'PG',
  JUA: 'BR',
  SJF: 'VI',
  CFO: 'BR',
  NPR: 'BR',
  AQM: 'BR',
  JRT: 'BR',
  RIG: 'BR',
  JTC: 'BR',
  ARS: 'BR',
  ECO: 'CO',
  ARO: 'CO',
  SJR: 'CO',
  NPU: 'CO',
  PCC: 'CO',
  SQF: 'CO',
  AYI: 'CO',
  CUI: 'CO',
  MOY: 'CO',
  EUO: 'CO',
  PRE: 'CO',
  SQE: 'CO',
  TAU: 'CO',
  AYC: 'CO',
  DZI: 'CO',
  SJH: 'CO',
  BHF: 'CO',
  JUO: 'CO',
  UNC: 'CO',
  AYA: 'CO',
  NBB: 'CO',
  MND: 'CO',
  NAD: 'CO',
  GCA: 'CO',
  SRO: 'CO',
  BAC: 'CO',
  CQT: 'CO',
  ELJ: 'CO',
  SOH: 'CO',
  ECR: 'CO',
  MQR: 'CO',
  ISD: 'CO',
  AZT: 'CO',
  HRR: 'CO',
  SQB: 'CO',
  ARF: 'CO',
  MFB: 'CO',
  MHF: 'CO',
  CSR: 'CO',
  LPE: 'CO',
  ACR: 'CO',
  ACD: 'CO',
  AFI: 'CO',
  API: 'CO',
  AXM: 'CO',
  PUU: 'CO',
  ARQ: 'CO',
  ELB: 'CO',
  BGA: 'CO',
  BOG: 'CO',
  BAQ: 'CO',
  BSC: 'CO',
  BUN: 'CO',
  CPB: 'CO',
  CUC: 'CO',
  COG: 'CO',
  CTG: 'CO',
  CCO: 'CO',
  CLO: 'CO',
  CIM: 'CO',
  RAV: 'CO',
  TCO: 'CO',
  CUO: 'CO',
  CAQ: 'CO',
  CVE: 'CO',
  CZU: 'CO',
  EBG: 'CO',
  EJA: 'CO',
  FLA: 'CO',
  FDA: 'CO',
  LGT: 'CO',
  GIR: 'CO',
  CRC: 'CO',
  GPI: 'CO',
  CPL: 'CO',
  HTZ: 'CO',
  IBE: 'CO',
  IGO: 'CO',
  IPI: 'CO',
  APO: 'CO',
  LQM: 'CO',
  MCJ: 'CO',
  LPD: 'CO',
  LET: 'CO',
  EOH: 'CO',
  MFS: 'CO',
  MGN: 'CO',
  MTB: 'CO',
  MTR: 'CO',
  MVP: 'CO',
  MZL: 'CO',
  LMC: 'CO',
  NCI: 'CO',
  NQU: 'CO',
  NVA: 'CO',
  OCV: 'CO',
  ORC: 'CO',
  RON: 'CO',
  PCR: 'CO',
  PDA: 'CO',
  PEI: 'CO',
  PTX: 'CO',
  PLT: 'CO',
  NAR: 'CO',
  PPN: 'CO',
  PAL: 'CO',
  PBE: 'CO',
  PSO: 'CO',
  PVA: 'CO',
  PZA: 'CO',
  MQU: 'CO',
  MDE: 'CO',
  RCH: 'CO',
  RVE: 'CO',
  SJE: 'CO',
  SSL: 'CO',
  SMR: 'CO',
  SOX: 'CO',
  ADZ: 'CO',
  SRS: 'CO',
  SVI: 'CO',
  TIB: 'CO',
  TDA: 'CO',
  TLU: 'CO',
  TME: 'CO',
  TQS: 'CO',
  TRB: 'CO',
  URI: 'CO',
  AUC: 'CO',
  UIB: 'CO',
  ULQ: 'CO',
  URR: 'CO',
  VGZ: 'CO',
  PYA: 'CO',
  VUP: 'CO',
  VVC: 'CO',
  AYG: 'CO',
  EYP: 'CO',
  MHW: 'BO',
  SRE: 'BO',
  APB: 'BO',
  ASC: 'BO',
  BJO: 'BO',
  CAM: 'BO',
  CBB: 'BO',
  CCA: 'BO',
  CIJ: 'BO',
  CEP: 'BO',
  SRZ: 'BO',
  GYA: 'BO',
  BVK: 'BO',
  SLJ: 'AU',
  SJS: 'BO',
  SJB: 'BO',
  SJV: 'BO',
  LPB: 'BO',
  MGD: 'BO',
  ORU: 'BO',
  POI: 'BO',
  PUR: 'BO',
  PSZ: 'BO',
  SRD: 'BO',
  RBO: 'BO',
  RIB: 'BO',
  REY: 'BO',
  SBL: 'BO',
  SRJ: 'BO',
  SNG: 'BO',
  SNM: 'BO',
  SRB: 'BO',
  MQK: 'BO',
  TJA: 'BO',
  TDD: 'BO',
  UYU: 'BO',
  VAH: 'BO',
  VLM: 'BO',
  VVI: 'BO',
  BYC: 'BO',
  ABN: 'SR',
  TOT: 'SR',
  DRJ: 'SR',
  SMH: 'PG',
  PBM: 'SR',
  MOJ: 'SR',
  ICK: 'SR',
  OEM: 'SR',
  SMZ: 'SR',
  AGI: 'SR',
  ORG: 'SR',
  JSO: 'BR',
  JAW: 'BR',
  APY: 'BR',
  APQ: 'BR',
  AMJ: 'BR',
  AIF: 'BR',
  BDC: 'BR',
  BVM: 'BR',
  BRA: 'BR',
  BSS: 'BR',
  BMS: 'BR',
  BQQ: 'BR',
  EEA: 'BR',
  CPU: 'BR',
  QCH: 'BR',
  RDC: 'BR',
  LEP: 'BR',
  DTI: 'BR',
  DIQ: 'BR',
  JPE: 'BR',
  CNV: 'BR',
  VCC: 'BR',
  SXX: 'BR',
  GUZ: 'BR',
  GDP: 'BR',
  GNM: 'BR',
  QGP: 'BR',
  ITN: 'BR',
  SET: 'BR',
  IRE: 'BR',
  QIG: 'BR',
  QIT: 'BR',
  IPU: 'BR',
  JCM: 'BR',
  FEC: 'BR',
  JEQ: 'BR',
  JNA: 'BR',
  JDR: 'BR',
  CDI: 'BR',
  QCP: 'BR',
  SSO: 'BR',
  MTE: 'BR',
  MVS: 'BR',
  SBJ: 'BR',
  PTQ: 'BR',
  NNU: 'BR',
  PSW: 'BR',
  FEJ: 'BR',
  ORX: 'BR',
  PCS: 'BR',
  POJ: 'BR',
  MXQ: 'BR',
  PIV: 'BR',
  SNQ: 'MX',
  FLB: 'BR',
  PDF: 'BR',
  CAU: 'BR',
  OPP: 'BR',
  SFK: 'BR',
  TXF: 'BR',
  OBI: 'BR',
  TFL: 'BR',
  JPO: 'BR',
  VAL: 'BR',
  QID: 'BR',
  BVS: 'BR',
  CMC: 'BR',
  JCS: 'BR',
  GGF: 'BR',
  PHI: 'BR',
  GMS: 'BR',
  PPY: 'BR',
  PYT: 'BR',
  ITE: 'BR',
  BXX: 'SO',
  SOA: 'VN',
  CAY: 'GF',
  GSI: 'GF',
  MPY: 'GF',
  OYP: 'GF',
  LDX: 'GF',
  REI: 'GF',
  XAU: 'GF',
  SOR: 'SY',
  ALD: 'PE',
  AOP: 'PE',
  AYX: 'PE',
  MBP: 'PE',
  BLP: 'PE',
  IBP: 'PE',
  PCL: 'PE',
  TDP: 'PE',
  CHM: 'PE',
  TGI: 'PE',
  CIX: 'PE',
  AYP: 'PE',
  ANS: 'PE',
  ATA: 'PE',
  UMI: 'PE',
  LIM: 'PE',
  UCZ: 'PE',
  RIJ: 'PE',
  JAE: 'PE',
  JJI: 'PE',
  JAU: 'PE',
  JUL: 'PE',
  SJA: 'PE',
  CJA: 'PE',
  RIM: 'PE',
  ILQ: 'PE',
  TBP: 'PE',
  MZA: 'PE',
  YMS: 'PE',
  HUU: 'PE',
  SQU: 'PE',
  CHH: 'PE',
  IQT: 'PE',
  AQP: 'PE',
  TRU: 'PE',
  PIO: 'PE',
  TPP: 'PE',
  SYC: 'PE',
  TCQ: 'PE',
  PEM: 'PE',
  PIU: 'PE',
  TYL: 'PE',
  NZC: 'PE',
  CUZ: 'PE',
  SQD: 'CN',
  SQJ: 'CN',
  SQT: 'PG',
  AAJ: 'SR',
  KCB: 'SR',
  SRL: 'MX',
  SRM: 'AU',
  SRV: 'US',
  CZB: 'BR',
  APU: 'BR',
  BGV: 'BR',
  BNU: 'BR',
  CCI: 'BR',
  CSS: 'BR',
  CEL: 'BR',
  CKO: 'BR',
  DOU: 'BR',
  ERM: 'BR',
  FBE: 'BR',
  GGJ: 'BR',
  HRZ: 'BR',
  IJU: 'BR',
  ITQ: 'BR',
  JCB: 'BR',
  CBW: 'BR',
  QDB: 'BR',
  QCR: 'BR',
  OAL: 'BR',
  LOI: 'BR',
  ALQ: 'BR',
  QGF: 'BR',
  QHV: 'BR',
  SQX: 'BR',
  APX: 'BR',
  VRZ: 'BR',
  PTO: 'BR',
  PNG: 'BR',
  PVI: 'BR',
  PBB: 'BR',
  SQY: 'BR',
  SSS: 'PG',
  JBS: 'BR',
  CSU: 'BR',
  TJL: 'BR',
  UMU: 'BR',
  ZFU: 'BR',
  UVI: 'BR',
  VIA: 'BR',
  CTQ: 'BR',
  AXE: 'BR',
  AAG: 'BR',
  SRA: 'BR',
  PGZ: 'BR',
  ATI: 'UY',
  BUV: 'UY',
  CYR: 'UY',
  DZO: 'UY',
  PDP: 'UY',
  MLZ: 'UY',
  MVD: 'UY',
  PDU: 'UY',
  RVY: 'UY',
  STY: 'UY',
  TAW: 'UY',
  TYT: 'UY',
  VCH: 'UY',
  AGV: 'VE',
  AAO: 'VE',
  LPJ: 'VE',
  BLA: 'VE',
  BNS: 'VE',
  BRM: 'VE',
  MYC: 'VE',
  CBL: 'VE',
  CXA: 'VE',
  ZRZ: 'VE',
  CUV: 'VE',
  CLZ: 'VE',
  CAJ: 'VE',
  CUP: 'VE',
  CZE: 'VE',
  CUM: 'VE',
  PPZ: 'VE',
  EOR: 'VE',
  EOZ: 'VE',
  GDO: 'VE',
  GUI: 'VE',
  GUQ: 'VE',
  HGE: 'VE',
  ICA: 'VE',
  ICC: 'VE',
  LSP: 'VE',
  KAV: 'VE',
  LFR: 'VE',
  MAR: 'VE',
  MRD: 'VE',
  PMV: 'VE',
  CCS: 'VE',
  MUN: 'VE',
  CBS: 'VE',
  PYH: 'VE',
  PBL: 'VE',
  PDZ: 'VE',
  PPH: 'VE',
  SCI: 'VE',
  PZO: 'VE',
  PTM: 'VE',
  LRV: 'VE',
  SVS: 'US',
  SVZ: 'VE',
  SBB: 'VE',
  SNV: 'VE',
  STD: 'VE',
  SNF: 'VE',
  SFD: 'VE',
  SOM: 'VE',
  STB: 'VE',
  TUV: 'VE',
  TMO: 'VE',
  URM: 'VE',
  VLN: 'VE',
  VIG: 'VE',
  VLV: 'VE',
  VDP: 'VE',
  BAZ: 'BR',
  JSB: 'BR',
  LCB: 'BR',
  RBB: 'BR',
  CAF: 'BR',
  CQS: 'BR',
  DMT: 'BR',
  DNO: 'BR',
  SWE: 'PG',
  ERN: 'BR',
  CQA: 'BR',
  SXO: 'BR',
  SWG: 'PG',
  GRP: 'BR',
  AUX: 'BR',
  GGB: 'BR',
  HUW: 'BR',
  IPG: 'BR',
  IDO: 'BR',
  JPR: 'BR',
  JIA: 'BR',
  JRN: 'BR',
  JTI: 'BR',
  CCX: 'BR',
  CIZ: 'BR',
  NSR: 'BR',
  TLZ: 'BR',
  LBR: 'BR',
  RVD: 'BR',
  MBZ: 'BR',
  NVP: 'BR',
  BCR: 'BR',
  NQL: 'BR',
  APS: 'BR',
  FBA: 'BR',
  PIN: 'BR',
  PBQ: 'BR',
  PBX: 'BR',
  SWR: 'PG',
  AAI: 'BR',
  ROO: 'BR',
  AIR: 'BR',
  OPS: 'BR',
  STZ: 'BR',
  IRZ: 'BR',
  TGQ: 'BR',
  AZL: 'BR',
  QHN: 'BR',
  SQM: 'BR',
  MTG: 'BR',
  VLP: 'BR',
  MBK: 'BR',
  NOK: 'BR',
  IUP: 'BR',
  SWZ: 'AU',
  SXH: 'PG',
  SXP: 'US',
  AHL: 'GY',
  NAI: 'GY',
  SYB: 'US',
  BMJ: 'GY',
  GFO: 'GY',
  GEO: 'GY',
  SYF: 'CA',
  OGL: 'GY',
  IMB: 'GY',
  KAR: 'GY',
  KRM: 'GY',
  KRG: 'GY',
  KTO: 'GY',
  KKG: 'GY',
  SYL: 'US',
  LUB: 'GY',
  LTM: 'GY',
  USI: 'GY',
  MHA: 'GY',
  VEG: 'GY',
  MYM: 'GY',
  MWJ: 'GY',
  SYN: 'US',
  QSX: 'GY',
  ORJ: 'GY',
  PMT: 'GY',
  PRR: 'GY',
  SDC: 'GY',
  SKM: 'GY',
  SZN: 'US',
  SZP: 'US',
  TAO: 'CN',
  ANU: 'AG',
  BBQ: 'AG',
  TBE: 'PG',
  BGI: 'BB',
  TBQ: 'PG',
  TBV: 'MH',
  TCK: 'PG',
  TCT: 'US',
  TDB: 'PG',
  DCF: 'DM',
  DOM: 'DM',
  TDS: 'PG',
  TEO: 'PG',
  TFB: 'PG',
  DSD: 'GP',
  BBR: 'GP',
  SFC: 'GP',
  FDF: 'MQ',
  SFG: 'MF',
  SBH: 'BL',
  GBJ: 'GP',
  PTP: 'GP',
  LSS: 'GP',
  TGL: 'PG',
  GND: 'GD',
  CRU: 'GD',
  THW: 'LK',
  STT: 'VI',
  STX: 'VI',
  ARE: 'PR',
  BQN: 'PR',
  TJC: 'PA',
  CPX: 'PR',
  SIG: 'PR',
  MAZ: 'PR',
  PSE: 'PR',
  NRR: 'PR',
  SJU: 'PR',
  VQS: 'PR',
  TKE: 'US',
  TKL: 'US',
  SKB: 'KN',
  NEV: 'KN',
  TLP: 'PG',
  SLU: 'LC',
  UVF: 'LC',
  TLT: 'US',
  KEA: 'TM',
  AUA: 'AW',
  BON: 'BQ',
  CUR: 'CW',
  EUX: 'BQ',
  SXM: 'SX',
  SAB: 'BQ',
  TNW: 'EC',
  TOK: 'PG',
  TOV: 'VG',
  PTA: 'US',
  TPT: 'LR',
  AXA: 'AI',
  BGG: 'TR',
  MNI: 'MS',
  TSG: 'US',
  TSI: 'PG',
  TAB: 'TT',
  POS: 'TT',
  TTW: 'LK',
  TUJ: 'ET',
  NGD: 'VG',
  EIS: 'VG',
  VIJ: 'VG',
  SVD: 'VC',
  BQU: 'VC',
  CIW: 'VC',
  MQS: 'VC',
  UNI: 'VC',
  DSX: 'TW',
  CMJ: 'TW',
  TWC: 'CN',
  TWH: 'US',
  BDA: 'BM',
  TYE: 'US',
  GIT: 'TZ',
  LUY: 'TZ',
  TZO: 'MG',
  DBS: 'US',
  MXR: 'UA',
  KHU: 'UA',
  KCP: 'UA',
  ALA: 'KZ',
  BXH: 'KZ',
  BXJ: 'KZ',
  TDK: 'KZ',
  NQZ: 'KZ',
  KOV: 'KZ',
  PPK: 'KZ',
  DMB: 'KZ',
  UAE: 'PG',
  IKU: 'KG',
  FRU: 'KG',
  OSS: 'KG',
  CIT: 'KZ',
  HSA: 'KZ',
  DZN: 'KZ',
  KGF: 'KZ',
  KZO: 'KZ',
  URA: 'KZ',
  EKB: 'KZ',
  UKK: 'KZ',
  PWQ: 'KZ',
  PLX: 'KZ',
  SZI: 'KZ',
  SCO: 'KZ',
  GUW: 'KZ',
  AKX: 'KZ',
  AYK: 'KZ',
  KSN: 'KZ',
  GYD: 'AZ',
  KVD: 'AZ',
  LLK: 'AZ',
  NAJ: 'AZ',
  GBB: 'AZ',
  ZTU: 'AZ',
  ZZE: 'AZ',
  YLV: 'AZ',
  UBI: 'PG',
  LWN: 'AM',
  EVN: 'AM',
  BQJ: 'RU',
  SUK: 'RU',
  UKG: 'RU',
  TLK: 'RU',
  ADH: 'RU',
  YKS: 'RU',
  NER: 'RU',
  MQJ: 'RU',
  GYG: 'RU',
  OLZ: 'RU',
  USR: 'RU',
  UMS: 'RU',
  VHV: 'RU',
  NYR: 'RU',
  SUY: 'RU',
  VYI: 'RU',
  ULK: 'RU',
  ONK: 'RU',
  PYJ: 'RU',
  MJZ: 'RU',
  SYS: 'RU',
  BGN: 'RU',
  SEK: 'RU',
  CKH: 'RU',
  CYX: 'RU',
  IKS: 'RU',
  ZKP: 'RU',
  KHX: 'UG',
  OYG: 'UG',
  UGB: 'US',
  KUT: 'GE',
  BUS: 'GE',
  SUI: 'GE',
  TBS: 'GE',
  BQS: 'RU',
  TYD: 'RU',
  KHV: 'RU',
  KXK: 'RU',
  GVN: 'RU',
  DYR: 'RU',
  PVS: 'RU',
  KPW: 'RU',
  GDX: 'RU',
  KVM: 'RU',
  PWE: 'RU',
  SWV: 'RU',
  BQG: 'RU',
  NLI: 'RU',
  OHO: 'RU',
  PKC: 'RU',
  BVV: 'RU',
  OHH: 'RU',
  ITU: 'RU',
  EKS: 'RU',
  DEE: 'RU',
  ZZO: 'RU',
  UUS: 'RU',
  AEM: 'RU',
  ETL: 'RU',
  EDN: 'RU',
  KVR: 'RU',
  TLY: 'RU',
  NEI: 'RU',
  VVO: 'RU',
  HTA: 'RU',
  BTK: 'RU',
  UIK: 'RU',
  IKT: 'RU',
  ODO: 'RU',
  ERG: 'RU',
  KCK: 'RU',
  RZH: 'RU',
  UKX: 'RU',
  UUD: 'RU',
  UJE: 'MH',
  UJN: 'KR',
  KBP: 'UA',
  KRQ: 'UA',
  MPW: 'UA',
  SEV: 'UA',
  DNK: 'UA',
  OZH: 'UA',
  KWG: 'UA',
  UKS: 'UA',
  SIP: 'UA',
  KHC: 'UA',
  UKH: 'OM',
  HRK: 'UA',
  PLV: 'UA',
  UMY: 'UA',
  CKC: 'UA',
  KGO: 'UA',
  IEV: 'UA',
  ZTR: 'UA',
  UCK: 'UA',
  HMJ: 'UA',
  IFO: 'UA',
  LWO: 'UA',
  CWC: 'UA',
  RWN: 'UA',
  UDJ: 'UA',
  KHE: 'UA',
  NLV: 'UA',
  ODS: 'UA',
  VIN: 'UA',
  ARH: 'RU',
  VKV: 'RU',
  LDG: 'RU',
  NNM: 'RU',
  CSH: 'RU',
  CEE: 'RU',
  AMV: 'RU',
  VRI: 'RU',
  ULH: 'SA',
  KSZ: 'RU',
  LED: 'RU',
  KVK: 'RU',
  MMK: 'RU',
  PKV: 'RU',
  PES: 'RU',
  VGD: 'RU',
  BQT: 'BY',
  GME: 'BY',
  VTB: 'BY',
  KGD: 'RU',
  GNA: 'BY',
  MSQ: 'BY',
  MVQ: 'BY',
  ABA: 'RU',
  BAX: 'RU',
  RGK: 'RU',
  KEJ: 'RU',
  EIE: 'RU',
  TGP: 'RU',
  KJA: 'RU',
  ACS: 'RU',
  KYZ: 'RU',
  OVB: 'RU',
  OMS: 'RU',
  SWT: 'RU',
  TOF: 'RU',
  NOZ: 'RU',
  DKS: 'RU',
  HTG: 'RU',
  IAA: 'RU',
  NSK: 'RU',
  THX: 'RU',
  AAQ: 'RU',
  EIK: 'RU',
  GDZ: 'RU',
  KRR: 'RU',
  MCX: 'RU',
  MRV: 'RU',
  NAL: 'RU',
  OGZ: 'RU',
  IGT: 'RU',
  STW: 'RU',
  ROV: 'RU',
  TGK: 'RU',
  AER: 'RU',
  ASF: 'RU',
  ESL: 'RU',
  VOG: 'RU',
  BNH: 'US',
  XWA: 'US',
  RTL: 'US',
  WWT: 'US',
  CEK: 'RU',
  MQF: 'RU',
  SBT: 'RU',
  BVJ: 'RU',
  SLY: 'RU',
  YMK: 'RU',
  KKQ: 'RU',
  TQL: 'RU',
  UEN: 'RU',
  EZV: 'RU',
  HMA: 'RU',
  IRM: 'RU',
  NYA: 'RU',
  OVS: 'RU',
  URJ: 'RU',
  EYK: 'RU',
  IJK: 'RU',
  KVX: 'RU',
  NYM: 'RU',
  NUX: 'RU',
  NJC: 'RU',
  PEE: 'RU',
  KGP: 'RU',
  NFG: 'RU',
  NOJ: 'RU',
  SGC: 'RU',
  SVX: 'RU',
  RMZ: 'RU',
  TOX: 'RU',
  TJM: 'RU',
  KRO: 'RU',
  BKN: 'TM',
  GMV: 'US',
  ASB: 'TM',
  KRW: 'TM',
  MYP: 'TM',
  TAZ: 'TM',
  CRZ: 'TM',
  DYU: 'TJ',
  TJU: 'TJ',
  LBD: 'TJ',
  KQT: 'TJ',
  AZN: 'UZ',
  FEG: 'UZ',
  NMA: 'UZ',
  MOK: 'UZ',
  NCU: 'UZ',
  UGC: 'UZ',
  NVI: 'UZ',
  BHK: 'UZ',
  KSQ: 'UZ',
  AFS: 'UZ',
  SKD: 'UZ',
  TMJ: 'UZ',
  TAS: 'UZ',
  UTU: 'PA',
  KMW: 'RU',
  KLF: 'RU',
  IWA: 'RU',
  RYB: 'RU',
  BZK: 'RU',
  ZIA: 'RU',
  DME: 'RU',
  IAR: 'RU',
  SVO: 'RU',
  KLD: 'RU',
  OSF: 'RU',
  CKL: 'RU',
  EGO: 'RU',
  URS: 'RU',
  LPK: 'RU',
  VOZ: 'RU',
  TBW: 'RU',
  UUU: 'PG',
  RZN: 'RU',
  TYA: 'RU',
  VKO: 'RU',
  UCT: 'RU',
  INA: 'RU',
  PEX: 'RU',
  USK: 'RU',
  VKT: 'RU',
  UTS: 'RU',
  SCW: 'RU',
  GOJ: 'RU',
  UUA: 'RU',
  KZN: 'RU',
  NBC: 'RU',
  JOK: 'RU',
  CSY: 'RU',
  ZIX: 'RU',
  ULV: 'RU',
  ULY: 'RU',
  REN: 'RU',
  OSW: 'RU',
  PEZ: 'RU',
  SKX: 'RU',
  BWO: 'RU',
  GSV: 'RU',
  BCX: 'RU',
  OKT: 'RU',
  UFA: 'RU',
  KUF: 'RU',
  UZM: 'CA',
  UZR: 'KZ',
  REW: 'IN',
  DIU: 'IN',
  GDB: 'IN',
  AMD: 'IN',
  AKD: 'IN',
  IXU: 'IN',
  BOM: 'IN',
  PAB: 'IN',
  BHJ: 'IN',
  IXG: 'IN',
  BDQ: 'IN',
  BHO: 'IN',
  BHU: 'IN',
  NMB: 'IN',
  GUX: 'IN',
  GOI: 'IN',
  HBX: 'IN',
  IDR: 'IN',
  JLR: 'IN',
  JGA: 'IN',
  IXY: 'IN',
  HJR: 'IN',
  KLH: 'IN',
  IXK: 'IN',
  NDC: 'IN',
  NAG: 'IN',
  ISK: 'IN',
  PNQ: 'IN',
  PBD: 'IN',
  RTC: 'IN',
  RAJ: 'IN',
  RPR: 'IN',
  SAG: 'IN',
  SSE: 'IN',
  STV: 'IN',
  UDR: 'IN',
  CMB: 'LK',
  ACJ: 'LK',
  BTC: 'LK',
  RML: 'LK',
  ADP: 'LK',
  HIM: 'LK',
  JAF: 'LK',
  KCT: 'LK',
  KTY: 'LK',
  GIU: 'LK',
  TRR: 'LK',
  WRZ: 'LK',
  HRI: 'LK',
  BBM: 'KH',
  KZC: 'KH',
  KKZ: 'KH',
  MWV: 'KH',
  PNH: 'KH',
  RBE: 'KH',
  REP: 'KH',
  TNX: 'KH',
  KOS: 'KH',
  KZD: 'KH',
  ELX: 'VE',
  LGY: 'VE',
  KTV: 'VE',
  SFX: 'VE',
  SVV: 'VE',
  WOK: 'VE',
  DBR: 'IN',
  IXV: 'IN',
  IXA: 'IN',
  IXB: 'IN',
  RGH: 'IN',
  SHL: 'IN',
  BBI: 'IN',
  CCU: 'IN',
  COH: 'IN',
  DBD: 'IN',
  RDP: 'IN',
  DEP: 'IN',
  GOP: 'IN',
  GAU: 'IN',
  GAY: 'IN',
  IMF: 'IN',
  JRG: 'IN',
  PYB: 'IN',
  IXW: 'IN',
  JRH: 'IN',
  KBK: 'IN',
  IXQ: 'IN',
  IXH: 'IN',
  IXS: 'IN',
  IXN: 'IN',
  AJL: 'IN',
  IXI: 'IN',
  LDA: 'IN',
  DIB: 'IN',
  DMU: 'IN',
  MZU: 'IN',
  IXT: 'IN',
  PAT: 'IN',
  IXR: 'IN',
  RRK: 'IN',
  RUP: 'IN',
  TEZ: 'IN',
  VTZ: 'IN',
  ZER: 'IN',
  BZL: 'BD',
  CXB: 'BD',
  CLA: 'BD',
  CGP: 'BD',
  IRD: 'BD',
  JSR: 'BD',
  RJH: 'BD',
  SPD: 'BD',
  TKR: 'BD',
  ZHM: 'BD',
  ZYL: 'BD',
  DAC: 'BD',
  HKG: 'HK',
  HHP: 'HK',
  SPB: 'VI',
  SSB: 'VI',
  AGR: 'IN',
  IXD: 'IN',
  ATQ: 'IN',
  AIP: 'IN',
  BKB: 'IN',
  VNS: 'IN',
  KUU: 'IN',
  BUP: 'IN',
  BEK: 'IN',
  IXC: 'IN',
  KNU: 'IN',
  DED: 'IN',
  DEL: 'IN',
  DHM: 'IN',
  GWL: 'IN',
  HSS: 'IN',
  JDH: 'IN',
  JAI: 'IN',
  JSA: 'IN',
  IXJ: 'IN',
  KQH: 'IN',
  KTU: 'IN',
  LUH: 'IN',
  IXL: 'IN',
  LKO: 'IN',
  IXP: 'IN',
  PGH: 'IN',
  SLV: 'IN',
  SXR: 'IN',
  TNI: 'IN',
  VIV: 'PG',
  VJQ: 'MZ',
  AOU: 'LA',
  HOE: 'LA',
  LPQ: 'LA',
  LXG: 'LA',
  ODY: 'LA',
  PKZ: 'LA',
  ZBY: 'LA',
  ZVK: 'LA',
  NEU: 'LA',
  VNA: 'LA',
  THK: 'LA',
  VTE: 'LA',
  XKH: 'LA',
  XIE: 'LA',
  VMI: 'PY',
  MFM: 'MO',
  VDH: 'VN',
  BJH: 'NP',
  BHP: 'NP',
  BGL: 'NP',
  BHR: 'NP',
  BJU: 'NP',
  BIT: 'NP',
  BWA: 'NP',
  BDP: 'NP',
  DNP: 'NP',
  DHI: 'NP',
  DAP: 'NP',
  DOP: 'NP',
  SIH: 'NP',
  GKH: 'NP',
  JIR: 'NP',
  JUM: 'NP',
  JKR: 'NP',
  JMO: 'NP',
  KTM: 'NP',
  LDN: 'NP',
  LUA: 'NP',
  LTG: 'NP',
  NGX: 'NP',
  MEY: 'NP',
  XMG: 'NP',
  KEP: 'NP',
  PKR: 'NP',
  PPL: 'NP',
  RJB: 'NP',
  RHP: 'NP',
  RUK: 'NP',
  RPA: 'NP',
  RUM: 'NP',
  SYH: 'NP',
  SIF: 'NP',
  SKH: 'NP',
  FEB: 'NP',
  IMK: 'NP',
  TPJ: 'NP',
  TPU: 'NP',
  TMI: 'NP',
  BIR: 'NP',
  LTU: 'IN',
  TCR: 'IN',
  AGX: 'IN',
  BEP: 'IN',
  BLR: 'IN',
  IXX: 'IN',
  VGA: 'IN',
  CJB: 'IN',
  COK: 'IN',
  CCJ: 'IN',
  CDP: 'IN',
  CBD: 'IN',
  GBI: 'IN',
  HYD: 'IN',
  BPM: 'IN',
  CNN: 'IN',
  KJB: 'IN',
  IXM: 'IN',
  IXE: 'IN',
  MAA: 'IN',
  MYQ: 'IN',
  IXZ: 'IN',
  PNY: 'IN',
  PUT: 'IN',
  RMD: 'IN',
  RJA: 'IN',
  SXV: 'IN',
  SDW: 'IN',
  TJV: 'IN',
  TIR: 'IN',
  TRZ: 'IN',
  TRV: 'IN',
  WGC: 'IN',
  YON: 'BT',
  BUT: 'BT',
  GLU: 'BT',
  PBH: 'BT',
  HRF: 'MV',
  HDK: 'MV',
  NMF: 'MV',
  IFU: 'MV',
  DRV: 'MV',
  FVM: 'MV',
  GAN: 'MV',
  HAQ: 'MV',
  KDO: 'MV',
  MLE: 'MV',
  GKK: 'MV',
  KDM: 'MV',
  DDD: 'MV',
  VAM: 'MV',
  TMF: 'MV',
  RUL: 'MV',
  DMK: 'TH',
  KKM: 'TH',
  KDT: 'TH',
  TDX: 'TH',
  BKK: 'TH',
  UTP: 'TH',
  CNX: 'TH',
  HGN: 'TH',
  PYY: 'TH',
  LPT: 'TH',
  NNT: 'TH',
  PRH: 'TH',
  CEI: 'TH',
  BAO: 'TH',
  PHY: 'TH',
  HHQ: 'TH',
  TKH: 'TH',
  MAQ: 'TH',
  THS: 'TH',
  PHS: 'TH',
  TKT: 'TH',
  UTR: 'TH',
  URT: 'TH',
  NAW: 'TH',
  CJM: 'TH',
  NST: 'TH',
  KBV: 'TH',
  SGZ: 'TH',
  PAN: 'TH',
  USM: 'TH',
  HKT: 'TH',
  UNN: 'TH',
  HDY: 'TH',
  TST: 'TH',
  UTH: 'TH',
  SNO: 'TH',
  PXR: 'TH',
  KKC: 'TH',
  LOE: 'TH',
  BFV: 'TH',
  NAK: 'TH',
  UBP: 'TH',
  ROI: 'TH',
  KOP: 'TH',
  VUU: 'MW',
  BMV: 'VN',
  VCL: 'VN',
  HPH: 'VN',
  CAH: 'VN',
  CXR: 'VN',
  VCS: 'VN',
  VCA: 'VN',
  DIN: 'VN',
  DLI: 'VN',
  DAD: 'VN',
  VVN: 'PE',
  HAN: 'VN',
  SQH: 'VN',
  HUI: 'VN',
  UIH: 'VN',
  PXU: 'VN',
  PQC: 'VN',
  PHA: 'VN',
  VKG: 'VN',
  TBB: 'VN',
  SGN: 'VN',
  THD: 'VN',
  VDO: 'VN',
  VII: 'VN',
  VTG: 'VN',
  VBA: 'MM',
  NYU: 'MM',
  BMO: 'MM',
  VBP: 'MM',
  VBC: 'MM',
  TVY: 'MM',
  NYT: 'MM',
  GAW: 'MM',
  GWA: 'MM',
  HOX: 'MM',
  TIO: 'MM',
  KET: 'MM',
  KHM: 'MM',
  KMV: 'MM',
  KYP: 'MM',
  KAW: 'MM',
  KYT: 'MM',
  LIW: 'MM',
  LSH: 'MM',
  MDL: 'MM',
  MGZ: 'MM',
  MYT: 'MM',
  MNU: 'MM',
  MGU: 'MM',
  MOE: 'MM',
  MOG: 'MM',
  MGK: 'MM',
  MWQ: 'MM',
  NYW: 'MM',
  NMS: 'MM',
  NMT: 'MM',
  PAA: 'MM',
  PAU: 'MM',
  BSX: 'MM',
  PPU: 'MM',
  PBU: 'MM',
  PKK: 'MM',
  PRU: 'MM',
  AKY: 'MM',
  SNW: 'MM',
  THL: 'MM',
  XYE: 'MM',
  RGN: 'MM',
  FBS: 'US',
  RSJ: 'US',
  LKE: 'US',
  RCE: 'US',
  TQQ: 'ID',
  WSX: 'US',
  UPG: 'ID',
  BIK: 'ID',
  ONI: 'ID',
  FOO: 'ID',
  WET: 'ID',
  NBX: 'ID',
  ILA: 'ID',
  KOX: 'ID',
  TIM: 'ID',
  EWI: 'ID',
  WAD: 'MG',
  AMI: 'ID',
  BMU: 'ID',
  DPS: 'ID',
  LOP: 'ID',
  SWQ: 'ID',
  TMC: 'ID',
  WGP: 'ID',
  OTI: 'ID',
  TRT: 'ID',
  LLO: 'ID',
  PLW: 'ID',
  MJU: 'ID',
  MXB: 'ID',
  PSJ: 'ID',
  OJU: 'ID',
  LUW: 'ID',
  HMS: 'ID',
  KLP: 'ID',
  PKY: 'ID',
  YIA: 'ID',
  CXP: 'ID',
  KWB: 'ID',
  BUI: 'ID',
  ZRM: 'ID',
  DJJ: 'ID',
  LHI: 'ID',
  LII: 'ID',
  OKL: 'ID',
  SEH: 'ID',
  WRR: 'ID',
  MDP: 'ID',
  BXD: 'ID',
  MKQ: 'ID',
  OKQ: 'ID',
  KEI: 'ID',
  TMH: 'ID',
  GHS: 'ID',
  TJS: 'ID',
  DTD: 'ID',
  BEJ: 'ID',
  BPN: 'ID',
  TRK: 'ID',
  AAP: 'ID',
  TSX: 'ID',
  BYQ: 'ID',
  GLX: 'ID',
  PGQ: 'ID',
  GTO: 'ID',
  NAH: 'ID',
  TLI: 'ID',
  GEB: 'ID',
  KAZ: 'ID',
  MDC: 'ID',
  MNA: 'ID',
  BRG: 'ID',
  TTE: 'ID',
  UOL: 'ID',
  WAN: 'AU',
  BTW: 'ID',
  PKN: 'ID',
  KBU: 'ID',
  TJG: 'ID',
  BDJ: 'ID',
  SMQ: 'ID',
  AHI: 'ID',
  NDA: 'ID',
  DOB: 'ID',
  MAL: 'ID',
  LUV: 'ID',
  NRE: 'ID',
  LAH: 'ID',
  BJK: 'ID',
  SQN: 'ID',
  AMQ: 'ID',
  NAM: 'ID',
  TAX: 'ID',
  WBA: 'ID',
  LNU: 'ID',
  MLG: 'ID',
  CPF: 'ID',
  JOG: 'ID',
  SOC: 'ID',
  SUB: 'ID',
  SRG: 'ID',
  SUP: 'ID',
  NTI: 'ID',
  RSK: 'ID',
  KEQ: 'ID',
  FKQ: 'ID',
  INX: 'ID',
  KNG: 'ID',
  RDE: 'ID',
  RJM: 'ID',
  BXB: 'ID',
  MKW: 'ID',
  TXM: 'ID',
  WSR: 'ID',
  BJW: 'ID',
  MOF: 'ID',
  ENE: 'ID',
  RTG: 'ID',
  ARD: 'ID',
  LBJ: 'ID',
  RTI: 'ID',
  SAU: 'ID',
  KOE: 'ID',
  WMX: 'ID',
  BUW: 'ID',
  WNI: 'ID',
  KSR: 'ID',
  SQR: 'ID',
  TTR: 'ID',
  KDI: 'ID',
  SOQ: 'ID',
  WBB: 'US',
  WBC: 'PG',
  BTU: 'MY',
  BLG: 'MY',
  LSM: 'MY',
  LGL: 'MY',
  KCH: 'MY',
  ODN: 'MY',
  LMN: 'MY',
  MKM: 'MY',
  LKH: 'MY',
  MUR: 'MY',
  BSE: 'MY',
  KPI: 'MY',
  BKM: 'MY',
  MYY: 'MY',
  SBW: 'MY',
  TGC: 'MY',
  LSU: 'MY',
  LWY: 'MY',
  BBN: 'MY',
  SMM: 'MY',
  LDU: 'MY',
  TEL: 'MY',
  KGU: 'MY',
  SXS: 'MY',
  BKI: 'MY',
  LBU: 'MY',
  TMG: 'MY',
  GSA: 'MY',
  SPE: 'MY',
  PAY: 'MY',
  RNU: 'MY',
  SDK: 'MY',
  KUD: 'MY',
  TWU: 'MY',
  MZV: 'MY',
  BWN: 'BN',
  WDA: 'YE',
  WEA: 'US',
  WED: 'PG',
  WHL: 'AU',
  CJN: 'ID',
  PKU: 'ID',
  DUM: 'ID',
  RGT: 'ID',
  RKO: 'ID',
  SEQ: 'ID',
  TJB: 'ID',
  KJT: 'ID',
  BDO: 'ID',
  CBN: 'ID',
  TSY: 'ID',
  BTH: 'ID',
  PPR: 'ID',
  LMU: 'ID',
  MWK: 'ID',
  TNJ: 'ID',
  SIQ: 'ID',
  MPC: 'ID',
  HLP: 'ID',
  PCB: 'ID',
  CGK: 'ID',
  TKG: 'ID',
  TFY: 'ID',
  GNS: 'ID',
  AEG: 'ID',
  MES: 'ID',
  KNO: 'ID',
  DTB: 'ID',
  SIW: 'ID',
  FLZ: 'ID',
  LSR: 'ID',
  TJQ: 'ID',
  NPO: 'ID',
  KTG: 'ID',
  NTX: 'ID',
  PNK: 'ID',
  PSU: 'ID',
  SQG: 'ID',
  DJB: 'ID',
  LLJ: 'ID',
  BUU: 'ID',
  PGK: 'ID',
  BKS: 'ID',
  PLM: 'ID',
  PDO: 'ID',
  PDG: 'ID',
  PXA: 'ID',
  TPK: 'ID',
  SBG: 'ID',
  MEQ: 'ID',
  TXE: 'ID',
  LSX: 'ID',
  LSW: 'ID',
  BTJ: 'ID',
  SXT: 'MY',
  MEP: 'MY',
  SWY: 'MY',
  TPG: 'MY',
  TOD: 'MY',
  AOR: 'MY',
  BWH: 'MY',
  KBR: 'MY',
  KUA: 'MY',
  KTE: 'MY',
  IPH: 'MY',
  JHB: 'MY',
  KUL: 'MY',
  LGK: 'MY',
  MKZ: 'MY',
  TGG: 'MY',
  PEN: 'MY',
  PKG: 'MY',
  RDN: 'MY',
  SZB: 'MY',
  DTR: 'US',
  WNU: 'PG',
  SXK: 'ID',
  AUT: 'TL',
  UAI: 'TL',
  DIL: 'TL',
  BCH: 'TL',
  MPT: 'TL',
  OEC: 'TL',
  VIQ: 'TL',
  ABU: 'ID',
  LKA: 'ID',
  SGQ: 'ID',
  LBW: 'ID',
  BXT: 'ID',
  NNX: 'ID',
  TNB: 'ID',
  LPU: 'ID',
  SZH: 'ID',
  WSA: 'PG',
  QPG: 'SG',
  TGA: 'SG',
  WSB: 'US',
  UGI: 'US',
  WSM: 'US',
  XSP: 'SG',
  SIN: 'SG',
  WTT: 'PG',
  WUV: 'PG',
  WUZ: 'CN',
  GWV: 'US',
  WZQ: 'CN',
  MPB: 'US',
  XBB: 'CA',
  XBN: 'PG',
  SKL: 'GB',
  XIG: 'BR',
  XMA: 'PH',
  XRQ: 'CN',
  LNX: 'RU',
  XZM: 'MO',
  UKN: 'US',
  ALH: 'AU',
  ABG: 'AU',
  AWN: 'AU',
  AUD: 'AU',
  YAJ: 'CA',
  MRP: 'AU',
  AXL: 'AU',
  AXC: 'AU',
  ADO: 'AU',
  AMX: 'AU',
  AMT: 'AU',
  WLP: 'AU',
  AYL: 'AU',
  YAQ: 'CA',
  ARY: 'AU',
  GYL: 'AU',
  ARM: 'AU',
  AAB: 'AU',
  AUU: 'AU',
  AWP: 'AU',
  AVG: 'AU',
  AYQ: 'AU',
  AYR: 'AU',
  BCI: 'AU',
  ASP: 'AU',
  BDD: 'AU',
  BKP: 'AU',
  BNE: 'AU',
  OOL: 'AU',
  BKQ: 'AU',
  CNS: 'AU',
  CTL: 'AU',
  BDW: 'AU',
  BXG: 'AU',
  BVI: 'AU',
  BXF: 'AU',
  BTX: 'AU',
  BEE: 'AU',
  OCM: 'AU',
  BQW: 'AU',
  YBH: 'CA',
  BHQ: 'AU',
  HTI: 'AU',
  BEU: 'AU',
  BIW: 'AU',
  BZP: 'AU',
  YBJ: 'CA',
  BRK: 'AU',
  BUC: 'AU',
  BLN: 'AU',
  LCN: 'AU',
  ZBL: 'AU',
  BLS: 'AU',
  BQB: 'AU',
  ISA: 'AU',
  BFC: 'AU',
  MKY: 'AU',
  BNK: 'AU',
  BSJ: 'AU',
  GIC: 'AU',
  OKY: 'AU',
  BQL: 'AU',
  YBP: 'CN',
  BMP: 'AU',
  KAH: 'AU',
  PPP: 'AU',
  YBQ: 'CA',
  ROK: 'AU',
  BOX: 'AU',
  BME: 'AU',
  BZD: 'AU',
  BTD: 'AU',
  BWQ: 'AU',
  BYP: 'AU',
  MCY: 'AU',
  BHS: 'AU',
  BRT: 'AU',
  TSV: 'AU',
  BLT: 'AU',
  BVW: 'AU',
  BDB: 'AU',
  BUY: 'AU',
  BIP: 'AU',
  ZBO: 'AU',
  WEI: 'AU',
  BCK: 'AU',
  WTB: 'AU',
  BWB: 'AU',
  BVZ: 'AU',
  CTR: 'AU',
  CGV: 'AU',
  CLH: 'AU',
  CVQ: 'AU',
  CSI: 'AU',
  CAZ: 'AU',
  COJ: 'AU',
  CBY: 'AU',
  CBI: 'AU',
  CPD: 'AU',
  CRB: 'AU',
  CCL: 'AU',
  CNC: 'AU',
  CNJ: 'AU',
  CBX: 'AU',
  CUD: 'AU',
  CED: 'AU',
  CVC: 'AU',
  YCF: 'CA',
  CFI: 'AU',
  CFH: 'AU',
  CQP: 'AU',
  CFS: 'AU',
  LLG: 'AU',
  CRH: 'AU',
  CKW: 'AU',
  CXT: 'AU',
  DCN: 'AU',
  CKI: 'AU',
  CTN: 'AU',
  CMQ: 'AU',
  CMA: 'AU',
  CML: 'AU',
  NIF: 'AU',
  CES: 'AU',
  CNB: 'AU',
  ODL: 'AU',
  CUQ: 'AU',
  CIE: 'AU',
  OOM: 'AU',
  CDA: 'AU',
  CWW: 'AU',
  CFP: 'AU',
  CYG: 'AU',
  CXQ: 'AU',
  CDQ: 'AU',
  KCE: 'AU',
  CMD: 'AU',
  CUG: 'AU',
  CUY: 'AU',
  CJF: 'AU',
  CWR: 'AU',
  CCW: 'AU',
  CWT: 'AU',
  COY: 'AU',
  DJR: 'AU',
  DBY: 'AU',
  DRN: 'AU',
  DNB: 'AU',
  DRB: 'AU',
  DFP: 'AU',
  DGD: 'AU',
  DNG: 'AU',
  DXD: 'AU',
  DKI: 'AU',
  DLK: 'AU',
  DNQ: 'AU',
  DDN: 'AU',
  DLV: 'AU',
  DYW: 'AU',
  DMD: 'AU',
  DVR: 'AU',
  NLF: 'AU',
  DRD: 'AU',
  DVP: 'AU',
  DPO: 'AU',
  DOX: 'AU',
  DRY: 'AU',
  DHD: 'AU',
  DRR: 'AU',
  SRR: 'AU',
  DKV: 'AU',
  DYA: 'AU',
  ECH: 'AU',
  EUC: 'AU',
  ETD: 'AU',
  ENB: 'AU',
  EIH: 'AU',
  ELC: 'AU',
  EKD: 'AU',
  EMD: 'AU',
  YEQ: 'PG',
  EDD: 'AU',
  ERB: 'AU',
  ERQ: 'AU',
  EPR: 'AU',
  EVD: 'AU',
  EVH: 'AU',
  WHB: 'AU',
  EXM: 'AU',
  FRB: 'AU',
  KFE: 'AU',
  FLY: 'AU',
  FLS: 'AU',
  FVL: 'AU',
  FIK: 'AU',
  FOS: 'AU',
  FVR: 'AU',
  FOT: 'AU',
  JFM: 'AU',
  FIZ: 'AU',
  YGA: 'CN',
  GBP: 'AU',
  GAH: 'AU',
  GBL: 'AU',
  GUH: 'AU',
  GOO: 'AU',
  GDD: 'AU',
  GGD: 'AU',
  YGE: 'CA',
  GET: 'AU',
  GFN: 'AU',
  GBW: 'AU',
  GBV: 'AU',
  GKL: 'AU',
  GLT: 'AU',
  GUL: 'AU',
  GLG: 'AU',
  GLI: 'AU',
  GLM: 'AU',
  YGN: 'CA',
  GFE: 'AU',
  GVP: 'AU',
  GPD: 'AU',
  GPN: 'AU',
  GSC: 'AU',
  GTE: 'AU',
  GFF: 'AU',
  GTT: 'AU',
  GEE: 'AU',
  GYP: 'AU',
  HWK: 'AU',
  HXX: 'AU',
  HVB: 'AU',
  HUB: 'AU',
  HRY: 'AU',
  HIP: 'AU',
  HIG: 'AU',
  HID: 'AU',
  HLL: 'AU',
  HCQ: 'AU',
  HMG: 'AU',
  HLT: 'AU',
  HOK: 'AU',
  MHU: 'AU',
  HTU: 'AU',
  HPE: 'AU',
  HSM: 'AU',
  HAT: 'AU',
  HGD: 'AU',
  IDK: 'AU',
  IFL: 'AU',
  IFF: 'AU',
  IGH: 'AU',
  IKP: 'AU',
  INJ: 'AU',
  INM: 'AU',
  IVW: 'AU',
  ISI: 'AU',
  IVR: 'AU',
  JAB: 'AU',
  JUN: 'AU',
  JCK: 'AU',
  JUR: 'AU',
  UBU: 'AU',
  KDB: 'AU',
  KAX: 'AU',
  KBY: 'AU',
  KBJ: 'AU',
  KCS: 'AU',
  OOD: 'AU',
  KRA: 'AU',
  KNS: 'AU',
  KBB: 'AU',
  KFG: 'AU',
  KOH: 'AU',
  KKP: 'AU',
  KCI: 'AU',
  KRB: 'AU',
  KML: 'AU',
  KPS: 'AU',
  KNI: 'AU',
  KWM: 'AU',
  KPP: 'AU',
  KGY: 'AU',
  KGC: 'AU',
  YKT: 'CA',
  KUG: 'AU',
  KRD: 'AU',
  LWH: 'AU',
  LGH: 'AU',
  LNO: 'AU',
  LEL: 'AU',
  LFP: 'AU',
  LDH: 'AU',
  IRG: 'AU',
  LTP: 'AU',
  LIB: 'AU',
  LDC: 'AU',
  LSY: 'AU',
  LNH: 'AU',
  BBL: 'AU',
  BEO: 'AU',
  LKD: 'AU',
  LOC: 'AU',
  LOA: 'AU',
  LTV: 'AU',
  YLP: 'CA',
  LUU: 'AU',
  LHG: 'AU',
  LRE: 'AU',
  LUT: 'AU',
  LER: 'AU',
  LVO: 'AU',
  TGN: 'AU',
  LZR: 'AU',
  UBB: 'AU',
  MYI: 'AU',
  AVV: 'AU',
  ABX: 'AU',
  MRG: 'AU',
  MBB: 'AU',
  XMC: 'AU',
  MFP: 'AU',
  MLR: 'AU',
  DGE: 'AU',
  MQA: 'AU',
  MNW: 'AU',
  MKR: 'AU',
  MEB: 'AU',
  MIM: 'AU',
  MLV: 'AU',
  MGT: 'AU',
  MNG: 'AU',
  GSN: 'AU',
  MGV: 'AU',
  MQZ: 'AU',
  MVU: 'AU',
  HBA: 'AU',
  MHO: 'AU',
  MCV: 'AU',
  MQL: 'AU',
  XML: 'AU',
  MIH: 'AU',
  MWY: 'AU',
  MTQ: 'AU',
  MJP: 'AU',
  WLE: 'AU',
  LST: 'AU',
  MBW: 'AU',
  WUI: 'AU',
  MEL: 'AU',
  MMM: 'AU',
  MTL: 'AU',
  WME: 'AU',
  ONR: 'AU',
  MSF: 'AU',
  OXY: 'AU',
  MMG: 'AU',
  OOR: 'AU',
  MRZ: 'AU',
  MET: 'AU',
  MIN: 'AU',
  MQE: 'AU',
  MOV: 'AU',
  RRE: 'AU',
  MWB: 'AU',
  MYA: 'AU',
  MTD: 'AU',
  MIY: 'AU',
  UTB: 'AU',
  MGB: 'AU',
  ONG: 'AU',
  MNQ: 'AU',
  MUQ: 'AU',
  MNE: 'AU',
  MVK: 'AU',
  MUP: 'AU',
  MXU: 'AU',
  MWT: 'AU',
  MXD: 'AU',
  MBH: 'AU',
  RTY: 'AU',
  NMR: 'AU',
  NRA: 'AU',
  NAA: 'AU',
  RPM: 'AU',
  NBH: 'AU',
  NLS: 'AU',
  NKB: 'AU',
  NMP: 'AU',
  NPP: 'AU',
  ABM: 'AU',
  NAC: 'AU',
  NRG: 'AU',
  QRM: 'AU',
  RVT: 'AU',
  NSV: 'AU',
  NSM: 'AU',
  YNT: 'CN',
  NTN: 'AU',
  NUR: 'AU',
  NLL: 'AU',
  NUB: 'AU',
  UTD: 'AU',
  ZNE: 'AU',
  NYN: 'AU',
  OPI: 'AU',
  YOI: 'CA',
  XCO: 'AU',
  OLP: 'AU',
  ONS: 'AU',
  ODD: 'AU',
  MOO: 'AU',
  RBS: 'AU',
  OAG: 'AU',
  ODR: 'AU',
  OSO: 'AU',
  OYN: 'AU',
  ADL: 'AU',
  PUG: 'AU',
  PMK: 'AU',
  PBO: 'AU',
  CCK: 'CC',
  PDN: 'AU',
  PDE: 'AU',
  DRW: 'AU',
  PRD: 'AU',
  GOV: 'AU',
  PPI: 'AU',
  JAD: 'AU',
  KTA: 'AU',
  KGI: 'AU',
  PKE: 'AU',
  PKT: 'AU',
  KNX: 'AU',
  PLO: 'AU',
  LEA: 'AU',
  PXH: 'AU',
  EDR: 'AU',
  PQQ: 'AU',
  PEY: 'AU',
  PTJ: 'AU',
  PHE: 'AU',
  PER: 'AU',
  PEA: 'AU',
  KTR: 'AU',
  UMR: 'AU',
  XCH: 'CX',
  UIR: 'AU',
  YQJ: 'CA',
  ULP: 'AU',
  UEE: 'AU',
  RRV: 'AU',
  YRC: 'CA',
  YRD: 'CA',
  RMK: 'AU',
  RCM: 'AU',
  RAM: 'AU',
  ROH: 'AU',
  RBU: 'AU',
  RBC: 'AU',
  RMA: 'AU',
  RPB: 'AU',
  RSB: 'AU',
  RTS: 'AU',
  RTP: 'AU',
  RHL: 'AU',
  NDS: 'AU',
  BWU: 'AU',
  CBR: 'AU',
  WCD: 'AU',
  CDU: 'AU',
  NSO: 'AU',
  SQC: 'AU',
  DBO: 'AU',
  SGO: 'AU',
  SIX: 'AU',
  ZGL: 'AU',
  SGP: 'AU',
  MJK: 'AU',
  WOL: 'AU',
  WSY: 'AU',
  SHT: 'AU',
  SBR: 'AU',
  GOS: 'AU',
  SIO: 'AU',
  SOI: 'AU',
  SHU: 'AU',
  STH: 'AU',
  SNB: 'AU',
  NLK: 'NF',
  NOA: 'AU',
  SNH: 'AU',
  SCG: 'AU',
  SHQ: 'AU',
  KSV: 'AU',
  XRH: 'AU',
  SRN: 'AU',
  SYD: 'AU',
  HLS: 'AU',
  STF: 'AU',
  TMW: 'AU',
  SSP: 'AU',
  WGA: 'AU',
  SWH: 'AU',
  SWC: 'AU',
  XTR: 'AU',
  TBL: 'AU',
  XTO: 'AU',
  TAQ: 'AU',
  TBK: 'AU',
  TDR: 'AU',
  TQP: 'AU',
  TEF: 'AU',
  TEM: 'AU',
  TAN: 'AU',
  XTG: 'AU',
  GTS: 'AU',
  TDN: 'AU',
  TYG: 'AU',
  TYB: 'AU',
  TKY: 'AU',
  PHQ: 'AU',
  TUM: 'AU',
  TYP: 'AU',
  TXR: 'AU',
  THG: 'AU',
  TCA: 'AU',
  TCW: 'AU',
  TRO: 'AU',
  TTX: 'AU',
  TWB: 'AU',
  UDA: 'AU',
  CZY: 'AU',
  USL: 'AU',
  VCD: 'AU',
  VNR: 'AU',
  WAU: 'AU',
  WLA: 'AU',
  WAV: 'AU',
  WMB: 'AU',
  SYU: 'AU',
  WIO: 'AU',
  WLC: 'AU',
  WAZ: 'AU',
  WND: 'AU',
  WRN: 'AU',
  WNR: 'AU',
  WON: 'AU',
  MFL: 'AU',
  GYB: 'AU',
  WGT: 'AU',
  WYA: 'AU',
  WIT: 'AU',
  WKB: 'AU',
  WGE: 'AU',
  NTL: 'AU',
  WUN: 'AU',
  WPK: 'AU',
  WDI: 'AU',
  WLL: 'AU',
  QRR: 'AU',
  SXE: 'AU',
  WLO: 'AU',
  WIN: 'AU',
  WUD: 'AU',
  WEW: 'AU',
  WRW: 'AU',
  WWI: 'AU',
  WWY: 'AU',
  WYN: 'AU',
  BWT: 'AU',
  YYA: 'CN',
  YLG: 'AU',
  OKR: 'AU',
  KYF: 'AU',
  XMY: 'AU',
  YUE: 'AU',
  NGA: 'AU',
  ORR: 'AU',
  KYI: 'AU',
  KKI: 'US',
  BCC: 'US',
  JBT: 'US',
  CZP: 'US',
  KBW: 'US',
  KBC: 'US',
  CZC: 'US',
  HPR: 'ZA',
  ULX: 'ZA',
  TDT: 'ZA',
  HZV: 'ZA',
  KHO: 'ZA',
  MBM: 'ZA',
  INY: 'ZA',
  TSD: 'ZA',
  SSX: 'ZA',
  KIG: 'ZA',
  PEK: 'CN',
  PKX: 'CN',
  CDE: 'CN',
  CIF: 'CN',
  CIH: 'CN',
  BPE: 'CN',
  DSN: 'CN',
  DAT: 'CN',
  ERL: 'CN',
  YIE: 'CN',
  HDG: 'CN',
  HET: 'CN',
  HUO: 'CN',
  HLD: 'CN',
  LFQ: 'CN',
  LLV: 'CN',
  NZH: 'CN',
  BAV: 'CN',
  SJW: 'CN',
  TSN: 'CN',
  TGO: 'CN',
  UCB: 'CN',
  WUA: 'CN',
  HLH: 'CN',
  XIL: 'CN',
  XNT: 'CN',
  WUT: 'CN',
  YCU: 'CN',
  TYN: 'CN',
  RLK: 'CN',
  NZL: 'CN',
  ZDM: 'PS',
  ZDY: 'AE',
  ZEN: 'PG',
  BHY: 'CN',
  CGD: 'CN',
  HJJ: 'CN',
  HCZ: 'CN',
  DYG: 'CN',
  CAN: 'CN',
  CSX: 'CN',
  HCJ: 'CN',
  SHF: 'CN',
  HNY: 'CN',
  KWL: 'CN',
  LLF: 'CN',
  MXZ: 'CN',
  NNG: 'CN',
  SWA: 'CN',
  ZUH: 'CN',
  WGN: 'CN',
  SZX: 'CN',
  XIN: 'CN',
  YLX: 'CN',
  LZH: 'CN',
  AYN: 'CN',
  CGO: 'CN',
  ENH: 'CN',
  LHK: 'CN',
  WUH: 'CN',
  SHS: 'CN',
  LYA: 'CN',
  NNY: 'CN',
  HPG: 'CN',
  WDS: 'CN',
  XFN: 'CN',
  YIH: 'CN',
  ZHH: 'CA',
  ZIZ: 'PK',
  HAK: 'CN',
  BAR: 'CN',
  SYX: 'CN',
  XYI: 'CN',
  FNJ: 'KP',
  DSO: 'KP',
  WOS: 'KP',
  AKA: 'CN',
  DNH: 'CN',
  HXD: 'CN',
  GOQ: 'CN',
  GYU: 'CN',
  HTT: 'CN',
  HZG: 'CN',
  INC: 'CN',
  JNG: 'CN',
  JGN: 'CN',
  LHW: 'CN',
  LNL: 'CN',
  IQN: 'CN',
  SIA: 'CN',
  GXH: 'CN',
  XNN: 'CN',
  XIY: 'CN',
  UYN: 'CN',
  ZHY: 'CN',
  UMB: 'ZM',
  AVK: 'MN',
  LTI: 'MN',
  BYN: 'MN',
  UGA: 'MN',
  UGT: 'MN',
  HBU: 'MN',
  UUN: 'MN',
  COQ: 'MN',
  UBN: 'MN',
  ZMD: 'BR',
  ULZ: 'MN',
  DLZ: 'MN',
  KHR: 'MN',
  HJT: 'MN',
  HVD: 'MN',
  MXV: 'MN',
  TSZ: 'MN',
  TNZ: 'MN',
  ULN: 'MN',
  ULO: 'MN',
  ULG: 'MN',
  ZNC: 'US',
  ZNU: 'CA',
  CWJ: 'CN',
  DLU: 'CN',
  DIG: 'CN',
  JHG: 'CN',
  JMJ: 'CN',
  LJG: 'CN',
  LUM: 'CN',
  KMG: 'CN',
  SYM: 'CN',
  WNH: 'CN',
  ZAT: 'CN',
  ZRI: 'ID',
  XMN: 'CN',
  AQG: 'CN',
  BFU: 'CN',
  CZX: 'CN',
  KHN: 'CN',
  FUG: 'CN',
  FOC: 'CN',
  KOW: 'CN',
  HGH: 'CN',
  HZA: 'CN',
  JDZ: 'CN',
  JIU: 'CN',
  TNA: 'CN',
  JUZ: 'CN',
  LCX: 'CN',
  HYN: 'CN',
  LYI: 'CN',
  NGB: 'CN',
  NKG: 'CN',
  HFE: 'CN',
  PVG: 'CN',
  JJN: 'CN',
  RUG: 'CN',
  RIZ: 'CN',
  SHA: 'CN',
  SZV: 'CN',
  TXN: 'CN',
  WHA: 'CN',
  WEF: 'CN',
  WEH: 'CN',
  WHU: 'CN',
  WUX: 'CN',
  WUS: 'CN',
  WNZ: 'CN',
  XUZ: 'CN',
  YTY: 'CN',
  YIC: 'CN',
  YNZ: 'CN',
  YIW: 'CN',
  HSN: 'CN',
  NGQ: 'CN',
  AVA: 'CN',
  BPX: 'CN',
  BFJ: 'CN',
  CKG: 'CN',
  DCY: 'CN',
  DAX: 'CN',
  GHN: 'CN',
  GYS: 'CN',
  KWE: 'CN',
  GZG: 'CN',
  JZH: 'CN',
  KGT: 'CN',
  KJH: 'CN',
  LLB: 'CN',
  LIA: 'CN',
  LXA: 'CN',
  LZO: 'CN',
  UNR: 'MN',
  WMT: 'CN',
  MIG: 'CN',
  NAO: 'CN',
  HZH: 'CN',
  LZY: 'CN',
  LPF: 'CN',
  LGZ: 'CN',
  TCZ: 'CN',
  TFU: 'CN',
  TEN: 'CN',
  CTU: 'CN',
  CQW: 'CN',
  WSK: 'CN',
  WXN: 'CN',
  XIC: 'CN',
  ACX: 'CN',
  ZYI: 'CN',
  AKU: 'CN',
  ACF: 'CN',
  BPL: 'CN',
  IQM: 'CN',
  FYN: 'CN',
  HMI: 'CN',
  KCA: 'CN',
  KRL: 'CN',
  KRY: 'CN',
  KJI: 'CN',
  NLT: 'CN',
  RQA: 'CN',
  QSZ: 'CN',
  KHG: 'CN',
  SXJ: 'CN',
  TCG: 'CN',
  TLQ: 'CN',
  HTN: 'CN',
  URC: 'CN',
  YIN: 'CN',
  YTW: 'CN',
  AOG: 'CN',
  CGQ: 'CN',
  CNI: 'CN',
  CHG: 'CN',
  DTU: 'CN',
  FYJ: 'CN',
  HRB: 'CN',
  HEK: 'CN',
  JIL: 'CN',
  JMU: 'CN',
  JSJ: 'CN',
  JXA: 'CN',
  LDS: 'CN',
  YUS: 'CN',
  MDG: 'CN',
  OHE: 'CN',
  NDG: 'CN',
  YSQ: 'CN',
  DLC: 'CN',
  TNH: 'CN',
  SHE: 'CN',
  YNJ: 'CN',
  YKH: 'CN',
};
