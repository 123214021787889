import {
  type Booking,
  BookingRefund,
  type ClientInvoice,
  type ClientInvoiceMapping,
} from '@prisma/client';
import type Decimal from 'decimal.js';
import type { CellObject } from 'xlsx';

type TravelNumberFromArc = string;
type TravelNumberFromBookings = string;
type StatusMessage = string;

export type XlsxRow = [
  TravelNumberFromArc,
  TravelNumberFromBookings | CellObject,
  StatusMessage,
];

export type XlsxRowWithDebug = [
  TravelNumberFromArc,
  TravelNumberFromBookings | CellObject,
  DateEntered: string,
  Pnr: string,
  TicketType: string,
  Void: string,
  Sabre: string,
  Notes: string,
  StatusMessage,
];

export function debugToXlsxRow(row: XlsxRowWithDebug): XlsxRow {
  return [row[0], row[1], row[8]];
}

export interface RecordType {
  totalAmountChargedToCard: Decimal;
  tax: Decimal;
  commission: Decimal;
  netCommission?: Decimal;
  netRemit?: Decimal;
  totalN?: Decimal;
  totalO?: Decimal;
}

export interface TicketTypeMapValue {
  ticketType: TicketType;
  travelNumberFromBos: string;
}

export type getRelatedBookingsResponseDto = {
  originalBooking: Booking;
  exchangedBooking: Booking | undefined;
};

//json aggregation uses string as values instead of decimal
export type bookingRefundAggregateType = {
  id: string;
  commissionableValueAmount: string;
  amount: string;
  estCommissionAmount: string;
};

export type BookingWithRefundsAggregated = Booking & {
  refunds: bookingRefundAggregateType[] | null;
  returnedCouponUsage?: string;
};

export type ClientInvoiceWithMappings = ClientInvoice & {
  clientInvoiceMapping?: ClientInvoiceMapping[];
};

export type FindBookingOrFeeResponse = {
  foundBookingTree: BookingWithRefundsAggregated[] | undefined;
  foundBooking: BookingWithRefundsAggregated | undefined;
  foundMappedBooking: Booking | null;
  foundFee: ClientInvoiceWithMappings | null;
  directExchangeBookings: BookingWithRefundsAggregated[] | undefined;
};

export interface CsvRecord {
  'Carrier Number': string;
  'Document Number': string;
  'Branch (Agency)': string;
  'Entry Date': string;
  'Status (V = void)': string;
  'Total Amount charged to card': string;
  Tax: string;
  Commission: string;
  'FOP Type (CC - Credit card/CA - CASH)': string;
  PNR: string;
  'Returned Coupon Usage': string;
  'Admin/Penalty Fee': string;
  'Primary Document Number': string;
  'Returned Document Number': string;
  'Credit card number': string;
  'System Entry Date': string;
  'Date Voided': string;
  'Ticket Type': string;
  'ESAC Airline approval code': string; //cspell: disable-line
  'Service Provider ID': string;
  'Tour Code': string;
  'Net Remit': string;
  'Passenger Name': string;
}

//If the Ticket Type in the BOS file is MC, it refers to a fee.
export enum TicketType {
  //RF = refund, MC = service fee, ET = straight sale, EX = exchange, MX = fare difference
  //CM = credit memo, DM = debit memo,
  //CN = not known, maybe cancelled
  REFUND = 'RF',
  SERVICE_FEE = 'MC',
  STRAIGHT_SALE = 'ET',
  EXCHANGE = 'EX',
  FARE_DIFFERENCE = 'MX',
  CREDIT_MEMO = 'CM',
  DEBIT_MEMO = 'DM',
  SYSTEM_CANCELLED_VOID = 'CN',
  EMPTY = '',
}

// The field is populated as follows: F - Full Refund, P - Partial Refund, N - New Document in an Exchange,
//O - Old Document in an Exchange, M - Adjustment Memo, and D - Disputed Ticket.
export enum ReturnedCouponUsage {
  FULL_REFUND = 'F',
  PARTIAL_REFUND = 'P',
  NEW_DOCUMENT_IN_AN_EXCHANGE = 'N',
  OLD_DOCUMENT_IN_AN_EXCHANGE = 'O',
  ADJUSTMENT_MEMO = 'M',
  DISPUTED_TICKET = 'D',
}

export enum ReconciliationFieldNames {
  totalAmountChargedToCard = 'Total Amount Charged To Card',
  totalTaxAmount = 'Total Tax Amount',
  commissionAmount = 'Commission Amount',
  amountNotMatch = 'Amount Do Not Match',
}

export enum ExceptionMessages {
  matches = 'BOS document entry matches a booking or fee in TS. ',
  missingBosRecord = 'BOS document entry not found in TripSuite. ',
  missingTSBooking = 'TripSuite booking not found in BOS file. ',
  missingBookingData = 'TS bookings missing total or commissionableValue. ',
  ticketVoided = 'ticket voided in BOS but not in TS. ',
  creditMemo = 'Credit Memo',
  debitMemo = 'Debit Memo',
  spoiled = 'SPOIL; BOS document entry not found in TripSuite. ',
  totalNotMatch = `${ReconciliationFieldNames.totalAmountChargedToCard} ${ReconciliationFieldNames.amountNotMatch}`,
  taxNotMatch = `${ReconciliationFieldNames.totalTaxAmount} ${ReconciliationFieldNames.amountNotMatch}`,
  commissionNotMatch = `${ReconciliationFieldNames.commissionAmount} ${ReconciliationFieldNames.amountNotMatch}`,
}

export const memoToExceptionMessageMap: Record<string, ExceptionMessages> = {
  CM: ExceptionMessages.creditMemo,
  DM: ExceptionMessages.debitMemo,
};

export interface CouponUsage {
  couponType: string;
  totalAmount: Decimal;
}
