export enum TaskTriggerType {
  NONE = 'NONE',
  TRIP_START = 'TRIP_START',
  TRIP_END = 'TRIP_END',
  SPECIFIC_DATE = 'SPECIFIC_DATE',
}

export enum TaskTriggerTiming {
  BEFORE_AFTER = 'BEFORE_AFTER',
  DAY_OF = 'DAY_OF',
}

export enum TaskTriggerTimingQualifier {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
}

export enum TaskTriggerActionType {
  SEND_EMAIL = 'SEND_EMAIL',
}
