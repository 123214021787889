import {
  type ZodObject,
  ZodOptional,
  type ZodRawShape,
  type ZodTypeAny,
  z,
} from 'zod';

// Helper type to extract the inner type of an optional field
type InnerType<T extends ZodTypeAny> = T extends ZodOptional<infer U> ? U : T;

// Utility function to make fields required
export function makeFieldsRequired<T extends ZodRawShape>(
  schema: ZodObject<T>,
): ZodObject<{ [k in keyof T]: InnerType<T[k]> }> {
  const shape = {} as { [k in keyof T]: ZodTypeAny };

  for (const key in schema.shape) {
    if (Object.prototype.hasOwnProperty.call(schema.shape, key)) {
      const field = schema.shape[key];
      shape[key] = field instanceof ZodOptional ? field._def.innerType : field;
    }
  }

  return z.object(shape) as ZodObject<{ [k in keyof T]: InnerType<T[k]> }>;
}

// Helper function to create a trimmed string schema
export const trimmedString = () => z.string().transform((str) => str.trim());
