import type { DashboardPaymentsResponseDto } from 'dtos/dashboard.dtos';
import type {
  DateRangeFilter,
  PaymentTypeFilter,
} from 'services/dashboard/types';
import useSWR from 'swr';
import { getUrlSearchParams } from 'utils/client/fetch';
import type { PaginatedData, SortModel } from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseDashboardPaymentsProps = {
  page: number;
  pageSize: number;
  sortModel: SortModel;
  typeFilter?: PaymentTypeFilter;
  dateRange?: DateRangeFilter;
};

export default function useDashboardPayments({
  page = 1,
  pageSize = 20,
  sortModel: { sort, sortDirection },
  typeFilter,
  dateRange,
}: UseDashboardPaymentsProps) {
  const params = getUrlSearchParams({
    page,
    pageSize,
    sort,
    sortDirection,
    typeFilter,
    dateRange,
    today: new Date().toLocaleDateString('en-CA'),
  });

  const {
    data: response,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<PaginatedData<DashboardPaymentsResponseDto>, Error>(
    `dashboard/payments?${params}`,
    fetcher,
  );

  const { data, meta } = response || {};

  return { data, meta, isLoading, error, refresh: mutate };
}
