export const iataAirlineCodes = [
  { numeric: '001', alpha: 'AA', name: 'American Airlines' },
  { numeric: '002', alpha: '2G', name: 'CargoItalia (alternate)' },
  { numeric: '005', alpha: 'CO', name: 'Continental Airlines' },
  { numeric: '006', alpha: 'DL', name: 'Delta Air Lines' },
  { numeric: '012', alpha: 'NW', name: 'Northwest Airlines (alternate site)' },
  { numeric: '014', alpha: 'AC', name: 'Air Canada' },
  { numeric: '016', alpha: 'UA', name: 'United Airlines' },
  { numeric: '018', alpha: 'CP', name: 'Canadian Airlines Int´l' },
  { numeric: '020', alpha: 'LH', name: 'Lufthansa Cargo AG' },
  { numeric: '023', alpha: 'FX', name: 'Fedex' },
  { numeric: '027', alpha: 'AS', name: 'Alaska Airlines' },
  { numeric: '037', alpha: 'US', name: 'USAirways' },
  { numeric: '042', alpha: 'RG', name: 'VARIG Brazilian Airlines' },
  { numeric: '043', alpha: 'KA', name: 'Dragonair' },
  { numeric: '045', alpha: 'LA', name: 'LAN Chile' },
  { numeric: '047', alpha: 'TP', name: 'TAP Air Portugal' },
  { numeric: '048', alpha: 'CY', name: 'Cyprus Airways' },
  { numeric: '050', alpha: 'OA', name: 'Olympic Airways' },
  { numeric: '053', alpha: 'EI', name: 'Aer Lingus Cargo' },
  { numeric: '055', alpha: 'AZ', name: 'Alitalia' },
  { numeric: '057', alpha: 'AF', name: 'Air France' },
  { numeric: '058', alpha: 'IC', name: 'Indian Airlines' },
  { numeric: '061', alpha: 'HM', name: 'Air Seychelles' },
  { numeric: '064', alpha: 'OK', name: 'Czech Airlines' },
  { numeric: '065', alpha: 'SV', name: 'Saudi Arabian Airlines' },
  { numeric: '070', alpha: 'RB', name: 'Syrian Arab Airlines' },
  { numeric: '071', alpha: 'ET', name: 'Ethiopian Airlines' },
  { numeric: '072', alpha: 'GF', name: 'Gulf Air' },
  { numeric: '074', alpha: 'KL', name: 'KLM Cargo' },
  { numeric: '075', alpha: 'IB', name: 'Iberia' },
  { numeric: '076', alpha: 'ME', name: 'Middle East Airlines' },
  { numeric: '077', alpha: 'MS', name: 'Egyptair' },
  { numeric: '079', alpha: 'PR', name: 'Philippine Airlines' },
  { numeric: '057', alpha: 'AF', name: 'Air France' },
  { numeric: '080', alpha: 'LO', name: 'LOT Polish Airlines' },
  { numeric: '081', alpha: 'QF', name: 'Qantas Airways' },
  { numeric: '082', alpha: 'SN', name: 'Brussels Airlines' },
  { numeric: '083', alpha: 'SA', name: 'South African Airways' },
  { numeric: '086', alpha: 'NZ', name: 'Air New Zealand' },
  { numeric: '090', alpha: 'IT', name: 'Kingfisher Airlines' },
  { numeric: '093', alpha: 'KD', name: 'KD Avia' },
  { numeric: '096', alpha: 'IR', name: 'Iran Air' },
  { numeric: '098', alpha: 'AI', name: 'Air India' },
  { numeric: '105', alpha: 'AY', name: 'Finnair' },
  { numeric: '106', alpha: 'BW', name: 'Caribbean Airlines' },
  { numeric: '108', alpha: 'FI', name: 'Icelandair' },
  { numeric: '112', alpha: 'CK', name: 'China Cargo Airlines' },
  { numeric: '114', alpha: 'LY', name: 'EL AL' },
  { numeric: '115', alpha: 'JU', name: 'JAT Airways' },
  { numeric: '117', alpha: 'SK', name: 'SAS-Scandinavian Airlines System' },
  { numeric: '118', alpha: 'DT', name: 'TAAG Angola Airlines' },
  { numeric: '119', alpha: 'LM', name: 'Air ALM' },
  { numeric: '124', alpha: 'AH', name: 'Air Algerie' },
  { numeric: '125', alpha: 'BA', name: 'British Airways' },
  { numeric: '126', alpha: 'GA', name: 'Garuda Indonesia' },
  { numeric: '129', alpha: 'MP', name: 'Martinair Cargo' },
  { numeric: '131', alpha: 'JL', name: 'Japan Airlines' },
  { numeric: '133', alpha: 'LR', name: 'LACSA Airlines of Costa Rica' },
  { numeric: '139', alpha: 'AM', name: 'Aeromexico Cargo' },
  { numeric: '140', alpha: 'LI', name: 'LIAT Airlines' },
  { numeric: '147', alpha: 'AT', name: 'Royal Air Maroc' },
  { numeric: '148', alpha: 'LN', name: 'Libyan Airlines' },
  { numeric: '157', alpha: 'QR', name: 'Qatar Airways' },
  { numeric: '160', alpha: 'CX', name: 'Cathay Pacific Airways' },
  { numeric: '163', alpha: '3V', name: 'TNT Airways' },
  { numeric: '165', alpha: 'JP', name: 'Adria Airways' },
  { numeric: '172', alpha: 'CV', name: 'Cargolux Airlines' },
  { numeric: '176', alpha: 'EK', name: 'Emirates' },
  { numeric: '180', alpha: 'KE', name: 'Korean Air' },
  { numeric: '182', alpha: 'MA', name: 'Malev Hungarian Airlines' },
  { numeric: '183', alpha: 'RG', name: 'VARIG Brazilian Airlines' },
  { numeric: '189', alpha: 'JI', name: 'Jade Cargo International' },
  { numeric: '201', alpha: 'JM', name: 'Air Jamaica' },
  { numeric: '202', alpha: 'TA', name: 'TACA' },
  { numeric: '205', alpha: 'NH', name: 'ANA All Nippon Cargo' },
  { numeric: '214', alpha: 'PK', name: 'Pakistan Int´l Airlines' },
  { numeric: '217', alpha: 'TG', name: 'Thai Airways' },
  { numeric: '229', alpha: 'KU', name: 'Kuwait Airways' },
  { numeric: '230', alpha: 'CM', name: 'Copa Airlines Cargo' },
  { numeric: '231', alpha: 'NG', name: 'Lauda Air' },
  { numeric: '232', alpha: 'MH', name: 'Malaysian Airline System' },
  { numeric: '232', alpha: 'MH', name: ' ' },
  { numeric: '232', alpha: 'MH', name: 'Homepage' },
  { numeric: '234', alpha: 'JD', name: 'Japan Air System' },
  { numeric: '235', alpha: 'TK', name: 'Turkish Airlines' },
  { numeric: '236', alpha: 'BD', name: 'British Midland Airways' },
  { numeric: '239', alpha: 'MK', name: 'Air Mauritius' },
  { numeric: '257', alpha: 'OS', name: 'Austrian Cargo' },
  { numeric: '258', alpha: 'MD', name: 'Air Madagascar' },
  { numeric: '265', alpha: 'EF', name: 'Far Eastern Air Transport' },
  { numeric: '266', alpha: 'LT', name: 'LTU (Leisure Cargo)' },
  { numeric: '270', alpha: 'TL', name: 'Trans Mediterranean Airways' },
  { numeric: '272', alpha: 'K4', name: 'Kalitta Air' },
  { numeric: '288', alpha: 'LD', name: 'Air Hong Kong' },
  { numeric: '297', alpha: 'CI', name: 'China Airlines' },
  { numeric: '301', alpha: '5S', name: 'Global Aviation and Services' },
  { numeric: '302', alpha: 'OO', name: 'Sky West Airlines' },
  { numeric: '307', alpha: 'WE', name: 'Centurion Air Cargo' },
  { numeric: '324', alpha: 'SC', name: 'Shandong Airlines (Chinese)' },
  { numeric: '330', alpha: 'RF', name: 'Florida West International Airways' },
  { numeric: '345', alpha: 'NC', name: 'Northern Air Cargo' },
  { numeric: '356', alpha: 'C8', name: 'Cargolux Italia' },
  { numeric: '369', alpha: '5Y', name: 'Atlas Air' },
  { numeric: '378', alpha: 'KX', name: 'Cayman Airways' },
  { numeric: '390', alpha: 'A3', name: 'Aegean Airlines' },
  { numeric: '403', alpha: 'PO', name: 'Polar Air Cargo' },
  { numeric: '404', alpha: 'JW', name: 'Arrow Air' },
  { numeric: '406', alpha: '5X', name: 'UPS Air Cargo' },
  { numeric: '416', alpha: 'N8', name: 'National Air Cargo' },
  { numeric: '421', alpha: 'S7', name: 'Siberia Airlines' },
  { numeric: '423', alpha: 'ER', name: 'DHL Aviation/DHL Airways' },
  { numeric: '465', alpha: 'KC', name: 'Air Astana' },
  { numeric: '479', alpha: 'ZH', name: 'Shenzhen Airlines (Chinese)' },
  { numeric: '507', alpha: 'SU', name: 'Aeroflot' },
  { numeric: '512', alpha: 'RJ', name: 'Royal Jordanian' },
  { numeric: '526', alpha: 'WN', name: 'Southwest Airlines' },
  { numeric: '529', alpha: 'A2', name: 'Cielos Airlines' },
  { numeric: '549', alpha: 'M3', name: 'ABSA Aerolinhas Brasileiras' },
  { numeric: '552', alpha: 'M2', name: 'Mario’s Air' },
  { numeric: '564', alpha: 'XQ', name: 'Sun Express' },
  { numeric: '566', alpha: 'PS', name: 'Ukraine Int´l Airlines' },
  { numeric: '572', alpha: '9U', name: 'Air Moldova' },
  { numeric: '575', alpha: '7C', name: 'Coyne Airways' },
  { numeric: '580', alpha: 'RU', name: 'AirBridge Cargo' },
  { numeric: '589', alpha: '9W', name: 'Jet Airways' },
  { numeric: '603', alpha: 'UL', name: 'SriLankan Cargo' },
  { numeric: '603', alpha: 'UL', name: 'more AWB tracking' },
  { numeric: '604', alpha: 'UY', name: 'Cameroon Airlines' },
  { numeric: '607', alpha: 'EY', name: 'ETIHAD Airways' },
  {
    numeric: '615',
    alpha: 'QY',
    name: 'DHL Aviation / European Air Transport',
  },
  { numeric: '618', alpha: 'SQ', name: 'Singapore Airlines' },
  { numeric: '623', alpha: 'FB', name: 'Bulgaria Air' },
  { numeric: '631', alpha: 'GL', name: 'Air Greenland' },
  { numeric: '635', alpha: 'IY', name: 'Yemenia Yemen Airways' },
  { numeric: '643', alpha: 'KM', name: 'Air Malta' },
  { numeric: '656', alpha: 'PX', name: 'Air Niugini' },
  { numeric: '657', alpha: 'BT', name: 'Air Baltic' },
  { numeric: '672', alpha: 'BI', name: 'Royal Brunei Airlines' },
  { numeric: '675', alpha: 'NX', name: 'Air Macau' },
  { numeric: '695', alpha: 'BR', name: 'Eva Airways' },
  { numeric: '700', alpha: '5C', name: 'CAL Cargo Air Lines' },
  { numeric: '706', alpha: 'KQ', name: 'Kenya Airways' },
  { numeric: '716', alpha: 'MB', name: 'MNG Airlines' },
  { numeric: '724', alpha: 'LX', name: 'Swiss' },
  { numeric: '729', alpha: 'QT', name: 'Tampa Airlines' },
  { numeric: '731', alpha: 'MF', name: 'Xiamen Airlines' },
  { numeric: '737', alpha: 'SP', name: 'SATA Air Acores' },
  { numeric: '738', alpha: 'VN', name: 'Vietnam Airlines' },
  { numeric: '757', alpha: 'SM', name: 'Avient' },
  { numeric: '771', alpha: 'J2', name: 'Azerbaijan Airlines' },
  { numeric: '774', alpha: 'FM', name: 'Shanghai Airlines' },
  { numeric: '781', alpha: 'MU', name: 'China Eastern Airlines' },
  { numeric: '784', alpha: 'CZ', name: 'China Southern Airlines' },
  { numeric: '800', alpha: 'GD', name: 'Grandstar Cargo' },
  { numeric: '803', alpha: 'AE', name: 'Mandarin Airlines' },
  { numeric: '810', alpha: 'M6', name: 'Amerijet International' },
  { numeric: '817', alpha: 'S6', name: 'SAC South American Airways' },
  { numeric: '825', alpha: 'F4', name: 'Shanghai Airlines Cargo' },
  { numeric: '831', alpha: 'OU', name: 'Croatia Airlines' },
  { numeric: '851', alpha: 'N8', name: 'Hong Kong Airlines' },
  { numeric: '858', alpha: 'FK', name: 'Africa West' },
  { numeric: '862', alpha: 'EV', name: 'Atlantic Southeast Airlines' },
  { numeric: '865', alpha: 'MY', name: 'MASAir' },
  { numeric: '870', alpha: 'VV', name: 'Aerosvit' },
  { numeric: '871', alpha: 'Y8', name: 'Yangtze River Express Airlines' },
  { numeric: '873', alpha: '6R', name: 'AeroUnion' },
  { numeric: '876', alpha: '3U', name: 'Sichuan Airlines' },
  { numeric: '880', alpha: 'HU', name: 'Hainan Airlines (Chinese)' },
  { numeric: '881', alpha: 'DE', name: 'Condor Flugdienst' },
  { numeric: '886', alpha: 'OH', name: 'Comair' },
  { numeric: '901', alpha: 'B1', name: 'TAB Cargo' },
  { numeric: '907', alpha: 'QN', name: 'Air Armenia' },
  { numeric: '928', alpha: 'UZ', name: 'Buraq Air Transport (Arabic only)' },
  { numeric: '932', alpha: 'VS', name: 'Virgin Atlantic' },
  { numeric: '933', alpha: 'KZ', name: 'Nippon Cargo Airlines' },
  { numeric: '957', alpha: 'JJ', name: 'TAM Brazilian Airlines' },
  { numeric: '958', alpha: '7I', name: 'Insel Air Cargo' },
  { numeric: '960', alpha: 'OV', name: 'Estonian Air' },
  { numeric: '976', alpha: 'QO', name: 'Aeromexpress Cargo' },
  { numeric: '988', alpha: 'OZ', name: 'Asiana Airlines' },
  { numeric: '989', alpha: 'IJ', name: 'Great Wall Airlines' },
  { numeric: '996', alpha: 'UX', name: 'Air Europa Cargo' },
  { numeric: '997', alpha: 'BG', name: 'Biman Bangladesh' },
  { numeric: '999', alpha: 'CA', name: 'Air China' },
];

let airlineCodesAlphaToNumeric: Record<string, string>;
export function getAirlineCodesAlphaToNumericMap() {
  if (airlineCodesAlphaToNumeric) {
    return airlineCodesAlphaToNumeric;
  }

  airlineCodesAlphaToNumeric = {};
  for (const line of iataAirlineCodes) {
    airlineCodesAlphaToNumeric[line.alpha] = line.numeric;
  }
  return airlineCodesAlphaToNumeric;
}
