export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export function notEmpty<T>(value: T | null | undefined): value is T {
  if (value === null || value === undefined) return false;
  const _: T = value;
  return true;
}

function isEmpty<T>(value: T) {
  return value === null || value === undefined;
}

export function sameOrEmpty<T>(a: T, b: T): boolean {
  return (isEmpty(a) && isEmpty(b)) || a === b;
}

export function enumFromStringValue<T>(
  enm: { [s: string]: T },
  value: string,
): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : undefined;
}

export enum InterfaceType {
  EXCHANGE = 'Exchange',
  FARE_DIFFERENCE = 'Fare_Difference',
  CREDIT_DEBIT_MEMO = 'Credit_Debit_Memo',
  AIR_NDC = 'AIR_NDC', // intentionally all caps b/c the PNR XML uses this
}

// The following 2-letter codes are from an ARC BOS file but we want them to be more human readable/understandable
//RF = refund, MC = service fee, ET = straight sale, EX = exchange, MX = fare difference
//CM = credit memo, DM = debit memo,
//CN = not known, maybe cancelled
export enum ArcTicketType {
  TICKET = 'Ticket', // ET
  EXCHANGE = 'Exchange', // EX
  BOOKING_REFUND = 'Refund (Booking)', // RF
  FEE_REFUND = 'Refund (Fee)', // RF
  FEE = 'Fee', // MC
  FARE_DIFFERENCE = 'Fare Difference', // MX
  CREDIT_MEMO = 'Credit Memo', // CM
  DEBIT_MEMO = 'Debit Memo', // DM
  // add more like CN
  UNKNOWN = 'Unknown', // this does not match ARC BOS file but is used for internal purposes
}

export function hasProperty<T, K extends keyof T>(
  key: K,
): (item: T) => item is T & Record<K, NonNullable<T[K]>> {
  return (item: T): item is T & Record<K, NonNullable<T[K]>> => !!item[key];
}

// Utility function to ensure exhaustive checks
export function assertNever(x: never): never {
  throw new Error(`Unexpected value: ${x}`);
}

export type InferAsyncGeneratorType<T> = T extends AsyncGenerator<
  infer U,
  infer _TReturn,
  infer _TNext
>
  ? U
  : never;
export type InferAsyncGeneratorReturn<T> = T extends AsyncGenerator<
  infer _U,
  infer TReturn,
  infer _TNext
>
  ? TReturn
  : never;
export type InferAsyncGeneratorNext<T> = T extends AsyncGenerator<
  infer _U,
  infer _TReturn,
  infer TNext
>
  ? TNext
  : never;
