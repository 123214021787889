import Decimal from 'decimal.js';

export const TRIPSUITE_TIMEZONE = 'America/New_York' as const;
// Sabre uses Tulsa time, aka America/Chicago since Tulsa is in the Central Time Zone
export const TULSA_TIME = 'America/Chicago' as const;
export const QBO_TIME = 'America/Los_Angeles' as const;
export const ZERO = new Decimal(0);
export const ONE = new Decimal(1);

export const CLIENT_INVOICE_CC_MAX_AMOUNT_FOR_NON_ORG_USER = 2000;

export const PROD_ORG_IDS = {
  cadence: '09ed000a-1773-4019-ba91-df600e2a9071',
  pique: '2994d63b-d03c-41b9-9af8-941c3d7bb96b',
  mwt: '67cf3193-9134-4c27-9198-c54e50376fe0',
  // cspell:disable-next-line
  travellustre: '9b06a434-1b7e-4a59-89d2-a117c99b4f81',
  wells: '9f50fb26-5f8f-4b71-ac98-0af4e2093327',
  destinations: '9aa2d09f-4e2c-437f-b3cc-1bab65f9579c',
  brownell: '9ab0304c-0d79-4839-901a-dba72170e0d0',
  // cspell:disable-next-line
  gerling: '6c1f5449-fdfa-4bc2-9d9a-07bc45a0b247',
  a2t: 'e3910ae5-3143-4637-a13b-b3fb4a361750',
  '1pt': 'ff39b22b-732c-477e-bd36-1001e5261bd7',
  wallacePierson: 'e14c7248-ea75-4d43-b157-3cbed57453bf',
  paulKlein: 'f25b7d3a-775c-47a2-83fd-07b54f09502d',
} as const;

export const PROD_ORG_NAMES = {
  JaceyOrg: 'JaceyOrg',
} as const;

export const PROD_ORGS_BY_ID = Object.fromEntries(
  Object.entries(PROD_ORG_IDS).map(([key, value]) => [value, key]),
) as {
  [key in (typeof PROD_ORG_IDS)[keyof typeof PROD_ORG_IDS]]: keyof typeof PROD_ORG_IDS;
};

export function isMappedOrgId(id: string): id is keyof typeof PROD_ORGS_BY_ID {
  return id in PROD_ORGS_BY_ID;
}

export const DEFAULT_SUPPLIER_TYPE_COMMISSION_PCT = 10;
export const DEFAULT_COMMISSION_DUE_DAYS = 45; // generally the number of days after the checkoutDate

export const INACTIVE_RECIPIENTS_ID = 'inactive';
