export interface BaseClientEntityNeedingTask {
  id: string;
  clientId: string;
  type: ClientTaskDateType;
  date: Date;
  createdAt: Date;
  createdById: string;
  entityType?: string;
  entityId?: string;
  updatedAt?: Date;
  deletedAt?: Date | null;
  clientName: string;
  agencyUserId?: string;
}

export type ClientEntityNeedingTask = BaseClientEntityNeedingTask & {
  dueAt: Date;
  remindAt: Date;
} & (
    | { entityType: string; entityId: string }
    | { entityType?: never; entityId?: never }
  );

export enum ClientTaskDateType {
  BIRTHDAY = 'BIRTHDAY',
  ANNIVERSARY = 'ANNIVERSARY',
  PASSPORT_EXPIRATION = 'PASSPORT_EXPIRATION',
}
