import {
  LeadStageResponseDto,
  createPaginatedResponseDto,
  dateRegex,
  getPaginatedRequestDto,
  isoDateRegex,
} from 'dtos';
import { ClientTaskDateType } from 'services/task/types';
import { trimmedString } from 'utils/shared/zod';
import { z } from 'zod';

// Trips
export const DashboardTripStatusesResponseDto = z.array(
  z.object({
    name: z.string(),
    count: z.number(),
  }),
);
export type DashboardTripStatusesResponseDto = z.infer<
  typeof DashboardTripStatusesResponseDto
>;

const DashboardTripsSortKeys = [
  'travelerName',
  'name',
  'destinations',
  'advisorName',
  'startDate',
  'endDate',
  'stage',
] as const;

export const DashboardTripsSortKey = z.enum(DashboardTripsSortKeys);
export type DashboardTripsSortKey = z.infer<typeof DashboardTripsSortKey>;

export const DashboardTripsPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(DashboardTripsSortKeys),
  z.enum(['status']),
).extend({
  status: z.string().optional(),
});
export type DashboardTripsPaginatedRequestDto = z.infer<
  typeof DashboardTripsPaginatedRequestDto
>;

export const DashboardTripsResponseDto = z.object({
  id: z.string(),
  travelerName: z.string(),
  name: z.string(),
  destinations: z.string(),
  advisorName: z.string(),
  startDate: z.string().regex(isoDateRegex).optional(),
  endDate: z.string().regex(isoDateRegex).optional(),
  stage: LeadStageResponseDto,
});
export type DashboardTripsResponseDto = z.infer<
  typeof DashboardTripsResponseDto
>;

export const DashboardTripsPaginatedResponseDto = createPaginatedResponseDto(
  DashboardTripsResponseDto,
);
export type DashboardTripsPaginatedResponseDto = z.infer<
  typeof DashboardTripsPaginatedResponseDto
>;

// Payments
export enum DashboardParticipantType {
  CLIENT = 'CLIENT',
  AGENCY = 'AGENCY',
  AGENCY_USER = 'AGENCY_USER',
  GROUP = 'GROUP',
  ORGANIZATION = 'ORGANIZATION',
}

export enum DashboardPaymentType {
  BOOKING = 'BOOKING',
  CLIENT = 'CLIENT',
}

export const DashboardPaymentsSortKeys = [
  'subject',
  'from',
  'to',
  'amount',
  'dueDate',
  'client',
  'advisor',
] as const;

export type DashboardPaymentsSortKey =
  (typeof DashboardPaymentsSortKeys)[number];

export const DashboardParticipantResponseDto = z.object({
  id: z.string().uuid(),
  type: z.nativeEnum(DashboardParticipantType),
  name: trimmedString(),
  color: trimmedString().optional(),
  profileImageUrl: trimmedString().optional(),
});

export const DashboardPaymentsResponseDto = z.object({
  type: z.nativeEnum(DashboardPaymentType),
  id: z.string().uuid(),
  bookingId: z.string().uuid().optional(),
  tripId: z.string().uuid(),
  subject: z.string(),
  from: DashboardParticipantResponseDto,
  to: z.string(),
  amount: z.number(),
  dueDate: z.string().regex(dateRegex),
  client: DashboardParticipantResponseDto,
  advisor: DashboardParticipantResponseDto,
});
export type DashboardPaymentsResponseDto = z.infer<
  typeof DashboardPaymentsResponseDto
>;

export const DashboardPaymentsPaginatedResponseDto =
  createPaginatedResponseDto<DashboardPaymentsResponseDto>(
    DashboardPaymentsResponseDto,
  );
export type DashboardPaymentsPaginatedResponseDto = z.infer<
  typeof DashboardPaymentsPaginatedResponseDto
>;

// Tasks
export enum DashboardTaskType {
  TRIP = 'TRIP',
  BIRTHDAY = 'BIRTHDAY',
  ANNIVERSARY = 'ANNIVERSARY',
  PASSPORT_EXPIRATION = 'PASSPORT_EXPIRATION',
}

export const DashboardTasksSortKeys = [
  'title',
  'dueAt',
  'client',
  'trip',
  'advisor',
] as const;

export type DashboardTasksSortKey = (typeof DashboardTasksSortKeys)[number];

export const DashboardTasksResponseDto = z.object({
  id: z.string().uuid(),
  type: z.nativeEnum(DashboardTaskType),
  trip: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
    })
    .optional(),
  title: z.string(),
  dueAt: z.string().regex(dateRegex).optional(),
  client: DashboardParticipantResponseDto.optional(),
  advisor: DashboardParticipantResponseDto.optional(),
});
export type DashboardTasksResponseDto = z.infer<
  typeof DashboardTasksResponseDto
>;

export const DashboardTasksPaginatedResponseDto =
  createPaginatedResponseDto<DashboardTasksResponseDto>(
    DashboardTasksResponseDto,
  );
export type DashboardTasksPaginatedResponseDto = z.infer<
  typeof DashboardTasksPaginatedResponseDto
>;

export const DashboardTaskUpdateRequestDto = z.object({
  isCompleted: z.boolean(),
});
export type DashboardTaskUpdateRequestDto = z.infer<
  typeof DashboardTaskUpdateRequestDto
>;

export const DashboardTaskRequestDto = z.object({
  taskId: z.string().uuid(),
});
export type DashboardTaskRequestDto = z.infer<typeof DashboardTaskRequestDto>;

// Dates
export enum DashboardDateType {
  BIRTHDAYS = ClientTaskDateType.BIRTHDAY,
  PASSPORTS = ClientTaskDateType.PASSPORT_EXPIRATION,
  ANNIVERSARIES = ClientTaskDateType.ANNIVERSARY,
  ALL = 'ALL',
}

export const DATE_TYPE_LABELS: Record<ClientTaskDateType | 'ALL', string> = {
  ALL: 'ALL',
  [ClientTaskDateType.BIRTHDAY]: 'BIRTHDAYS',
  [ClientTaskDateType.PASSPORT_EXPIRATION]: 'PASSPORTS',
  [ClientTaskDateType.ANNIVERSARY]: 'ANNIVERSARIES',
} as const;

export const DATE_TYPE_VALUES = [
  'ALL',
  ...Object.values(ClientTaskDateType),
] as const;
export type DateTypeValue = (typeof DATE_TYPE_VALUES)[number];

export enum DashboardDateRange {
  ALL = 'ALL',
  TODAY = 'TODAY',
  NEXT_7_DAYS = 'NEXT_7_DAYS',
  THIS_MONTH = 'THIS_MONTH',
  COMPLETED = 'COMPLETED',
}

export const DASHBOARD_DATE_SORT_KEYS = {
  SUBJECT: 'type',
  DATE: 'dueAt',
  CLIENT: 'client',
  GROUP: 'groups',
  ADVISOR: 'advisor',
} as const;

export const DASHBOARD_DATE_SORT_KEYS_ARRAY = [
  DASHBOARD_DATE_SORT_KEYS.SUBJECT,
  DASHBOARD_DATE_SORT_KEYS.DATE,
  DASHBOARD_DATE_SORT_KEYS.CLIENT,
  DASHBOARD_DATE_SORT_KEYS.GROUP,
  DASHBOARD_DATE_SORT_KEYS.ADVISOR,
] as const;

export const DashboardDatesSortKeys = z.enum(DASHBOARD_DATE_SORT_KEYS_ARRAY);
export type DashboardDatesSortKeys = z.infer<typeof DashboardDatesSortKeys>;

export const DashboardDatesResponseDto = z.object({
  id: z.string().uuid(),
  type: z.nativeEnum(DashboardDateType),
  subject: z.string(),
  remindAt: z.string().regex(dateRegex),
  dueAt: z.string().regex(dateRegex),
  client: DashboardParticipantResponseDto,
  groups: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
      }),
    )
    .optional(),
  advisor: DashboardParticipantResponseDto.optional(),
});
export type DashboardDatesResponseDto = z.infer<
  typeof DashboardDatesResponseDto
>;

export const DashboardDatesPaginatedResponseDto =
  createPaginatedResponseDto<DashboardDatesResponseDto>(
    DashboardDatesResponseDto,
  );
export type DashboardDatesPaginatedResponseDto = z.infer<
  typeof DashboardDatesPaginatedResponseDto
>;

// Due Today
export const DashboardDueTodaySortKeys = [
  'title',
  'client',
  'trip',
  'assignee',
] as const;

export type DashboardDueTodaySortKey =
  (typeof DashboardDueTodaySortKeys)[number];

export enum DashboardDueTodayType {
  TASK = 'TASK',
  CLIENT_PAYMENT = 'CLIENT_PAYMENT',
  BOOKING_PAYMENT = 'BOOKING_PAYMENT',
  BIRTHDAY = 'BIRTHDAY',
  ANNIVERSARY = 'ANNIVERSARY',
  PASSPORT_EXPIRATION = 'PASSPORT_EXPIRATION',
}

export const DashboardDueTodayResponseDto = z.object({
  id: z.string().uuid(),
  type: z.nativeEnum(DashboardDueTodayType),
  title: z.string(),
  client: DashboardParticipantResponseDto.optional(),
  trip: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      bookingId: z.string().uuid().optional(),
    })
    .optional(),
  assignee: DashboardParticipantResponseDto.optional(),
});
export type DashboardDueTodayResponseDto = z.infer<
  typeof DashboardDueTodayResponseDto
>;

const BasePaginatedDashboardDueTodayResponseDto =
  createPaginatedResponseDto<DashboardDueTodayResponseDto>(
    DashboardDueTodayResponseDto,
  );

export const DashboardDueTodayPaginatedResponseDto =
  BasePaginatedDashboardDueTodayResponseDto.extend({
    meta: BasePaginatedDashboardDueTodayResponseDto.shape.meta.extend({
      paymentsCount: z.number(),
      tasksCount: z.number(),
      datesCount: z.number(),
    }),
  });
export type DashboardDueTodayPaginatedResponseDto = z.infer<
  typeof DashboardDueTodayPaginatedResponseDto
>;
