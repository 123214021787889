import type {
  Booking,
  BookingExpense,
  BookingPayment,
  BookingRefund,
  Commission,
  Supplier,
} from '@prisma/client';
import Decimal from 'decimal.js';
import {
  BookingType,
  PayingEntity,
  SupplierType,
  getBookingCommissionInvoiceStatus,
} from 'dtos';
import { ARC_SUBMIT_TO, DocumentType } from 'services/ens/constants';
import { SourceType } from 'services/ens/types';
import { InterfaceType } from './types';

export const getBookingMaxRefundAmount = (
  booking: Booking & {
    expenses: (BookingExpense & {
      payments: BookingPayment[];
    })[];
  },
) =>
  booking.payingEntity === PayingEntity.AGENCY
    ? booking.expenses
        .filter((e) => !e.deletedAt)
        .reduce((acc, expense) => {
          const totalPaid = expense.payments
            .filter((p) => !p.voidedAt && !p.deletedAt)
            .reduce((acc, payment) => {
              return acc.plus(payment.amountHome);
            }, new Decimal(0));
          return acc.plus(totalPaid);
        }, new Decimal(0))
    : booking.baseTotalHome ?? booking.totalHome;

export type RefundTotals = {
  refundedAmount?: Decimal;
  refundCommissionableValue?: Decimal;
  refundEstCommission?: Decimal;
  refundPenalty?: Decimal;
  refundCommissionPenalty?: Decimal;
};

export function getRefundTotalsFromBookingRefunds(
  refunds: BookingRefund[],
): RefundTotals {
  if (refunds.length === 0) {
    return {
      refundedAmount: undefined,
      refundCommissionableValue: undefined,
      refundEstCommission: undefined,
      // set to 0 for legacy reasons b/c they were this way before
      refundPenalty: new Decimal(0),
      refundCommissionPenalty: new Decimal(0),
    };
  }

  return refunds.reduce(
    (acc, refund) => {
      if (refund.deletedAt) {
        return acc;
      }

      return {
        refundedAmount: acc.refundedAmount.plus(refund.amount),
        refundCommissionableValue: acc.refundCommissionableValue.plus(
          refund.commissionableValueAmount ?? new Decimal(0),
        ),
        refundEstCommission: acc.refundEstCommission.plus(
          refund.estCommissionAmount ?? new Decimal(0),
        ),
        refundPenalty: acc.refundPenalty.plus(refund.penalty ?? new Decimal(0)),
        refundCommissionPenalty: acc.refundCommissionPenalty.plus(
          refund.commissionPenalty ?? new Decimal(0),
        ),
      };
    },
    {
      refundedAmount: new Decimal(0),
      refundCommissionableValue: new Decimal(0),
      refundEstCommission: new Decimal(0),
      refundPenalty: new Decimal(0),
      refundCommissionPenalty: new Decimal(0),
    },
  );
}

export const isArcBooking = (booking: Booking) =>
  sparseIsArcBooking(booking.documentType, booking.submitTo);

export const sparseIsArcBooking = (
  documentType: Booking['documentType'],
  submitTo: Booking['submitTo'],
) =>
  documentType === DocumentType.ARC_DOCUMENT_TYPE || submitTo === ARC_SUBMIT_TO;

// we haven't created a booking yet but we need to know if it would be an ARC booking
// not including the submitTo check b/c that is only set from import and this method won't be used for imports
export const isArcProspectiveBooking = (
  supplier: Supplier,
  documentType: string,
): boolean => {
  return (
    supplier.type === SupplierType.AIRLINE &&
    documentType === DocumentType.ARC_DOCUMENT_TYPE
  );
};

export function getUnvoidableReasons(
  booking: Booking & {
    commissions: Commission[];
  },
  source = SourceType.MANUAL,
) {
  if (booking.voidedAt) {
    return ['Booking is already voided'];
  }

  const isArc = isArcBooking(booking);

  const unvoidableReasons = [];
  if (isArc && source !== SourceType.MANUAL_ARC_DASHBOARD) {
    // the ARC dashboard is the only place where ARC bookings can be voided from
    unvoidableReasons.push(
      'ARC bookings can only be voided from the ARC dashboard',
    );
  }
  if (
    booking.payingEntity === PayingEntity.AGENCY &&
    // MCOs are a credit with the airline, there isn't a concept of agency paid or client paid for those
    booking.bookingType !== InterfaceType.FARE_DIFFERENCE
  ) {
    unvoidableReasons.push('Booking that are Agency Paid cannot be voided');
  }

  // NOTE: ARC bookings will always have commissions since they're managed differently
  if (booking.commissions.length > 0 && !isArc) {
    unvoidableReasons.push(
      'Booking with commissions that are already paid cannot be voided',
    );
  }

  return unvoidableReasons;
}
