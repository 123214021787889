import { z } from 'zod';

export const int = (x: z.ZodTypeAny) =>
  z.preprocess(
    (s: unknown) => Number.parseInt(<string>s, 10) || (s === '0' ? 0 : s),
    x,
  );

export const float = (x: z.ZodTypeAny) =>
  z.preprocess((s) => {
    const parsed = Number.parseFloat(s as string);
    return Number.isNaN(parsed) ? s : parsed;
  }, x);
