// biome-ignore lint/style/useNodejsImportProtocol: Reading from "node:crypto" is not handled by plugins (Unhandled scheme). Webpack supports "data:" and "file:" URIs by default.
import * as crypto from 'crypto';
import type {
  BillPaymentMethod as AccountingPaymentMethod,
  AdvisorExpense,
  Agency,
  AgencyAgreement,
  AgencyMapping,
  AgencyUser,
  AgencyUserMapping,
  AirSegment,
  ApiKeyOrganizationMap,
  AssociatedSupplier,
  Attachment,
  Booking,
  BookingClient,
  BookingExpense,
  BookingPayment,
  BookingRefund,
  Card,
  Client,
  ClientAddress,
  ClientDate,
  ClientFlag,
  ClientGroup,
  ClientInvoice,
  ClientInvoiceRefund,
  ClientPassportInfo,
  ClientPaymentMethod,
  ClientProfileCreditCard,
  Commission,
  CommissionAdjustment,
  CommissionBooking,
  CommissionGroup,
  CreditCardMetadata,
  EntitySplitConfig,
  ExpenseCategory,
  FareDifferenceDocument,
  FeeType,
  Form,
  FormSubmit,
  GeoName,
  GiftCard,
  GiftCardLedger,
  GiftCardTransfer,
  Group,
  GroupContact,
  Identifier,
  Lead,
  LeadStage,
  Organization,
  OrganizationCurrencyMapping,
  OrganizationHostConfig,
  OrganizationMapping,
  OrganizationRole,
  OrganizationUser,
  PaymentReversal,
  PaymentTransaction,
  PccConfig,
  PccGroup,
  Prisma,
  SplitConfig,
  Statement,
  StatementExpense,
  StatementSchedule,
  Supplier,
  SupplierContact,
  SupplierMapping,
  SupplierOrganizationConfig,
  SupplierPaymentMethod,
  SupplierTag,
  Tag,
  Trip,
  TripClient,
  TripDestination,
  TripInvoice,
  TripInvoiceAuthorization,
  TripInvoiceInstallment,
  TripInvoiceInstallmentSubmit,
  TripMapping,
  TripMarkupWithdrawal,
  TripPnr,
  TripRemark,
  TripSplit,
  TripTag,
  User,
  UserMapping,
} from '@prisma/client';
import {
  AttachmentResponseDto,
  attachmentToDto,
} from 'components/trips/attachments/types';
import { USD, getCurrencyByCode } from 'data/currencies';
import Decimal from 'decimal.js';
import {
  PaymentReversalResponseDto,
  type ReversalType,
  getLastPaymentDate,
  isCancelledOrReversed,
  isCompletedTransferAndFullyPaid,
  isFailedPayment,
  isLikePendingStatus,
  paymentHasCompletedRefund,
  paymentHasPartialRefund,
  paymentHasPendingCancellation,
  paymentHasPendingRefund,
} from 'dtos.payments';
import { CardPaymentStatusEnum, MoneySchema } from 'dtos/moov.dtos';
import { PaymentStatusEnum } from 'dtos/paymentProcessor-shared.dtos';
import { ShareAccessType } from 'dtos/shareAccess.dtos';
import {
  TaskTriggerTiming,
  TaskTriggerTimingQualifier,
  TaskTriggerType,
} from 'dtos/workflowTemplates.types';
import type { TaskWithInclude } from 'models/task/types';
import moment from 'moment-timezone';
import { calculateBookingNetRemit } from 'services/arc/utils';
import type { ScoredBookingWithInclude } from 'services/booking.service';
import type { ClientWithProfile } from 'services/client.service';
import { isSurveyJSForm } from 'services/form/types';
import type { StatementBucket } from 'services/statement.service';
import type { TripList } from 'services/tripService/types';
import {
  getItineraryTypeFromSegments,
  mapAirSegmentsToItinerary,
} from 'utils/server/bookings';
import { float, int } from 'utils/server/zodHelper';
import {
  getBookingMaxRefundAmount,
  getRefundTotalsFromBookingRefunds,
  getUnvoidableReasons,
  isArcBooking,
} from 'utils/shared/booking';
import { getPublicUrl } from 'utils/shared/cdn';
import {
  CLIENT_INVOICE_CC_MAX_AMOUNT_FOR_NON_ORG_USER,
  ZERO,
} from 'utils/shared/constants';
import { supplierNameAlreadyHasOrgName } from 'utils/shared/string';
import { InterfaceType, notEmpty } from 'utils/shared/types';
import { trimmedString } from 'utils/shared/zod';
import { validate as isUuid } from 'uuid';
import { string, z } from 'zod';

export const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
const hexColorRegex = /^#[0-9A-F]{6}$/i; // Do not change this- the client requires that the hex be all 6 hex digits
export const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
const numberRegex = /^\d+$/;

export enum PayoutType {
  COMMISSION = 'COMMISSION',
  FEE = 'FEE',
  PRODUCTIVITY = 'PRODUCTIVITY',
}
export const PayoutTypeEnum = z.nativeEnum(PayoutType);

export enum SourcedBy {
  ADVISOR = 'ADVISOR',
  AGENCY = 'AGENCY',
}
export const SourcedByEnum = z.nativeEnum(SourcedBy);

export enum GroupType {
  CORPORATE = 'CORPORATE',
  LEISURE = 'LEISURE',
}
export const GroupTypeEnum = z.nativeEnum(GroupType);

export enum TravelType {
  CORPORATE = 'corporate',
  LEISURE = 'leisure',
}

export const TravelTypeEnum = z.nativeEnum(TravelType);

export enum PaymentMethod {
  CREDIT_CARD = 'CREDIT_CARD',
  ACH = 'ACH',
  CHECK = 'CHECK',
  WIRE = 'WIRE',
  CASH = 'CASH',
  GIFT_CARD = 'GIFT_CARD',
}

export enum CorporateRoles {
  PMIC = 'Primary M&I Contact',
  ADMIN = 'Admin',
  MICC = 'M&I Corporate Contact',
  VIP = 'VIP',
}

export enum ItineraryType {
  DOMESTIC = 'D',
  INTERNATIONAL = 'I',
  TRANSBORDER = 'T',
}

// API keys are associated with an TripSuite organization and an external type
// eg 'ECS'
export enum ApiKeyExternalType {
  ECS = 'ECS',
}

export const StatusResponseDto = z.object({
  status: z.literal('ok'),
});
export type StatusResponseDto = z.infer<typeof StatusResponseDto>;

export const ErrorResponseDto = z.object({
  type: z.literal('error').or(z.literal('warning')),
  code: z.number(),
  message: trimmedString(),
  error: z.any().optional(),
});
export type ErrorResponseDto = z.infer<typeof ErrorResponseDto>;

export const PaginationOptions = z.object({
  sort: trimmedString().optional(),
  sortDirection: z.enum(['asc', 'desc']).default('asc').optional(),
  page: int(z.number().default(0)),
  pageSize: int(z.number().gte(0).max(1000000).default(10)),
});

export type PaginationOptions = z.infer<typeof PaginationOptions>;

export const PaginatedRequestDto = PaginationOptions.extend({
  query: z.string().optional(),
  queryOn: z.union([z.string().array(), z.string()]),
});
// .strict();
export type PaginatedRequestDto = z.infer<typeof PaginatedRequestDto>;

const NeverEnum = z.enum(['NEVER_USE_THIS_VALUE']);

export const getPaginatedRequestDto = <
  SortableType extends z.ZodEnum<[string, ...string[]]>,
  QueryableType extends z.ZodEnum<[string, ...string[]]>,
>(
  cols: SortableType,
  queryCols: QueryableType,
) => {
  const baseShape = {
    query: trimmedString().optional(),
    sort: cols.optional(),
    sortDirection: z.enum(['asc', 'desc']).default('asc').optional(),
    page: int(z.number().default(0)),
    pageSize: int(z.number().gte(0).max(1000000).default(100)),
    tz: trimmedString().optional(),
    queryOn: z.array(queryCols).optional(),
  };

  if (queryCols) {
    const queryOnType = z.union([queryCols.array(), queryCols]);
    return z.object({
      ...baseShape,
      queryOn: queryOnType.optional(),
    });
  }
  return z.object(baseShape);
};

export const createPaginatedResponseDto = <R>(listItem: z.ZodType<R>) => {
  return z.object({
    data: z.array(listItem),
    meta: z.object({
      page: int(z.number()),
      pageSize: int(z.number()),
      totalRowCount: int(z.number()),
    }),
  });
};

export enum FeesRecipient {
  ORGANIZATION = 'ORGANIZATION',
  ADVISOR = 'ADVISOR',
  PLATFORM = 'PLATFORM',
}

export function createPaginatedDtos<TBaseSchema extends z.ZodTypeAny>(
  queryOnEnum: z.ZodEnum<[string, ...string[]]>,
  sortEnum: z.ZodEnum<[string, ...string[]]>,
  baseSchema: TBaseSchema,
) {
  const paginatedRequestDto = getPaginatedRequestDto(sortEnum, queryOnEnum);
  const responseDto = baseSchema;
  const paginatedResponseDto = createPaginatedResponseDto(baseSchema);

  return {
    paginatedRequestDto,
    responseDto,
    paginatedResponseDto,
  };
}

export enum AccountingBasis {
  CASH = 'CASH',
  ACCRUAL = 'ACCRUAL',
  HYBRID = 'HYBRID',
}

const OrganizationDto = z.object({
  name: trimmedString(),
  address: trimmedString(),
  email: z.string().email(),
  phone: trimmedString(),
  feesRecipient: z.nativeEnum(FeesRecipient),
  accountingBasis: z.nativeEnum(AccountingBasis).optional(),
  forfeitThresholdDays: z.number().optional(),
  leadExpirationDays: z.number().optional(),
  restrictedTripMarkupPayouts: z.boolean().optional(),
  feesCcFeePercent: z.number().optional(),
  tripCostsCcFeePercent: z.number().optional(),
  isFeePayoutGrossOfFees: z.boolean().optional(),
  isTripCostPayoutGrossOfFees: z.boolean().optional(),
  useManagedSuppliers: z.boolean().optional(),
  paymentInstructions: trimmedString().optional(),
  allowSinesPerPcc: z.boolean().optional(),
  useManagedTaxes: z.boolean().optional(),
});
type OrganizationDto = z.infer<typeof OrganizationDto>;

export const AdminOrganizationCreateRequestDto = OrganizationDto.strict();
export type AdminOrganizationCreateRequestDto = z.infer<typeof OrganizationDto>;

export const AdminOrganizationUpdateRequestDto = OrganizationDto.strict();
export type AdminOrganizationUpdateRequestDto = z.infer<
  typeof AdminOrganizationUpdateRequestDto
>;

export const AdminOrganizationResponseDto = OrganizationDto.extend({
  id: z.string().uuid(),
  importedAt: z.string().regex(isoDateRegex).optional(),
  allowSinesPerPcc: z.boolean(),
  useManagedTaxes: z.boolean(),
}).strict();
export type AdminOrganizationResponseDto = z.infer<
  typeof AdminOrganizationResponseDto
>;

export const organizationToAdminDto = (
  organization: Organization,
): AdminOrganizationResponseDto => {
  return {
    id: organization.id,
    name: organization.name,
    address: organization.address,
    email: organization.email,
    phone: organization.phone,
    feesRecipient: organization.feesRecipient as FeesRecipient,
    accountingBasis: organization.accountingBasis as AccountingBasis,
    forfeitThresholdDays: organization.forfeitThresholdDays ?? undefined,
    leadExpirationDays: organization.leadExpirationDays ?? undefined,
    restrictedTripMarkupPayouts: organization.restrictedTripMarkupPayouts,
    feesCcFeePercent: organization.feesCcFeePercent ?? undefined,
    tripCostsCcFeePercent: organization.tripCostsCcFeePercent ?? undefined,
    importedAt: organization.importedAt?.toISOString(),
    allowSinesPerPcc: organization.allowSinesPerPcc,
    isFeePayoutGrossOfFees: organization.isFeePayoutGrossOfFees,
    isTripCostPayoutGrossOfFees: organization.isTripCostPayoutGrossOfFees,
    useManagedSuppliers: organization.useManagedSuppliers,
    paymentInstructions: organization.paymentInstructions ?? undefined,
    useManagedTaxes: organization.useManagedTaxes,
  };
};

export const OrganizationRequestDto = z.object({
  organizationId: z.string().uuid(),
});
export type OrganizationRequestDto = z.infer<typeof OrganizationRequestDto>;

export const OrganizationPaymentInstructionsUpsertRequestDto = z.object({
  paymentInstructions: trimmedString().optional(),
});
export type OrganizationPaymentInstructionsUpsertRequestDto = z.infer<
  typeof OrganizationPaymentInstructionsUpsertRequestDto
>;

export const OrganizationPaymentInstructionsResponseDto =
  OrganizationPaymentInstructionsUpsertRequestDto;
export type OrganizationPaymentInstructionsResponseDto = z.infer<
  typeof OrganizationPaymentInstructionsResponseDto
>;

export const MergeTaskRequestDto = z.object({
  mergeTaskId: trimmedString(),
  limit: trimmedString().optional(),
});
export type MergeTaskRequestDto = z.infer<typeof MergeTaskRequestDto>;

export const OrganizationPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['name', 'createdAt']),
  z.enum(['name']),
);
export type OrganizationPaginatedRequestDto = z.infer<
  typeof OrganizationPaginatedRequestDto
>;

export const OrganizationPaginatedResponseDto = createPaginatedResponseDto(
  AdminOrganizationResponseDto,
);
export type OrganizationPaginatedResponseDto = z.infer<
  typeof OrganizationPaginatedResponseDto
>;

export enum SupplierType {
  AIRLINE = 'AIRLINE',
  CRUISE_LINE = 'CRUISE_LINE',
  HOTEL = 'HOTEL',
  INSURANCE = 'INSURANCE',
  RAIL = 'RAIL',
  TOUR_DMC = 'TOUR_DMC',
  TRANSPORTATION = 'TRANSPORTATION',
  ANCILLARIES = 'ANCILLARIES',
  OTHER = 'OTHER',
}

export enum CommissionReleaseTrigger {
  COMMISSION_RECEIVED = 'COMMISSION_RECEIVED',
  AT_TRAVEL = 'AT_TRAVEL',
}

export const SupplierTypeConfigDto = z.object({
  supplierType: z.nativeEnum(SupplierType),
  commissionReleaseTrigger: z.nativeEnum(CommissionReleaseTrigger),
});
export type SupplierTypeConfigDto = z.infer<typeof SupplierTypeConfigDto>;

export const SupplierTypeConfigsResponseDto = z.object({
  data: z.array(SupplierTypeConfigDto),
});
export type SupplierTypeConfigsResponseDto = z.infer<
  typeof SupplierTypeConfigsResponseDto
>;

export const AgencyCreateRequestDto = z
  .object({
    name: trimmedString(),
    displayName: trimmedString().optional(),
    parentAgencyId: z.string().uuid().optional(),
    commissionParentTakePct: z.number(),
    feeParentTakePct: z.number(),
  })
  .strict();
export type AgencyCreateRequestDto = z.infer<typeof AgencyCreateRequestDto>;

export const AdminAgencyCreateRequestDto = z
  .object({
    name: trimmedString(),
    displayName: trimmedString().optional(),
    parentAgencyId: z.string().uuid().optional(),
    commissionParentTakePct: z.number(),
    feeParentTakePct: z.number(),
    leadCommissionParentTakePct: z.number().min(0).max(100).optional(),
    leadFeeParentTakePct: z.number().min(0).max(100).optional(),
    isActive: z.boolean().optional(),
    externalPayoutId: trimmedString().optional(),
  })
  .strict();

export type AdminAgencyCreateRequestDto = z.infer<
  typeof AdminAgencyCreateRequestDto
>;

export const AgencyRequestDto = z.object({
  agencyId: z.string().uuid(),
});
export type AgencyRequestDto = z.infer<typeof AgencyRequestDto>;

export const AgencyUpdateRequestDto = z.object({
  name: trimmedString(),
  displayName: trimmedString().optional(),
  parentAgencyId: z.string().uuid().optional(),
});
export type AgencyUpdateRequestDto = z.infer<typeof AgencyUpdateRequestDto>;

export const AgencyBrandingUpdateRequestDto = z.object({
  imageFilename: trimmedString(),
});
export type AgencyBrandingUpdateRequestDto = z.infer<
  typeof AgencyBrandingUpdateRequestDto
>;

export const AgencyBrandingResponseDto = z.object({
  imageUrl: trimmedString(),
});

export type AgencyBrandingResponseDto = z.infer<
  typeof AgencyBrandingResponseDto
>;

export const AdminSplitConfigResponseDto = z.object({
  id: z.string().uuid(),
  payoutType: PayoutTypeEnum,
  parentTakePercent: z.number().min(0).max(100),
  sourcedBy: SourcedByEnum.optional(),
  supplierType: z.nativeEnum(SupplierType).optional(),
  agencyId: z.string().uuid().optional(),
  agencyUserId: z.string().uuid().optional(),
});
export type AdminSplitConfigResponseDto = z.infer<
  typeof AdminSplitConfigResponseDto
>;

export const AdminSplitConfigsListResponseDto = z.object({
  data: z.array(AdminSplitConfigResponseDto),
});
export type AdminSplitConfigsListResponseDto = z.infer<
  typeof AdminSplitConfigsListResponseDto
>;

export const AgencyResponseDto = z.object({
  id: z.string().uuid(),
  name: trimmedString(),
  displayName: trimmedString().optional(),
  color: trimmedString(),
  imageUrl: trimmedString().optional(),
  statementsLockedAt: z.string().regex(isoDateRegex).optional(),
});
export type AgencyResponseDto = z.infer<typeof AgencyResponseDto>;

export const agencyToDto = (
  agency: Agency & {
    paidOutUsers?: User[];
  },
): AgencyResponseDto => {
  const allUsersLocked =
    agency.paidOutUsers?.every((user) => user.statementsLockedAt) ?? false;
  const userLockedAtIsSame =
    new Set(
      agency.paidOutUsers
        ?.map((user) => user.statementsLockedAt?.getTime())
        .filter(notEmpty) ?? [],
    ).size === 1;

  return {
    id: agency.id,
    name: agency.name,
    displayName: agency.displayName ?? undefined,
    color: generateColorForId(agency.id),
    imageUrl: agency.imageFilename
      ? getPublicUrl(agency.imageFilename)
      : undefined,
    statementsLockedAt:
      agency.paidOutUsers && allUsersLocked && userLockedAtIsSame
        ? agency.paidOutUsers?.[0].statementsLockedAt?.toISOString()
        : undefined,
  };
};

export function splitConfigsListToAdminDto(
  splitConfigs: SplitConfig[],
): AdminSplitConfigsListResponseDto {
  return AdminSplitConfigsListResponseDto.parse({
    data: splitConfigs.map((splitConfig) => ({
      ...splitConfig,
      parentTakePercent: splitConfig.parentTakePercent.toNumber(),
      supplierType: splitConfig.supplierType ?? undefined,
      agencyId: splitConfig.agencyId ?? undefined,
      agencyUserId: splitConfig.agencyUserId ?? undefined,
    })),
  });
}

export const AdminAgencyResponseDto = AgencyResponseDto.extend({
  parentAgencyId: z.string().uuid().optional(),
  commissionParentTakePct: z.number(),
  feeParentTakePct: z.number(),
  leadCommissionParentTakePct: z.number().optional(),
  leadFeeParentTakePct: z.number().optional(),
  isActive: z.boolean().optional(),
  externalPayoutId: trimmedString().optional(),
  splitConfigs: AdminSplitConfigsListResponseDto,
});
export type AdminAgencyResponseDto = z.infer<typeof AdminAgencyResponseDto>;

export const agencyToAdminDto = (
  agency: Agency & { splitConfigs: SplitConfig[] },
): AdminAgencyResponseDto => {
  const commissionParentTakePercent = agency.splitConfigs
    .find(
      (config) =>
        config.payoutType === TripSplitType.COMMISSION &&
        config.sourcedBy === SourcedBy.ADVISOR,
    )
    ?.parentTakePercent.toNumber();
  const feeParentTakePercent = agency.splitConfigs
    .find(
      (config) =>
        config.payoutType === TripSplitType.FEE &&
        config.sourcedBy === SourcedBy.ADVISOR,
    )
    ?.parentTakePercent.toNumber();
  const leadCommissionParentTakePercent = agency.splitConfigs
    .find(
      (config) =>
        config.payoutType === TripSplitType.COMMISSION &&
        config.sourcedBy === SourcedBy.AGENCY,
    )
    ?.parentTakePercent.toNumber();
  const leadFeeParentTakePercent = agency.splitConfigs
    .find(
      (config) =>
        config.payoutType === TripSplitType.FEE &&
        config.sourcedBy === SourcedBy.AGENCY,
    )
    ?.parentTakePercent.toNumber();

  return {
    id: agency.id,
    name: agency.name,
    displayName: agency.displayName ?? undefined,
    parentAgencyId: agency.parentAgencyId ?? undefined,
    commissionParentTakePct:
      commissionParentTakePercent ?? agency.commissionParentTakePct,
    feeParentTakePct: feeParentTakePercent ?? agency.feeParentTakePct,
    leadCommissionParentTakePct:
      leadCommissionParentTakePercent ??
      agency.leadCommissionParentTakePct ??
      undefined,
    leadFeeParentTakePct:
      leadFeeParentTakePercent ?? agency.leadFeeParentTakePct ?? undefined,
    color: generateColorForId(agency.id),
    isActive: agency.isActive,
    externalPayoutId: agency.externalPayoutId ?? undefined,
    splitConfigs: splitConfigsListToAdminDto(agency.splitConfigs),
  };
};

export const AdminAgencyPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['name', 'displayName', 'createdAt']),
  z.enum(['name', 'displayName']),
);
export type AdminAgencyPaginatedRequestDto = z.infer<
  typeof AdminAgencyPaginatedRequestDto
>;

export const AgencyPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['id', 'name', 'displayName', 'lastUsed']),
  z.enum(['name', 'displayName']),
);
export type AgencyPaginatedRequestDto = z.infer<
  typeof AgencyPaginatedRequestDto
>;
export type AgencySort = z.infer<typeof AgencyPaginatedRequestDto>['sort'];

export const AgencyPaginatedResponseDto =
  createPaginatedResponseDto(AgencyResponseDto);
export type AgencyPaginatedResponseDto = z.infer<
  typeof AgencyPaginatedResponseDto
>;

export const AdminAgencyPaginatedResponseDto = createPaginatedResponseDto(
  AdminAgencyResponseDto,
);
export type AdminAgencyPaginatedResponseDto = z.infer<
  typeof AdminAgencyPaginatedResponseDto
>;

export const AgencyAgreementUpdateRequestDto = z.object({
  subject: trimmedString(),
  terms: trimmedString(),
});
export type AgencyAgreementUpdateRequestDto = z.infer<
  typeof AgencyAgreementUpdateRequestDto
>;

export const AgencyAgreementCreateRequestDto =
  AgencyAgreementUpdateRequestDto.extend({ agencyId: z.string().uuid() });
export type AgencyAgreementCreateRequestDto = z.infer<
  typeof AgencyAgreementCreateRequestDto
>;

export const AgencyAgreementRequestDto = z.object({
  agencyAgreementId: z.string().uuid(),
});
export type AgencyAgreementRequestDto = z.infer<
  typeof AgencyAgreementRequestDto
>;

export const AgencyAgreementResponseDto =
  AgencyAgreementUpdateRequestDto.extend({
    id: z.string().uuid(),
  });
export type AgencyAgreementResponseDto = z.infer<
  typeof AgencyAgreementResponseDto
>;

export function agencyAgreementToDto(
  agencyAgreement: AgencyAgreement,
): AgencyAgreementResponseDto {
  return {
    id: agencyAgreement.id,
    subject: agencyAgreement.subject,
    terms: agencyAgreement.terms,
  };
}

export const AgencyAgreementPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['subject']),
  z.enum(['subject']),
).extend({
  agencyId: z.string().uuid(),
});
export type AgencyAgreementPaginatedRequestDto = z.infer<
  typeof AgencyAgreementPaginatedRequestDto
>;

export const AgencyAgreementPaginatedResponseDto = createPaginatedResponseDto(
  AgencyAgreementResponseDto,
);
export type AgencyAgreementPaginatedResponseDto = z.infer<
  typeof AgencyAgreementPaginatedResponseDto
>;

export const UserRequestDto = z.object({
  userId: z.string().uuid(),
});
export type UserRequestDto = z.infer<typeof UserRequestDto>;

export enum UserMappingType {
  VIRTUOSO = 'VIRTUOSO',
  TRAVEL_LEADERS = 'TRAVEL_LEADERS',
  PROFILENO = 'PROFILENO',
}

export const UserMappingsRequestDto = z.object({
  type: z.nativeEnum(UserMappingType),
});
export type UserMappingsRequestDto = z.infer<typeof UserMappingsRequestDto>;

export const UserOrMeRequestDto = z.object({
  meOrUserId: z.union([z.string().uuid(), z.literal('me')]),
});
export type UserOrMeRequestDto = z.infer<typeof UserOrMeRequestDto>;

export const UserCreateRequestDto = z
  .object({
    remoteId: trimmedString(),
    email: z.string().email(),
    firstName: trimmedString(),
    lastName: trimmedString(),
    statementsEnabled: z.boolean().optional(),
    isActive: z.boolean().optional(),
  })
  .strict();
export type UserCreateRequestDto = z.infer<typeof UserCreateRequestDto>;

export const UserUpdateMappingsRequestDto = z.object({
  externalId: trimmedString(),
});
export type UserUpdateMappingsRequestDto = z.infer<
  typeof UserUpdateMappingsRequestDto
>;

export const UserMappingsResponseDto = z.object({
  id: z.number(),
  userId: z.string().uuid(),
  type: trimmedString(),
  externalId: trimmedString(),
});
export type UserMappingsResponseDto = z.infer<typeof UserMappingsResponseDto>;

export const UserMappingsListResponseDto = z.object({
  data: z.array(UserMappingsResponseDto),
});
export type UserMappingsListResponseDto = z.infer<
  typeof UserMappingsListResponseDto
>;

export const userMappingsToDto = (
  userMapping: UserMapping,
): UserMappingsResponseDto => ({
  id: userMapping.id,
  userId: userMapping.userId,
  type: userMapping.type as UserMappingType,
  externalId: userMapping.externalId,
});

export const UserResponseDto = z.object({
  id: z.string().uuid(),
  remoteId: z.string().nullable(),
  email: trimmedString(),
  firstName: trimmedString(),
  lastName: trimmedString(),
  statementsEnabled: z.boolean(),
  isActive: z.boolean(),
  externalPayoutId: z.string().nullable().optional(),
  paidOutByAgencyId: z.string().uuid().nullable().optional(),
  mappings: z.array(UserMappingsResponseDto).optional(),
});
export type UserResponseDto = z.infer<typeof UserResponseDto>;

export const UserUpdateRequestDto = z.object({
  email: z.string().email(),
  firstName: trimmedString(),
  lastName: trimmedString(),
  statementsEnabled: z.boolean(),
  isActive: z.boolean(),
  externalPayoutId: z.string().nullable().optional(),
  paidOutByAgencyId: z.string().uuid().nullable().optional(),
});
export type UserUpdateRequestDto = z.infer<typeof UserUpdateRequestDto>;

export const userToDto = (user: User): UserResponseDto => ({
  id: user.id,
  remoteId: user.remoteId,
  email: user.email,
  firstName: user.firstName,
  lastName: user.lastName,
  statementsEnabled: user.statementsEnabled,
  isActive: user.isActive,
  externalPayoutId: user.externalPayoutId,
  paidOutByAgencyId: user.paidOutByAgencyId,
});

export enum OrganizationMappingType {
  VIRTUOSO = 'VIRTUOSO',
  SABRE_PCC = 'SABRE_PCC',
  SPOTNANA = 'SPOTNANA',
  LAYER_BUSINESS_ID = 'LAYER_BUSINESS_ID',
}

export const OrganizationMappingRequestDto = z.object({
  type: z.nativeEnum(OrganizationMappingType),
});
export type OrganizationMappingRequestDto = z.infer<
  typeof OrganizationMappingRequestDto
>;

export const OrganizationMappingCreateRequestDto = z.object({
  externalId: trimmedString(),
});
export type OrganizationMappingCreateRequestDto = z.infer<
  typeof OrganizationMappingCreateRequestDto
>;

export const OrganizationMappingResponseDto = z.object({
  type: trimmedString(),
  externalId: trimmedString(),
});
export type OrganizationMappingResponseDto = z.infer<
  typeof OrganizationMappingResponseDto
>;

export const OrganizationMappingsListResponseDto = z.object({
  data: z.array(OrganizationMappingResponseDto),
});
export type OrganizationMappingsListResponseDto = z.infer<
  typeof OrganizationMappingsListResponseDto
>;

export const organizationMappingsToDto = (
  organizationMapping: OrganizationMapping,
): OrganizationMappingResponseDto => ({
  type: organizationMapping.type as OrganizationMappingType,
  externalId: organizationMapping.externalId,
});

export const UserPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['createdAt', 'email', 'firstName', 'lastName']),
  z.enum(['email', 'firstName', 'lastName']),
);
export type UserPaginatedRequestDto = z.infer<typeof UserPaginatedRequestDto>;

export const UserPaginatedResponseDto =
  createPaginatedResponseDto(UserResponseDto);
export type UserPaginatedResponseDto = z.infer<typeof UserPaginatedResponseDto>;

export const AdminUserPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['createdAt', 'email', 'firstName', 'lastName']),
  z.enum(['email', 'firstName', 'lastName']),
);
export type AdminUserPaginatedRequestDto = z.infer<
  typeof AdminUserPaginatedRequestDto
>;

export const AdminUserPaginatedResponseDto =
  createPaginatedResponseDto(UserResponseDto);
export type AdminUserPaginatedResponseDto = z.infer<
  typeof AdminUserPaginatedResponseDto
>;

export enum OrganizationUserType {
  ADMIN = 'ADMIN',
  ASSISTANT = 'ASSISTANT',
}

export const OrganizationUserCreateRequestDto = z.object({
  userId: z.string().uuid(),
  type: z.nativeEnum(OrganizationUserType),
});
export type OrganizationUserCreateRequestDto = z.infer<
  typeof OrganizationUserCreateRequestDto
>;

export const OrganizationUserResponseDto = z.object({
  id: z.string().uuid(),
  userId: z.string().uuid(),
  firstName: trimmedString(),
  lastName: trimmedString(),
  email: trimmedString(),
  type: z.nativeEnum(OrganizationUserType),
});
export type OrganizationUserResponseDto = z.infer<
  typeof OrganizationUserResponseDto
>;

export const organizationUserToDto = (
  organizationUser: OrganizationUser & {
    user: User;
  },
): OrganizationUserResponseDto => ({
  id: organizationUser.id,
  userId: organizationUser.userId,
  firstName: organizationUser.user.firstName,
  lastName: organizationUser.user.lastName,
  email: organizationUser.user.email,
  type: organizationUser.type as OrganizationUserType,
});

export const OrganizationUserPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['createdAt', 'name']),
  z.enum(['firstName', 'lastName']),
);
export type OrganizationUserPaginatedRequestDto = z.infer<
  typeof OrganizationUserPaginatedRequestDto
>;

export const OrganizationUserPaginatedResponseDto = createPaginatedResponseDto(
  OrganizationUserResponseDto,
);
export type OrganizationUserPaginatedResponseDto = z.infer<
  typeof OrganizationUserPaginatedResponseDto
>;

export const OrganizationUserRequestDto = z.object({
  organizationUserId: z.string().uuid(),
});
export type OrganizationUserRequestDto = z.infer<
  typeof OrganizationUserRequestDto
>;

export const ProfileResponseDto = z.object({
  id: z.string().uuid(),
  agencyUserId: z.string().uuid(),
  remoteId: z.string().nullable(),
  name: trimmedString(),
  color: trimmedString(),
  profileImageUrl: z.string().nullable().optional(),
});
export type ProfileResponseDto = z.infer<typeof ProfileResponseDto>;

export const ProfileWithAgencyNameResponseDto = ProfileResponseDto.extend({
  agencyName: trimmedString(),
});
export type ProfileWithAgencyNameResponseDto = z.infer<
  typeof ProfileWithAgencyNameResponseDto
>;

export enum AgencyMappingType {
  QBO_CLASS_ID = 'QBO_CLASS_ID',
  BRANCHNO = 'BRANCHNO',
  BRANCH_NAME = 'BRANCH_NAME',
}

export const AgencyMappingRequestDto = z.object({
  type: z.nativeEnum(AgencyMappingType),
});
export type AgencyMappingRequestDto = z.infer<typeof AgencyMappingRequestDto>;

export const AgencyMappingCreateRequestDto = z.object({
  externalId: trimmedString(),
});
export type AgencyMappingCreateRequestDto = z.infer<
  typeof AgencyMappingCreateRequestDto
>;

export const AgencyMappingResponseDto = z.object({
  type: trimmedString(),
  externalId: trimmedString(),
});
export type AgencyMappingResponseDto = z.infer<typeof AgencyMappingResponseDto>;

export const AgencyMappingsListResponseDto = z.object({
  data: z.array(AgencyMappingResponseDto),
});
export type AgencyMappingsListResponseDto = z.infer<
  typeof AgencyMappingsListResponseDto
>;

export const agencyMappingsToDto = (
  agencyMapping: AgencyMapping,
): AgencyMappingResponseDto => ({
  type: agencyMapping.type as AgencyMappingType,
  externalId: agencyMapping.externalId,
});

export enum AgencyUserMappingType {
  // these two are intentionally not the same. It should be SABRE_SINE for both but we were
  // already locked into SABRE_SIGN in the DB and making the change everywhere would be a pain
  SABRE_SINE = 'SABRE_SIGN',
  SABRE_INTERFACE_ID = 'SABRE_INTERFACE_ID',
  VIRTUOSO = 'VIRTUOSO',
  PROFILENO = 'PROFILENO',
  BRANCHNO = 'BRANCHNO',
  SPOTNANA = 'SPOTNANA',
}

export const agencyUserToProfileResponseDto = (
  agencyUser: AgencyUser & { user: User },
): ProfileResponseDto => ({
  id: agencyUser.user.id,
  agencyUserId: agencyUser.id,
  remoteId: agencyUser.user.remoteId,
  name: `${agencyUser.user.firstName} ${agencyUser.user.lastName}`,
  color: generateColorForId(agencyUser.agencyId),
  profileImageUrl: agencyUser.user.profileImageUrl ?? undefined,
});

export const agencyUserToProfileWithAgencyNameResponseDto = (
  agencyUser: AgencyUser & { user: User; agency: Agency },
): ProfileWithAgencyNameResponseDto => ({
  ...agencyUserToProfileResponseDto(agencyUser),
  agencyName: agencyUser.agency.name,
});

export enum AgencyUserRole {
  ADMIN = 'ADMIN',
  EMPLOYEE = 'EMPLOYEE',
  IC = 'IC',
}

export const AgencyUserCreateRequestDto = z
  .object({
    userId: trimmedString(),
    role: z.nativeEnum(AgencyUserRole),
    agencyTakePct: z.number().min(0).max(100),
    feeAgencyTakePct: z.number().min(0).max(100).optional(),
    leadCommissionAgencyTakePct: z.number().min(0).max(100).optional(),
    leadFeeAgencyTakePct: z.number().min(0).max(100).optional(),
  })
  .strict();
export type AgencyUserCreateRequestDto = z.infer<
  typeof AgencyUserCreateRequestDto
>;

export const AgencyUserRequestDto = z.object({
  agencyUserId: z.string().uuid(),
});
export type AgencyUserRequestDto = z.infer<typeof AgencyUserRequestDto>;

export const AgencyUserPaginatedRequestQuery = getPaginatedRequestDto(
  z.enum(['name']),
  z.enum(['name']),
);
export type AgencyUserPaginatedRequestQuery = z.infer<
  typeof AgencyUserPaginatedRequestQuery
>;

export const AgencyUserResponseDto = z.object({
  id: z.string().uuid(),
  firstName: trimmedString(),
  lastName: trimmedString(),
  email: trimmedString(),
  company: trimmedString(),
  role: z.enum([
    AgencyUserRole.ADMIN,
    AgencyUserRole.EMPLOYEE,
    AgencyUserRole.IC,
  ]),
  // todo: remove these in favor of splitConfigs vv
  agencyTakePct: z.number(),
  feeAgencyTakePct: z.number().optional(),
  leadCommissionAgencyTakePct: z.number().optional(),
  leadFeeAgencyTakePct: z.number().optional(),
  // todo: remove these ^^
  color: string(),
  profilePictureUri: trimmedString().optional(),
});
export type AgencyUserResponseDto = z.infer<typeof AgencyUserResponseDto>;

export const AgencyUserPaginatedResponseDto =
  createPaginatedResponseDto<AgencyUserResponseDto>(AgencyUserResponseDto);

export type AgencyUserPaginatedResponseDto = z.infer<
  typeof AgencyUserPaginatedResponseDto
>;

export const agencyUserToDto = (
  agencyUser: AgencyUser & {
    agency: Agency;
    user: User;
  },
): AgencyUserResponseDto => ({
  id: agencyUser.id,
  firstName: agencyUser.user.firstName,
  lastName: agencyUser.user.lastName,
  email: agencyUser.user.email,
  role: agencyUser.role as AgencyUserRole,
  company: agencyUser.agency.name,
  // todo: remove these vv
  agencyTakePct: agencyUser.agencyTakePct,
  feeAgencyTakePct: agencyUser.feeAgencyTakePct ?? undefined,
  leadCommissionAgencyTakePct:
    agencyUser.leadCommissionAgencyTakePct ?? undefined,
  leadFeeAgencyTakePct: agencyUser.leadFeeAgencyTakePct ?? undefined,
  // todo: remove these ^^
  color: generateColorForId(agencyUser.agencyId),
  profilePictureUri: agencyUser.user.profileImageUrl ?? undefined,
});

export const AdminAgencyUserResponseDto = AgencyUserResponseDto.extend({
  splitConfigs: AdminSplitConfigsListResponseDto,
});
export type AdminAgencyUserResponseDto = z.infer<
  typeof AdminAgencyUserResponseDto
>;

export const agencyUserToAdminDto = (
  agencyUser: AgencyUser & {
    agency: Agency;
    user: User;
    splitConfigs: SplitConfig[];
  },
): AdminAgencyUserResponseDto => {
  return {
    ...agencyUserToDto({
      ...agencyUser,
      agencyTakePct:
        agencyUser.splitConfigs
          .find(
            (splitConfig) =>
              splitConfig.payoutType === TripSplitType.COMMISSION &&
              splitConfig.sourcedBy === SourcedBy.ADVISOR &&
              splitConfig.supplierType === null,
          )
          ?.parentTakePercent.toNumber() ?? agencyUser.agencyTakePct,
      feeAgencyTakePct:
        agencyUser.splitConfigs
          .find(
            (splitConfig) =>
              splitConfig.payoutType === TripSplitType.FEE &&
              splitConfig.sourcedBy === SourcedBy.ADVISOR &&
              splitConfig.supplierType === null,
          )
          ?.parentTakePercent.toNumber() ?? agencyUser.feeAgencyTakePct,
      leadCommissionAgencyTakePct:
        agencyUser.splitConfigs
          .find(
            (splitConfig) =>
              splitConfig.payoutType === TripSplitType.COMMISSION &&
              splitConfig.sourcedBy === SourcedBy.AGENCY &&
              splitConfig.supplierType === null,
          )
          ?.parentTakePercent.toNumber() ??
        agencyUser.leadCommissionAgencyTakePct,
      leadFeeAgencyTakePct:
        agencyUser.splitConfigs
          .find(
            (splitConfig) =>
              splitConfig.payoutType === TripSplitType.FEE &&
              splitConfig.sourcedBy === SourcedBy.AGENCY &&
              splitConfig.supplierType === null,
          )
          ?.parentTakePercent.toNumber() ?? agencyUser.leadFeeAgencyTakePct,
    }),
    splitConfigs: splitConfigsListToAdminDto(agencyUser.splitConfigs),
  };
};

export const AdminAgencyUserPaginatedResponseDto =
  createPaginatedResponseDto<AdminAgencyUserResponseDto>(
    AdminAgencyUserResponseDto,
  );

export type AdminAgencyUserPaginatedResponseDto = z.infer<
  typeof AdminAgencyUserPaginatedResponseDto
>;

// Forms

export enum FormType {
  LEAD = 'LEAD',
  CLIENT = 'CLIENT',
}

export enum FormSubmitRecipientType {
  CREATOR = 'CREATOR',
  AGENCY_USER = 'AGENCY_USER',
}

export const FormStructureData = z.record(z.any());
export type FormStructureData = z.infer<typeof FormStructureData>;

export const FormCreateRequestDto = z.object({
  name: trimmedString(),
  type: z.nativeEnum(FormType),
  assignLeadsToMe: z.boolean().optional(),
  structure: FormStructureData.optional(),
  isShared: z.boolean().optional(),
  formSubmitRecipientType: z.nativeEnum(FormSubmitRecipientType).optional(),
});
export type FormCreateRequestDto = z.infer<typeof FormCreateRequestDto>;

export const AdminFormCreateRequestDto = FormCreateRequestDto.extend({
  agencyUserId: z.string().uuid(),
});
export type AdminFormCreateRequestDto = z.infer<
  typeof AdminFormCreateRequestDto
>;

export const FormUpdateRequestDto = z.object({
  name: trimmedString().optional(),
  type: z.nativeEnum(FormType).optional(),
});
export type FormUpdateRequestDto = z.infer<typeof FormUpdateRequestDto>;

export const FormStructureUpdateRequestDto = z.object({
  data: FormStructureData,
});
export type FormStructureUpdateRequestDto = z.infer<
  typeof FormStructureUpdateRequestDto
>;

export const FormStructureResponseDto = z.object({
  data: FormStructureData.optional(),
});
export type FormStructureResponseDto = z.infer<typeof FormStructureResponseDto>;

export const FormPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['createdAt', 'name', 'type', 'numSubmits', 'updatedAt']),
  z.enum(['name']),
);
export type FormPaginatedRequestDto = z.infer<typeof FormPaginatedRequestDto>;

export const FormResponseDto = z.object({
  id: z.string().uuid(),
  name: trimmedString(),
  type: z.nativeEnum(FormType),
  assignLeadsToAgencyUserId: z.string().uuid().optional(),
  createdAt: z.string().regex(isoDateRegex),
  structure: FormStructureData.optional(),
  numSubmits: z.number(),
  isShared: z.boolean().optional(),
  shareAccessType: z.nativeEnum(ShareAccessType).optional(),
  formSubmitRecipientType: z.nativeEnum(FormSubmitRecipientType).optional(),
});
export type FormResponseDto = z.infer<typeof FormResponseDto>;

export const FormPaginatedResponseDto =
  createPaginatedResponseDto(FormResponseDto);
export type FormPaginatedResponseDto = z.infer<typeof FormPaginatedResponseDto>;

export const AdminFormResponseDto = FormResponseDto.extend({
  apiKey: trimmedString().optional(),
});
export type AdminFormResponseDto = z.infer<typeof AdminFormResponseDto>;

export const AdminFormPaginatedResponseDto =
  createPaginatedResponseDto(AdminFormResponseDto);
export type AdminFormPaginatedResponseDto = z.infer<
  typeof AdminFormPaginatedResponseDto
>;

export const FormRequestDto = z.object({
  formId: z.string().uuid(),
});
export type FormRequestDto = z.infer<typeof FormRequestDto>;

export const formToDto = (
  form: Form & {
    agencyUser?: AgencyUser;
    shareAccessType?: ShareAccessType | null;
    shareAccess?: { id: string }[] | null;
  },
  numbSubmits: number,
): FormResponseDto => ({
  id: form.id,
  name: form.name,
  type: form.type as FormType,
  assignLeadsToAgencyUserId: form.assignLeadsToAgencyUserId ?? undefined,
  structure: form.structure
    ? JSON.parse(JSON.stringify(form.structure))
    : undefined,
  createdAt: form.createdAt.toISOString(),
  numSubmits: numbSubmits,
  // if the form has been shared at all
  isShared: (form.shareAccess ?? []).length > 0,
  // what type of share access the requesting user has
  // (if undefined, the user has access to the form natively)
  shareAccessType: form.shareAccessType ?? undefined,
});

export const formToAdminDto = (
  form: Form & {
    agencyUser?: AgencyUser;
  },
  numSubmits: number,
): AdminFormResponseDto => ({
  ...formToDto(form, numSubmits),
  apiKey: form.apiKey ?? undefined,
});

export const FormSubmitPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['name', 'email', 'createdAt']),
  z.enum(['name']),
);

export type FormSubmitPaginatedRequestDto = z.infer<
  typeof FormSubmitPaginatedRequestDto
>;

export const FormSubmitData = z.record(z.any());
export type FormSubmitData = z.infer<typeof FormSubmitData>;

export const FormSubmitResponseDto = z.object({
  id: z.number(),
  name: trimmedString(),
  createdAt: z.string().regex(isoDateRegex),
  data: FormSubmitData,
  email: trimmedString(),
  submitterName: trimmedString().optional(),
  advisorName: trimmedString().optional(),
  formType: z.nativeEnum(FormType),
  formId: z.string().uuid(),
  clientId: z.string().uuid().optional(),
  leadId: z.string().uuid().optional(),
});
export type FormSubmitResponseDto = z.infer<typeof FormSubmitResponseDto>;

export const formSubmitToFormSubmitResponseDto = (
  formSubmit: FormSubmit & {
    form: Form & {
      agencyUser?: {
        user: {
          firstName: string;
          lastName: string;
        };
      };
    };
  },
): FormSubmitResponseDto => {
  let submitterName: string | undefined;
  const structure = formSubmit.form.structure;
  if (structure && isSurveyJSForm(structure)) {
    let nameQuestion: string | undefined;
    if (structure.pages) {
      for (const page of structure.pages) {
        for (const element of page.elements ?? []) {
          if (element.type === 'ts-fullname') {
            nameQuestion = element.name;
          }
        }
      }
    }

    if (nameQuestion) {
      const parsedData = JSON.parse(formSubmit.data);
      const nameValue = parsedData[nameQuestion];
      if (typeof nameValue === 'string') {
        submitterName = nameValue;
      } else if (typeof nameValue === 'object' && nameValue !== null) {
        submitterName = `${nameValue.firstName ?? ''} ${nameValue.lastName ?? ''}`;
      }
    }
  }

  return {
    id: formSubmit.id,
    name: formSubmit.form.name,
    createdAt: formSubmit.createdAt.toISOString(),
    data: JSON.parse(formSubmit.data),
    email: formSubmit.email,
    formType: formSubmit.form.type as FormType,
    formId: formSubmit.form.id,
    clientId: formSubmit.clientId ?? undefined,
    leadId: formSubmit.leadId ?? undefined,
    advisorName: formSubmit.form.agencyUser?.user
      ? `${formSubmit.form.agencyUser.user.firstName} ${formSubmit.form.agencyUser.user.lastName}`
      : undefined,
    submitterName: submitterName ?? undefined,
  };
};

export const FormSubmitPaginatedResponseDto = createPaginatedResponseDto(
  FormSubmitResponseDto,
);
export type FormSubmitPaginatedResponseDto = z.infer<
  typeof FormSubmitPaginatedResponseDto
>;

export type FormItemType =
  | 'text'
  | 'comment'
  | 'radiogroup'
  | 'checkbox'
  | 'dropdown'
  | 'boolean'
  | 'rating'
  | 'date'
  | 'signaturepad'
  | 'ts-fullname'
  | 'ts-email'
  | 'ts-address'
  | 'ts-phone'
  | 'ts-birthday'
  | 'ts-emergency-contact'
  | 'ts-passport'
  | 'ts-trusted-traveler'
  | 'ts-attachment'
  | 'ts-preferences'
  | 'ts-health'
  | 'ts-anniversary'
  | 'ts-programs';

export const ClientGroupDto = z.object({
  isPrimary: z.boolean(),
  relationship: trimmedString().optional(),
});
export type ClientGroupDto = z.infer<typeof ClientGroupDto>;

export const ClientGroupUpdateRequestDto = ClientGroupDto;
export type ClientGroupUpdateRequestDto = z.infer<
  typeof ClientGroupUpdateRequestDto
>;

export const ClientDto = z.object({
  firstName: trimmedString(),
  middleName: trimmedString().optional(),
  lastName: trimmedString(),
  name: trimmedString(),
  email: z.string().email().optional(),
  phone: trimmedString().optional(),
  gender: trimmedString().optional(),
  company: trimmedString().optional(),
  color: trimmedString(),
  notes: trimmedString().optional(),
  tag: trimmedString().optional(),
  leadSource: trimmedString().optional(),
  assignedToAgencyUser: AgencyUserResponseDto.optional(),
  createdByUser: UserResponseDto.optional(),
});
export type ClientDto = z.infer<typeof ClientDto>;

export const ClientResponseDto = ClientDto.extend({
  id: z.string().uuid(),
  externalClientId: trimmedString().optional(),
  createdAt: z.string().regex(isoDateRegex),
  sourcedBy: SourcedByEnum,
  email: trimmedString().optional(),
  phone: trimmedString().optional(),
  isActive: z.boolean(),
  agencyId: z.string().uuid().optional(),
  attachments: z.array(AttachmentResponseDto).optional(),
  passportNumber: z.string().optional(),
  dateOfBirth: z.string().regex(isoDateRegex).optional(),
  relationship: z.string().optional(),
});
export type ClientResponseDto = z.infer<typeof ClientResponseDto>;

export const GroupDto = z.object({
  type: GroupTypeEnum,
  sourcedBy: SourcedByEnum.optional(),
  name: trimmedString(),
  industry: trimmedString().optional(),
  dkNumber: trimmedString().optional(),
  email: z.string().optional(),
  phone: trimmedString().optional(),
  address1: trimmedString().optional(),
  address2: trimmedString().optional(),
  city: trimmedString().optional(),
  state: trimmedString().optional(),
  zip: trimmedString().optional(),
  assignedToAgencyUserId: z.string().uuid().optional(),
});
export type GroupDto = z.infer<typeof GroupDto>;

export enum CreditCardStatus {
  ACTIVE = 'active',
  EXPIRED = 'expired',
  NONE = 'none',
}

export const GroupMemberResponseDto = z.object({
  isPrimary: z.boolean(),
  id: z.string(),
  color: z.string(),
  lastName: trimmedString(),
  middleName: trimmedString().optional(),
  firstName: trimmedString(),
  name: trimmedString(),
  dateOfBirth: z.string().regex(isoDateRegex).optional(),
  email: z.string().optional(),
  phone: z.string().optional(),
  passportNumber: trimmedString().optional(),
  isActive: z.boolean(),
  relationship: trimmedString().optional(),
  primaryAddress: z
    .object({
      address1: trimmedString().optional(),
      state: trimmedString().optional(),
    })
    .optional(),
});
export type GroupMemberResponseDto = z.infer<typeof GroupMemberResponseDto>;

export const GroupResponseDto = GroupDto.extend({
  id: z.string().uuid(),
  email: trimmedString().optional(),
  createdAt: z.string().regex(isoDateRegex),
  updatedAt: z.string().regex(isoDateRegex),
  color: trimmedString(),
  primaryMemberId: z.string().uuid().optional(),
  primaryMemberName: trimmedString().optional(),
  memberCount: z.number(),
  members: z.array(GroupMemberResponseDto),
  assignedToAgencyUser: AgencyUserResponseDto.optional(),
  notes: trimmedString().optional(),
  internalClientId: trimmedString().optional(),
  canUseMergeToPnr: z.boolean().optional(),
});
export type GroupResponseDto = z.infer<typeof GroupResponseDto>;

export const ClientGroupResponseDto = ClientGroupDto.extend({
  groupId: z.string().uuid(),
  clientId: z.string().uuid(),
  group: GroupResponseDto,
});
export type ClientGroupResponseDto = z.infer<typeof ClientGroupResponseDto>;

export const ClientWithGroupsResponseDto = ClientResponseDto.extend({
  groups: z.array(ClientGroupResponseDto),
});
export type ClientWithGroupsResponseDto = z.infer<
  typeof ClientWithGroupsResponseDto
>;

export const TripTravelerResponseDto = ClientResponseDto.extend({
  isPrimary: z.boolean(),
  dateOfBirth: trimmedString().optional(),
  passportNumber: trimmedString().optional(),
  creditCardStatus: z.nativeEnum(CreditCardStatus),
});

export type TripTravelerResponseDto = z.infer<typeof TripTravelerResponseDto>;

export const TripTravelerWithGroupsResponseDto =
  ClientWithGroupsResponseDto.extend({
    dateOfBirth: trimmedString().optional(),
    passportNumber: trimmedString().optional(),
    creditCardStatus: z.nativeEnum(CreditCardStatus).optional(),
    isPrimary: z.boolean(),
  });

export type TripTravelerWithGroupsResponseDto = z.infer<
  typeof TripTravelerWithGroupsResponseDto
>;

export const ClientProfileCorporateInfoDto = z.object({
  corporateGroup: GroupResponseDto.optional(),
  company: trimmedString().optional(),
  title: trimmedString().optional(),
  role: trimmedString().optional(),
  department: trimmedString().optional(),
  assistant: trimmedString().optional(),
  assistantEmail: trimmedString().optional(),
  assistantPhone: trimmedString().optional(),
  notes: trimmedString().optional(),
});
export type ClientProfileCorporateInfoDto = z.infer<
  typeof ClientProfileCorporateInfoDto
>;

export const ClientProfileUpdateCorporateInfoDto =
  ClientProfileCorporateInfoDto.omit({
    corporateGroup: true,
  }).extend({
    corporateGroupId: z.string().uuid().optional(),
  });
export type ClientProfileUpdateCorporateInfoDto = z.infer<
  typeof ClientProfileUpdateCorporateInfoDto
>;

export const ClientCreateRequestDto = z
  .object({
    firstName: trimmedString().optional(),
    middleName: trimmedString().optional(),
    lastName: trimmedString().optional(),
    preferredName: trimmedString().optional(),
    prefix: trimmedString().optional(),
    suffix: trimmedString().optional(),
    email: z.string().email().optional(),
    phone: trimmedString().optional(),
    company: trimmedString().optional(),
    leadId: z.string().uuid().optional(),
    agencyId: z.string().uuid().optional(),
    sourcedBy: SourcedByEnum.optional(),
    corporateGroupId: z.string().uuid().optional(),
    corporateInfo: ClientProfileCorporateInfoDto.optional(),
    groups: z
      .array(
        z.object({
          id: z.string().uuid().optional(),
          name: z.string().optional(),
        }),
      )
      .optional(),
  })
  .refine(
    (data) => {
      return data.leadId || (data.firstName && data.lastName);
    },
    {
      message: 'Either leadId or firstName and lastName must be provided',
    },
  )
  .refine(
    ({ corporateGroupId, corporateInfo }) =>
      (corporateGroupId && corporateInfo) ||
      (!corporateGroupId && !corporateInfo),
    {
      message:
        'corporateGroupId and corporateInfo must be both provided, or neither',
    },
  );
export type ClientCreateRequestDto = z.infer<typeof ClientCreateRequestDto>;

export const clientToMinimalDto = (
  client: Client & {
    leads?: Lead[] | null;
    assignedToAgencyUser?:
      | (AgencyUser & {
          agency: Agency;
          user: User;
        })
      | null;
    createdByUser?: User;
  },
): ClientResponseDto => {
  return {
    id: client.id,
    firstName: client.firstName,
    lastName: client.lastName,
    name: `${client.firstName} ${client.lastName}`,
    createdAt: client.createdAt.toISOString(),
    sourcedBy: client.sourcedBy as SourcedBy,
    color: generateColorForId(client.id),
    isActive: !client.deactivatedAt,
    email: client.email ?? undefined,
    phone: client.phone ?? undefined,
  };
};

export const clientToMinimalWithGroupsDto = (
  client: Client & {
    leads?: Lead[] | null;
    assignedToAgencyUser?:
      | (AgencyUser & {
          agency: Agency;
          user: User;
        })
      | null;
    createdByUser?: User;
    groups: (ClientGroup & {
      group: Group & {
        assignedToAgencyUser:
          | (AgencyUser & {
              agency: Agency;
              user: User;
            })
          | null;
      };
    })[];
  },
): ClientWithGroupsResponseDto => ({
  ...clientToMinimalDto(client),
  groups: client.groups.map(clientGroupToDto),
});

export const clientToDto = (
  client: Client & {
    leads?: Lead[] | null;
    assignedToAgencyUser?:
      | (AgencyUser & {
          agency: Agency;
          user: User;
        })
      | null;
    createdByUser?: User;
    attachments?: Attachment[];
    passports?: ClientPassportInfo[];
    dates?: ClientDate[];
    groups?: { relationship: string | null }[];
  },
): ClientResponseDto => {
  const [firstLead] =
    client.leads?.sort((a, b) => {
      return a.createdAt.getTime() - b.createdAt.getTime();
    }) ?? [];

  return {
    id: client.id,
    firstName: client.firstName,
    middleName: client.middleName ?? undefined,
    lastName: client.lastName,
    name: `${client.firstName} ${client.lastName}`,
    email: client.email ?? undefined,
    phone: client.phone ?? undefined,
    company: client.company ?? undefined,
    color: generateColorForId(client.id),
    leadSource: firstLead?.source ?? undefined,
    agencyId: client.agencyId ?? undefined,
    assignedToAgencyUser: client.assignedToAgencyUser
      ? agencyUserToDto(client.assignedToAgencyUser)
      : undefined,
    createdByUser: client.createdByUser
      ? userToDto(client.createdByUser)
      : undefined,
    createdAt: client.createdAt.toISOString(),
    sourcedBy: client.sourcedBy as SourcedBy,
    isActive: !client.deactivatedAt,
    gender: client.gender ?? undefined,
    dateOfBirth:
      client.dates?.find((d) => d.type === 'BIRTHDAY')?.date?.toISOString() ??
      undefined,

    attachments: client.attachments?.length
      ? client.attachments.map(attachmentToDto)
      : undefined,
    passportNumber: client.passports?.[0]?.passportNumber ?? undefined,
    relationship: client.groups?.at(0)?.relationship ?? undefined,
  };
};

export const clientToTravelerDto = (
  client: Client & {
    dates?: ClientDate[];
    passports?: ClientPassportInfo[];
    creditCards?: ClientProfileCreditCard[];
    leads?: Lead[] | null;
    assignedToAgencyUser?:
      | (AgencyUser & {
          agency: Agency;
          user: User;
        })
      | null;
    createdByUser?: User;
  },
  options?: {
    isPrimary: boolean;
  },
): TripTravelerResponseDto => {
  const traveler = {
    ...clientToDto(client),
    dateOfBirth: client.dates?.find((d) => d.type === 'BIRTHDAY')?.date
      ? moment(client.dates?.find((d) => d.type === 'BIRTHDAY')?.date).format(
          'YYYY-MM-DD',
        )
      : undefined,
    passportNumber: client.passports?.[0]?.passportNumber ?? undefined,
    isPrimary: !!options?.isPrimary,
    creditCardStatus: client.creditCards?.some((cc) =>
      moment(`${cc.expMonth}/${cc.expYear}`, 'MM/YYYY').isAfter(moment()),
    )
      ? CreditCardStatus.ACTIVE
      : client.creditCards?.length
        ? CreditCardStatus.EXPIRED
        : CreditCardStatus.NONE,
  };

  return traveler;
};

export const clientWithGroupsToDto = (
  client: Client & {
    leads?: Lead[] | null;
    assignedToAgencyUser?:
      | (AgencyUser & {
          agency: Agency;
          user: User;
        })
      | null;
    createdByUser?: User;
    groups: (ClientGroup & {
      group: Group & {
        assignedToAgencyUser:
          | (AgencyUser & {
              agency: Agency;
              user: User;
            })
          | null;
      };
    })[];
  },
): ClientWithGroupsResponseDto => ({
  ...clientToDto(client),
  groups: client.groups.map(clientGroupToDto),
});

export const tripTravelerWithGroupsToDto = (
  client: Client & {
    passports?: ClientPassportInfo[];
    creditCards?: ClientProfileCreditCard[];
    dates?: ClientDate[];
    leads?: Lead[] | null;
    assignedToAgencyUser?:
      | (AgencyUser & {
          agency: Agency;
          user: User;
        })
      | null;
    createdByUser?: User;
    groups: (ClientGroup & {
      group: Group & {
        assignedToAgencyUser:
          | (AgencyUser & {
              agency: Agency;
              user: User;
            })
          | null;
      };
    })[];
  },
  options?: { isPrimary: boolean },
): TripTravelerWithGroupsResponseDto => ({
  ...clientToTravelerDto(client, { isPrimary: !!options?.isPrimary }),
  groups: client.groups.map(clientGroupToDto),
});

// TODO: needs to be updated once new address feature is on
export const addressToDto = (
  addresses: ClientAddress[],
): ClientProfileAddressesDto => {
  const primary = addresses.find((a) => a.type === 'PRIMARY');
  const secondary = addresses.find((a) => a.type === 'SECONDARY');
  const billing = addresses.find((a) => a.type === 'BILLING');
  const business = addresses.find((a) => a.type === 'BUSINESS');

  return {
    addresses: addresses.map((address) => ({
      id: address.id,
      type: address.type,
      description: address.address?.trim().length ? address.address : undefined,
      poBox: address.poBox ?? undefined,
      address1: address.address1 ?? undefined,
      address2: address.address2 ?? undefined,
      city: address.city ?? undefined,
      state: address.state ?? undefined,
      zip: address.zip ?? undefined,
      country: address.country ?? undefined,
      notes: address.notes ?? undefined,
      effectiveMonths: (address.effectiveMonths as Months[]) ?? [],
    })),
    primary: primary?.address ?? undefined,
    ...(primary
      ? {
          primaryDetails: {
            id: primary.id,
            placeId: primary.placeId ?? undefined,
            description: primary.address?.trim().length
              ? primary.address
              : undefined,
            latitude: primary.lat ?? undefined,
            longitude: primary.lng ?? undefined,
            poBox: primary.poBox ?? undefined,
            address1: primary.address1 ?? undefined,
            address2: primary.address2 ?? undefined,
            city: primary.city ?? undefined,
            state: primary.state ?? undefined,
            zip: primary.zip ?? undefined,
            country: primary.country ?? undefined,
            notes: primary.notes ?? undefined,
            effectiveMonths: primary.effectiveMonths as Months[],
          },
        }
      : {}),
    secondary: secondary?.address ?? undefined,
    ...(secondary
      ? {
          secondaryDetails: {
            id: secondary.id,
            placeId: secondary.placeId ?? undefined,
            description: secondary.address?.trim().length
              ? secondary.address
              : undefined,
            latitude: secondary.lat ?? undefined,
            longitude: secondary.lng ?? undefined,
            poBox: secondary.poBox ?? undefined,
            address1: secondary.address1 ?? undefined,
            address2: secondary.address2 ?? undefined,
            city: secondary.city ?? undefined,
            state: secondary.state ?? undefined,
            zip: secondary.zip ?? undefined,
            country: secondary.country ?? undefined,
            notes: secondary.notes ?? undefined,
          },
        }
      : {}),

    billing: billing?.address ?? undefined,
    ...(billing
      ? {
          billingDetails: {
            id: billing.id,
            placeId: billing.placeId ?? undefined,
            description: billing.address?.trim().length
              ? billing.address
              : undefined,
            latitude: billing.lat ?? undefined,
            longitude: billing.lng ?? undefined,
            poBox: billing.poBox ?? undefined,
            address1: billing.address1 ?? undefined,
            address2: billing.address2 ?? undefined,
            city: billing.city ?? undefined,
            state: billing.state ?? undefined,
            zip: billing.zip ?? undefined,
            country: billing.country ?? undefined,
            notes: billing.notes ?? undefined,
            effectiveMonths: billing.effectiveMonths as Months[],
          },
        }
      : {}),
    business: business?.address ?? undefined,
    ...(business
      ? {
          businessDetails: {
            id: business.id,
            placeId: business.placeId ?? undefined,
            description: business.address?.trim().length
              ? business.address
              : undefined,
            latitude: business.lat ?? undefined,
            longitude: business.lng ?? undefined,
            poBox: business.poBox ?? undefined,
            address1: business.address1 ?? undefined,
            address2: business.address2 ?? undefined,
            city: business.city ?? undefined,
            state: business.state ?? undefined,
            zip: business.zip ?? undefined,
            country: business.country ?? undefined,
            notes: business.notes ?? undefined,
            effectiveMonths: business.effectiveMonths as Months[],
          },
        }
      : {}),
  };
};

export const corporateToDto = (
  client: ClientWithProfile,
): ClientProfileCorporateInfoDto | undefined => {
  const corporate = {
    corporateGroup: getClientCorporateGroupDto(client.groups),
    company: client.corporate?.company ?? client.company ?? undefined,
    title: client.corporate?.title ?? undefined,
    role: client.corporate?.role ?? undefined,
    department: client.corporate?.department ?? undefined,
    assistant: client.corporate?.assistant ?? undefined,
    assistantPhone: client.corporate?.assistantPhone ?? undefined,
    assistantEmail: client.corporate?.assistantEmail ?? undefined,
    notes: client.corporate?.notes ?? undefined,
  };

  const isDefined = Object.values(corporate).some(
    (value) => value !== undefined,
  );

  return isDefined ? corporate : undefined;
};

export const getClientCorporateGroupDto = (
  groups: ClientWithProfile['groups'],
): GroupResponseDto | undefined => {
  const corporateGroup = groups.find(
    ({ group }) => group.type === GroupType.CORPORATE,
  )?.group;
  return corporateGroup ? groupToDto(corporateGroup) : undefined;
};

export enum GiftCardLedgerType {
  FUND = 'FUND',
  SPEND = 'SPEND',
  TRANSFER = 'TRANSFER',
  MANUAL = 'MANUAL',
}

export const GiftCardOwnerDto = z.object({
  clientId: z.string().uuid().optional(),
  groupId: z.string().uuid().optional(),
});
export type GiftCardOwnerDto = z.infer<typeof GiftCardOwnerDto>;

export const GiftCardOwnerResponseDto = GiftCardOwnerDto.extend({
  client: ClientResponseDto.optional(),
  group: GroupResponseDto.optional(),
});
export type GiftCardOwnerResponseDto = z.infer<typeof GiftCardOwnerResponseDto>;

export const GiftCardDto = GiftCardOwnerDto.extend({
  notes: trimmedString().optional(),
});
export type GiftCardDto = z.infer<typeof GiftCardDto>;

export const GiftCardResponseDto = GiftCardDto.merge(
  GiftCardOwnerResponseDto,
).extend({
  id: z.string().uuid(),
  publicId: trimmedString(),
  initialBalance: z.number(),
  balance: z.number(),
  transferredFrom: GiftCardOwnerResponseDto.optional(),
});
export type GiftCardResponseDto = z.infer<typeof GiftCardResponseDto>;

export const GiftCardListResponseDto = z.array(GiftCardResponseDto);
export type GiftCardListResponseDto = z.infer<typeof GiftCardListResponseDto>;

export const GiftCardCreateRequestDto = GiftCardDto.extend({
  initialBalance: z.number(),
}).refine(
  ({ clientId, groupId }) => (clientId ? 1 : 0) + (groupId ? 1 : 0) === 1,
  {
    message: 'Either clientId or groupId is required, but not both.',
  },
);
export type GiftCardCreateRequestDto = z.infer<typeof GiftCardCreateRequestDto>;

export const GiftCardUpdateRequestDto = GiftCardDto.omit({
  clientId: true,
  groupId: true,
});
export type GiftCardUpdateRequestDto = z.infer<typeof GiftCardUpdateRequestDto>;

export const GiftCardRequestDto = z.object({
  giftCardId: z.string().uuid(),
});
export type GiftCardRequestDto = z.infer<typeof GiftCardRequestDto>;

export const giftCardToResponseDto = (
  giftCard: GiftCard & {
    client: Client | null;
    group:
      | (Group & {
          assignedToAgencyUser:
            | (AgencyUser & {
                agency: Agency;
                user: User;
              })
            | null;
        })
      | null;
    ledger: GiftCardLedger[];
    giftCardTransfers: (GiftCardTransfer & {
      previousClient: Client | null;
      previousGroup:
        | (Group & {
            assignedToAgencyUser:
              | (AgencyUser & {
                  agency: Agency;
                  user: User;
                })
              | null;
          })
        | null;
    })[];
  },
): GiftCardResponseDto => {
  const { initialBalance, balance } = giftCard.ledger.reduce(
    (acc, entry) => {
      acc.balance = acc.balance.plus(entry.amount);

      if (entry.type === GiftCardLedgerType.FUND) {
        acc.initialBalance = acc.initialBalance.plus(entry.amount);
      }

      return acc;
    },
    {
      initialBalance: ZERO,
      balance: ZERO,
    },
  );

  const latestTransfer = giftCard.giftCardTransfers
    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
    .find(
      (transfer) =>
        transfer.newClientId === giftCard.clientId ||
        transfer.newGroupId === giftCard.groupId,
    );

  const transferredFrom = latestTransfer
    ? ({
        ...(latestTransfer.previousClient && {
          clientId: latestTransfer.previousClient.id,
          client: clientToDto(latestTransfer.previousClient),
        }),
        ...(latestTransfer.previousGroup && {
          groupId: latestTransfer.previousGroup.id,
          group: groupToDto(latestTransfer.previousGroup),
        }),
      } as GiftCardOwnerResponseDto)
    : undefined;

  return {
    id: giftCard.id,
    publicId: giftCard.publicId,
    notes: giftCard.notes ?? undefined,
    initialBalance: initialBalance.toNumber(),
    balance: balance.toNumber(),
    clientId: giftCard.clientId ?? undefined,
    client: giftCard.client ? clientToDto(giftCard.client) : undefined,
    groupId: giftCard.groupId ?? undefined,
    group: giftCard.group ? groupToDto(giftCard.group) : undefined,
    transferredFrom,
  };
};

export const clientToProfileDto = (
  client: ClientWithProfile,
): ClientProfileResponseDto => {
  const [firstLead] = client.leads.sort((a, b) => {
    return a.createdAt.getTime() - b.createdAt.getTime();
  });

  return {
    id: client.id,
    externalClientId: client.externalClientId ?? undefined,
    firstName: client.firstName,
    lastName: client.lastName,
    createdAt: client.createdAt.toISOString(),
    name: `${client.firstName} ${client.lastName}`,
    email: client.email ?? undefined,
    phone: client.phone ?? undefined,
    company: client.company ?? undefined,
    color: generateColorForId(client.id),
    notes: client.notes ?? undefined,
    tag: client.tag ?? undefined,
    leadSource: firstLead?.source ?? undefined,
    assignedToAgencyUser: client.assignedToAgencyUser
      ? agencyUserToDto(client.assignedToAgencyUser)
      : undefined,
    createdByUser: userToDto(client.createdByUser),
    sourcedBy: client.sourcedBy as SourcedBy,
    isActive: !client.deactivatedAt,
    basicInfo: {
      externalClientId: client.externalClientId ?? undefined,
      firstName: client.firstName,
      middleName: client.middleName ?? undefined,
      lastName: client.lastName,
      email: client.email ?? undefined,
      secondaryEmail: client.secondaryEmail ?? undefined,
      secondaryEmailNickname: client.secondaryEmailNickname ?? undefined,
      phone: client.phone ?? undefined,
      secondaryPhone: client.secondaryPhone ?? undefined,
      secondaryPhoneNickname: client.secondaryPhoneNickname ?? undefined,
      prefix: client.prefix ?? undefined,
      suffix: client.suffix ?? undefined,
      preferredName: client.preferredName ?? undefined,
      pronouns: client.pronouns ?? undefined,
      emergencyContactName: client.emergencyContactName ?? undefined,
      emergencyContactRelationship:
        client.emergencyContactRelationship ?? undefined,
      emergencyContactEmail: client.emergencyContactEmail ?? undefined,
      emergencyContactPhone: client.emergencyContactPhone ?? undefined,
      gender: client.gender ?? undefined,
      referralSource: client.referralSource ?? undefined,
    },
    addresses: addressToDto(client.addresses),
    corporate: corporateToDto(client),
    dates: {
      anniversary: client.dates
        .find((d) => d.type === 'ANNIVERSARY')
        ?.date?.toISOString()
        .slice(0, 10),
      birthday: client.dates
        .find((d) => d.type === 'BIRTHDAY')
        ?.date?.toISOString()
        .slice(0, 10),
      notes: client.dateNotes ?? undefined,
    },
    health: {
      allergies: client.health?.allergies ?? [],
      dietaryRestrictions: client.health?.dietaryRestrictions ?? [],
      mobilityRestrictions: client.health?.mobilityRestrictions ?? [],
      notes: client.health?.notes ?? undefined,
    },
    preferences: {
      airlines: client.preferences?.airlines ?? [],
      airlineClass: client.preferences?.airlineClass ?? undefined,
      airlineSeat: client.preferences?.airlineSeat ?? undefined,
      hotels: client.preferences?.hotels ?? [],
      bedSize: client.preferences?.bedSize ?? undefined,
      notes: client.preferences?.notes ?? undefined,
    },
    programs: {
      memberships: client.programs.map((p) => ({
        id: p.id,
        name: p.name,
        number: p.number,
      })),
      notes: client.programNotes ?? undefined,
    },
    relationships: {
      people: client.relations.map((r) => ({
        id: r.id,
        name: `${r.relatedClient.firstName} ${r.relatedClient.lastName}`,
        relationship: r.relationship,
        relatedClientId: r.relatedClientId,
      })),
      tags: client.affiliations,
      notes: client.relationNotes ?? undefined,
    },
    socials: {
      facebook: client.social?.facebook ?? undefined,
      instagram: client.social?.instagram ?? undefined,
      linkedin: client.social?.linkedin ?? undefined,
      twitter: client.social?.twitter ?? undefined,
      whatsapp: client.social?.whatsapp ?? undefined,
    },
    formSubmits: [
      ...client.formSubmits.map(formSubmitToFormSubmitResponseDto),
      ...client.leads
        .flatMap((l) => l.formSubmits)
        .map(formSubmitToFormSubmitResponseDto),
    ].sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    }),
    creditCards: {
      notes: client.ccNotes ?? undefined,
      cards: client.creditCards.map((c) => clientProfileCreditCardToDto(c)),
    },
    passport: {
      id: client.passports?.[0]?.id ?? undefined,
      givenName: client.passports?.[0]?.givenName ?? undefined,
      surname: client.passports?.[0]?.surname ?? undefined,
      passportNumber: client.passports?.[0]?.passportNumber ?? undefined,
      issuingCountry: client.passports?.[0]?.issuingCountry ?? undefined,
      issueDate: client.passports?.[0]?.issueDate?.toISOString() ?? undefined,
      expirationDate:
        client.passports?.[0]?.expirationDate?.toISOString() ?? undefined,
      passportImage: client.passports?.[0]?.imageFileName ?? undefined,
      notes: client.passports?.[0]?.notes ?? undefined,
    },
    attachments: client.attachments?.length
      ? client.attachments.map(attachmentToDto)
      : undefined,
    giftCards: client.giftCards.map(giftCardToResponseDto),
  };
};

export enum ClientActiveInactive {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ACTIVE_OR_INACTIVE = 'ACTIVE_OR_INACTIVE',
}

export const ClientPaginatedRequestDto = getPaginatedRequestDto(
  z.enum([
    'firstName',
    'lastName',
    'name',
    'email',
    'phone',
    'company',
    'assignedToAgencyUser',
    'createdByUser',
    'updatedAt',
  ]),
  z.enum(['name', 'email', 'phone', 'company', 'advisor', 'createdBy']),
).extend({
  whatFor: z.enum(['select']).optional(),
  active: z.nativeEnum(ClientActiveInactive).optional(),
  groupId: z.string().uuid().optional(),
  assignedToAgencyUserId: z.string().uuid().optional(),
});
export type ClientPaginatedRequestDto = z.infer<
  typeof ClientPaginatedRequestDto
>;
export type ClientSort = z.infer<typeof ClientPaginatedRequestDto>['sort'];

export const ClientAdminPaginatedRequestDto = ClientPaginatedRequestDto.extend({
  organizationId: z.string().uuid().optional(),
});
export type ClientAdminPaginatedRequestDto = z.infer<
  typeof ClientAdminPaginatedRequestDto
>;

export const ClientPaginatedResponseDto = z.object({
  data: z.array(ClientResponseDto),
  meta: z.object({
    page: int(z.number()),
    pageSize: int(z.number()),
    totalRowCount: int(z.number()),
  }),
});
export type ClientPaginatedResponseDto = z.infer<
  typeof ClientPaginatedResponseDto
>;

export const ClientWithGroupsPaginatedResponseDto = z.object({
  data: z.array(ClientWithGroupsResponseDto),
  meta: z.object({
    page: int(z.number()),
    pageSize: int(z.number()),
    totalRowCount: int(z.number()),
  }),
});
export type ClientWithGroupsPaginatedResponseDto = z.infer<
  typeof ClientWithGroupsPaginatedResponseDto
>;

export const ClientRequestDto = z.object({
  clientId: z.string().uuid(),
  includePassportSignedUrl: trimmedString().optional(),
});
export type ClientRequestDto = z.infer<typeof ClientRequestDto>;

export const ClientUpdateRequestDto = z.object({
  firstName: trimmedString(),
  middleName: trimmedString().optional(),
  lastName: trimmedString(),
  email: z.string().email().optional(),
  phone: trimmedString().optional(),
  company: trimmedString().optional(),
  assignedToAgencyUserId: z.string().uuid().optional(),
  sourcedBy: SourcedByEnum.optional(),
  notes: trimmedString().optional(),
});
export type ClientUpdateRequestDto = z.infer<typeof ClientUpdateRequestDto>;

export const DestinationResponseDto = z.object({
  id: trimmedString(),
  name: trimmedString(),
});
export type DestinationResponseDto = z.infer<typeof DestinationResponseDto>;

// Leads

// TODO: Not needed anymore remove, SyntheticLeadStage and rename ConcreteLeadStageType to LeadStageType
export enum ConcreteLeadStageType {
  NEW = 'NEW',
  DISCOVERY = 'DISCOVERY',
  PLANNING = 'PLANNING',
  BOOKED = 'BOOKED',
  DECLINED = 'DECLINED',
  LOST = 'LOST',
}

export enum SyntheticLeadStage {
  TRIP_CREATED = 'TRIP_CREATED',
  PACKING = 'PACKING',
  TRAVELING = 'TRAVELING',
  UNPACKING = 'UNPACKING',
  TRAVELED = 'TRAVELED',
  CANCELED = 'CANCELED',
  LOCKED = 'LOCKED',
}

export const LeadStageType = {
  ...ConcreteLeadStageType,
  ...SyntheticLeadStage,
};
export type LeadStageType = ConcreteLeadStageType | SyntheticLeadStage;

export const terminalStages: LeadStageType[] = [
  LeadStageType.DECLINED,
  LeadStageType.LOST,
];

export enum LeadStageKind {
  CUSTOM = 'CUSTOM',
  SYNTHETIC = 'SYNTHETIC',
}

export const LeadStageResponseDto = z.object({
  id: z.string().uuid(),
  type: z.nativeEnum(LeadStageType),
  name: trimmedString(),
  color: trimmedString(),
  order: z.number(),
  leadStageKind: z.nativeEnum(LeadStageKind),
});

export type LeadStageResponseDto = z.infer<typeof LeadStageResponseDto>;

// TODO: color and leadStageKind not needed anymore
export const syntheticStages: Record<SyntheticLeadStage, LeadStageResponseDto> =
  {
    [SyntheticLeadStage.TRIP_CREATED]: {
      id: '00000000-0000-4000-0000-000000000001',
      type: SyntheticLeadStage.TRIP_CREATED,
      name: 'Trip Created',
      color: '#EBEBEB',
      order: 0,
      leadStageKind: LeadStageKind.SYNTHETIC,
    },
    [SyntheticLeadStage.PACKING]: {
      id: '00000000-0000-4000-0000-000000000002',
      type: SyntheticLeadStage.PACKING,
      name: 'Packing',
      color: '#EBEBEB',
      order: 0,
      leadStageKind: LeadStageKind.SYNTHETIC,
    },
    [SyntheticLeadStage.TRAVELING]: {
      id: '00000000-0000-4000-0000-000000000003',
      type: SyntheticLeadStage.TRAVELING,
      name: 'Traveling',
      color: '#0288D1',
      order: 1,
      leadStageKind: LeadStageKind.SYNTHETIC,
    },
    [SyntheticLeadStage.UNPACKING]: {
      id: '00000000-0000-4000-0000-000000000004',
      type: SyntheticLeadStage.UNPACKING,
      name: 'Unpacking',
      color: '#EBEBEB',
      order: 1,
      leadStageKind: LeadStageKind.SYNTHETIC,
    },
    [SyntheticLeadStage.TRAVELED]: {
      id: '00000000-0000-4000-0000-000000000005',
      type: SyntheticLeadStage.TRAVELED,
      name: 'Traveled',
      color: '#2E7D32',
      order: 2,
      leadStageKind: LeadStageKind.SYNTHETIC,
    },
    [SyntheticLeadStage.CANCELED]: {
      id: '00000000-0000-4000-0000-000000000006',
      type: SyntheticLeadStage.CANCELED,
      name: 'Canceled',
      color: '#B71C1C',
      order: 3,
      leadStageKind: LeadStageKind.SYNTHETIC,
    },
    [SyntheticLeadStage.LOCKED]: {
      id: '00000000-0000-4000-0000-000000000007',
      type: SyntheticLeadStage.LOCKED,
      name: 'Locked',
      color: '#673AB7',
      order: 3,
      leadStageKind: LeadStageKind.SYNTHETIC,
    },
  };

export const LeadStageListResponseDto = z.object({
  stages: z.array(LeadStageResponseDto),
});
export type LeadStageListResponseDto = z.infer<typeof LeadStageListResponseDto>;

export enum LeadAssignmentFilter {
  ALL_LEADS = 'ALL_LEADS',
  ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
  ASSIGNED_TO_OTHERS = 'ASSIGNED_TO_OTHERS',
  UNASSIGNED = 'UNASSIGNED',
}

export const LeadPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['receivedAt', 'name', 'email', 'phone', 'stage']),
  z.enum(['name', 'email', 'phone']),
).extend({
  stageFilter: trimmedString().optional(),
  assigneeFilter: z.nativeEnum(LeadAssignmentFilter).optional(),
});
export type LeadPaginatedRequestDto = z.infer<typeof LeadPaginatedRequestDto>;

export const LeadResponseDto = z.object({
  id: z.string().uuid(),
  email: trimmedString(),
  firstName: trimmedString(),
  middleName: trimmedString().optional(),
  lastName: trimmedString(),
  preferredName: trimmedString().optional(),
  prefix: trimmedString().optional(),
  suffix: trimmedString().optional(),
  phone: trimmedString().optional(),
  address: trimmedString().optional(),
  color: trimmedString(),
  profilePictureUri: trimmedString().optional(),
  leadSource: trimmedString().optional(),
  stage: LeadStageResponseDto,
  assignee: ProfileResponseDto.optional(),
  notes: trimmedString().optional(),
  clientId: trimmedString().optional(),
  formSubmits: FormSubmitResponseDto.optional(),
  clientTag: trimmedString().optional(),
  tripId: trimmedString().optional(),
  sourcedBy: SourcedByEnum,
  receivedAt: z.string().regex(isoDateRegex),
  agencyId: z.string().uuid().optional(),
});
export type LeadResponseDto = z.infer<typeof LeadResponseDto>;

function getSyntheticStageForTrip(
  trip: Trip & {
    stage: LeadStage | null;
  },
  tz?: string,
): LeadStageResponseDto | undefined {
  if (trip.canceledAt) {
    return syntheticStages[LeadStageType.CANCELED];
  }

  if (trip.lockedAt) {
    return syntheticStages[LeadStageType.LOCKED];
  }

  if (
    trip.stage &&
    terminalStages.includes(trip.stage.type as ConcreteLeadStageType)
  ) {
    return undefined;
  }

  const localNow = tz
    ? moment().tz(tz).startOf('day')
    : moment().startOf('day');

  const localEndDate = tz
    ? moment(trip.endDate).tz(tz).startOf('day')
    : moment(trip.endDate).startOf('day');
  const localStartDate = tz
    ? moment(trip.startDate).tz(tz).startOf('day')
    : moment(trip.startDate).startOf('day');

  if (localNow.isAfter(localEndDate.clone().add(1, 'week'))) {
    return syntheticStages[LeadStageType.TRAVELED];
  }

  if (localNow.isAfter(localEndDate)) {
    return syntheticStages[LeadStageType.UNPACKING];
  }

  if (localNow.isAfter(localStartDate) && localNow.isBefore(localEndDate)) {
    return syntheticStages[LeadStageType.TRAVELING];
  }

  if (localNow.isAfter(localStartDate.clone().subtract(1, 'week'))) {
    return syntheticStages[LeadStageType.PACKING];
  }

  if (trip.stage) {
    return leadStageToDto(trip.stage);
  }

  return undefined;
}

function getStageForTrip(
  trip: Trip & {
    lead:
      | (Lead & {
          stage: LeadStage;
          assignedTo:
            | (AgencyUser & {
                agency: Agency;
                user: User;
              })
            | null;
          formSubmits: (FormSubmit & { form: Form })[];
          client: Client | null;
        })
      | null;
    stage: LeadStage | null;
  },
  tz?: string,
): LeadStageResponseDto {
  const stage = trip.stage ?? trip.lead?.stage ?? null;

  const syntheticStage = getSyntheticStageForTrip(trip, tz);

  if (syntheticStage) return syntheticStage;

  return stage ? leadStageToDto(stage) : syntheticStages.TRIP_CREATED;
}

export function leadStageToDto(leadStage: LeadStage): LeadStageResponseDto {
  return {
    id: leadStage.id,
    type: leadStage.type as unknown as LeadStageType,
    name: leadStage.name,
    color: leadStage.color,
    order: leadStage.order,
    leadStageKind: LeadStageKind.CUSTOM,
  };
}

export const leadToDto = (
  lead: Lead & {
    stage: LeadStage;
    assignedTo:
      | (AgencyUser & {
          agency: Agency;
          user: User;
        })
      | null;
    formSubmits: (FormSubmit & { form: Form })[];
    client: Client | null;
  },
  trip:
    | (Trip & {
        stage: LeadStage | null;
      })
    | null,
  tz?: string,
): LeadResponseDto => {
  return {
    id: lead.id,
    agencyId: lead.agencyId ?? undefined,
    firstName: lead.firstName,
    lastName: lead.lastName,
    middleName: lead.middleName ?? undefined,
    preferredName: lead.preferredName ?? undefined,
    prefix: lead.prefix ?? undefined,
    suffix: lead.suffix ?? undefined,
    email: lead.email,
    phone: lead.phone ?? undefined,
    address: lead.address ?? undefined,
    notes: lead.notes ?? undefined,
    color: generateColorForId(lead.id),
    clientId: lead.clientId ?? undefined,
    leadSource: lead.source ?? undefined,
    stage:
      (trip && getSyntheticStageForTrip(trip, tz)) ??
      leadStageToDto(lead.stage),
    assignee: lead.assignedTo
      ? agencyUserToProfileResponseDto(lead.assignedTo)
      : undefined,
    formSubmits: lead.formSubmits?.map(formSubmitToFormSubmitResponseDto)?.[0],
    clientTag: lead.client?.tag ?? undefined,
    tripId: trip?.id ?? undefined,
    sourcedBy: lead.sourcedBy as SourcedBy,
    receivedAt: lead.createdAt.toISOString(),
  };
};

export const LeadPaginatedResponseDto =
  createPaginatedResponseDto<LeadResponseDto>(LeadResponseDto);
export type LeadPaginatedResponseDto = z.infer<typeof LeadPaginatedResponseDto>;

export const LeadCreateRequestDto = z.object({
  agencyId: z.string().uuid().optional(),
  firstName: trimmedString(),
  lastName: trimmedString(),
  email: z.string().email(),
  phone: trimmedString().optional(),
  leadSource: trimmedString().optional(),
  notes: trimmedString().optional(),
  assigneeId: z.string().uuid().optional(),
  sourcedBy: SourcedByEnum.optional(),
  clientId: z.string().uuid().optional(),
});
export type LeadCreateRequestDto = z.infer<typeof LeadCreateRequestDto>;

export const LeadUpdateRequestDto = z.object({
  stageId: z.string().uuid().optional(),
  assigneeId: z.string().uuid().optional(),
  notes: trimmedString().optional(),
});
export type LeadUpdateRequestDto = z.infer<typeof LeadUpdateRequestDto>;

export const LeadRequestDto = z.object({
  leadId: z.string().uuid(),
});
export type LeadRequestDto = z.infer<typeof LeadRequestDto>;

// Trips

export const TripCreateRequestDto = z
  .object({
    agencyId: z.string().uuid(),
    name: trimmedString(),
    startDate: z.string().regex(isoDateRegex).optional(),
    endDate: z.string().regex(isoDateRegex).optional(),
    primaryClientId: z.string().uuid().optional(),
    additionalClientIds: z.array(z.string().uuid()),
    tags: z.array(z.string()),
    destinationIds: z.array(z.string()),
    terms: trimmedString(),
    notes: trimmedString().optional(),
    pnr: trimmedString().optional(),
    commissionAgencyTakePct: z.number().min(0).max(100).optional(),
    feeAgencyTakePct: z.number().min(0).max(100).optional(),
    leadId: z.string().uuid().optional(),
    corporateGroupId: z.string().uuid().optional(),
    mergeToPnrGroupId: z.string().uuid().optional(),
    travelType: TravelTypeEnum.optional(),
    dkNumber: trimmedString().optional(),
    invoiceGroupName: trimmedString().optional(),
    isCorporateProgram: z.boolean().optional(),
    onBehalfOfUserId: z.string().uuid().optional(),
    pcc: trimmedString().optional(),
  })
  .refine(
    (data) => {
      return data.corporateGroupId || data.leadId || data.primaryClientId;
    },
    {
      message:
        'Either corporateGroupId, leadId, or primaryClientId must be provided',
    },
  )
  .refine(
    (data) => {
      return (
        data.travelType === TravelType.LEISURE ||
        !data.travelType ||
        (data.travelType === TravelType.CORPORATE && data.corporateGroupId)
      );
    },
    {
      message: 'Corporate Client must be provided for corporate trips',
    },
  )
  .refine((data) => !data.isCorporateProgram || data.corporateGroupId, {
    message:
      'corporateGroupId must be provided when isCorporateProgram is true',
  });
export type TripCreateRequestDto = z.infer<typeof TripCreateRequestDto>;

export enum TripStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
}

export enum BookingStatus {
  CANCELED = 'CANCELED',
  ACTIVE = 'ACTIVE',
  VOIDED = 'VOIDED',
}

export enum RemarkType {
  HS = 'HS',
  HD = 'HD',
  REG = 'REG',
  QQ = 'QQ',
  CLIADR = 'CLIADR', // cspell:disable-line
  DELADR = 'DELADR', // cspell:disable-line
  INVOICE = 'INVOICE',
  ITINERARY = 'ITINERARY',
  INTERFACE = 'INTERFACE',
  FILLER = 'FILLER',
  CODED = 'CODED',
  INVSEGASSOC = 'INVSEGASSOC', // cspell:disable-line
  ITINSEGASSOC = 'ITINSEGASSOC', // cspell:disable-line
  FOP = 'FOP',
  CORPORATE = 'CORPORATE',
  PRTONTKT = 'PRTONTKT', // cspell:disable-line
  PRINT_ON_TICKET = 'PRINT_ON_TICKET',
  ALPHA_CODED = 'ALPHA_CODED',
  GENERAL = 'GENERAL',
  HISTORICAL = 'HISTORICAL',
  CLIENT_ADDRESS = 'CLIENT_ADDRESS',
  DELIVERY_ADDRESS = 'DELIVERY_ADDRESS',
  HIDDEN = 'HIDDEN',
  FORM_OF_PAYMENT = 'FORM_OF_PAYMENT',
  FILLER_STRIP = 'FILLER_STRIP',
  QUEUE_PLACE = 'QUEUE_PLACE',
}

export const TripRemarkResponseDto = z.object({
  type: z.nativeEnum(RemarkType),
  text: trimmedString(),
  alphaCode: trimmedString().optional(),
  pnr: trimmedString().optional(),
});
export type TripRemarkResponseDto = z.infer<typeof TripRemarkResponseDto>;

export const tripRemarkToDto = (remark: TripRemark): TripRemarkResponseDto => {
  return {
    type: remark.type as RemarkType,
    text: remark.text,
    alphaCode: remark.alphaCode ?? undefined,
    pnr: remark.pnr ?? undefined,
  };
};

export const SabrePnrDto = z.object({
  createdAt: z.string().regex(isoDateRegex),
  pnr: trimmedString(),
  responseCode: z.number(),
  responseStatus: trimmedString(),
  responseText: trimmedString().optional(),
});
export type SabrePnrDto = z.infer<typeof SabrePnrDto>;

export enum SplitType {
  AGENCY = 'AGENCY',
  ADVISOR = 'ADVISOR',
}

export const SplitDto = z.object({
  takePercent: z.number(),
});
export type SplitDto = z.infer<typeof SplitDto>;

export const SplitResponseDto = SplitDto.extend({
  id: z.string().uuid().optional(),
  agencyUserId: z.string().uuid(),
  agencyId: z.string().uuid().optional(),
  name: trimmedString(),
  canEdit: z.boolean(),
  color: trimmedString(),
  type: z.nativeEnum(SplitType),
  isOverride: z.boolean(),
});
export type SplitResponseDto = z.infer<typeof SplitResponseDto>;

export const TripSplitsResponseValueDto = z.object({
  maxPercent: z.number(),
  splits: z.array(SplitResponseDto),
});
export type TripSplitsResponseValueDto = z.infer<
  typeof TripSplitsResponseValueDto
>;

export const DEFAULT = 'DEFAULT' as const;

export const TripSplitsResponseDto = z.record(
  z.enum([DEFAULT, ...Object.values(SupplierType)]),
  TripSplitsResponseValueDto,
);
export type TripSplitsResponseDto = Required<
  z.infer<typeof TripSplitsResponseDto>
>;

export const TripSplitsUpdateRequestDto = z.record(
  z.enum([DEFAULT, ...Object.values(SupplierType)]),
  z.object({
    maxPercent: z.number(),
    splits: z.array(
      SplitResponseDto.extend({
        takePercent: z.number(), //.min(0).max(100),
      }),
    ),
  }),
);
export type TripSplitsUpdateRequestDto = z.infer<
  typeof TripSplitsUpdateRequestDto
>;

export enum CorporateProgramIdentifierType {
  DK_NUMBER = 'DK_NUMBER',
  INVOICE_GROUP_NAME = 'INVOICE_GROUP_NAME',
}

export const TripResponseDto = z.object({
  id: z.string().uuid(),
  num: z.number().optional(),
  agencyId: z.string().uuid(),
  name: trimmedString(),
  startDate: z.string().regex(isoDateRegex).optional(),
  endDate: z.string().regex(isoDateRegex).optional(),
  isDateUserSet: z.boolean(),
  primaryClientId: z.string().uuid().optional(),
  primaryClient: TripTravelerWithGroupsResponseDto.optional(),
  additionalClientIds: z.array(z.string().uuid()),
  additionalClients: z.array(TripTravelerResponseDto),
  tags: z.array(z.string()),
  destinations: z.array(DestinationResponseDto),
  terms: trimmedString(),
  notes: trimmedString(),
  remarks: z.array(TripRemarkResponseDto).optional(),
  commissionAgencyTakePct: z.number().min(0).max(100).optional(),
  feeAgencyTakePct: z.number().min(0).max(100).optional(),
  advisorUserId: z.string().uuid(),
  tripStatus: z.nativeEnum(TripStatus),
  lockedAt: z.string().regex(isoDateRegex).optional(),
  agencyUserIdLockedAt: z.string().regex(isoDateRegex).optional(),
  leadId: z.string().uuid().optional(),
  lead: LeadResponseDto.optional(),
  agencyUser: AgencyUserResponseDto,
  stage: LeadStageResponseDto,
  corporateGroup: GroupResponseDto.optional(),
  travelType: z.nativeEnum(TravelType).optional(),
  identifierType: z.nativeEnum(CorporateProgramIdentifierType).optional(),
  dkNumber: trimmedString().optional(),
  invoiceGroupName: trimmedString().optional(),
  isCorporateProgram: z.boolean(),
  splits: TripSplitsResponseDto,
  allowAxusSync: z.boolean(),
  allowTravefySync: z.boolean(),
  axusLastSyncedAt: z.string().regex(isoDateRegex).optional(),
  travefyLastSyncedAt: z.string().regex(isoDateRegex).optional(),
  canReassign: z.boolean(),
  canCancel: z.boolean(),
  pnrs: z.array(z.string()).optional(),
  attachments: z.array(AttachmentResponseDto).optional(),
  canUseMergeToPnr: z.boolean().optional(),
  pcc: trimmedString().optional(),
});
export type TripResponseDto = z.infer<typeof TripResponseDto>;

export type TripExtended = Trip & {
  primaryClient:
    | (Client & {
        dates?: ClientDate[];
        passports?: ClientPassportInfo[];
        creditCards?: ClientProfileCreditCard[];
        groups: (ClientGroup & {
          group: Group & {
            assignedToAgencyUser:
              | (AgencyUser & {
                  agency: Agency;
                  user: User;
                })
              | null;
          };
        })[];
      })
    | null;
  tripClients: (TripClient & {
    client: Client & {
      dates?: ClientDate[];
      passports?: ClientPassportInfo[];
      creditCards?: ClientProfileCreditCard[];
    };
  })[];
  agencyUser: AgencyUser & {
    agency: Agency;
    user: User;
  };
  tripTags: (TripTag & { tag: Tag })[];
  tripDestinations: (TripDestination & { geoName: GeoName })[];
  lead:
    | (Lead & {
        stage: LeadStage;
        assignedTo:
          | (AgencyUser & {
              agency: Agency;
              user: User;
            })
          | null;
        formSubmits: (FormSubmit & { form: Form })[];
        client: Client | null;
      })
    | null;
  stage: LeadStage | null;
  remarks?: TripRemark[];
  corporateGroup:
    | (Group & {
        assignedToAgencyUser:
          | (AgencyUser & {
              agency: Agency;
              user: User;
            })
          | null;
      })
    | null;
  attachments?: Attachment[];
  mappings?: TripMapping[];
  tripPnrs?: TripPnr[];
};
// TODO: Refactor and extract into mappers folder
export const tripToDto = (
  trip: TripExtended,
  tz?: string,
  splits?: TripSplitsResponseDto,
  allowAxusSync?: boolean,
  allowTravefySync?: boolean,
  canReassign?: boolean,
  canCancel?: boolean,
  canUseMergeToPnr?: boolean,
): TripResponseDto => {
  return {
    id: trip.id,
    num: trip.num ?? undefined,
    agencyId: trip.agencyUser.agencyId,
    name: trip.name,
    ...(trip.startDate && { startDate: trip.startDate.toISOString() }),
    ...(trip.endDate && { endDate: trip.endDate.toISOString() }),
    isDateUserSet: trip.isDateUserSet,
    primaryClientId: trip.primaryClientId ?? undefined,
    primaryClient: trip.primaryClient
      ? tripTravelerWithGroupsToDto(trip.primaryClient, { isPrimary: true })
      : undefined,
    additionalClientIds: trip.tripClients.map((tc) => tc.clientId),
    additionalClients: trip.tripClients
      .map((tc) => tc.client)
      .map((client) => clientToTravelerDto(client)),
    tags: trip.tripTags.map((current) => current.tag.name),
    destinations: trip.tripDestinations.map((td) => ({
      id: td.geoName.id.toString(),
      name: td.geoName.name,
      nameAscii: td.geoName.nameAscii,
    })),
    terms: trip.terms,
    notes: trip.notes,
    remarks: trip.remarks ? trip.remarks.map(tripRemarkToDto) : undefined,
    commissionAgencyTakePct: trip.commissionAgencyTakePct ?? undefined,
    feeAgencyTakePct: trip.feeAgencyTakePct ?? undefined,
    advisorUserId: trip.agencyUser.userId,
    tripStatus: trip.canceledAt ? TripStatus.CANCELED : TripStatus.ACTIVE,
    lockedAt: trip.lockedAt?.toISOString(),
    agencyUserIdLockedAt: trip.agencyUserIdLockedAt?.toISOString(),
    leadId: trip.leadId ?? undefined,
    lead: trip.lead ? leadToDto(trip.lead, trip, tz) : undefined,
    agencyUser: agencyUserToDto(trip.agencyUser),
    stage: getStageForTrip(trip, tz),
    travelType: (trip?.travelType as TravelType) ?? undefined,
    corporateGroup: trip?.corporateGroup
      ? groupToDto(trip.corporateGroup)
      : undefined,
    dkNumber: trip.dkNumber ?? undefined,
    invoiceGroupName: trip.invoiceGroupName ?? undefined,
    isCorporateProgram: trip.isCorporateProgram,
    splits: splits ?? {},
    allowAxusSync: allowAxusSync ?? false,
    allowTravefySync: allowTravefySync ?? false,
    axusLastSyncedAt: trip.axusLastSyncedAt?.toISOString() ?? undefined,
    travefyLastSyncedAt: trip.travefyLastSyncedAt?.toISOString() ?? undefined,
    canReassign: canReassign ?? false,
    canCancel: canCancel ?? false,
    pnrs: trip.tripPnrs?.map((x) => x.pnr) ?? [],
    attachments: trip.attachments?.map(attachmentToDto) ?? undefined,
    ...(canUseMergeToPnr ? { canUseMergeToPnr: true } : {}),
    pcc: trip.mappings?.find(
      (m) => !m.deletedAt && m.type === TripMappingType.PCC,
    )?.externalId,
  };
};

export const AdminTripResponseDto = z.object({
  id: z.string().uuid(),
  name: trimmedString(),
  createdAt: z.string().regex(isoDateRegex),
  lockedAt: z.string().regex(isoDateRegex).optional(),
});
export type AdminTripResponseDto = z.infer<typeof AdminTripResponseDto>;

export const tripToAdminDto = (trip: Trip): AdminTripResponseDto => ({
  id: trip.id,
  name: trip.name,
  createdAt: trip.createdAt.toISOString(),
  lockedAt: trip.lockedAt?.toISOString(),
});

export const TripLightResponseDto = z.object({
  id: z.string().uuid(),
  name: trimmedString(),
  pnrs: z.array(z.string()).optional(),
  pcc: trimmedString().optional(),
});
export type TripLightResponseDto = z.infer<typeof TripLightResponseDto>;

export const tripToLightDto = (
  trip: Trip & {
    mappings?: TripMapping[];
    tripPnrs: TripPnr[];
  },
): TripLightResponseDto => ({
  id: trip.id,
  name: trip.name,
  pnrs: trip.tripPnrs.map((x) => x.pnr) || undefined,
  pcc: trip.mappings?.find(
    (m) => !m.deletedAt && m.type === TripMappingType.PCC,
  )?.externalId,
});

export const TripLightListResponseDto = z.object({
  id: z.string().uuid(),
  name: z.string(),
});
export type TripLightListResponseDto = z.infer<typeof TripLightListResponseDto>;

export const tripToLightListDto = (
  trip: Trip & {
    mappings?: TripMapping[];
  },
): TripLightListResponseDto => ({
  id: trip.id,
  name: trip.name,
});

export const tripResponseDtoToTripUpdateRequestDto = (
  trip: TripResponseDto | undefined,
): TripUpdateRequestDto | undefined => {
  if (!trip) return undefined;
  return {
    startDate: trip.startDate,
    endDate: trip.endDate,
    name: trip.name,
    additionalClientIds: trip.additionalClientIds,
    agencyId: trip.agencyId,
    primaryClientId: trip.primaryClientId ?? undefined,
    tags: trip.tags,
    destinationIds: trip.destinations.map((d) => d.id),
    terms: trip.terms,
    isDateUserSet: trip.isDateUserSet,
    notes: trip.notes,
    commissionAgencyTakePct: trip.commissionAgencyTakePct,
    feeAgencyTakePct: trip.feeAgencyTakePct,
    agencyUserId: trip.agencyUser.id,
  };
};

export const TripListResponseDto = z.object({
  id: z.string().uuid(),
  num: z.number().optional(),
  agencyId: z.string().uuid(),
  name: trimmedString(),
  startDate: z.string().regex(isoDateRegex).optional(),
  endDate: z.string().regex(isoDateRegex).optional(),
  isDateUserSet: z.boolean(),
  primaryClientId: z.string().uuid().optional(),
  primaryClient: TripTravelerWithGroupsResponseDto.optional(),
  additionalClientIds: z.array(z.string().uuid()),
  additionalClients: z.array(TripTravelerResponseDto),
  advisorUserId: z.string().uuid(),
  tripStatus: z.nativeEnum(TripStatus),
  stage: LeadStageResponseDto,
  agencyUser: AgencyUserResponseDto,
  destinations: z.array(DestinationResponseDto),
  lead: LeadResponseDto.optional(),
  dkNumber: z.string().optional(),
  invoiceGroupName: z.string().optional(),
});
export type TripListResponseDto = z.infer<typeof TripListResponseDto>;

export const tripToListDto = (
  trip: TripList,
  tz?: string,
): TripListResponseDto => {
  return {
    id: trip.id,
    num: trip.num ?? undefined,
    agencyId: trip.agencyUser.agencyId,
    name: trip.name,
    ...(trip.startDate && { startDate: trip.startDate.toISOString() }),
    ...(trip.endDate && { endDate: trip.endDate.toISOString() }),
    isDateUserSet: trip.isDateUserSet,
    primaryClientId: trip.primaryClientId ?? undefined,
    primaryClient: trip.primaryClient
      ? tripTravelerWithGroupsToDto(trip.primaryClient, { isPrimary: true })
      : undefined,
    additionalClientIds: trip.tripClients.map((tc) => tc.clientId),
    additionalClients: trip.tripClients
      .map((tc) => tc.client)
      .map((client) => clientToTravelerDto(client)),
    advisorUserId: trip.agencyUser.userId,
    tripStatus: trip.canceledAt ? TripStatus.CANCELED : TripStatus.ACTIVE,
    agencyUser: agencyUserToDto(trip.agencyUser),
    stage: getStageForTrip(trip, tz),
    destinations: trip.tripDestinations.map((td) => ({
      id: td.geoName.id.toString(),
      name: td.geoName.name,
      nameAscii: td.geoName.nameAscii,
    })),
    lead: trip.lead ? leadToDto(trip.lead, trip, tz) : undefined,
    dkNumber: trip.dkNumber ?? undefined,
    invoiceGroupName: trip.invoiceGroupName ?? undefined,
  };
};

export const TripPaginatedRequestDto = getPaginatedRequestDto(
  z.enum([
    'id',
    'name',
    'clientName',
    'agencyUser',
    'startDate',
    'endDate',
    'dkNumber',
    'updatedAt',
  ]),
  z.enum(['name', 'clientName', 'agencyUser', 'dkNumber', 'invoiceGroupName']),
).extend({
  clientId: z.string().uuid().optional(),
  minDate: z.string().regex(dateRegex).optional(),
  maxDate: z.string().regex(dateRegex).optional(),
  status: z
    .union([z.nativeEnum(LeadStageType).array(), z.nativeEnum(LeadStageType)])
    .optional(),
  leadStage: z.union([z.string().array(), z.string()]).optional(),
  corporateClientId: z.string().uuid().optional(),
  isCorporateProgram: z
    .enum(['true', 'false'])
    .transform((v) => v === 'true')
    .optional(),
  daysToSearch: z.coerce.number().optional(),
  agencyUserId: z.string().uuid().optional(),
  queryOnly: z.enum(['pnr']).optional(),
});
export type TripPaginatedRequestDto = z.infer<typeof TripPaginatedRequestDto>;
export type TripSort = z.infer<typeof TripPaginatedRequestDto>['sort'];

export const TripPaginatedResponseDto = z.object({
  data: z.array(TripListResponseDto),
  meta: z.object({
    page: int(z.number()),
    pageSize: int(z.number()),
    totalRowCount: int(z.number()),
  }),
});
export type TripPaginatedResponseDto = z.infer<typeof TripPaginatedResponseDto>;

export const TripRequestDto = z.object({
  tripId: z.string().uuid(),
});

export type TripRequestDto = z.infer<typeof TripRequestDto>;

export const TripUpdateRequestDto = z.object({
  agencyId: z.string().uuid(),
  name: trimmedString(),
  startDate: z.string().regex(isoDateRegex).optional(),
  endDate: z.string().regex(isoDateRegex).optional(),
  isDateUserSet: z.boolean().optional(), // TODO: remove this since the backend is determining this based on startDate and endDate
  primaryClientId: z.string().uuid().optional(), // TODO: Make this nullable() instead of optional() when corporate programs are a thing *
  additionalClientIds: z.array(z.string().uuid()),
  tags: z.array(z.string()),
  destinationIds: z.array(z.string()),
  terms: trimmedString(),
  notes: trimmedString(),
  pnr: trimmedString().optional(), // there could be multiple PNRs on a trip but we are only updating one
  commissionAgencyTakePct: z.number().min(0).max(100).optional(),
  feeAgencyTakePct: z.number().min(0).max(100).optional(),
  agencyUserId: z.string().uuid().optional(),
  corporateGroupId: z.string().uuid().optional(), // TODO: * also this
  travelType: z.nativeEnum(TravelType).optional(),
  dkNumber: trimmedString().optional(),
  invoiceGroupName: trimmedString().optional(),
  splits: TripSplitsUpdateRequestDto.optional(),
  pcc: trimmedString().optional(),
  agencyUserIdLockedAt: z.string().regex(isoDateRegex).optional(),
});
export type TripUpdateRequestDto = z.infer<typeof TripUpdateRequestDto>;

export enum TripRefundItemType {
  BOOKING = 'BOOKING',
  CLIENT_INVOICE = 'CLIENT_INVOICE',
}

export const AdminTripLockedAtUpdateRequestDto = z.object({
  lockedAt: z.string().regex(isoDateRegex),
});
export type AdminTripLockedAtUpdateRequestDto = z.infer<
  typeof AdminTripLockedAtUpdateRequestDto
>;

export enum TripMappingType {
  VIRTUOSO_INVOICE = 'VIRTUOSO_INVOICE',
  VIRTUOSO_BOOKING = 'VIRTUOSO_BOOKING',
  RESCARDNO = 'RESCARDNO',
  PCC = 'PCC',
  SPOTNANA = 'SPOTNANA',
  TRAVEFY_TRIP_ID = 'TRAVEFY_TRIP_ID',
  TRAVEFY_TRIP_SHORT_URL_PATH = 'TRAVEFY_TRIP_SHORT_URL_PATH',
}

const BookingRefundDto = z.object({
  itemId: z.string().uuid(),
  type: z.literal(TripRefundItemType.BOOKING),
  amount: z.string().transform((n) => new Decimal(n)),
  estCommission: z
    .string()
    .transform((n) => new Decimal(n))
    .optional(),
  commissionableValue: z
    .string()
    .transform((n) => new Decimal(n))
    .optional(),
  refundPaymentMethod: z.nativeEnum(PaymentMethod).optional(),
  refundBillPaymentMethodId: z.string().uuid().optional(),
  refundReceivedDate: z.string().regex(dateRegex).optional(),
  currency: trimmedString().optional(),
  exchangeRate: z.number().optional(),
  exchangeRateLockedAt: z.string().regex(isoDateRegex).nullable().optional(),
});
export type BookingRefundDto = z.infer<typeof BookingRefundDto>;

const ClientInvoiceRefundDto = z.object({
  itemId: z.string().uuid(),
  type: z.literal(TripRefundItemType.CLIENT_INVOICE),
  amount: z.string().transform((n) => new Decimal(n)),
  refundedAt: z.string().regex(isoDateRegex).optional(),
  paymentMethod: z.nativeEnum(PaymentMethod).optional(),
  processingCost: z
    .string()
    .transform((n) => new Decimal(n))
    .optional(),
});
export type ClientInvoiceRefundDto = z.infer<typeof ClientInvoiceRefundDto>;

export const TripItemRefundDto = z.union([
  BookingRefundDto,
  ClientInvoiceRefundDto,
]);
export type TripItemRefundDto = z.infer<typeof TripItemRefundDto>;

export const TripCancelRequestDto = z.object({
  tripItemRefunds: z.array(TripItemRefundDto),
});
export type TripCancelRequestDto = z.infer<typeof TripCancelRequestDto>;

export const AdminSplitConfigsRequestDto = z.object({
  agencyUserId: trimmedString().optional(),
  agencyId: trimmedString().optional(),
});
export type AdminSplitConfigsRequestDto = z.infer<
  typeof AdminSplitConfigsRequestDto
>;

export const adminSplitConfigToDto = (
  splitConfig: SplitConfig,
): AdminSplitConfigResponseDto => {
  return {
    id: splitConfig.id,
    payoutType: splitConfig.payoutType as PayoutType,
    sourcedBy: splitConfig.sourcedBy as SourcedBy,
    parentTakePercent: splitConfig.parentTakePercent.toNumber(),
    supplierType: (splitConfig.supplierType as SupplierType) ?? undefined,
    agencyId: splitConfig.agencyId ?? undefined,
    agencyUserId: splitConfig.agencyUserId ?? undefined,
  };
};

export const AdminSplitConfigCreateRequestDto = z.object({
  payoutType: PayoutTypeEnum,
  parentTakePercent: z.number().min(0).max(100),
  sourcedBy: SourcedByEnum.optional(),
  supplierType: z.nativeEnum(SupplierType).optional(),
  agencyId: z.string().uuid().optional(),
  agencyUserId: z.string().uuid().optional(),
});
export type AdminSplitConfigCreateRequestDto = z.infer<
  typeof AdminSplitConfigCreateRequestDto
>;

export const AdminSplitConfigRequestDto = z.object({
  splitConfigId: z.string().uuid(),
});
export type AdminSplitConfigRequestDto = z.infer<
  typeof AdminSplitConfigRequestDto
>;

export const AdminSplitConfigUpdateRequestDto = z.object({
  parentTakePercent: z.number().min(0).max(100),
  payoutType: PayoutTypeEnum.optional(),
  sourcedBy: SourcedByEnum.optional(),
  supplierType: z.nativeEnum(SupplierType).optional(),
});
export type AdminSplitConfigUpdateRequestDto = z.infer<
  typeof AdminSplitConfigUpdateRequestDto
>;

export const FeeTypeRequestDto = z.object({
  feeTypeId: z.string().uuid(),
});
export type FeeTypeRequestDto = z.infer<typeof FeeTypeRequestDto>;

export const AdminFeeTypeResponseDto = z.object({
  id: z.string().uuid(),
  name: trimmedString(),
  order: z.number(),
  mergeId: trimmedString().optional(),
  remoteId: trimmedString().optional(),
  isActive: z.boolean().optional(),
});
export type AdminFeeTypeResponseDto = z.infer<typeof AdminFeeTypeResponseDto>;

export const FeeTypeResponseDto = z.object({
  id: z.string().uuid(),
  name: trimmedString(),
});
export type FeeTypeResponseDto = z.infer<typeof FeeTypeResponseDto>;

export const FeeTypeListResponseDto = z.object({
  data: z.array(FeeTypeResponseDto),
});
export type FeeTypeListResponseDto = z.infer<typeof FeeTypeListResponseDto>;

export const feeTypeToAdminDto = (
  feeType: FeeType,
): AdminFeeTypeResponseDto => {
  return {
    id: feeType.id,
    name: feeType.name,
    order: feeType.order,
    mergeId: feeType.mergeId ?? undefined,
    remoteId: feeType.remoteId ?? undefined,
    isActive: feeType.isActive,
  };
};

export const feeTypeToDto = (feeType: FeeType): FeeTypeResponseDto => {
  return {
    id: feeType.id,
    name: feeType.name,
  };
};

export const FeeTypeUpsertRequestDto = z.object({
  name: trimmedString(),
  order: z.number(),
  mergeId: trimmedString().optional(),
  remoteId: trimmedString().optional(),
  isActive: z.boolean().optional(),
});
export type FeeTypeUpsertRequestDto = z.infer<typeof FeeTypeUpsertRequestDto>;

export const AdminFeeTypeListResponseDto = z.object({
  data: z.array(AdminFeeTypeResponseDto),
});
export type AdminFeeTypeListResponseDto = z.infer<
  typeof AdminFeeTypeListResponseDto
>;

export const AdminOrganizationRoleResponseDto = z.object({
  id: z.number(),
  role: trimmedString(),
});
export type AdminOrganizationRoleResponseDto = z.infer<
  typeof AdminOrganizationRoleResponseDto
>;

export const AdminOrganizationRolesListResponseDto = z.object({
  data: z.array(AdminOrganizationRoleResponseDto),
});
export type AdminOrganizationRolesListResponseDto = z.infer<
  typeof AdminOrganizationRolesListResponseDto
>;

export const organizationRoleToAdminDto = (
  role: OrganizationRole,
): AdminOrganizationRoleResponseDto => {
  return {
    id: role.id,
    role: role.role,
  };
};

export const OrganizationRoleCreateRequestDto = z.object({
  role: trimmedString(),
});
export type OrganizationRoleCreateRequestDto = z.infer<
  typeof OrganizationRoleCreateRequestDto
>;

export const OrganizationRoleRequestDto = z.object({
  roleId: trimmedString(),
});
export type OrganizationRoleRequestDto = z.infer<
  typeof OrganizationRoleRequestDto
>;

export const OrganizationCurrencyMappingRequestDto = z.object({
  currencyMappingId: trimmedString(),
});
export type OrganizationCurrencyMappingRequestDto = z.infer<
  typeof OrganizationCurrencyMappingRequestDto
>;

export const AdminOrganizationCurrencyMappingResponseDto = z.object({
  id: z.number(),
  currencyCode: trimmedString(),
});
export type AdminOrganizationCurrencyMappingResponseDto = z.infer<
  typeof AdminOrganizationCurrencyMappingResponseDto
>;

export const OrganizationCurrencyMappingResponseDto = z.object({
  id: z.number(),
  currencyCode: trimmedString(),
});
export type OrganizationCurrencyMappingResponseDto = z.infer<
  typeof OrganizationCurrencyMappingResponseDto
>;

export const OrganizationCurrencyMappingListResponseDto = z.object({
  data: z.array(OrganizationCurrencyMappingResponseDto),
});
export type OrganizationCurrencyMappingListResponseDto = z.infer<
  typeof OrganizationCurrencyMappingListResponseDto
>;

export const OrganizationCurrencyMappingToAdminDto = (
  organizationCurrencyMapping: OrganizationCurrencyMapping,
): AdminOrganizationCurrencyMappingResponseDto => {
  return {
    id: organizationCurrencyMapping.id,
    currencyCode: organizationCurrencyMapping.currencyCode,
  };
};

export const organizationCurrencyMappingToDto = (
  organizationCurrencyMapping: OrganizationCurrencyMapping,
): OrganizationCurrencyMappingResponseDto => {
  return {
    id: organizationCurrencyMapping.id,
    currencyCode: organizationCurrencyMapping.currencyCode,
  };
};

export const OrganizationCurrencyMappingCreateRequestDto = z.object({
  currencyCode: trimmedString(),
});
export type OrganizationCurrencyMappingCreateRequestDto = z.infer<
  typeof OrganizationCurrencyMappingCreateRequestDto
>;

export const AdminOrganizationCurrencyMappingListResponseDto = z.object({
  data: z.array(AdminOrganizationCurrencyMappingResponseDto),
});
export type AdminOrganizationCurrencyMappingListResponseDto = z.infer<
  typeof AdminOrganizationCurrencyMappingListResponseDto
>;

export const AccountingPaymentMethodResponseDto = z.object({
  id: z.string().uuid(),
  type: z.nativeEnum(PaymentMethod),
  name: trimmedString(),
});
export type AccountingPaymentMethodResponseDto = z.infer<
  typeof AccountingPaymentMethodResponseDto
>;

export const AdminAccountingPaymentMethodResponseDto =
  AccountingPaymentMethodResponseDto.extend({
    order: z.number(),
    mergeId: trimmedString().optional(),
    remoteId: trimmedString().optional(),
  });
export type AdminAccountingPaymentMethodResponseDto = z.infer<
  typeof AdminAccountingPaymentMethodResponseDto
>;

export const accountingPaymentMethodToAdminDto = (
  accountingPaymentMethod: AccountingPaymentMethod,
): AdminAccountingPaymentMethodResponseDto => {
  return {
    id: accountingPaymentMethod.id,
    type: accountingPaymentMethod.type as PaymentMethod,
    name: accountingPaymentMethod.name,
    order: accountingPaymentMethod.order,
    mergeId: accountingPaymentMethod.mergeId ?? undefined,
    remoteId: accountingPaymentMethod.remoteId ?? undefined,
  };
};

export const AccountingPaymentMethodUpsertRequestDto = z.object({
  type: z.nativeEnum(PaymentMethod),
  name: trimmedString(),
  order: z.number(),
  mergeId: trimmedString().optional(),
  remoteId: trimmedString().optional(),
  accountRemoteId: trimmedString().optional(),
});
export type AccountingPaymentMethodUpsertRequestDto = z.infer<
  typeof AccountingPaymentMethodUpsertRequestDto
>;

export const ClientPaymentMethodResponseDto =
  AccountingPaymentMethodResponseDto.extend({
    processingCostPct: z.number(),
    supportsDirectPayment: z.boolean(),
  });
export type ClientPaymentMethodResponseDto = z.infer<
  typeof ClientPaymentMethodResponseDto
>;

export const PaymentTransactionResponseDto = z.object({
  paymentTransactionId: z.string().uuid(),
  tripPaymentPublicId: trimmedString().optional(),
  transferId: z.string().uuid(),
  amount: MoneySchema.optional(),
  status: PaymentStatusEnum,
  // TODO: need to define a more generic enum that can handle CC or ACH statuses since this is for card only.
  // also a statusReason which would include failure information (again intended to be somewhat generic to not leak info)
  sourceStatus: CardPaymentStatusEnum,
  createdOn: trimmedString(),
  paymentReversals: z.array(PaymentReversalResponseDto).optional(),
  paymentProcessedByUser: UserResponseDto.optional(),
});
export type PaymentTransactionResponseDto = z.infer<
  typeof PaymentTransactionResponseDto
>;

export const DIRECT_PAYMENT_METHOD_NAMES = ['TripSuite CC Processing'];

export const clientPaymentMethodToDto = (
  clientPaymentMethod: ClientPaymentMethod,
): ClientPaymentMethodResponseDto => {
  const supportsDirectPayment = DIRECT_PAYMENT_METHOD_NAMES.map((s) =>
    s.trim().toLowerCase(),
  ).includes(clientPaymentMethod.name.trim().toLowerCase());
  return {
    id: clientPaymentMethod.id,
    type: clientPaymentMethod.type as PaymentMethod,
    name: clientPaymentMethod.name,
    processingCostPct: clientPaymentMethod.processingCostPct.toNumber(),
    supportsDirectPayment,
  };
};

export const giftCardToClientPaymentMethodResponseDto = (
  giftCard: GiftCard & { ledger: GiftCardLedger[] },
  currencySymbol: string,
): ClientPaymentMethodResponseDto => {
  const giftCardBalance = giftCard.ledger.reduce(
    (acc, ledger) => acc.plus(ledger.amount),
    ZERO,
  );

  return {
    id: giftCard.id,
    type: PaymentMethod.GIFT_CARD,
    // TODO someday: Use GiftCard's currency to find correct symbol
    name: `Gift Card ${
      giftCard.publicId
    } - ${currencySymbol}${giftCardBalance.toDecimalPlaces(2)}`,
    processingCostPct: 0,
    supportsDirectPayment: false,
  };
};

export const paymentTransactionToDto = (
  paymentTransaction: PaymentTransaction & {
    paymentReversals: PaymentReversal[];
    createdBy: User | null;
  },
): PaymentTransactionResponseDto => {
  return {
    paymentTransactionId: paymentTransaction.id,
    transferId: paymentTransaction.partnerTransactionID,
    amount: {
      value: paymentTransaction.amount?.toNumber() || 0,
      currency: USD,
    },
    status: paymentTransaction.overallStatus as PaymentStatusEnum,
    sourceStatus: paymentTransaction.sourceStatus as CardPaymentStatusEnum,
    createdOn: paymentTransaction.createdAt.toISOString(),
    paymentReversals: paymentTransaction.paymentReversals.map((reversal) =>
      paymentReversalToDto(reversal),
    ),
    paymentProcessedByUser: paymentTransaction?.createdBy
      ? userToDto(paymentTransaction?.createdBy)
      : undefined,
  };
};

export const creditCardMetadataToDto = (
  creditCardMetadata?: CreditCardMetadata,
): CreditCardMetadataSchema | undefined => {
  if (!creditCardMetadata) {
    return undefined;
  }

  return {
    id: creditCardMetadata.id,
    bin: creditCardMetadata.bin || undefined,
    brand: creditCardMetadata.brand || undefined,
    billingAddressAddressLine1:
      creditCardMetadata.billingAddressAddressLine1 || undefined,
    billingAddressAddressLine2:
      creditCardMetadata.billingAddressAddressLine2 || undefined,
    billingAddressCity: creditCardMetadata.billingAddressCity || undefined,
    billingAddressState:
      creditCardMetadata.billingAddressStateOrProvince || undefined,
    billingAddressPostalCode:
      creditCardMetadata.billingAddressPostalCode || undefined,
    billingAddressCountry:
      creditCardMetadata.billingAddressCountry || undefined,
    holderName: creditCardMetadata.holderName || undefined,
    lastFourCardNumber: creditCardMetadata.lastFourCardNumber || undefined,
    expirationMonth: creditCardMetadata.expirationMonth || undefined,
    expirationYear: creditCardMetadata.expirationYear || undefined,
    cardNickname: creditCardMetadata.cardNickname || undefined,
  } as CreditCardMetadataSchema;
};

export const paymentReversalToDto = (
  paymentReversal: PaymentReversal,
): PaymentReversalResponseDto => {
  return {
    id: paymentReversal.id,
    amount: {
      value: paymentReversal.amount?.toNumber() || 0,
      currency: USD,
    },
    createdAt: paymentReversal.createdAt.toISOString(),
    updatedAt: paymentReversal.updatedAt.toISOString(),
    paymentTransactionId: paymentReversal.paymentTransactionId,
    clientInvoiceRefundId: paymentReversal.clientInvoiceRefundId,
    partnerTransactionID: paymentReversal.partnerTransactionID,
    partnerRefundID: paymentReversal.partnerRefundID || undefined,
    partnerCreatedOn: paymentReversal.partnerCreatedOn || undefined,
    partnerUpdatedOn: paymentReversal.partnerUpdatedOn || undefined,
    type: paymentReversal.type as ReversalType,
    cancelledStatus: paymentReversal.cancelledStatus || undefined,
    refundStatus: paymentReversal.refundStatus || undefined,
    refundCardDetailsCompletedAt:
      paymentReversal.refundCardDetailsCompletedAt || undefined,
    refundCardDetailsConfirmedAt:
      paymentReversal.refundCardDetailsConfirmedAt || undefined,
    refundCarDetailsFailedAt:
      paymentReversal.refundCarDetailsFailedAt || undefined,
    refundCardDetailsInitiatedAt:
      paymentReversal.refundCardDetailsInitiatedAt || undefined,
    refundCardDetailsSettledAt:
      paymentReversal.refundCardDetailsSettledAt || undefined,
    refundCardDetailsStatus:
      paymentReversal.refundCardDetailsStatus || undefined,
    refundCardDetailsFailureCode:
      paymentReversal.refundCardDetailsFailureCode || undefined,
  };
};

export const AdminClientPaymentMethodResponseDto =
  ClientPaymentMethodResponseDto.extend({
    order: z.number(),
    mergeId: trimmedString().optional(),
    remoteId: trimmedString().optional(),
    accountRemoteId: trimmedString().optional(),
    shouldPushToQbo: z.boolean(),
  });
export type AdminClientPaymentMethodResponseDto = z.infer<
  typeof AdminClientPaymentMethodResponseDto
>;

export const clientPaymentMethodToAdminDto = (
  clientPaymentMethod: ClientPaymentMethod,
): AdminClientPaymentMethodResponseDto => {
  return {
    ...clientPaymentMethodToDto(clientPaymentMethod),
    order: clientPaymentMethod.order,
    mergeId: clientPaymentMethod.mergeId ?? undefined,
    remoteId: clientPaymentMethod.remoteId ?? undefined,
    accountRemoteId: clientPaymentMethod.accountRemoteId ?? undefined,
    shouldPushToQbo: clientPaymentMethod.shouldPushToQbo,
  };
};

export const ClientPaymentMethodUpsertRequestDto =
  AccountingPaymentMethodUpsertRequestDto.extend({
    processingCostPct: z.number(),
    shouldPushToQbo: z.boolean(),
  });
export type ClientPaymentMethodUpsertRequestDto = z.infer<
  typeof ClientPaymentMethodUpsertRequestDto
>;

export const ClientPaymentMethodListResponseDto = z.object({
  data: z.array(ClientPaymentMethodResponseDto),
});
export type ClientPaymentMethodListResponseDto = z.infer<
  typeof ClientPaymentMethodListResponseDto
>;

export const AdminClientPaymentMethodListResponseDto = z.object({
  data: z.array(AdminClientPaymentMethodResponseDto),
});
export type AdminClientPaymentMethodListResponseDto = z.infer<
  typeof AdminClientPaymentMethodListResponseDto
>;

export const ClientPaymentMethodRequestDto = z.object({
  clientPaymentMethodId: z.string().uuid(),
});
export type ClientPaymentMethodRequestDto = z.infer<
  typeof ClientPaymentMethodRequestDto
>;

export enum GdsInterfaceType {
  SABRE = 'SABRE',
}

export enum InvoiceFor {
  FEES = 'FEES',
  TRIP = 'TRIP',
}

export enum ClientInvoiceType {
  DESIGN_FEES = 'Design Fees',
  TRIP_COSTS = 'Trip Cost',
}

export enum ClientInvoiceMappingType {
  DOCUMENT_NUMBER = 'DOCUMENT_NUMBER',
  VIRTUOSO = 'VIRTUOSO',
  RESERVATIONNO = 'RESERVATIONNO',
  INVOICENO = 'INVOICENO',
  BOOKINGNO = 'BOOKINGNO',
}

export type ClientInvoiceBookingType =
  | 'Airline'
  | 'Cruise Line'
  | 'Hotel'
  | 'Insurance'
  | 'Rail'
  | 'Tour DMC'
  | 'Transportation'
  | 'Other'
  | 'Ancillaries';

const ClientInvoiceDto = z.object({
  tripId: z.string().uuid(),
  recipientClientId: z.string().uuid().optional(),
  recipientCorporateGroupId: z.string().uuid().optional(),
  subject: trimmedString(),
  invoiceFor: z.nativeEnum(InvoiceFor),
  paymentMethod: z.nativeEnum(PaymentMethod),
  currency: trimmedString(),
  amount: z.number(),
  dueDate: z.string().regex(isoDateRegex).optional(),
  paidAt: z.string().regex(isoDateRegex).optional(),
  feeTypeId: z.string().uuid().optional(),
  processingCostPct: z.number().min(-100).max(100).optional(),
  processingCost: z.number().optional(),
  clientPaymentMethodId: z.string().uuid().optional(),
  giftCardId: z.string().uuid().optional(),
  documentNumber: trimmedString().optional(),
  documentType: trimmedString().optional(),
  pnr: trimmedString().optional(),
  pcc: trimmedString().optional(),
  voidedAt: z.string().regex(isoDateRegex).optional(),
  source: trimmedString().optional(),
  taxCodeId: z.string().uuid().optional(),
});
type ClientInvoiceDto = z.infer<typeof ClientInvoiceDto>;

export const ClientInvoiceCreateRequestDto = ClientInvoiceDto.omit({
  recipientClientId: true,
  recipientCorporateGroupId: true,
})
  .extend({
    recipientId: z.string().uuid(),
  })
  .refine(
    (data) =>
      !(
        process.env.NEXT_PUBLIC_TYPE_OF_FEE_FIELD_ENABLED === '1' &&
        data.invoiceFor === InvoiceFor.FEES &&
        data.feeTypeId == null
      ),
    {
      message: 'Fee type ID must be provided when invoice is for FEES',
      path: ['feeTypeId'],
    },
  );
export type ClientInvoiceCreateRequestDto = z.infer<
  typeof ClientInvoiceCreateRequestDto
>;

export enum InvoiceStatus {
  SENT = 'SENT',
  PENDING_PAID = 'PENDING_PAID',
  PAID = 'PAID',
  LATE = 'LATE',
  PENDING_REFUND = 'PENDING_REFUND',
  REFUNDED = 'REFUNDED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PENDING_CANCELED = 'PENDING_CANCELED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  VOIDED = 'VOIDED',
}

// Similar to InvoiceStatus but could differ since multiple ClientInvoices could be associated with a single Installment
export enum TripInvoiceInstallmentPaymentStatus {
  SENT = 'SENT',
  NOT_SENT = 'NOT_SENT',
  AUTHORIZED = 'AUTHORIZED', // for payments not processed by TripSuite where advisor has to manually process the payment
  PENDING_PAID = 'PENDING_PAID',
  PAID = 'PAID',
  LATE = 'LATE',
  PENDING_REFUND = 'PENDING_REFUND',
  REFUNDED = 'REFUNDED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PENDING_CANCELED = 'PENDING_CANCELED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
}

export enum DbInvoiceStatus {
  PENDING = 'PENDING',
  SENT = 'SENT',
  PAID = 'PAID',
}

export const GroupedSplitsResponseDto = z.object({
  maxPercent: z.number(),
  primarySplits: z.array(SplitResponseDto),
  secondarySplits: z.array(z.array(SplitResponseDto)),
});
export type GroupedSplitsResponseDto = z.infer<typeof GroupedSplitsResponseDto>;

export const CreditCardMetadataSchema = z.object({
  id: trimmedString(),
  bin: trimmedString().optional(),
  brand: trimmedString().optional(),
  cardType: trimmedString().optional(), // Consider using z.enum([...]) if there are specific predefined types
  holderName: trimmedString().optional(),
  billingAddressAddressLine1: trimmedString().optional(),
  billingAddressAddressLine2: trimmedString().optional(),
  billingAddressCity: trimmedString().optional(),
  billingAddressPostalCode: trimmedString().optional(),
  billingAddressStateOrProvince: trimmedString().optional(),
  billingAddressCountry: trimmedString().optional(),
  expirationMonth: trimmedString().optional(),
  expirationYear: trimmedString().optional(),
  lastFourCardNumber: trimmedString().optional(),
  cardNickname: trimmedString().optional(),
});
export type CreditCardMetadataSchema = z.infer<typeof CreditCardMetadataSchema>;

export const ClientInvoiceResponseDto = ClientInvoiceDto.omit({
  recipientClientId: true,
  recipientCorporateGroupId: true,
  tripId: true,
  feeTypeId: true,
  dueDate: true,
  paidAt: true,
}).extend({
  id: z.string().uuid(),
  createdAt: z.string().regex(isoDateRegex),
  recipientClient: ClientResponseDto.optional(),
  recipientCorporateGroup: GroupResponseDto.optional(),
  amountAfterRefund: z.number(),
  refundedAmount: z.number().optional(),
  refundReceivedDate: z.string().regex(isoDateRegex).optional(),
  refundPaymentMethod: z.nativeEnum(PaymentMethod).optional(),
  refundedProcessingCostAmount: z.number().optional(),
  amountNetOfRefundsAndProcessingCost: z.number().optional(),
  num: z.number().gt(0).optional(),
  dueDate: z.date().optional(),
  paidAt: z.string().regex(isoDateRegex).optional(),
  status: z.nativeEnum(InvoiceStatus),
  trip: TripLightResponseDto,
  locked: z.boolean(),
  agencyUser: AgencyUserResponseDto,
  isLegacyPayment: z.boolean(),
  feeType: FeeTypeResponseDto.optional(),
  clientPaymentMethodId: z.string().uuid().optional(),
  clientPaymentMethod: ClientPaymentMethodResponseDto.optional(),
  paymentTransaction: PaymentTransactionResponseDto.optional(),
  documentNumber: trimmedString().optional(),
  isArc: z.boolean().optional(),
  processingCost: z.number().optional(),
  lastFour: trimmedString().optional(),
  splits: GroupedSplitsResponseDto.optional(),
  expMonth: z.number().optional(),
  expYear: z.number().optional(),
  creditCardBranch: trimmedString().optional(),
  clientHasCreditCards: z.boolean(),
  supportsDirectPayment: z.boolean(),
  directPaymentIneligibilityReasons: z.array(z.string()).optional(),
  cardUsedForPayment: CreditCardMetadataSchema.optional(),
  paymentProcessedByUser: UserResponseDto.optional(),
  giftCard: GiftCardResponseDto.optional(),
  canceledAt: z.date().optional(),
  isVoidable: z.boolean().optional(),
  unvoidableReasons: z.array(z.string()),
});
export type ClientInvoiceResponseDto = z.infer<typeof ClientInvoiceResponseDto>;

export type ClientInvoiceRefundIncludePaymentReversals = ClientInvoiceRefund & {
  paymentReversal: PaymentReversal | null;
};

export const ClientInvoiceListResponseDto = ClientInvoiceDto.omit({
  recipientClientId: true,
  recipientCorporateGroupId: true,
  tripId: true,
  feeTypeId: true,
  dueDate: true,
  paidAt: true,
}).extend({
  id: z.string().uuid(),
  createdAt: z.string().regex(isoDateRegex),
  recipientClient: ClientResponseDto.optional(),
  recipientCorporateGroup: GroupResponseDto.optional(),
  amountAfterRefund: z.number(),
  refundedAmount: z.number().optional(),
  refundReceivedDate: z.string().regex(isoDateRegex).optional(),
  refundPaymentMethod: z.nativeEnum(PaymentMethod).optional(),
  refundedProcessingCostAmount: z.number().optional(),
  amountNetOfRefundsAndProcessingCost: z.number().optional(),
  num: z.number().gt(0).optional(),
  dueDate: z.date().optional(),
  paidAt: z.date().optional(),
  client: ClientResponseDto,
  status: z.nativeEnum(InvoiceStatus),
  trip: TripLightListResponseDto,
  locked: z.boolean(),
  agencyUser: z.object({
    id: z.string().uuid(),
    firstName: z.string(),
    lastName: z.string(),
  }),
  paymentTransaction: PaymentTransactionResponseDto.optional(),
  documentNumber: z.string().optional(),
  clientHasCreditCards: z.boolean(),
  supportsDirectPayment: z.boolean(),
  directPaymentIneligibilityReasons: z.array(z.string()).optional(),
  cardUsedForPayment: CreditCardMetadataSchema.optional(),
  paymentProcessedByUser: UserResponseDto.optional(),
  canceledAt: z.date().optional(),
  voidedAt: z.string().regex(isoDateRegex).optional(),
  isVoidable: z.boolean(),
  unvoidableReasons: z.array(z.string()),
});
export type ClientInvoiceListResponseDto = z.infer<
  typeof ClientInvoiceListResponseDto
>;

const BaseClientInvoicePaginatedRequestDto = getPaginatedRequestDto(
  z.enum([
    'trip',
    'client',
    'subject',
    'status',
    'createdAt',
    'invoiceFor',
    'agencyUser',
    'updatedAt',
  ]),
  z.enum(['trip', 'client', 'subject']),
);

export const ClientInvoicePaginatedRequestDto =
  BaseClientInvoicePaginatedRequestDto.extend({
    tripId: z.string().uuid().optional(),
  });

export type ClientInvoicePaginatedRequestDto = z.infer<
  typeof ClientInvoicePaginatedRequestDto
>;
export type ClientInvoiceSort = z.infer<
  typeof ClientInvoicePaginatedRequestDto
>['sort'];

export const ClientInvoicePaginatedResponseDto = z.object({
  data: z.array(ClientInvoiceResponseDto),
  meta: z.object({
    page: int(z.number()),
    pageSize: int(z.number()),
    totalRowCount: int(z.number()),
  }),
});
export type ClientInvoicePaginatedResponseDto = z.infer<
  typeof ClientInvoicePaginatedResponseDto
>;

export const ClientInvoiceListPaginatedResponseDto = z.object({
  data: z.array(ClientInvoiceListResponseDto),
  meta: z.object({
    page: int(z.number()),
    pageSize: int(z.number()),
    totalRowCount: int(z.number()),
  }),
});
export type ClientInvoiceListPaginatedResponseDto = z.infer<
  typeof ClientInvoiceListPaginatedResponseDto
>;

export const ClientInvoiceUpdateRequestDto = ClientInvoiceDto.omit({
  tripId: true,
  createdAt: true,
  recipientClientId: true,
  recipientCorporateGroupId: true,
})
  .extend({
    recipientId: z.string().uuid(),
    refundedAmount: z.number().optional(),
    refundIssuedAt: z.string().regex(isoDateRegex).optional(),
    refundedProcessingCostAmount: z.number().optional(),
    refundPaymentMethod: z.nativeEnum(PaymentMethod).optional(),
    documentNumber: trimmedString().optional(),
    splits: z
      .array(
        SplitResponseDto.extend({
          takePercent: z.number().min(0).max(100),
        }),
      )
      .optional(),
  })
  .refine(
    (data) =>
      !(
        process.env.NEXT_PUBLIC_TYPE_OF_FEE_FIELD_ENABLED === '1' &&
        data.invoiceFor === InvoiceFor.FEES &&
        data.feeTypeId == null
      ),
    {
      message: 'Fee type ID must be provided when invoice is for FEES',
      path: ['feeTypeId'],
    },
  );

export type ClientInvoiceUpdateRequestDto = z.infer<
  typeof ClientInvoiceUpdateRequestDto
>;

export const ClientInvoiceRequestDto = z.object({
  clientInvoiceId: z.string().uuid(),
});
export type ClientInvoiceRequestDto = z.infer<typeof ClientInvoiceRequestDto>;

export const ClientInvoiceRefundRequestDto = z.object({
  clientInvoiceRefundId: z.string().uuid(),
});
export type ClientInvoiceRefundRequestDto = z.infer<
  typeof ClientInvoiceRequestDto
>;

export const InviteUserRequestDto = z.object({
  email: z.string().email(),
  firstName: trimmedString(),
  lastName: trimmedString(),
  agencyId: z.string().uuid(),
  role: z.nativeEnum(AgencyUserRole),
  agencyTakePct: z.number().min(0).max(100),
  //todo: remove optional when we make sure we have coordinated deploy of BE and FE(retool)
  feeAgencyTakePct: z.number().min(0).max(100).optional(),
  leadCommissionAgencyTakePct: z.number().min(0).max(100).optional(),
  leadFeeAgencyTakePct: z.number().min(0).max(100).optional(),
  statementsEnabled: z.boolean().optional(),
});
export type InviteUserRequestDto = z.infer<typeof InviteUserRequestDto>;

export const AdminInactiveUserCreateRequestDto = z.object({
  email: z.string().email(),
  firstName: trimmedString(),
  lastName: trimmedString(),
  role: z.nativeEnum(AgencyUserRole),
});
export type AdminInactiveUserCreateRequestDto = z.infer<
  typeof AdminInactiveUserCreateRequestDto
>;

export const ClerkPublicMetadataDto = z.object({
  external_id: z.string().uuid(),
});
export type ClerkPublicMetadataDto = z.infer<typeof ClerkPublicMetadataDto>;

export enum TagType {
  TRIP = 'TRIP',
  SUPPLIER = 'SUPPLIER',
  SUPPLIER_BADGE = 'SUPPLIER_BADGE',
}

export const TagCreateRequestDto = z.object({
  name: trimmedString(),
  type: z.nativeEnum(TagType),
  icon: trimmedString().optional(),
});
export type TagCreateRequestDto = z.infer<typeof TagCreateRequestDto>;

export const TagPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['name', 'type']),
  z.enum(['name']),
).extend({
  type: z.nativeEnum(TagType),
});
export type TagPaginatedRequestDto = z.infer<typeof TagPaginatedRequestDto>;

export const TagResponseDto = z.object({
  id: z.string().uuid(),
  name: trimmedString(),
  type: z.nativeEnum(TagType),
  icon: trimmedString().optional(),
});
export type TagResponseDto = z.infer<typeof TagResponseDto>;

export function TagToResponseDto(tag: Tag): TagResponseDto {
  return {
    id: tag.id,
    name: tag.name,
    type: tag.type as TagType,
    icon: tag.icon ?? undefined,
  };
}

export const TagPaginatedResponseDto = z.object({
  data: z.array(TagResponseDto),
  meta: z.object({
    page: int(z.number()),
    pageSize: int(z.number()),
    totalRowCount: int(z.number()),
  }),
});
export type TagPaginatedResponseDto = z.infer<typeof TagPaginatedResponseDto>;

export const TagResponseListDto = z.object({
  data: z.array(TagResponseDto),
});
export type TagResponseListDto = z.infer<typeof TagResponseListDto>;

export const EntityTagCreateRequestDto = z.object({
  tagId: z.string().uuid(),
});
export type EntityTagCreateRequestDto = z.infer<
  typeof EntityTagCreateRequestDto
>;

export const TagRequestDto = z.object({
  tagId: z.string().uuid(),
});
export type TagRequestDto = z.infer<typeof TagRequestDto>;

export const OrganizationTagRequestDto =
  OrganizationRequestDto.merge(TagRequestDto);
export type OrganizationTagRequestDto = z.infer<
  typeof OrganizationTagRequestDto
>;

export const TagUpdateRequestDto = z.object({
  name: trimmedString(),
  icon: trimmedString().optional(),
});
export type TagUpdateRequestDto = z.infer<typeof TagUpdateRequestDto>;

export const EntityTagResponseDto = z.string();
export type EntityTagResponseDto = z.infer<typeof EntityTagResponseDto>;

export const EntityTagResponseListDto = z.array(EntityTagResponseDto);
export type EntityTagResponseListDto = z.infer<typeof EntityTagResponseListDto>;

export const TripTagRequestDto = TripRequestDto.extend({
  tagId: z.string().uuid(),
});
export type TripTagRequestDto = z.infer<typeof TripTagRequestDto>;

export function filterExpiredCreditCards(
  creditCards: ClientProfileCreditCard[],
) {
  return creditCards.filter((cc) => {
    const { expMonth, expYear } = cc;
    // Normalize year to handle 2-digit years and create expiration date
    const normalizedYear = expYear < 100 ? 2000 + expYear : expYear;
    const expirationDate = moment({
      year: normalizedYear,
      month: expMonth - 1,
    }).endOf('month');
    return expirationDate.isAfter(moment());
  });
}

export function supplierTypeEnumToString(supplierType: SupplierType): string {
  switch (supplierType) {
    case SupplierType.AIRLINE:
      return 'Airline';
    case SupplierType.CRUISE_LINE:
      return 'Cruise Line';
    case SupplierType.HOTEL:
      return 'Hotel';
    case SupplierType.INSURANCE:
      return 'Insurance';
    case SupplierType.RAIL:
      return 'Rail';
    case SupplierType.TOUR_DMC:
      return 'Tour / DMC';
    case SupplierType.TRANSPORTATION:
      return 'Transportation';
    case SupplierType.OTHER:
      return 'Other';
    case SupplierType.ANCILLARIES:
      return 'Ancillaries';
    default:
      return 'Hotel';
  }
}

export const SupplierTypeEnum = z.nativeEnum(SupplierType);

export enum CommunicationMethod {
  EMAIL = 'EMAIL',
  SECURE_EMAIL = 'SECURE_EMAIL',
  FAX = 'FAX',
  SERTIFI = 'SERTIFI',
}

export const SupplierCreateRequestDto = z.object({
  type: SupplierTypeEnum,
  name: trimmedString(),
  email: z.string().email().optional(),
  address: trimmedString().optional(),
  phone: trimmedString().optional(),
  website: trimmedString().optional(),
  fax: trimmedString().optional(),
  defaultCommissionPercent: z.number().optional(),
  defaultCommissionDueDateOffset: z.number().optional(),
  interfaceId: trimmedString().optional(),
  isClearinghouse: z.boolean().optional(),

  address1: trimmedString().optional(),
  address2: trimmedString().optional(),
  city: trimmedString().optional(),
  state: trimmedString().optional(),
  zip: trimmedString().optional(),
  country: trimmedString().optional(),

  parentId: z.string().uuid().optional(),
  tags: z.array(z.string()).optional(),
  badgeIds: z.array(z.string().uuid()).optional(),
  associatedSupplierIds: z.array(z.string().uuid()).optional(),
});
export type SupplierCreateRequestDto = z.infer<typeof SupplierCreateRequestDto>;

export enum SupplierMappingType {
  AMADEUS = 'AMADEUS',
  GALILEO_APOLLO = 'GALILEO_APOLLO',
  SABRE = 'SABRE',
  WORLDSPAN = 'WORLDSPAN',
  TRIPADVISOR = 'TRIPADVISOR',
  VIRTUOSO = 'VIRTUOSO',
  SPOTNANA = 'SPOTNANA',
}

export const AdminSupplierCreateRequestDto = SupplierCreateRequestDto.extend({
  isParent: z.boolean().optional(),
  parentId: z.string().uuid().optional(),
  communicationMethod: z.nativeEnum(CommunicationMethod).optional(),
  isClearinghouse: z.boolean().optional(),
  isPrivate: z.boolean().optional(),
  mappings: z
    .object({
      [SupplierMappingType.AMADEUS]: trimmedString().optional(),
      [SupplierMappingType.GALILEO_APOLLO]: trimmedString().optional(),
      [SupplierMappingType.SABRE]: trimmedString().optional(),
      [SupplierMappingType.WORLDSPAN]: trimmedString().optional(),
      [SupplierMappingType.TRIPADVISOR]: trimmedString().optional(),
    })
    .optional(),
  taLocationId: trimmedString().optional(),
})
  .omit({
    tags: true,
    badgeIds: true,
  })
  .strict();
export type AdminSupplierCreateRequestDto = z.infer<
  typeof AdminSupplierCreateRequestDto
>;

export const SupplierResponseMinDto = z.object({
  id: z.string().uuid(),
  name: trimmedString(),
  phone: trimmedString().optional(),
  website: trimmedString().optional(),
  type: SupplierTypeEnum,
});
export type SupplierResponseMinDto = z.infer<typeof SupplierResponseMinDto>;

function supplierToMinResponseDto(supplier: Supplier): SupplierResponseMinDto {
  return {
    id: supplier.id,
    name: supplier.name,
    type: supplier.type as SupplierType,
    phone: supplier.phone ?? undefined,
    website: supplier.website ?? undefined,
  };
}

export const SupplierResponseDto = z.object({
  id: z.string().uuid(),
  interfaceId: trimmedString().optional(),
  type: SupplierTypeEnum,
  name: trimmedString(),
  email: trimmedString().optional(),
  address: trimmedString().optional(),
  phone: trimmedString().optional(),
  website: trimmedString().optional(),
  fax: trimmedString().optional(),
  isCustom: z.boolean(),
  isRetired: z.boolean().optional(),

  address1: trimmedString().optional(),
  address2: trimmedString().optional(),
  city: trimmedString().optional(),
  state: trimmedString().optional(),
  zip: trimmedString().optional(),
  country: trimmedString().optional(),
  defaultCommissionPercent: z.number().optional(),
  defaultCommissionDueDateOffset: z.number().optional(),
  notes: trimmedString().optional(),
  parent: SupplierResponseMinDto.optional(),
  isClearinghouse: z.boolean(),
  attachments: z.array(AttachmentResponseDto).optional(),
  children: z.array(SupplierResponseMinDto).optional(),
  tags: z.string().array().optional(),
  badges: z.array(TagResponseDto).optional(),
  associatedSuppliers: z.array(SupplierResponseMinDto).optional(),
});
export type SupplierResponseDto = z.infer<typeof SupplierResponseDto>;

export const SupplierNotesDto = z.object({
  notes: trimmedString().optional(),
});
export type SupplierNotesDto = z.infer<typeof SupplierNotesDto>;

const SupplierOrganizationConfigPartial = z.object({
  organizationId: z.string().uuid(),
  defaultCommissionPercent: z.number().nullable().optional(),
  defaultCommissionDueDateOffset: z.number().nullable().optional(),
  isPreferred: z.boolean().nullable().optional(),
});

export const AdminSupplierResponseDto = SupplierResponseDto.extend({
  isParent: z.boolean().optional(),
  parentId: z.string().uuid().optional(),
  parentName: trimmedString().optional(),
  organizationName: trimmedString().optional(),
  organizationId: z.string().uuid().optional(),
  communicationMethod: z.nativeEnum(CommunicationMethod).optional(),
  isPrivate: z.boolean(),
  isRetired: z.boolean(),
  mappings: z
    .object({
      [SupplierMappingType.AMADEUS]: trimmedString().optional(),
      [SupplierMappingType.GALILEO_APOLLO]: trimmedString().optional(),
      [SupplierMappingType.SABRE]: trimmedString().optional(),
      [SupplierMappingType.WORLDSPAN]: trimmedString().optional(),
      [SupplierMappingType.TRIPADVISOR]: trimmedString().optional(),
    })
    .optional(),
  supplierOrganizationConfigs: z
    .array(SupplierOrganizationConfigPartial)
    .optional(),
});
export type AdminSupplierResponseDto = z.infer<typeof AdminSupplierResponseDto>;

export const OrganizationResponseMinimalDto = z.object({
  id: trimmedString(),
  name: trimmedString(),
  isConnectedToGds: z.boolean().optional(),
  useManagedTaxes: z.boolean(),
});
export type OrganizationResponseMinimalDto = z.infer<
  typeof OrganizationResponseMinimalDto
>;

export const supplierToDto = (
  supplier: Supplier & {
    mappings?: SupplierMapping[];
    organization: Organization | OrganizationResponseMinimalDto | null;
    parent?: Supplier | null;
    supplierOrganizationConfigs?: SupplierOrganizationConfig[]; // only set when creating/updating/fetching an individual supplier
    attachments?: Attachment[];
    children?: Supplier[];
    tags?: (SupplierTag & { tag: Tag })[];
    associatedSuppliers?: (AssociatedSupplier & {
      supplier: Supplier;
    })[];
  },
  includeSupplierOrg = false,
): SupplierResponseDto => {
  const [supplierOrgConfig] = supplier.supplierOrganizationConfigs ?? [];

  const sabreInterfaceId = supplier.mappings?.find(
    (mapping) => mapping.type === SupplierMappingType.SABRE,
  )?.externalId;

  // there should be at most one of these
  const [supplierOrganizationConfig] =
    supplier.supplierOrganizationConfigs ?? [];

  const tags = supplier.tags
    ?.filter((supplierTag) => supplierTag.tag.type === TagType.SUPPLIER)
    .map((supplierTag) => supplierTag.tag);
  const badges = supplier.tags
    ?.filter((supplierTag) => supplierTag.tag.type === TagType.SUPPLIER_BADGE)
    .map((supplierTag) => supplierTag.tag);

  return {
    id: supplier.id,
    interfaceId: sabreInterfaceId ?? undefined,
    type: supplier.type as SupplierType,
    name: includeSupplierOrg
      ? getSupplierNameWithOrg({
          orgName: supplier.organization?.name,
          supplierName: supplier.name,
          isRetired: supplier.isRetired,
        })
      : supplier.name,
    email: supplier.email ?? undefined,
    address: supplier.address ?? undefined,
    phone: supplier.phone ?? undefined,
    website: supplier.website ?? undefined,
    fax: supplier.fax ?? undefined,
    isCustom: !!supplier.organizationId,

    address1: supplier.address1 ?? undefined,
    address2: supplier.address2 ?? undefined,
    city: supplier.city ?? undefined,
    state: supplier.state ?? undefined,
    zip: supplier.zip ?? undefined,
    country: supplier.country ?? undefined,
    defaultCommissionPercent:
      supplierOrgConfig?.defaultCommissionPercent ?? undefined,
    defaultCommissionDueDateOffset:
      supplierOrgConfig?.defaultCommissionDueDateOffset ?? undefined,
    isClearinghouse: supplier.isClearinghouse,
    attachments: supplier.attachments ?? [],
    isRetired: supplier.isRetired,
    notes: supplierOrganizationConfig?.notes ?? undefined,
    parent: supplier.parent
      ? supplierToMinResponseDto(supplier.parent)
      : undefined,
    children: supplier.children?.map((child) =>
      supplierToMinResponseDto(child),
    ),
    tags: tags?.map((tag) => tag.name),
    badges: badges?.map(TagToResponseDto),
    associatedSuppliers: supplier.associatedSuppliers?.map(
      (associatedSupplier) =>
        supplierToMinResponseDto(associatedSupplier.supplier),
    ),
  };
};

export const supplierToAdminDto = (
  supplier: Supplier & {
    mappings: SupplierMapping[];
    organization: Organization | null;
    parent?: Supplier | null;
    supplierOrganizationConfigs?: SupplierOrganizationConfig[];
    tags?: (SupplierTag & { tag: Tag })[];
  },
): AdminSupplierResponseDto => {
  return {
    ...supplierToDto(supplier),
    isParent: supplier.isParent,
    parentId: supplier.parentId ?? undefined,
    parentName: supplier.parent?.name,
    organizationName: supplier.organization?.name,
    isPrivate: supplier.isPrivate,
    isRetired: supplier.isRetired,
    organizationId: supplier.organization?.id,
    communicationMethod: supplier.communicationMethod
      ? (supplier.communicationMethod as CommunicationMethod)
      : undefined,
    mappings: supplier.mappings?.reduce(
      (acc, mapping) => ({
        ...acc,
        [mapping.type]: mapping.externalId,
      }),
      {},
    ),
    supplierOrganizationConfigs: supplier.supplierOrganizationConfigs?.map(
      (config) => ({
        organizationId: config.organizationId,
        defaultCommissionPercent: config.defaultCommissionPercent,
        defaultCommissionDueDateOffset: config.defaultCommissionDueDateOffset,
        isPreferred: config.isPreferred,
      }),
    ),
  };
};

const BaseSupplierPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['name']),
  z.enum(['name', 'address', 'phone', 'website']),
);
type BaseSupplierPaginatedRequestDto = z.infer<
  typeof BaseSupplierPaginatedRequestDto
>;

export type SupplierSort = z.infer<typeof SupplierPaginatedRequestDto>['sort'];

export const SupplierPaginatedRequestDto =
  BaseSupplierPaginatedRequestDto.extend({
    isParent: z.enum(['true', 'false']).optional(),
    isClearinghouse: z.enum(['true', 'false']).optional(),
    isRetired: z.enum(['true', 'false']).optional(),
    parentId: z
      .string()
      .optional()
      .refine(
        (value) => value === undefined || value === 'null' || isUuid(value),
        {
          message: 'ParentId must be a UUID or "null" or undefined',
        },
      ),
    type: SupplierTypeEnum.optional(),
  });
export type SupplierPaginatedRequestDto = z.infer<
  typeof SupplierPaginatedRequestDto
>;

export const SupplierPaginatedResponseDto = z.object({
  data: z.array(SupplierResponseDto),
  meta: z.object({
    page: int(z.number()),
    pageSize: int(z.number()),
    totalRowCount: int(z.number()),
  }),
});
export type SupplierPaginatedResponseDto = z.infer<
  typeof SupplierPaginatedResponseDto
>;

export const AdminSupplierPaginatedResponseDto = z.object({
  data: z.array(AdminSupplierResponseDto),
  meta: z.object({
    page: int(z.number()),
    pageSize: int(z.number()),
    totalRowCount: int(z.number()),
  }),
});
export type AdminSupplierPaginatedResponseDto = z.infer<
  typeof AdminSupplierPaginatedResponseDto
>;

export const SupplierRequestDto = z.object({
  supplierId: z.string().uuid(),
});
export type SupplierRequestDto = z.infer<typeof SupplierRequestDto>;

export const SupplierUpdateRequestDto = z.object({
  type: SupplierTypeEnum,
  name: trimmedString(),
  email: z.string().email().optional(),
  address: trimmedString().optional(),
  phone: trimmedString().optional(),
  website: trimmedString().optional(),
  fax: trimmedString().optional(),
  isPrivate: z.boolean().optional(),
  organizationId: z.string().uuid().optional(),
  defaultCommissionPercent: z.number().optional(),
  defaultCommissionDueDateOffset: z.number().optional(),
  interfaceId: trimmedString().optional(),
  isClearinghouse: z.boolean().optional(),

  address1: trimmedString().optional(),
  address2: trimmedString().optional(),
  city: trimmedString().optional(),
  state: trimmedString().optional(),
  zip: trimmedString().optional(),
  country: trimmedString().optional(),

  parentId: z.string().uuid().optional(),
  tags: z.array(z.string()).optional(),
  badgeIds: z.array(z.string().uuid()).optional(),

  associatedSupplierIds: z.array(z.string().uuid()).optional(),
});
export type SupplierUpdateRequestDto = z.infer<typeof SupplierUpdateRequestDto>;

export const SupplierStatusUpdateRequestDto = z.object({
  isRetired: z.boolean(),
});

export type SupplierStatusUpdateRequestDto = z.infer<
  typeof SupplierStatusUpdateRequestDto
>;

export const AdminSupplierUpdateRequestDto = SupplierUpdateRequestDto.extend({
  isParent: z.boolean().optional(),
  parentId: z.string().uuid().nullable().optional(),
  isRetired: z.boolean().optional(),
  communicationMethod: z.nativeEnum(CommunicationMethod).optional(),
  mappings: z
    .object({
      [SupplierMappingType.AMADEUS]: trimmedString().optional(),
      [SupplierMappingType.GALILEO_APOLLO]: trimmedString().optional(),
      [SupplierMappingType.SABRE]: trimmedString().optional(),
      [SupplierMappingType.WORLDSPAN]: trimmedString().optional(),
      [SupplierMappingType.TRIPADVISOR]: trimmedString().optional(),
    })
    .optional(),
  taLocationId: trimmedString().optional(),
});
export type AdminSupplierUpdateRequestDto = z.infer<
  typeof AdminSupplierUpdateRequestDto
>;

export enum CommissionAdjustmentType {
  MANUAL = 'MANUAL',
  CLEARINGHOUSE_FEE = 'CLEARINGHOUSE_FEE',
  HOST_SPLIT = 'HOST_SPLIT',
}

export const CommissionAdjustmentResponseDto = z.object({
  id: z.string().uuid(),
  amountUsd: z.number(),
  notes: trimmedString().optional(),
  createdAt: z.string().regex(isoDateRegex),
  type: z.nativeEnum(CommissionAdjustmentType),
});
export type CommissionAdjustmentResponseDto = z.infer<
  typeof CommissionAdjustmentResponseDto
>;

export const CommissionAdjustmentCreateRequestDto = z.object({
  amountUsd: z.number(),
  notes: trimmedString().optional(),
  type: z.nativeEnum(CommissionAdjustmentType),
});
export type CommissionAdjustmentCreateRequestDto = z.infer<
  typeof CommissionAdjustmentCreateRequestDto
>;

export enum CommissionStatus {
  MATCHED = 'Matched',
  UNMATCHED = 'Unmatched',
  FORFEITED = 'Forfeited',
}

export const CommissionResponseDto = z.object({
  id: z.string().uuid(),
  publicId: trimmedString(),
  bookingId: z.string().uuid().optional(),
  amountUsd: z.number(),
  amountHome: z.number(),
  createdAt: z.string().regex(isoDateRegex),
  supplierName: trimmedString(),
  confirmationNumber: trimmedString().optional(),
  referenceNumber: trimmedString(),
  receivedDate: z.string().regex(isoDateRegex),
  status: z.nativeEnum(CommissionStatus).optional(),
  adjustments: z.array(CommissionAdjustmentResponseDto),
  forfeitedAt: z.string().regex(isoDateRegex).optional(),
  agencyUser: AgencyUserResponseDto.optional(),
  isLegacyPayment: z.boolean(),
  currency: trimmedString().optional(),
  exchangeRate: z.number().optional(),
  exchangeRateLockedAt: z.string().regex(isoDateRegex).nullable().optional(),
  clientNameActual: trimmedString().optional(),
  advisorNameActual: trimmedString().optional(),
  supplierNameActual: trimmedString().optional(),
  commissionableValueActual: z.number().optional(),
  totalActual: z.number().optional(),
  checkInActual: z.string().regex(dateRegex).optional(),
  checkOutActual: z.string().regex(dateRegex).optional(),
  notes: trimmedString().optional(),
  sequenceNumber: z.number().optional(),
  accountingIgnore: z.boolean(),
});
export type CommissionResponseDto = z.infer<typeof CommissionResponseDto>;

export const CommissionCreateRequestDto = z.object({
  bookingId: z.string().uuid().optional(),
  amountUsd: z.number(),
  confirmationNumber: trimmedString().optional(),
  adjustments: z.array(CommissionAdjustmentCreateRequestDto),
  currency: trimmedString().optional(),
  exchangeRate: z.number().optional(),
  exchangeRateLockedAt: z.string().regex(isoDateRegex).nullable().optional(),
  clientNameActual: trimmedString().optional(),
  advisorNameActual: trimmedString().optional(),
  supplierNameActual: trimmedString().optional(),
  commissionableValueActual: z.number().optional(),
  totalActual: z.number().optional(),
  checkInActual: z.string().regex(dateRegex).optional(),
  checkOutActual: z.string().regex(dateRegex).optional(),
  notes: trimmedString().optional(),
  sequenceNumber: z.number().optional(),
});
export type CommissionCreateRequestDto = z.infer<
  typeof CommissionCreateRequestDto
>;

export const BookingRequestDto = z.object({
  bookingId: z.string().uuid(),
});
export type BookingRequestDto = z.infer<typeof BookingRequestDto>;

export enum PayingEntity {
  AGENCY = 'AGENCY',
  CLIENT = 'CLIENT',
}

export const BookingDto = z.object({
  clientId: z.string().uuid().optional(),
  corporateGroupId: z.string().uuid().optional(),
  supplierId: z.string().uuid(),
  supplierType: z.nativeEnum(SupplierType).optional(),
  isConfirmed: z.boolean().optional(),
  confirmationNumber: trimmedString(),
  checkIn: z.string().regex(isoDateRegex),
  checkOut: z.string().regex(isoDateRegex),
  payingEntity: z.nativeEnum(PayingEntity),
  total: z.number(),
  isCommissionable: z.boolean(),
  commissionableValue: z.number().optional(),
  estCommission: z.number().optional(),
  commissionDue: z.string().regex(isoDateRegex).optional(),
  notes: trimmedString().optional(),
  refundedAmount: z.number().optional(),
  refundCommissionableValue: z.number().optional(),
  refundEstCommission: z.number().optional(),
  currency: trimmedString().optional(),
  exchangeRate: z.number().optional(),
  exchangeRateLockedAt: z.string().regex(isoDateRegex).nullable().optional(),
  subject: trimmedString().optional(),
  invoiceRemarks: trimmedString().optional(),
  taxesAndFees: z.number().optional(),
  markupPercent: z.number().optional(),
  markup: z.number().optional(),
  totalWithMarkup: z.number().optional(),
  issuedAt: z.string().regex(isoDateRegex).optional(),
  documentType: trimmedString().optional(),
  submitTo: trimmedString().optional(),
  bookingType: z.nativeEnum(InterfaceType).optional(),
  originalBookingId: z.string().uuid().optional(),
  voidedAt: z.string().regex(isoDateRegex).optional(),
  pnr: trimmedString().optional(),
  pcc: trimmedString().optional(),
  penalty: z.number().optional(),
  commissionPenalty: z.number().optional(),
  refundPenalty: z.number().optional(),
  refundCommissionPenalty: z.number().optional(),
});

export type BookingDto = z.infer<typeof BookingDto>;

export const BookingExpenseUpsertRequestDto = z.object({
  id: z.string().uuid().optional(),
  subject: trimmedString(),
  amount: z.number(),
  paymentMethod: z.nativeEnum(PaymentMethod).optional(),
  dueDate: z.string().regex(isoDateRegex),
  paidAt: z.string().regex(isoDateRegex).optional(),
  notes: trimmedString().optional(),
  billPaymentMethodId: z.string().uuid().optional(),
  currency: trimmedString().optional(),
  exchangeRate: z.number().optional(),
  exchangeRateLockedAt: z.string().regex(isoDateRegex).nullable().optional(),
  isPaidAtCheckout: z.boolean().optional(),
});
export type BookingExpenseUpsertRequestDto = z.infer<
  typeof BookingExpenseUpsertRequestDto
>;

export const BookingUpsertRequestBaseDto = BookingDto.extend({
  isRefund: z.boolean().optional(),
  refundPaymentMethod: z.nativeEnum(PaymentMethod).optional(),
  refundBillPaymentMethodId: z.string().uuid().optional(),
  refundReceivedDate: z.string().regex(dateRegex).optional(),
  splits: z
    .array(
      SplitResponseDto.extend({
        takePercent: z.number().min(0).max(100),
      }),
    )
    .optional(),
  expenses: z.array(BookingExpenseUpsertRequestDto).optional(),
  additionalClientIds: z.array(z.string().uuid()).optional(),
  flownCarrier: trimmedString().optional(),
  itinerary: trimmedString().optional(),
  itineraryType: z.nativeEnum(ItineraryType).optional(),
  voidedAt: z.string().regex(isoDateRegex).optional().nullable(),
  baseTotal: z.number().optional(),
  netRemit: z.number().optional(),
  source: trimmedString().optional(),
  clientPaidTaxes: z.number().optional(),
});

export const BookingUpsertRequestDto = BookingUpsertRequestBaseDto.refine(
  (input) => {
    return (
      !input.isCommissionable ||
      (input.commissionableValue !== undefined &&
        input.estCommission !== undefined &&
        input.commissionDue !== undefined)
    );
  },
  'Commissionable bookings must have commissionableValue, estCommission, and commissionDue defined',
);

export type BookingUpsertRequestDto = z.infer<typeof BookingUpsertRequestDto>;

export const BookingExpenseResponseDto = z.object({
  id: z.string().uuid(),
  subject: trimmedString(),
  paymentMethod: z.nativeEnum(PaymentMethod),
  lastFour: trimmedString().optional(),
  amount: z.number(),
  amountHome: z.number(),
  dueDate: z.string().regex(isoDateRegex),
  paidAt: z.string().regex(isoDateRegex).optional(),
  locked: z.boolean(),
  notes: trimmedString().optional(),
  billPaymentMethod: AccountingPaymentMethodResponseDto.optional(),
  isLegacyPayment: z.boolean(),
  currency: trimmedString().optional(),
  exchangeRate: z.number().optional(),
  exchangeRateLockedAt: z.string().regex(isoDateRegex).nullable().optional(),
  isPaidAtCheckout: z.boolean().optional(),
});
export type BookingExpenseResponseDto = z.infer<
  typeof BookingExpenseResponseDto
>;

export const BookingExpensePaginatedResponseDto =
  createPaginatedResponseDto<BookingExpenseResponseDto>(
    BookingExpenseResponseDto,
  );
export type BookingExpensePaginatedResponseDto = z.infer<
  typeof BookingExpensePaginatedResponseDto
>;

export const FareDifferenceDocumentResponseDto = z.object({
  id: z.string().uuid(),
  issuedAt: z.string().regex(isoDateRegex).optional(),
  expiresAt: z.string().regex(isoDateRegex).optional(),
  redeemedAt: z.string().regex(isoDateRegex).optional(),
  documentNumber: trimmedString(),
  clientId: trimmedString().optional().nullable(),
});
export type FareDifferenceDocumentResponseDto = z.infer<
  typeof FareDifferenceDocumentResponseDto
>;

function getSupplierNameWithOrg({
  orgName,
  supplierName,
  isRetired,
}: {
  orgName: string | undefined;
  supplierName: string;
  isRetired: boolean;
}) {
  const baseName = (
    orgName && !supplierNameAlreadyHasOrgName({ supplierName, orgName })
      ? `${supplierName} (${orgName})`
      : supplierName
  ).replaceAll('(inactive)', '');
  return isRetired ? `${baseName} (inactive)` : baseName;
}

export function fareDifferenceDocumentToDto(
  fdd: FareDifferenceDocument | undefined | null,
): FareDifferenceDocumentResponseDto | undefined {
  if (!fdd) {
    return undefined;
  }
  return {
    id: fdd.id,
    issuedAt: fdd.issuedAt?.toISOString(),
    expiresAt: fdd.expiresAt?.toISOString(),
    redeemedAt: fdd.redeemedAt?.toISOString(),
    documentNumber: fdd.documentNumber,
    clientId: fdd.clientId,
  } as FareDifferenceDocumentResponseDto;
}

export const BookingResponseDto = BookingDto.extend({
  id: z.string().uuid(),
  createdAt: z.string().regex(isoDateRegex),
  client: ClientResponseDto.optional(),
  additionalClients: z.array(ClientResponseDto).optional(),
  corporateGroup: GroupResponseDto.optional(),
  supplier: SupplierResponseDto,
  supplierType: z.nativeEnum(SupplierType),
  trip: TripResponseDto,
  commissions: z.array(CommissionResponseDto),
  estCommissionPct: z.number().optional(),
  commissionStatus: z.nativeEnum(InvoiceStatus),
  bookingStatus: z.nativeEnum(BookingStatus),
  commissionPaidAt: z.string().regex(isoDateRegex).optional(),
  agencyUser: AgencyUserResponseDto,
  receivedCommission: z.number(),
  canceledAt: z.string().regex(isoDateRegex).optional(),
  maxRefundAmountHome: z.number(),
  maxRefundAmount: z.number(),
  originalTotalHome: z.number(),
  originalTotal: z.number(),
  originalCommissionableValueHome: z.number().optional(),
  originalCommissionableValue: z.number().optional(),
  originalEstCommissionHome: z.number().optional(),
  originalEstCommission: z.number().optional(),
  taxesAndFees: z.number().optional(),
  refundPaymentMethod: z.nativeEnum(PaymentMethod).optional(),
  refundBillPaymentMethodId: z.string().uuid().optional(),
  refundReceivedDate: z.string().regex(dateRegex).optional(),
  isConfirmedLocked: z.boolean(),
  locked: z.boolean(),
  isExchange: z.boolean().optional(),
  isFareDifference: z.boolean().optional(),
  isCreditDebitMemo: z.boolean().optional(),
  originalBookingId: z.string().uuid().optional(),
  totalHome: z.number(),
  commissionableValueHome: z.number().optional(),
  estCommissionHome: z.number().optional(),
  netRemitHome: z.number().optional(),
  taxesAndFeesHome: z.number().optional(),
  currency: trimmedString().optional(),
  exchangeRate: z.number().optional(),
  splits: GroupedSplitsResponseDto.optional(),
  expenses: BookingExpensePaginatedResponseDto.optional(),
  markupHome: z.number().optional(),
  totalWithMarkupHome: z.number().optional(),
  useMarkup: z.boolean(),
  isArc: z.boolean().optional(),
  attachments: z.array(AttachmentResponseDto).optional(),
  itinerary: trimmedString().optional(),
  flownCarrier: trimmedString().optional(),
  itineraryType: z.nativeEnum(ItineraryType).optional(),
  ancillaryServicesCategory: trimmedString().optional(),
  // travel credit information
  fareDifferenceDocument: FareDifferenceDocumentResponseDto.optional(),
  passengerName: trimmedString().optional(),
  isVoidable: z.boolean().optional(),
  unvoidableReasons: z.array(z.string()),
  clientPaidTaxes: z.number().optional(),
});
export type BookingResponseDto = z.infer<typeof BookingResponseDto>;

export const bookingToDto = (
  booking: Booking & {
    expenses: (BookingExpense & {
      billPaymentMethod: AccountingPaymentMethod | null;
      payments: (BookingPayment & {
        billPaymentMethod: AccountingPaymentMethod | null;
      })[];
    })[];
    supplier: Supplier & {
      organization: Organization | null;
      tags: (SupplierTag & { tag: Tag })[];
    };
    client: Client | null;
    corporateGroup:
      | (Group & {
          assignedToAgencyUser:
            | (AgencyUser & {
                agency: Agency;
                user: User;
              })
            | null;
        })
      | null;
    commissions: (Commission & {
      commissionGroup: CommissionGroup;
      adjustments: CommissionAdjustment[];
    })[];
    commissionBookings: (CommissionBooking & {
      commission: Commission & {
        commissionGroup: CommissionGroup;
        adjustments: CommissionAdjustment[];
      };
    })[];
    trip: Trip & {
      primaryClient:
        | (Client & {
            groups: (ClientGroup & {
              group: Group & {
                assignedToAgencyUser:
                  | (AgencyUser & {
                      agency: Agency;
                      user: User;
                    })
                  | null;
              };
            })[];
          })
        | null;
      tripClients: (TripClient & {
        client: Client;
      })[];
      agencyUser: AgencyUser & {
        agency: Agency;
        user: User;
      };
      tripTags: (TripTag & { tag: Tag })[];
      tripDestinations: (TripDestination & { geoName: GeoName })[];
      lead:
        | (Lead & {
            stage: LeadStage;
            assignedTo:
              | (AgencyUser & {
                  agency: Agency;
                  user: User;
                })
              | null;
            formSubmits: (FormSubmit & { form: Form })[];
            client: Client | null;
          })
        | null;
      stage: LeadStage | null;
      remarks?: TripRemark[];
      organization: Organization;
      corporateGroup:
        | (Group & {
            assignedToAgencyUser:
              | (AgencyUser & {
                  agency: Agency;
                  user: User;
                })
              | null;
          })
        | null;
      tripPnrs: TripPnr[];
    };
    refunds: BookingRefund[];
    bookingClients: (BookingClient & { client: Client })[];
    attachments?: Attachment[];
    airSegments: AirSegment[];
    fareDifferenceDocument: FareDifferenceDocument | null;
  },
  tz?: string,
  splits?: GroupedSplitsResponseDto,
): BookingResponseDto => {
  const receivedCommission = booking.commissions.reduce((acc, commission) => {
    return acc.plus(commission.amountHome);
  }, new Decimal(0));

  const refundTotals = getRefundTotalsFromBookingRefunds(booking.refunds);

  const sampleRefund = booking.refunds[0];

  const commissions = booking.commissionBookings
    .filter((cb) => !cb.deletedAt && !cb.voidedAt)
    .map((cb) => cb.commission)
    .filter((c) => !c.deletedAt && !c.voidedAt && !c.commissionGroup.deletedAt);

  const payments = booking.expenses
    .filter((expense) => !expense.deletedAt)
    .flatMap((expense) =>
      expense.payments.filter(
        (payment) => !payment.deletedAt && !payment.voidedAt,
      ),
    );

  const isHybridBasis =
    booking.trip.organization.accountingBasis === AccountingBasis.HYBRID;
  const nonCommissionableCutoff = isHybridBasis
    ? booking.checkOut
    : moment(booking.checkOut).add(3, 'months').toDate();

  const isCommissionable =
    !!booking.estCommission && !booking.estCommission?.equals(0);
  const isLocked = Boolean(
    booking.trip.lockedAt ||
      (isCommissionable
        ? commissions.length > 0
        : nonCommissionableCutoff.getTime() < new Date().getTime()),
  );

  const maxRefundAmountHome = getBookingMaxRefundAmount(booking);
  const maxRefundAmount = maxRefundAmountHome
    .dividedBy(booking.exchangeRate)
    .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);
  const originalTotalHome = booking.baseTotalHome ?? booking.totalHome;
  const originalTotal = originalTotalHome
    .dividedBy(booking.exchangeRate)
    .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);
  const originalCommissionableValueHome =
    booking.baseCommissionableValueHome ?? booking.commissionableValueHome;
  const originalCommissionableValue = originalCommissionableValueHome
    ?.dividedBy(booking.exchangeRate)
    .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);
  const originalEstCommissionHome =
    booking.baseEstCommissionHome ?? booking.estCommissionHome;
  const netRemitHome = calculateBookingNetRemit(
    booking,
    originalEstCommissionHome ?? ZERO,
  );
  const originalEstCommission = originalEstCommissionHome
    ?.dividedBy(booking.exchangeRate)
    .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);

  const expenses: BookingExpensePaginatedResponseDto = {
    meta: {
      page: 1,
      pageSize: booking.expenses.length,
      totalRowCount: booking.expenses.length,
    },
    data: booking.expenses.map((expense) => {
      return bookingExpenseToDto({
        ...expense,
        booking,
      });
    }),
  };
  const isArc = isArcBooking(booking);
  const unvoidableReasons = getUnvoidableReasons(booking);

  return {
    locked: isLocked,
    id: booking.id,
    createdAt: booking.createdAt.toISOString(),
    clientId: booking.client?.id ?? booking.trip.primaryClient?.id ?? undefined,
    corporateGroupId:
      booking.corporateGroupId ?? booking.trip.corporateGroupId ?? undefined,
    supplierId: booking.supplier.id,
    isConfirmed: booking.isConfirmed,
    isConfirmedLocked: booking.isConfirmed && payments.length > 0,
    confirmationNumber: booking.confirmationNumber,
    checkIn: booking.checkIn.toISOString(),
    checkOut: booking.checkOut.toISOString(),
    clientPaidTaxes: booking.clientPaidTaxes?.toNumber(),
    payingEntity:
      booking.payingEntity === PayingEntity.AGENCY
        ? PayingEntity.AGENCY
        : PayingEntity.CLIENT,
    total: booking.total.toNumber(),
    totalHome: booking.totalHome.toNumber(),
    isCommissionable,
    commissionableValue: booking.commissionableValue?.toNumber(),
    commissionableValueHome: booking.commissionableValueHome?.toNumber(),
    estCommission: booking.estCommission?.toNumber(),
    estCommissionHome: booking.estCommissionHome?.toNumber(),
    netRemitHome: netRemitHome.toNumber(),
    commissionDue: booking.commissionDue?.toISOString(),
    notes: booking.notes ?? undefined,
    client: booking.client
      ? clientToDto(booking.client)
      : booking.trip.primaryClient
        ? clientToDto(booking.trip.primaryClient)
        : undefined,
    corporateGroup: booking.corporateGroup
      ? groupToDto(booking.corporateGroup)
      : booking.trip.corporateGroup
        ? groupToDto(booking.trip.corporateGroup)
        : undefined,
    supplier: supplierToDto(booking.supplier),
    trip: tripToDto(booking.trip, tz),
    commissions: booking.commissions.map((c) =>
      commissionToDto(
        c,
        booking,
        { ...c.commissionGroup, supplier: booking.supplier },
        booking.trip.organization.forfeitThresholdDays,
      ),
    ),
    estCommissionPct:
      booking.commissionableValue?.greaterThan(0) && booking.estCommission
        ? booking.estCommission
            .dividedBy(booking.commissionableValue)
            .times(100)
            .toDecimalPlaces(2, Decimal.ROUND_HALF_UP)
            .toNumber()
        : 0,
    commissionStatus: getBookingCommissionInvoiceStatus(booking),
    bookingStatus: booking.voidedAt
      ? BookingStatus.VOIDED
      : booking.canceledAt
        ? BookingStatus.CANCELED
        : BookingStatus.ACTIVE,
    commissionPaidAt: booking.commissions
      .sort(
        (a, b) =>
          b.commissionGroup.receivedDate.getTime() -
          a.commissionGroup.receivedDate.getTime(),
      )[0]
      ?.commissionGroup.receivedDate.toISOString(),
    agencyUser: agencyUserToDto(booking.trip.agencyUser),
    receivedCommission: receivedCommission.toNumber(),
    canceledAt: booking.canceledAt?.toISOString(),
    originalTotalHome: originalTotalHome.toNumber(),
    originalTotal: originalTotal.toNumber(),
    originalCommissionableValueHome:
      originalCommissionableValueHome?.toNumber(),
    originalCommissionableValue: originalCommissionableValue?.toNumber(),
    originalEstCommissionHome: originalEstCommissionHome?.toNumber(),
    originalEstCommission: originalEstCommission?.toNumber(),
    currency: booking.currency ?? undefined,
    exchangeRate: booking.exchangeRate.toNumber(),
    exchangeRateLockedAt:
      booking.exchangeRateLockedAt?.toISOString() ?? undefined,
    splits: splits ?? undefined,
    expenses: expenses ?? undefined,
    additionalClients: booking.bookingClients.map((bc) =>
      clientToDto(bc.client),
    ),
    subject: booking.subject ?? undefined,
    invoiceRemarks: booking.invoiceRemarks ?? undefined,
    taxesAndFees: booking.taxesAndFees
      ? booking.taxesAndFees.toNumber()
      : booking.total &&
          booking.baseCommissionableValue &&
          booking.total
            .minus(booking.baseCommissionableValue)
            .greaterThanOrEqualTo(0)
        ? booking.total.minus(booking.baseCommissionableValue).toNumber()
        : undefined,
    taxesAndFeesHome: booking.taxesAndFeesHome
      ? booking.taxesAndFeesHome.toNumber()
      : undefined,
    markupPercent: booking.markupPercent
      ? booking.markupPercent.toNumber()
      : undefined,
    markup: booking.markup ? booking.markup.toNumber() : undefined,
    markupHome: booking.markupHome ? booking.markupHome.toNumber() : undefined,
    totalWithMarkup: booking.totalWithMarkup
      ? booking.totalWithMarkup.toNumber()
      : undefined,
    totalWithMarkupHome: booking.totalWithMarkupHome
      ? booking.totalWithMarkupHome.toNumber()
      : undefined,
    useMarkup: booking.markup !== null,
    // When we parse a Sabre trip PNR, both exchange docs and fare difference docs will have
    // originalBookingId so we have to check if bookingType has not been set or is explicitly set to 'Exchange'
    isExchange: booking.originalBookingId
      ? booking.bookingType === InterfaceType.EXCHANGE || !booking.bookingType
      : false,
    isFareDifference: booking.bookingType === InterfaceType.FARE_DIFFERENCE,
    isCreditDebitMemo: booking.bookingType === InterfaceType.CREDIT_DEBIT_MEMO,
    originalBookingId: booking.originalBookingId ?? undefined,
    isArc,
    attachments: booking.attachments?.length
      ? booking.attachments.map(attachmentToDto)
      : undefined,
    issuedAt: booking.issuedAt?.toISOString() ?? undefined,
    documentType: booking.documentType ?? undefined,
    submitTo: booking.submitTo ?? undefined,
    flownCarrier: booking?.airSegments?.length
      ? booking.airSegments?.map((as) => as.airlineCode).join('/')
      : undefined,
    itinerary: mapAirSegmentsToItinerary(booking.airSegments),
    itineraryType:
      (booking.itineraryType as ItineraryType) ??
      getItineraryTypeFromSegments(booking.airSegments),
    supplierType: (booking.supplierType ??
      booking.supplier.type) as SupplierType,
    ancillaryServicesCategory: booking.ancillaryServicesCategory ?? undefined,
    voidedAt: booking.voidedAt?.toISOString() ?? undefined,
    fareDifferenceDocument: fareDifferenceDocumentToDto(
      booking.fareDifferenceDocument,
    ),
    passengerName: booking.passengerName ?? undefined,
    pnr: booking.pnr ?? undefined,
    pcc: booking.pcc ?? undefined,
    bookingType: booking.bookingType
      ? (booking.bookingType as InterfaceType)
      : undefined,
    penalty: booking?.penalty?.toNumber() ?? undefined,
    commissionPenalty: booking?.commissionPenalty?.toNumber() ?? undefined,
    refundedAmount: refundTotals.refundedAmount?.toNumber() ?? undefined,
    refundCommissionableValue:
      refundTotals.refundCommissionableValue?.toNumber() ?? undefined,
    refundEstCommission:
      refundTotals.refundEstCommission?.toNumber() ?? undefined,
    maxRefundAmountHome: maxRefundAmountHome.toNumber(),
    maxRefundAmount: maxRefundAmount.toNumber(),
    refundPenalty: refundTotals.refundPenalty?.toNumber() ?? undefined,
    refundCommissionPenalty:
      refundTotals.refundCommissionPenalty?.toNumber() ?? undefined,
    refundPaymentMethod: sampleRefund?.deletedAt
      ? undefined
      : (sampleRefund?.paymentMethod as PaymentMethod) ?? undefined,
    refundBillPaymentMethodId: sampleRefund?.deletedAt
      ? undefined
      : sampleRefund?.billPaymentMethodId ?? undefined,
    refundReceivedDate: sampleRefund?.deletedAt
      ? undefined
      : sampleRefund?.refundReceivedDate
        ? moment.utc(sampleRefund.refundReceivedDate).format('YYYY-MM-DD')
        : undefined,
    isVoidable: unvoidableReasons.length === 0,
    unvoidableReasons,
  };
};

export enum BookingType {
  COMMISSIONABLE = 'COMMISSIONABLE',
  NET = 'NET',
  CLIENT_PAID = 'CLIENT_PAID',
  AGENCY_PAID = 'AGENCY_PAID',
}

export const getBookingCommissionInvoiceStatus = (
  booking: Pick<Booking, 'commissionDue'> & {
    commissions: unknown[];
  },
): InvoiceStatus => {
  // TODO: see TS-4147 for update to use estCommission or originalEstCommission
  if (!booking.commissionDue || booking.commissions.length > 0) {
    return InvoiceStatus.PAID;
  }
  return booking.commissionDue < new Date()
    ? InvoiceStatus.LATE
    : InvoiceStatus.SENT;
};

export const bookingStatusToString = (status: InvoiceStatus | null) => {
  let text = '';

  switch (status) {
    case 'PAID': {
      text = 'Paid';
      break;
    }
    case 'LATE': {
      text = 'Past Due';
      break;
    }
    case 'SENT': {
      text = 'Owed';
      break;
    }
    // [CG] This won't come back from the API
    // case 'PENDING': {
    //   text = 'Sending soon'; //todo: add date
    //   bg = 'accent.gray.main';
    //   color = 'text.primary';
    //   break;
    // }
    default:
      return '';
  }

  return text;
};

export const BookingPaginatedRequestDto = getPaginatedRequestDto(
  z.enum([
    'createdAt',
    'supplier',
    'client',
    'corporateGroup',
    'category',
    'trip',
    'total',
    'totalHome',
    'estCommission',
    'estCommissionHome',
    'checkIn',
    'checkOut',
    'commissionDue',
    'commissionStatus',
    'agencyUser',
    'confirmationNumber',
  ]),
  z.enum(['supplier', 'client', 'trip']),
).extend({
  statusFilter: z.nativeEnum(InvoiceStatus).optional(),
  whatFor: z.enum(['bookings', 'commissions']).optional(),
  daysToSearch: z.coerce.number().optional(),
  hideNonCommissionable: z
    .string()
    .transform((value) => value === 'true')
    .optional(),
  queryOnly: z.enum(['confirmationNumber']).optional(),
});
export type BookingPaginatedRequestDto = z.infer<
  typeof BookingPaginatedRequestDto
>;
export type BookingSort = z.infer<typeof BookingPaginatedRequestDto>['sort'];

export const BookingPaginatedResponseDto =
  createPaginatedResponseDto<BookingResponseDto>(BookingResponseDto);
export type BookingPaginatedResponseDto = z.infer<
  typeof BookingPaginatedResponseDto
>;

export enum BookingMappingType {
  VIRTUOSO = 'VIRTUOSO',
  FARE_DIFFERENCE_DOCUMENT_NUMBER = 'FARE_DIFFERENCE_DOCUMENT_NUMBER',
  ARC_NUMBER = 'ARC_NUMBER',
  RESCARDNO = 'RESCARDNO',
  RESERVATIONNO = 'RESERVATIONNO',
  BOOKINGNO = 'BOOKINGNO',
  INVOICENO = 'INVOICENO',
  PROVIDERNO = 'PROVIDERNO',
  SPOTNANA = 'SPOTNANA',
}

export const SupplierBookingForPaymentRequestDto = z.object({
  estCommission: z.number().optional(),
  commissionDueDate: z.string().regex(dateRegex).optional(),
});
export type SupplierBookingForPaymentRequestDto = z.infer<
  typeof SupplierBookingForPaymentRequestDto
>;

export const SupplierBookingForPaymentsPaginatedRequestDto = z.object({
  query: trimmedString().optional(),
  page: int(z.number().default(0)),
  pageSize: int(z.number().gte(0).max(1000000).default(10)),
  receivedCommission: float(z.number().optional()),
  commissionReceivedDate: z.string().regex(dateRegex).optional(),
  confirmationNumber: trimmedString().optional(),
  clientId: z.string().uuid().optional(),
  advisorId: z.string().uuid().optional(),
  total: float(z.number().optional()),
  commissionableValue: float(z.number().optional()),
  checkIn: z.string().regex(dateRegex).optional(),
  checkOut: z.string().regex(dateRegex).optional(),
  notes: trimmedString().optional(),
  advisorName: trimmedString().optional(),
  clientName: trimmedString().optional(),
  supplierName: trimmedString().optional(),
});
export type SupplierBookingForPaymentsPaginatedRequestDto = z.infer<
  typeof SupplierBookingForPaymentsPaginatedRequestDto
>;

export const SupplierBookingPaginatedRequestDto =
  BookingPaginatedRequestDto.extend({
    confirmationNumber: trimmedString().optional(),
  });

export type SupplierBookingPaginatedRequestDto = z.infer<
  typeof SupplierBookingPaginatedRequestDto
>;
export type SupplierBookingSort = z.infer<
  typeof SupplierBookingPaginatedRequestDto
>['sort'];

export const SupplierBookingPaginatedResponseDto =
  createPaginatedResponseDto(BookingResponseDto);
export type SupplierBookingPaginatedResponseDto = z.infer<
  typeof SupplierBookingPaginatedResponseDto
>;

export const BookingScores = z.object({
  confirmationNumberScore: z.number(),
  clientNameScore: z.number(),
  supplierNameScore: z.number(),
  commissionableValueScore: z.number(),
  amountScore: z.number(),
  totalScore: z.number(),
  checkInScore: z.number(),
  checkOutScore: z.number(),
  advisorNameScore: z.number(),
  alreadyMatchedScore: z.number(),
  score: z.number(),
  isMatch: z.boolean(),
});
export type BookingScores = z.infer<typeof BookingScores>;

export const ScoredBookingResponseDto = z.object({
  id: z.string().uuid(),
  confirmationNumber: trimmedString(),
  total: z.number(),
  totalHome: z.number(),
  commissionStatus: z.nativeEnum(InvoiceStatus),
  commissionPaidAt: z.string().regex(isoDateRegex).optional(),
  estCommission: z.number().optional(),
  estCommissionHome: z.number().optional(),
  commissionableValueHome: z.number().optional(),
  commissionDue: z.string().regex(isoDateRegex).optional(),
  isCommissionable: z.boolean(),
  isConfirmed: z.boolean().optional(),
  canceledAt: z.string().regex(isoDateRegex).optional(),
  corporateGroup: z
    .object({
      name: trimmedString(),
    })
    .optional(),
  passengerName: trimmedString().optional(),
  additionalClients: z.array(
    z.object({
      id: z.string().uuid(),
      name: trimmedString(),
    }),
  ),
  client: z
    .object({
      id: z.string().uuid(),
      name: trimmedString(),
      firstName: trimmedString(),
      lastName: trimmedString(),
    })
    .optional(),
  checkIn: z.string().regex(isoDateRegex),
  checkOut: z.string().regex(isoDateRegex),
  notes: trimmedString().optional(),
  trip: z.object({
    id: z.string().uuid(),
    name: trimmedString(),
    agencyUser: z.object({
      firstName: trimmedString(),
      lastName: trimmedString(),
    }),
    additionalClients: z.array(
      z.object({
        id: z.string().uuid(),
        name: trimmedString(),
        firstName: trimmedString(),
        lastName: trimmedString(),
      }),
    ),
    destinations: z.array(
      z.object({
        id: z.number(),
        name: trimmedString(),
      }),
    ),
  }),
  agencyUser: z
    .object({
      firstName: trimmedString(),
      lastName: trimmedString(),
    })
    .optional(),
  supplier: z.object({
    name: trimmedString(),
  }),
  scores: BookingScores,
});
export type ScoredBookingResponseDto = z.infer<typeof ScoredBookingResponseDto>;

export const scoredBookingToDto = (
  booking: ScoredBookingWithInclude,
  scores: BookingScores,
): ScoredBookingResponseDto => {
  return {
    id: booking.id,
    confirmationNumber: booking.confirmationNumber,
    total: booking.total.toNumber(),
    totalHome: booking.totalHome.toNumber(),
    commissionStatus: getBookingCommissionInvoiceStatus(booking),
    commissionPaidAt: booking.commissions
      .sort(
        (a, b) =>
          b.commissionGroup.receivedDate.getTime() -
          a.commissionGroup.receivedDate.getTime(),
      )[0]
      ?.commissionGroup.receivedDate.toISOString(),
    estCommission: booking.estCommission?.toNumber(),
    estCommissionHome: booking.estCommissionHome?.toNumber(),
    commissionableValueHome: booking.commissionableValueHome?.toNumber(),
    commissionDue: booking.commissionDue?.toISOString(),
    isCommissionable: Boolean(booking.commissionDue),
    isConfirmed: booking.isConfirmed,
    canceledAt: booking.canceledAt?.toISOString(),
    corporateGroup: booking.corporateGroup ?? undefined,
    passengerName: booking.passengerName ?? undefined,
    additionalClients: booking.bookingClients.map(({ client }) => ({
      id: client.id,
      firstName: client.firstName,
      lastName: client.lastName,
      name: `${client.firstName} ${client.lastName}`,
    })),
    client: booking.client
      ? {
          id: booking.client.id,
          name: `${booking.client.firstName} ${booking.client.lastName}`,
          firstName: booking.client.firstName,
          lastName: booking.client.lastName,
        }
      : undefined,
    checkIn: booking.checkIn.toISOString(),
    checkOut: booking.checkOut.toISOString(),
    notes: booking.notes ?? undefined,
    trip: {
      id: booking.trip.id,
      name: booking.trip.name,
      agencyUser: {
        firstName: booking.trip.agencyUser.user.firstName,
        lastName: booking.trip.agencyUser.user.lastName,
      },
      additionalClients: booking.trip.tripClients.map(({ client }) => ({
        id: client.id,
        name: `${client.firstName} ${client.lastName}`,
        firstName: client.firstName,
        lastName: client.lastName,
      })),
      destinations: booking.trip.tripDestinations.map((td) => ({
        id: td.geoName.id,
        name: td.geoName.name,
      })),
    },
    agencyUser: {
      firstName: booking.trip.agencyUser.user.firstName,
      lastName: booking.trip.agencyUser.user.lastName,
    },
    supplier: {
      name: booking.supplier.name,
    },
    scores,
  };
};

export const SupplierContactCreateRequestDto = z.object({
  name: trimmedString(),
  title: trimmedString().optional(),
  email: z.string().email(),
  phone: trimmedString().optional(),
  primary: z.boolean(),
});
export type SupplierContactCreateRequestDto = z.infer<
  typeof SupplierContactCreateRequestDto
>;

export const SupplierContactResponseDto = z.object({
  id: z.string().uuid(),
  name: trimmedString(),
  title: trimmedString().optional(),
  email: trimmedString(),
  phone: trimmedString().optional(),
  primary: z.boolean(),
});
export type SupplierContactResponseDto = z.infer<
  typeof SupplierContactResponseDto
>;

export const supplierContactToSupplierContactDto = (
  contact: SupplierContact,
): SupplierContactResponseDto => {
  return {
    id: contact.id,
    name: contact.name,
    title: contact.title ?? undefined,
    email: contact.email,
    phone: contact.phone ?? undefined,
    primary: contact.primary,
  };
};

export const SupplierContactRequestDto = z.object({
  supplierContactId: z.string().uuid(),
});
export type SupplierContactRequestDto = z.infer<
  typeof SupplierContactRequestDto
>;

export const SupplierContactUpdateRequestDto = z.object({
  name: trimmedString(),
  title: trimmedString().optional(),
  email: z.string().email(),
  phone: trimmedString().optional(),
  primary: z.boolean(),
});
export type SupplierContactUpdateRequestDto = z.infer<
  typeof SupplierContactUpdateRequestDto
>;

export const SupplierContactPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['id', 'name', 'title', 'email', 'phone', 'primary']),
  z.enum(['name', 'title', 'email', 'phone']),
);
export type SupplierContactPaginatedRequestDto = z.infer<
  typeof SupplierContactPaginatedRequestDto
>;

export const SupplierContactPaginatedResponseDto = createPaginatedResponseDto(
  SupplierContactResponseDto,
);
export type SupplierContactPaginatedResponseDto = z.infer<
  typeof SupplierContactPaginatedResponseDto
>;

export const CardCreateRequestDto = z.object({
  spendLimitCents: z.number().optional(),
});
export type CardCreateRequestDto = z.infer<typeof CardCreateRequestDto>;

export const CardResponseDto = z.object({
  lastFour: trimmedString(),
  spendLimitCents: z.number(),
  spendCents: z.number(),
});
export type CardResponseDto = z.infer<typeof CardResponseDto>;

export const CardToCardResponseDto = (
  card: Card,
  spendCents: number,
): CardResponseDto => ({
  ...card,
  spendLimitCents: card.spendLimitCents.toNumber(),
  spendCents,
});

export const CardUpdateRequestDto = z.object({
  spendLimitCents: z.number().optional(),
});
export type CardUpdateRequestDto = z.infer<typeof CardUpdateRequestDto>;

export const CardEmbedResponseDto = z.object({
  url: trimmedString(),
});
export type CardEmbedResponseDto = z.infer<typeof CardEmbedResponseDto>;

export const UserProfileResponseDto = z.object({
  id: z.string().uuid(),
  agencyUserId: z.string().uuid(),
  firstName: trimmedString(),
  lastName: trimmedString(),
  email: trimmedString(),
  profileImageUrl: z.string().optional().nullable(),
  passwordEnabled: z.boolean(),
  agencyColor: trimmedString(),
  statementsLockedAt: z.string().regex(isoDateRegex).optional(),
});
export type UserProfileResponseDto = z.infer<typeof UserProfileResponseDto>;

export function userToUserProfileResponseDto(
  user: User & { agencyUsers: AgencyUser[] },
): UserProfileResponseDto {
  const firstAgencyUser = user.agencyUsers[0];
  return {
    id: user.id,
    agencyUserId: firstAgencyUser?.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    profileImageUrl: user.profileImageUrl ?? null,
    passwordEnabled: user.passwordEnabled ?? false,
    agencyColor: firstAgencyUser
      ? generateColorForId(firstAgencyUser.agencyId)
      : '#000000',
    statementsLockedAt: user.statementsLockedAt?.toISOString(),
  };
}

export const UserProfileUpdateRequestDto = z.object({
  firstName: trimmedString().optional(),
  lastName: trimmedString().optional(),
  email: z.string().email().optional(),
  profileImageUrl: z.string().optional().nullable(),
});
export type UserProfileUpdateRequestDto = z.infer<
  typeof UserProfileUpdateRequestDto
>;

export const ContextAgencyDto = z.object({
  agency: AgencyResponseDto,
  parentAgencyId: z.string().uuid().optional(),
  users: z.array(ProfileResponseDto),
  color: z.string().regex(hexColorRegex),
  singleUserAgency: z.boolean(),
});
export type ContextAgencyDto = z.infer<typeof ContextAgencyDto>;

const Colors = [
  '#C51162',
  '#00C853',
  '#AA00FF',
  '#00BFA5',
  '#6200EA',
  '#FF6D00',
  '#304FFE',
  '#00B8D4',
];

export function generateColorForId(id?: string): string {
  return id
    ? Colors[
        Number.parseInt(
          crypto.createHash('md5').update(id).digest('hex').substring(0, 8),
          16,
        ) % Colors.length
      ]
    : '#000';
}

export const agencyToContextAgencyDto = (
  agency: Agency & { agencyUsers: (AgencyUser & { user: User })[] },
): ContextAgencyDto => {
  const employeeMode =
    agency.agencyUsers.length === 1 &&
    agency.agencyUsers[0].role === AgencyUserRole.EMPLOYEE;
  return {
    agency: agencyToDto(agency),
    parentAgencyId: agency.parentAgencyId ?? undefined,
    users: agency.agencyUsers.map((au) => agencyUserToProfileResponseDto(au)),
    color: generateColorForId(agency.id),
    singleUserAgency: agency.agencyUsers.length === 1 && !employeeMode,
  };
};

export const ContextTokenRequestDto = z.object({
  agencyId: z.string().uuid(),
  userId: z.string().uuid().optional(),
});
export type ContextTokenRequestDto = z.infer<typeof ContextTokenRequestDto>;

export const ContextTokenResponseDto = z.object({
  token: trimmedString(),
});
export type ContextTokenResponseDto = z.infer<typeof ContextTokenResponseDto>;

export const ContextToken = z.object({
  agencyId: z.string().uuid(),
  userId: z.string().uuid().optional(),
  isAdminContext: z.boolean().optional(),
  isEmployeeContext: z.boolean().optional(),
});
export type ContextToken = z.infer<typeof ContextToken>;

export const ContextTokenDto = z.object({
  userId: z.string().uuid(),
  ctx: ContextToken,
});
export type ContextTokenDto = z.infer<typeof ContextTokenDto>;

export const ContextCookieDto = z.object({
  agencyId: z.string().uuid(),
  userId: z.string().uuid().optional(),
  token: trimmedString(),
});
export type ContextCookieDto = z.infer<typeof ContextCookieDto>;

export const PickerResponseDto = z.object({
  defaultCtx: ContextCookieDto.optional(),
  data: z.array(ContextAgencyDto),
});
export type PickerResponseDto = z.infer<typeof PickerResponseDto>;

export enum IdentifierType {
  IATA = 'IATA',
  CLIA = 'CLIA',
  VIRTUOSO = 'VIRTUOSO',
}

export const IdentifierCreateRequestDto = z.object({
  identifierNumber: trimmedString(),
  type: z.nativeEnum(IdentifierType),
});
export type IdentifierCreateRequestDto = z.infer<
  typeof IdentifierCreateRequestDto
>;

export const IdentifierUpdateRequestDto = IdentifierCreateRequestDto.extend({});
export type IdentifierUpdateRequestDto = z.infer<
  typeof IdentifierUpdateRequestDto
>;

export const IdentifierResponseDto = z.object({
  id: z.string().uuid(),
  identifierNumber: trimmedString(),
  organizationName: trimmedString(),
  type: z.nativeEnum(IdentifierType),
});
export type IdentifierResponseDto = z.infer<typeof IdentifierResponseDto>;

export function identifierToDto(
  identifier: Identifier & { organization: Organization },
): IdentifierResponseDto {
  return {
    id: identifier.id,
    identifierNumber: identifier.identifierNumber,
    organizationName: identifier.organization.name,
    type: identifier.type as IdentifierType,
  };
}

export const IdentifierPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['identifierNumber', 'organizationName', 'type']),
  z.enum(['identifierNumber', 'organizationName', 'type']),
);
export type IdentifierPaginatedRequestDto = z.infer<
  typeof IdentifierPaginatedRequestDto
>;

export const IdentifierPaginatedResponseDto = createPaginatedResponseDto(
  IdentifierResponseDto,
);
export type IdentifierPaginatedResponseDto = z.infer<
  typeof IdentifierPaginatedResponseDto
>;

export const UserAgencySplitResponseDto = z.object({
  agencyId: z.string().uuid(),
  split: z.number(),
});

export type UserAgencySplitResponseDto = z.infer<
  typeof UserAgencySplitResponseDto
>;

export const CurrencyDto = z.object({
  id: trimmedString(),
  code: trimmedString(),
  name: trimmedString(),
  symbol: trimmedString(),
});
export type CurrencyDto = z.infer<typeof CurrencyDto>;

export const UserInfoResponseDto = z.object({
  isOrgUser: z.boolean(),
  isOrgAssistant: z.boolean(),
  isOrgAdmin: z.boolean(),
  agencySplits: z.array(UserAgencySplitResponseDto),
  agencyUserId: z.string().uuid().optional(),
  context: z
    .object({
      agencyId: z.string().uuid(),
      userId: z.string().uuid().optional(),
      agencyUserId: z.string().uuid().optional(),
      isAgencyView: z.boolean(),
      isAdminContext: z.boolean(),
      isSelectedInRealm: z.boolean(),
      isSinglePersonAgency: z.boolean(),
    })
    .optional(),
  isAgencyOwner: z.boolean(),
  agencyId: z.string().uuid().optional(),
  isSinglePersonAgency: z.boolean(),
  role: z.nativeEnum(AgencyUserRole),
  token: trimmedString(),
  statementsEnabled: z.boolean(),
  consortium: z.string().nullable().optional(),
  homeCurrency: CurrencyDto,
  organization: OrganizationResponseMinimalDto,
  paidOutByAgencyId: z.string().uuid().optional(),
});
export type UserInfoResponseDto = z.infer<typeof UserInfoResponseDto>;

export enum CommissionGroupPaymentMethod {
  CHECK = 'CHECK',
  WIRE = 'WIRE',
  ACH = 'ACH',
}

export const CommissionGroupCreateRequestDto = z.object({
  supplierId: z.string().uuid(),
  type: z.nativeEnum(CommissionGroupPaymentMethod),
  referenceNum: trimmedString(),
  notes: trimmedString().optional(),
  totalUsd: z.number(),
  receivedDate: z.string().regex(isoDateRegex),
  commissions: z.array(CommissionCreateRequestDto),
  clearinghouseFee: z.number().optional(),
  currency: trimmedString(),
  supplierPaymentMethodId: z.string().uuid().optional(),
  pccGroupId: z.string().uuid().optional(),
});
export type CommissionGroupCreateRequestDto = z.infer<
  typeof CommissionGroupCreateRequestDto
>;

export const CommissionGroupPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['supplierName', 'referenceNum', 'receivedDate', 'totalUsd']),
  z.enum(['supplierName', 'referenceNum']),
);
export type CommissionGroupPaginatedRequestDto = z.infer<
  typeof CommissionGroupPaginatedRequestDto
>;

export const CommissionGroupResponseDto = z.object({
  id: z.string().uuid(),
  supplier: SupplierResponseDto,
  type: z.nativeEnum(CommissionGroupPaymentMethod),
  referenceNum: trimmedString(),
  notes: trimmedString().optional(),
  totalUsd: z.number(),
  receivedDate: z.string().regex(isoDateRegex),
  commissions: z.array(CommissionResponseDto),
  clearinghouseFee: z.number().optional(),
  locked: z.boolean(),
  currency: trimmedString(),
  supplierPaymentMethodId: z.string().uuid().optional(),
  supplierPaymentMethod: AccountingPaymentMethodResponseDto.optional(),
});
export type CommissionGroupResponseDto = z.infer<
  typeof CommissionGroupResponseDto
>;

export const CommissionGroupListItemResponseDto =
  CommissionGroupResponseDto.omit({ commissions: true }).extend({
    commissionCount: z.number(),
    matchedCommissionCount: z.number(),
    totalHome: z.number(),
  });
export type CommissionGroupListItemResponseDto = z.infer<
  typeof CommissionGroupListItemResponseDto
>;

export const CommissionGroupPaginatedResponseDto = createPaginatedResponseDto(
  CommissionGroupListItemResponseDto,
);
export type CommissionGroupPaginatedResponseDto = z.infer<
  typeof CommissionGroupPaginatedResponseDto
>;

export const commissionToDto = (
  commission: Commission & {
    adjustments: CommissionAdjustment[];
  },
  booking:
    | (Booking & {
        trip: Trip & {
          agencyUser: AgencyUser & {
            agency: Agency;
            user: User;
          };
        };
        supplier: Supplier;
      })
    | null,
  commissionGroup: CommissionGroup & {
    supplier: Supplier;
  },
  forfeitThresholdDays: number | null,
): CommissionResponseDto => ({
  id: commission.id,
  publicId: commission.publicId ?? '',
  bookingId: commission.bookingId ?? undefined,
  createdAt: commission.createdAt.toISOString(),
  amountUsd: commission.amountUsd.toNumber(),
  amountHome: commission.amountHome.toNumber(),
  confirmationNumber: commission.confirmationNumber ?? undefined,
  adjustments: commission.adjustments.map((adjustment) => ({
    id: adjustment.id,
    amountUsd: adjustment.amountUsd.toNumber(),
    notes: adjustment.notes ?? undefined,
    createdAt: adjustment.createdAt.toISOString(),
    type: adjustment.type as CommissionAdjustmentType,
  })),
  agencyUser: booking?.trip.agencyUser
    ? agencyUserToDto(booking.trip.agencyUser)
    : undefined,
  receivedDate: commissionGroup.receivedDate.toISOString(),
  status: getCommissionStatus({
    bookingId: commission.bookingId,
    forfeitThresholdDays,
    receivedDate: commissionGroup.receivedDate,
  }),
  referenceNumber: commissionGroup.referenceNum,
  supplierName: booking?.supplier.name ?? commissionGroup.supplier.name,
  isLegacyPayment: commission.accountingIgnore ?? false,
  currency: commission.currency ?? undefined,
  exchangeRate: commission.exchangeRate.toNumber(),
  exchangeRateLockedAt:
    commission.exchangeRateLockedAt?.toISOString() ?? undefined,

  clientNameActual: commission.clientNameActual ?? undefined,
  advisorNameActual: commission.advisorNameActual ?? undefined,
  supplierNameActual: commission.supplierNameActual ?? undefined,
  commissionableValueActual:
    commission.commissionableValueActual?.toNumber() ?? undefined,
  totalActual: commission.totalActual?.toNumber() ?? undefined,
  checkInActual: commission.checkInActual
    ? moment.utc(commission.checkInActual).format('YYYY-MM-DD')
    : undefined,
  checkOutActual: commission.checkOutActual
    ? moment.utc(commission.checkOutActual).format('YYYY-MM-DD')
    : undefined,
  notes: commission.notes ?? undefined,
  sequenceNumber: commission.sequenceNumber ?? undefined,
  accountingIgnore: commission.accountingIgnore ?? false,
});

export const commissionGroupToListItemDto = (
  commissionGroup: CommissionGroup & {
    supplier: Supplier & {
      organization: Organization | null;
      tags: (SupplierTag & { tag: Tag })[];
    };
    commissions: {
      id: string;
      bookingId: string | null;
      amountHome: Decimal;
    }[];
    supplierPaymentMethod: SupplierPaymentMethod | null;
  },
): CommissionGroupListItemResponseDto => {
  return {
    id: commissionGroup.id,
    supplier: supplierToDto(commissionGroup.supplier),
    type: commissionGroup.type as CommissionGroupPaymentMethod,
    referenceNum: commissionGroup.referenceNum,
    notes: commissionGroup.notes ?? undefined,
    totalUsd: commissionGroup.totalUsd.toNumber(),
    totalHome: commissionGroup.commissions
      .reduce(
        (acc, commission) => acc.plus(commission.amountHome),
        new Decimal(0),
      )
      .toNumber(),
    receivedDate: commissionGroup.receivedDate.toISOString(),
    clearinghouseFee: commissionGroup.clearinghouseFee?.toNumber() ?? undefined,
    locked: moment().diff(commissionGroup.createdAt, 'hours') > 24,
    currency: commissionGroup.currency,
    supplierPaymentMethodId:
      commissionGroup.supplierPaymentMethodId ?? undefined,
    supplierPaymentMethod: commissionGroup.supplierPaymentMethod
      ? {
          id: commissionGroup.supplierPaymentMethod.id,
          name: commissionGroup.supplierPaymentMethod.name,
          type: commissionGroup.supplierPaymentMethod.type as PaymentMethod,
        }
      : undefined,
    commissionCount: commissionGroup.commissions.length,
    matchedCommissionCount: commissionGroup.commissions.filter(
      (c) => c.bookingId,
    ).length,
  };
};

export const commissionGroupToDto = (
  commissionGroup: CommissionGroup & {
    supplier: Supplier & {
      organization: Organization | null;
      tags: (SupplierTag & { tag: Tag })[];
    };
    commissions: (Commission & {
      adjustments: CommissionAdjustment[];
      booking:
        | (Booking & {
            trip: Trip & {
              agencyUser: AgencyUser & {
                agency: Agency;
                user: User;
              };
            };
            supplier: Supplier;
          })
        | null;
    })[];
    supplierPaymentMethod: SupplierPaymentMethod | null;
  },
  forfeitThresholdDays: number | null,
): CommissionGroupResponseDto => {
  return {
    id: commissionGroup.id,
    supplier: supplierToDto(commissionGroup.supplier),
    type: commissionGroup.type as CommissionGroupPaymentMethod,
    referenceNum: commissionGroup.referenceNum,
    notes: commissionGroup.notes ?? undefined,
    totalUsd: commissionGroup.totalUsd.toNumber(),
    receivedDate: commissionGroup.receivedDate.toISOString(),
    clearinghouseFee: commissionGroup.clearinghouseFee?.toNumber() ?? undefined,
    commissions: commissionGroup.commissions.map((commission) =>
      commissionToDto(
        commission,
        commission.booking,
        commissionGroup,
        forfeitThresholdDays,
      ),
    ),
    locked: moment().diff(commissionGroup.createdAt, 'hours') > 24,
    currency: commissionGroup.currency,
    supplierPaymentMethodId:
      commissionGroup.supplierPaymentMethodId ?? undefined,
    supplierPaymentMethod: commissionGroup.supplierPaymentMethod
      ? {
          id: commissionGroup.supplierPaymentMethod.id,
          name: commissionGroup.supplierPaymentMethod.name,
          type: commissionGroup.supplierPaymentMethod.type as PaymentMethod,
        }
      : undefined,
  };
};

export const CommissionGroupRequestDto = z.object({
  commissionGroupId: z.string().uuid(),
});
export type CommissionGroupRequestDto = z.infer<
  typeof CommissionGroupRequestDto
>;

export const CommissionAdjustmentUpdateRequestDto =
  CommissionAdjustmentCreateRequestDto.extend({
    id: z.string().uuid().optional(),
  });
export type CommissionAdjustmentUpdateRequestDto = z.infer<
  typeof CommissionAdjustmentUpdateRequestDto
>;

export const CommissionUpdateRequestDto = CommissionCreateRequestDto.extend({
  id: z.string().uuid().optional(),
  adjustments: z.array(CommissionAdjustmentUpdateRequestDto),
});
export type CommissionUpdateRequestDto = z.infer<
  typeof CommissionUpdateRequestDto
>;

export const CommissionGroupUpdateRequestDto =
  CommissionGroupCreateRequestDto.extend({
    commissions: z.array(CommissionUpdateRequestDto),
  });
export type CommissionGroupUpdateRequestDto = z.infer<
  typeof CommissionGroupUpdateRequestDto
>;

export const StatementCommissionResponseDto = z.object({
  amountUsd: z.number(),
  commission: CommissionResponseDto,
});
export type StatementCommissionResponseDto = z.infer<
  typeof StatementCommissionResponseDto
>;

export const StatementSummaryListRequestDto = getPaginatedRequestDto(
  z.enum(['recipient', 'payout']),
  z.enum(['recipient']),
).extend({
  startDate: z.string().regex(isoDateRegex),
  endDate: z.string().regex(isoDateRegex),
  showInactive: z
    .string()
    .transform((v) => v?.toLowerCase() === 'true')
    .optional(),
});
export type StatementSummaryListRequestDto = z.infer<
  typeof StatementSummaryListRequestDto
>;

export const AgencyStatementSummaryListRequestDto =
  StatementSummaryListRequestDto.extend({
    agencyId: z.string(),
  });
export type AgencyStatementSummaryListRequestDto = z.infer<
  typeof AgencyStatementSummaryListRequestDto
>;

export const StatementSummaryResponseDto = z.object({
  startDate: z.string().regex(isoDateRegex),
  endDate: z.string().regex(isoDateRegex),
  payout: z.number(),
  isClosed: z.boolean(),
  route: z.string(),
});
export type StatementSummaryResponseDto = z.infer<
  typeof StatementSummaryResponseDto
>;

const BaseStatementSummaryPaginatedResponseDto = createPaginatedResponseDto(
  StatementSummaryResponseDto,
);

export const StatementSummaryPaginatedResponseDto =
  BaseStatementSummaryPaginatedResponseDto.extend({
    meta: BaseStatementSummaryPaginatedResponseDto.shape.meta.extend({
      payoutsTotal: z.number(),
    }),
  });

export type StatementSummaryPaginatedResponseDto = z.infer<
  typeof StatementSummaryPaginatedResponseDto
>;

export const VirtuosoFootprintRequestDto = z.object({
  sinceHoursAgo: trimmedString().optional(),
  batchSize: trimmedString().optional(),
});
export type VirtuosoFootprintRequestDto = z.infer<
  typeof VirtuosoFootprintRequestDto
>;

export const TravelLeadersFootprintRequestDto = z.object({
  sinceHoursAgo: trimmedString().optional(),
});
export type TravelLeadersFootprintRequestDto = z.infer<
  typeof TravelLeadersFootprintRequestDto
>;

export const GraspRequestDto = z.object({
  start: z.coerce.date(),
  end: z.coerce.date(),
  batchSize: trimmedString().optional(),
});
export type GraspRequestDto = z.infer<typeof GraspRequestDto>;

export const AgencyStatementSummaryResponseDto = z.object({
  recipient: z.object({
    agency: z
      .object({
        id: z.string(),
        name: trimmedString(),
        isActive: z.boolean(),
        isLocked: z.boolean(),
      })
      .optional(),
    user: z
      .object({
        id: z.string(),
        name: trimmedString(),
        isActive: z.boolean(),
        isLocked: z.boolean(),
      })
      .optional(),
    color: trimmedString(),
  }),
  payout: z.number(),
});
export type AgencyStatementSummaryResponseDto = z.infer<
  typeof AgencyStatementSummaryResponseDto
>;

const BaseAgencyStatementSummaryPaginatedResponseDto =
  createPaginatedResponseDto(AgencyStatementSummaryResponseDto);

export const AgencyStatementSummaryPaginatedResponseDto =
  BaseAgencyStatementSummaryPaginatedResponseDto.extend({
    meta: BaseAgencyStatementSummaryPaginatedResponseDto.shape.meta.extend({
      payoutsTotal: z.number(),
    }),
  });
export type AgencyStatementSummaryPaginatedResponseDto = z.infer<
  typeof AgencyStatementSummaryPaginatedResponseDto
>;

export enum StatementType {
  bookings = 'bookings',
  markups = 'trips',
  expenses = 'expenses',
}

export const StatementBookingsSortKeys = [
  'recipient',
  'type',
  'supplierName',
  'confirmationNumber',
  'clientName',
  'tripName',
  'paidAt',
  'totalFare',
  'profit',
  'remit',
  'percentage',
] as const;

export const StatementExpensesSortKeys = [
  'recipient',
  'category',
  'notes',
  'createdAt',
  'frequency',
  'amount',
] as const;

export const AdvisorStatementDetailsRequestDto = getPaginatedRequestDto(
  z.enum([...StatementBookingsSortKeys, ...StatementExpensesSortKeys]),
  z.enum(['paidAt']),
).extend({
  startDate: z.string().regex(isoDateRegex),
  type: z.nativeEnum(StatementType).optional(),
});
export type AdvisorStatementDetailsRequestDto = z.infer<
  typeof AdvisorStatementDetailsRequestDto
>;

export const AdvisorStatementBookingsResponseDto = z.object({
  id: z.string().uuid(),
  isAdjustment: z.boolean(),
  supplierName: trimmedString(),
  paidAt: z.string().regex(isoDateRegex),
  sales: z.number(),
  commission: z.number(),
  advisorCommission: z.number(),
  bookingId: z.string().uuid(),
});
export type AdvisorStatementBookingsResponseDto = z.infer<
  typeof AdvisorStatementBookingsResponseDto
>;

export enum ExpenseRecurrenceType {
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING',
  ROLLOVER = 'ROLLOVER',
  MANAGED = 'MANAGED',
}
export const ExpenseRecurrenceTypeEnum = z.nativeEnum(ExpenseRecurrenceType);

export const recurrenceTypeLabelMap: Record<ExpenseRecurrenceType, string> = {
  ONE_TIME: 'One-Time',
  RECURRING: 'Recurring',
  ROLLOVER: 'Rollover',
  MANAGED: 'Credit',
};

export enum StatementBookingRowType {
  COMMISSION = 'Commission',
  FEE = 'Fee',
}

export const StatementRecipientDto = z.object({
  id: z.string().uuid(),
  name: trimmedString(),
});
export type StatementRecipientDto = z.infer<typeof StatementRecipientDto>;

export const StatementBookingsResponseDto = z.object({
  id: z.string().uuid(),
  isEntityDeleted: z.boolean(),
  deletedMessage: trimmedString().optional(),
  type: z.nativeEnum(StatementBookingRowType),
  feeType: FeeTypeResponseDto.optional(),
  recipient: StatementRecipientDto,
  supplierName: trimmedString().optional(),
  clientName: trimmedString(),
  tripId: z.string().uuid(),
  tripName: trimmedString(),
  paidAt: z.string().regex(isoDateRegex),
  totalFare: z.number(),
  profit: z.number(),
  remit: z.number(),
  percentage: z.number().optional(),
  bookingId: z.string().uuid().optional(),
  confirmationNumber: trimmedString().optional(),
  clientInvoiceId: z.string().uuid().optional(),
});
export type StatementBookingsResponseDto = z.infer<
  typeof StatementBookingsResponseDto
>;

const BasePaginatedStatementBookingsResponseDto = createPaginatedResponseDto(
  StatementBookingsResponseDto,
);

export const PaginatedStatementBookingsResponseDto =
  BasePaginatedStatementBookingsResponseDto.extend({
    meta: BasePaginatedStatementBookingsResponseDto.shape.meta.extend({
      totalSales: z.number(),
      totalProfit: z.number(),
      totalRemit: z.number(),
      totalRowCount: z.number(),
    }),
  });
export type PaginatedStatementBookingsResponseDto = z.infer<
  typeof PaginatedStatementBookingsResponseDto
>;

export const StatementTripMarkupsResponseDto = z.object({
  id: z.string().uuid(),
  createdAt: z.string().regex(isoDateRegex),
  isEntityDeleted: z.boolean(),
  deletedMessage: trimmedString().optional(),
  isAdjustment: z.boolean(),
  recipient: StatementRecipientDto,
  clientName: trimmedString(),
  tripName: trimmedString(),
  endDate: z.string().regex(isoDateRegex).optional(),
  clientPayments: z.number(),
  bookings: z.number(),
  tripMarkup: z.number(),
  payout: z.number(),
  payments: z.array(
    z.object({
      subject: trimmedString(),
      amount: z.number(),
    }),
  ),
  expenses: z.array(
    z.object({
      supplierName: trimmedString(),
      amount: z.number(),
    }),
  ),
  percentage: z.number().optional(),
});
export type StatementTripMarkupsResponseDto = z.infer<
  typeof StatementTripMarkupsResponseDto
>;

const BasePaginatedStatementTripMarkupsResponseDto = createPaginatedResponseDto(
  StatementTripMarkupsResponseDto,
);

export const PaginatedStatementTripMarkupsResponseDto =
  BasePaginatedStatementTripMarkupsResponseDto.extend({
    meta: BasePaginatedStatementTripMarkupsResponseDto.shape.meta.extend({
      totalClientPayments: z.number(),
      totalBookings: z.number(),
      totalTripMarkup: z.number(),
      totalPayout: z.number(),
    }),
  });
export type PaginatedStatementTripMarkupsResponseDto = z.infer<
  typeof PaginatedStatementTripMarkupsResponseDto
>;

export enum StatementExpenseRecipientType {
  USER = 'USER',
  AGENCY = 'AGENCY',
}

export const StatementExpensesResponseDto = z.object({
  id: z.string().uuid(),
  isEntityDeleted: z.boolean(),
  deletedMessage: trimmedString().optional(),
  recipient: StatementRecipientDto,
  recipientType: z.nativeEnum(StatementExpenseRecipientType),
  category: z
    .object({
      id: z.string().uuid(),
      name: trimmedString(),
    })
    .optional(),
  createdAt: z.string().regex(isoDateRegex),
  frequency: ExpenseRecurrenceTypeEnum,
  amount: z.number(),
  isLocked: z.boolean(),
  notes: trimmedString().optional(),
  statementStartDate: z.string().regex(isoDateRegex).optional(),
});
export type StatementExpensesResponseDto = z.infer<
  typeof StatementExpensesResponseDto
>;

const BasePaginatedStatementExpensesResponseDto = createPaginatedResponseDto(
  StatementExpensesResponseDto,
);

export const PaginatedStatementExpensesResponseDto =
  BasePaginatedStatementExpensesResponseDto.extend({
    meta: BasePaginatedStatementExpensesResponseDto.shape.meta.extend({
      totalExpenses: z.number(),
    }),
  });
export type PaginatedStatementExpensesResponseDto = z.infer<
  typeof PaginatedStatementExpensesResponseDto
>;

export const StatementMetaResponseDto = z.object({
  payout: z.number(),
  commissionsAndFeesCount: z.number(),
  markupsCount: z.number(),
  expensesCount: z.number(),
});
export type StatementMetaResponseDto = z.infer<typeof StatementMetaResponseDto>;

const BaseAdvisorStatementBookingsPaginatedResponseDto =
  createPaginatedResponseDto(AdvisorStatementBookingsResponseDto);

export const AdvisorStatementBookingsPaginatedResponseDto =
  BaseAdvisorStatementBookingsPaginatedResponseDto.extend({
    meta: BaseAdvisorStatementBookingsPaginatedResponseDto.shape.meta.extend({
      payoutsTotal: z.number(),
    }),
  });
export type AdvisorStatementBookingsPaginatedResponseDto = z.infer<
  typeof AdvisorStatementBookingsPaginatedResponseDto
>;

export const AdvisorStatementFeesResponseDto = z.object({
  isAdjustment: z.boolean(),
  clientName: trimmedString(),
  paidAt: z.string().regex(isoDateRegex),
  tripName: trimmedString(),
  fees: z.number(),
  advisorFees: z.number(),
  createdAt: z.string().regex(isoDateRegex),
  id: z.string().uuid(),
  clientInvoiceId: z.string().uuid(),
});
export type AdvisorStatementFeesResponseDto = z.infer<
  typeof AdvisorStatementFeesResponseDto
>;

const BaseAdvisorStatementFeesPaginatedResponseDto = createPaginatedResponseDto(
  AdvisorStatementFeesResponseDto,
);

export const AdvisorStatementFeesPaginatedResponseDto =
  BaseAdvisorStatementFeesPaginatedResponseDto.extend({
    meta: BaseAdvisorStatementFeesPaginatedResponseDto.shape.meta.extend({
      payoutsTotal: z.number(),
    }),
  });

export type AdvisorStatementFeesPaginatedResponseDto = z.infer<
  typeof AdvisorStatementFeesPaginatedResponseDto
>;

export const AdvisorStatementTripMarkupsResponseDto = z.object({
  id: z.string().uuid(),
  isAdjustment: z.boolean(),
  tripName: trimmedString(),
  endDate: z.string().regex(isoDateRegex).optional(),
  clientTripPayments: z.number(),
  bookingExpenses: z.number(),
  tripProfits: z.number(),
  advisorPayout: z.number(),
  payments: z.array(
    z.object({
      subject: trimmedString(),
      amount: z.number(),
    }),
  ),
  expenses: z.array(
    z.object({
      supplierName: trimmedString(),
      amount: z.number(),
    }),
  ),
});
export type AdvisorStatementTripMarkupsResponseDto = z.infer<
  typeof AdvisorStatementTripMarkupsResponseDto
>;

const BaseAdvisorStatementTripMarkupsPaginatedResponseDto =
  createPaginatedResponseDto(AdvisorStatementTripMarkupsResponseDto);

export const AdvisorStatementTripMarkupsPaginatedResponseDto =
  BaseAdvisorStatementTripMarkupsPaginatedResponseDto.extend({
    meta: BaseAdvisorStatementTripMarkupsPaginatedResponseDto.shape.meta.extend(
      {
        payoutsTotal: z.number(),
      },
    ),
  });

export type AdvisorStatementTripMarkupsPaginatedResponseDto = z.infer<
  typeof AdvisorStatementTripMarkupsPaginatedResponseDto
>;

export const StatementRequestDto = z.object({
  statementId: z.string().uuid(),
});
export type StatementRequestDto = z.infer<typeof StatementRequestDto>;

export const StatementResponseDto = z.object({
  id: z.string().uuid(),
  startDate: z.string().regex(isoDateRegex),
  endDate: z.string().regex(isoDateRegex),
  totalUsd: z.number(),
  commissions: z.array(StatementCommissionResponseDto),
});
export type StatementResponseDto = z.infer<typeof StatementResponseDto>;

export enum StatementRecurrence {
  MONTHLY = 'MONTHLY',
  SEMIMONTHLY = 'SEMIMONTHLY',
  BIWEEKLY = 'BIWEEKLY',
  WEEKLY = 'WEEKLY',
}

export const StatementScheduleCreateRequestDto = z
  .object({
    recurrence: z.nativeEnum(StatementRecurrence),
    startDate: z.string().regex(isoDateRegex),
    recurrenceDate: z.number().optional(),
  })
  .refine(
    (data) => {
      if (data.recurrence === StatementRecurrence.SEMIMONTHLY) {
        return data.recurrenceDate !== undefined;
      }
      return data.recurrenceDate === undefined;
    },
    {
      message: 'recurrenceDate must be provided for semimonthly statements',
    },
  )
  .refine(
    (data) => {
      if (
        data.recurrence === StatementRecurrence.MONTHLY ||
        data.recurrence === StatementRecurrence.SEMIMONTHLY
      ) {
        const startDate = moment.utc(
          moment.utc(data.startDate).format('YYYY-MM-DD'),
        );
        return (
          startDate.date() < 29 &&
          (!data.recurrenceDate || data.recurrenceDate < 29)
        );
      }

      return true;
    },
    {
      message:
        'Monthly and semimonthly statements must have a start date before the 29th of the month',
    },
  );
export type StatementScheduleCreateRequestDto = z.infer<
  typeof StatementScheduleCreateRequestDto
>;

export const StatementScheduleResponseDto = z.object({
  id: z.string().uuid(),
  recurrence: z.nativeEnum(StatementRecurrence),
  startDate: z.string().regex(isoDateRegex),
  endDate: z.string().regex(isoDateRegex).optional(),
});
export type StatementScheduleResponseDto = z.infer<
  typeof StatementScheduleResponseDto
>;

export const StatementScheduleListResponseDto = z.object({
  data: z.array(StatementScheduleResponseDto),
});
export type StatementScheduleListResponseDto = z.infer<
  typeof StatementScheduleListResponseDto
>;

export const statementScheduleToDto = (
  statementSchedule: StatementSchedule,
): StatementScheduleResponseDto => {
  return {
    id: statementSchedule.id,
    recurrence: statementSchedule.recurrence as StatementRecurrence,
    startDate: statementSchedule.startDate.toISOString(),
    endDate: statementSchedule.endDate?.toISOString() ?? undefined,
  };
};

export const StatementScheduleRequestDto = z.object({
  statementScheduleId: z.string().uuid(),
});
export type StatementScheduleRequestDto = z.infer<
  typeof StatementScheduleRequestDto
>;

export const SupplierOverviewResponseDto = z.object({
  totalRevenue: z.number(),
  totalCommission: z.number(),
});
export type SupplierOverviewResponseDto = z.infer<
  typeof SupplierOverviewResponseDto
>;

export const SupplierYearlyStatsDto = z.object({
  year: z.number(),
  totalRevenue: z.number(),
  totalCommission: z.number(),
});
export type SupplierYearlyStatsDto = z.infer<typeof SupplierYearlyStatsDto>;

export const SupplierYearlyStatsPaginatedResponseDto =
  createPaginatedResponseDto(SupplierYearlyStatsDto);
export type SupplierYearlyStatsPaginatedResponseDto = z.infer<
  typeof SupplierYearlyStatsPaginatedResponseDto
>;

export const DestinationResponseListDto = z.object({
  data: z.array(DestinationResponseDto),
});

export type DestinationResponseListDto = z.infer<
  typeof DestinationResponseListDto
>;

export const DestinationPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['name', 'nameAscii']),
  z.enum(['name', 'nameAscii']),
);
export type DestinationPaginatedRequestDto = z.infer<
  typeof DestinationPaginatedRequestDto
>;

export const DestinationPaginatedResponseDto = createPaginatedResponseDto(
  DestinationResponseDto,
);
export type DestinationPaginatedResponseDto = z.infer<
  typeof DestinationPaginatedResponseDto
>;

export const AdminSupplierRequestParams = z.object({
  type: z.nativeEnum(SupplierType).optional(),
  scope: z.enum(['managed', 'custom']).optional(),
  organizationId: trimmedString().optional(),
  isRetired: z.enum(['true', 'false']).optional(),
});
export type AdminSupplierRequestParams = z.infer<
  typeof AdminSupplierRequestParams
>;

export const AdminSupplierMergeRequestDto = z.object({
  supplierIds: z.array(z.string().uuid()),
});
export type AdminSupplierMergeRequestDto = z.infer<
  typeof AdminSupplierMergeRequestDto
>;

export const AdminUpdateSupplierRequestDto =
  AdminSupplierUpdateRequestDto.extend({
    supplierId: z.string().uuid(),
  });
export type AdminUpdateSupplierRequestDto = z.infer<
  typeof AdminUpdateSupplierRequestDto
>;

export const AdminManageSupplierRequestDto = z.object({
  supplierId: z.string().uuid(),
});
export type AdminManageSupplierRequestDto = z.infer<
  typeof AdminManageSupplierRequestDto
>;

export const AdminSupplierManagementMergeRequestDto =
  SupplierUpdateRequestDto.extend({
    toSupplierId: z.string().uuid(),
    fromSupplierId: z.string().uuid(),
    supplierOrganizationConfigs: z
      .array(SupplierOrganizationConfigPartial)
      .optional(),
  });
export type AdminSupplierManagementMergeRequestDto = z.infer<
  typeof AdminSupplierManagementMergeRequestDto
>;

export const AdminSupplierManagementRequestDto = z.object({
  mergedSupplierList: z.optional(
    z.array(AdminSupplierManagementMergeRequestDto),
  ),
  managedSupplierList: z.optional(z.array(AdminManageSupplierRequestDto)),
  privateSupplierList: z.optional(z.array(AdminManageSupplierRequestDto)),
  supplierUpdatesList: z.optional(z.array(AdminUpdateSupplierRequestDto)),
  generalBatchSize: z.number().optional(),
  mergedListBatchSize: z.number().optional(),
});
export type AdminSupplierManagementRequestDto = z.infer<
  typeof AdminSupplierManagementRequestDto
>;

export const AdminClientMergeRequestDto = z.object({
  toClientId: z.string().uuid(),
  fromClientId: z.string().uuid(),
});
export type AdminClientMergeRequestDto = z.infer<
  typeof AdminClientMergeRequestDto
>;

export const BillPaymentMethodRequestDto = z.object({
  billPaymentMethodId: z.string().uuid(),
});
export type BillPaymentMethodRequestDto = z.infer<
  typeof BillPaymentMethodRequestDto
>;

export const SupplierPaymentMethodRequestDto = z.object({
  supplierPaymentMethodId: z.string().uuid(),
});
export type SupplierPaymentMethodRequestDto = z.infer<
  typeof SupplierPaymentMethodRequestDto
>;

export const AccountingPaymentMethodListResponseDto = z.object({
  data: z.array(AccountingPaymentMethodResponseDto),
});
export type AccountingPaymentMethodListResponseDto = z.infer<
  typeof AccountingPaymentMethodListResponseDto
>;

export const AdminAccountingPaymentMethodListResponseDto = z.object({
  data: z.array(AdminAccountingPaymentMethodResponseDto),
});
export type AdminAccountingPaymentMethodListResponseDto = z.infer<
  typeof AdminAccountingPaymentMethodListResponseDto
>;

export const ExpenseCategoryUpsertRequestDto = z.object({
  name: trimmedString(),
  order: z.number(),
  mergeId: trimmedString().optional(),
  remoteId: trimmedString(),
});
export type ExpenseCategoryUpsertRequestDto = z.infer<
  typeof ExpenseCategoryUpsertRequestDto
>;

export const ExpenseCategoryResponseDto = z.object({
  id: z.string().uuid(),
  name: trimmedString(),
});
export type ExpenseCategoryResponseDto = z.infer<
  typeof ExpenseCategoryResponseDto
>;

export const AdminExpenseCategoryResponseDto =
  ExpenseCategoryResponseDto.extend({
    order: z.number(),
    mergeId: trimmedString().optional(),
    remoteId: trimmedString(),
  });
export type AdminExpenseCategoryResponseDto = z.infer<
  typeof AdminExpenseCategoryResponseDto
>;

export const ExpenseCategoryRequestDto = z.object({
  expenseCategoryId: z.string().uuid(),
});
export type ExpenseCategoryRequestDto = z.infer<
  typeof ExpenseCategoryRequestDto
>;

export const expenseCategoryToDto = (
  expenseCategory: ExpenseCategory,
): ExpenseCategoryResponseDto => {
  return {
    id: expenseCategory.id,
    name: expenseCategory.name,
  };
};

export const expenseCategoryToAdminDto = (
  expenseCategory: ExpenseCategory,
): AdminExpenseCategoryResponseDto => {
  return {
    ...expenseCategoryToDto(expenseCategory),
    order: expenseCategory.order,
    mergeId: expenseCategory.mergeId ?? undefined,
    remoteId: expenseCategory.remoteId,
  };
};

export const ExpenseCategoryListResponseDto = z.object({
  data: z.array(ExpenseCategoryResponseDto),
});
export type ExpenseCategoryListResponseDto = z.infer<
  typeof ExpenseCategoryListResponseDto
>;

export const AdminExpenseCategoryListResponseDto = z.object({
  data: z.array(AdminExpenseCategoryResponseDto),
});
export type AdminExpenseCategoryListResponseDto = z.infer<
  typeof AdminExpenseCategoryListResponseDto
>;

export enum BookingExpenseMappingType {
  INVOICE_NUMBER = 'INVOICE_NUMBER',
}

export const AnalyticsTokenResponseDto = z.object({
  token: trimmedString(),
});
export type AnalyticsTokenResponseDto = z.infer<
  typeof AnalyticsTokenResponseDto
>;

export const BookingExpenseUpdateRequestDto =
  BookingExpenseUpsertRequestDto.omit({
    id: true,
  }).refine(
    (data) => {
      return data.paymentMethod || data.billPaymentMethodId;
    },
    { message: 'paymentMethod or billPaymentMethodId must be provided' },
  );
export type BookingExpenseUpdateRequestDto = z.infer<
  typeof BookingExpenseUpdateRequestDto
>;

export const BookingExpenseCreateRequestDto = BookingExpenseUpdateRequestDto;
export type BookingExpenseCreateRequestDto = z.infer<
  typeof BookingExpenseCreateRequestDto
>;

export const BookingExpensePaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['createdAt']),
  z.enum(['subject']),
);
export type BookingExpensePaginatedRequestDto = z.infer<
  typeof BookingExpensePaginatedRequestDto
>;

function getCommissionStatus({
  bookingId,
  forfeitThresholdDays,
  receivedDate,
}: {
  bookingId: string | null;
  forfeitThresholdDays: number | null;
  receivedDate: Date;
}) {
  if (bookingId !== null) return CommissionStatus.MATCHED;
  if (forfeitThresholdDays === null) return CommissionStatus.UNMATCHED;

  const forfeitDate = moment(receivedDate).add(forfeitThresholdDays, 'days');
  const isForfeited = forfeitDate.isBefore(moment());
  if (isForfeited) return CommissionStatus.FORFEITED;

  return CommissionStatus.UNMATCHED;
}

export function bookingExpenseToDto(
  bookingExpense: BookingExpense & {
    booking: Booking;
    billPaymentMethod: AccountingPaymentMethod | null;
    payments: (BookingPayment & {
      billPaymentMethod: AccountingPaymentMethod | null;
    })[];
  },
): BookingExpenseResponseDto {
  const payment = bookingExpense.payments.find(
    (p) => p.voidedAt === null && p.deletedAt === null,
  );

  // The booking should be locked if it was paid for by an agency
  // and it was marked as paid more than 24 hours ago
  const locked = payment
    ? bookingExpense.booking.payingEntity === PayingEntity.AGENCY &&
      payment.createdAt < moment().subtract(1, 'days').toDate()
    : false;

  return {
    id: bookingExpense.id,
    subject: bookingExpense.subject ?? 'Payment to Supplier',
    paymentMethod: bookingExpense.paymentMethod
      ? (bookingExpense.paymentMethod as PaymentMethod)
      : PaymentMethod.CREDIT_CARD,
    lastFour: bookingExpense.lastFour ?? undefined,
    amount: bookingExpense.amount.toNumber(),
    amountHome: bookingExpense.amountHome.toNumber(),
    dueDate: bookingExpense.dueDate.toISOString(),
    paidAt: bookingExpense.paidAt?.toISOString(),
    notes: bookingExpense.notes ?? undefined,
    locked,
    billPaymentMethod: billPaymentMethodToDto(
      payment?.billPaymentMethod ?? bookingExpense.billPaymentMethod ?? null,
    ),
    isLegacyPayment: payment?.accountingIgnore ?? false,
    currency: bookingExpense.currency ?? undefined,
    exchangeRate: bookingExpense.exchangeRate?.toNumber() ?? undefined,
    exchangeRateLockedAt:
      bookingExpense.exchangeRateLockedAt?.toISOString() ?? undefined,
    isPaidAtCheckout: bookingExpense.isPaidAtCheckout,
  };
}

export const billPaymentMethodToDto = (
  billPaymentMethod: AccountingPaymentMethod | null,
): AccountingPaymentMethodResponseDto | undefined => {
  return billPaymentMethod
    ? {
        id: billPaymentMethod.id,
        type: billPaymentMethod.type as PaymentMethod,
        name: billPaymentMethod.name,
      }
    : undefined;
};

export const ReportingRequestParams = z
  .object({
    period: z.enum(['ytd', '3m', '1y', 'custom']),
    view: z.enum(['booking', 'travel']),
    startDate: z.string().regex(isoDateRegex).optional(),
    endDate: z.string().regex(isoDateRegex).optional(),
    now: z.string().regex(isoDateRegex).optional(),
  })
  .refine(
    (data) =>
      data.period !== 'custom' ||
      (data.period === 'custom' && data.startDate && data.endDate),
    { message: 'startDate and endDate are required for custom period' },
  );
export type ReportingRequestParams = z.infer<typeof ReportingRequestParams>;

export const LimitableReportingRequestParams = z.intersection(
  z.object({
    limit: z.string().optional(),
  }),
  ReportingRequestParams,
);
export type LimitableReportingRequestParams = z.infer<
  typeof LimitableReportingRequestParams
>;

export const ReportingTimeboundRequestParams = z.object({
  startDate: z.string().regex(isoDateRegex).optional(),
  endDate: z.string().regex(isoDateRegex).optional(),
});
export type ReportingTimeboundRequestParams = z.infer<
  typeof ReportingTimeboundRequestParams
>;

export const UpcomingBookingPaymentsPaginatedRequestDto =
  getPaginatedRequestDto(
    z.enum(['dueDate', 'subject', 'from', 'to', 'amount', 'client']),
    z.enum(['dueDate']),
  ).merge(ReportingTimeboundRequestParams);
export type UpcomingBookingPaymentsPaginatedRequestDto = z.infer<
  typeof UpcomingBookingPaymentsPaginatedRequestDto
>;

export const UpcomingClientPaymentsPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['dueDate', 'subject', 'from', 'to', 'amount']),
  z.enum(['dueDate']),
).merge(ReportingTimeboundRequestParams);
export type UpcomingClientPaymentsPaginatedRequestDto = z.infer<
  typeof UpcomingClientPaymentsPaginatedRequestDto
>;

const ReportingPaymentResponse = z.object({
  tripId: z.string().uuid(),
  subject: trimmedString(),
  from: trimmedString(),
  to: trimmedString(),
  amount: z.number(),
  amountHome: z.number(),
  dueDate: z.string().regex(isoDateRegex),
});
export type ReportingPaymentResponse = z.infer<typeof ReportingPaymentResponse>;

export const ReportingBookingPaymentResponseDto =
  ReportingPaymentResponse.merge(
    z.object({
      bookingId: z.string().uuid(),
      expenseId: z.string().uuid(),
      client: ClientResponseDto.optional(),
    }),
  );
export type ReportingBookingPaymentResponseDto = z.infer<
  typeof ReportingBookingPaymentResponseDto
>;

export function bookingExpenseToPaymentResponseDto(
  bookingExpense: BookingExpense & {
    booking: Booking & {
      client:
        | (Client & {
            owningGroup: Group | null;
          })
        | null;
      trip: Trip & {
        primaryClient:
          | (Client & {
              owningGroup: Group | null;
            })
          | null;
      };
      supplier: Supplier;
    };
  },
): ReportingBookingPaymentResponseDto {
  const agencyName = 'Agency';

  const client =
    bookingExpense.booking.client ?? bookingExpense.booking.trip.primaryClient;

  const reportingBookingPaymentResponseDto: ReportingBookingPaymentResponseDto =
    {
      tripId: bookingExpense.booking.tripId,
      bookingId: bookingExpense.bookingId,
      expenseId: bookingExpense.id,
      subject: bookingExpense.subject ?? 'Booking Payment',
      from:
        bookingExpense.booking.payingEntity === PayingEntity.CLIENT
          ? client?.owningGroup?.name ??
            (client && `${client.firstName} ${client.lastName}`) ??
            bookingExpense.booking.trip.primaryClient?.owningGroup?.name ??
            ''
          : agencyName,
      to: bookingExpense.booking.supplier.name,
      amount: bookingExpense.amount.toNumber(),
      amountHome: bookingExpense.amountHome.toNumber(),
      dueDate: bookingExpense.dueDate.toISOString(),
      client: client ? clientToMinimalDto(client) : undefined,
    };

  return reportingBookingPaymentResponseDto;
}

export const ReportingBookingPaymentPaginatedResponseDto =
  createPaginatedResponseDto(ReportingBookingPaymentResponseDto);

export type ReportingBookingPaymentPaginatedResponseDto = z.infer<
  typeof ReportingBookingPaymentPaginatedResponseDto
>;

export const ReportingClientPaymentResponseDto = ReportingPaymentResponse.merge(
  z.object({
    invoiceId: z.string().uuid(),
  }),
);
export type ReportingClientPaymentResponseDto = z.infer<
  typeof ReportingClientPaymentResponseDto
>;

export function clientInvoiceToPaymentResponseDto(
  organization: Organization,
  clientInvoice: ClientInvoice & {
    recipientClient: Client & {
      owningGroup: Group | null;
    };
    trip: Trip & {
      agencyUser: AgencyUser & {
        user: User;
      };
    };
  },
): ReportingClientPaymentResponseDto | undefined {
  const dueDate = clientInvoice.dueDate;
  if (!dueDate) {
    return;
  }

  const agencyName = 'Agency';

  const agencyUser = clientInvoice.trip.agencyUser;
  const advisorName = `${agencyUser?.user.firstName} ${agencyUser?.user.lastName}`;

  const to =
    clientInvoice.invoiceFor === InvoiceFor.TRIP ||
    organization.feesRecipient === FeesRecipient.ORGANIZATION
      ? agencyName
      : organization.feesRecipient === FeesRecipient.ADVISOR
        ? advisorName
        : 'TripSuite';
  return {
    tripId: clientInvoice.tripId,
    invoiceId: clientInvoice.id,
    subject: clientInvoice.subject,
    from:
      clientInvoice.recipientClient.owningGroup?.name ??
      `${clientInvoice.recipientClient.firstName} ${clientInvoice.recipientClient.lastName}`,
    to,
    amount: clientInvoice.amount.toNumber(),
    amountHome: clientInvoice.amount.toNumber(),
    dueDate: (clientInvoice.dueDate ?? new Date()).toISOString(),
  };
}

export const ReportingClientPaymentPaginatedResponseDto =
  createPaginatedResponseDto(ReportingClientPaymentResponseDto);

export type ReportingClientPaymentPaginatedResponseDto = z.infer<
  typeof ReportingClientPaymentPaginatedResponseDto
>;

export enum CommissionBreakdownType {
  Paid = 'paid',
  Late = 'late',
  Owed = 'owed',
  Upcoming = 'upcoming',
}

export const ReportingBars = z.array(
  z.object({
    previous: z.object({
      date: z.string().regex(isoDateRegex),
      value: z.number(),
    }),
    current: z.object({
      date: z.string().regex(isoDateRegex),
      value: z.number(),
    }),
    future: z.object({
      date: z.string().regex(isoDateRegex),
      value: z.number(),
    }),
  }),
);
export type ReportingBars = z.infer<typeof ReportingBars>;

export const ReportingOverviewResponseDto = z.object({
  bookingRevenue: z.object({
    total: z.number(),
    trendPct: z.number().optional(),
    data: ReportingBars,
  }),
  commissions: z.object({
    total: z.number(),
    trendPct: z.number().optional(),
    paymentCount: z.number(),
    breakdown: z.array(
      z.object({
        type: z.nativeEnum(CommissionBreakdownType),
        value: z.number(),
      }),
    ),
    data: ReportingBars,
  }),
});
export type ReportingOverviewResponseDto = z.infer<
  typeof ReportingOverviewResponseDto
>;

export const ReportingOverviewNewResponseDto = z.object({
  tripRevenue: z.object({
    total: z.number(),
    trendPct: z.number().optional(),
    data: ReportingBars,
  }),
  tripProfit: z.object({
    total: z.number(),
    trendPct: z.number().optional(),
    data: ReportingBars,
  }),
  tripFee: z.object({
    total: z.number(),
    trendPct: z.number().optional(),
    data: ReportingBars,
  }),
  commissions: z.object({
    paymentCount: z.number(),
    breakdown: z.array(
      z.object({
        type: z.nativeEnum(CommissionBreakdownType),
        value: z.number(),
      }),
    ),
  }),
});
export type ReportingOverviewNewResponseDto = z.infer<
  typeof ReportingOverviewNewResponseDto
>;

export const ReportingTopAdvisorsResponseDto = z.object({
  bookings: z.array(
    z.object({
      agency: z.object({
        id: z.string().uuid(),
        name: trimmedString(),
        isSingleUserAgency: z.boolean(),
        userName: trimmedString(),
        color: trimmedString(),
      }),
      total: z.number(),
    }),
  ),
  commissions: z.array(
    z.object({
      agency: z.object({
        id: z.string().uuid(),
        name: trimmedString(),
        isSingleUserAgency: z.boolean(),
        userName: trimmedString(),
        color: trimmedString(),
      }),
      total: z.number(),
    }),
  ),
});
export type ReportingTopAdvisorsResponseDto = z.infer<
  typeof ReportingTopAdvisorsResponseDto
>;

export const ReportingTopSuppliersResponseDto = z.object({
  bookings: z.array(
    z.object({
      name: trimmedString(),
      total: z.number(),
    }),
  ),
  commissions: z.array(
    z.object({
      name: trimmedString(),
      total: z.number(),
    }),
  ),
});
export type ReportingTopSuppliersResponseDto = z.infer<
  typeof ReportingTopSuppliersResponseDto
>;

export const ReportingTopClientsResponseDto = z.object({
  bookings: z.array(
    z.object({
      client: z.object({
        id: z.string().uuid(),
        name: trimmedString(),
        color: trimmedString(),
      }),
      total: z.number(),
    }),
  ),
  commissions: z.array(
    z.object({
      client: z.object({
        id: z.string().uuid(),
        name: trimmedString(),
        color: trimmedString(),
      }),
      total: z.number(),
    }),
  ),
});
export type ReportingTopClientsResponseDto = z.infer<
  typeof ReportingTopClientsResponseDto
>;

export const CommissionPaginatedRequestDto = getPaginatedRequestDto(
  z.enum([
    'createdAt',
    'supplierName',
    'supplierNameActual',
    'clientNameActual',
    'advisorNameActual',
    'confirmationNumber',
    'checkOutActual',
    'referenceNumber',
    'receivedDate',
    'amountHome',
  ]),
  z.enum([
    'supplierName',
    'supplierNameActual',
    'advisorNameActual',
    'clientNameActual',
    'confirmationNumber',
    'checkOutActual',
    'referenceNumber',
    'receivedDate',
    'amountHome',
  ]),
).extend({
  statusFilter: z.nativeEnum(CommissionStatus).optional(),
  bookingId: z.string().uuid().optional(),
});

export type CommissionPaginatedRequestDto = z.infer<
  typeof CommissionPaginatedRequestDto
>;

export const CommissionPaginatedResponseDto = createPaginatedResponseDto(
  CommissionResponseDto,
);

export type CommissionPaginatedResponseDto = z.infer<
  typeof CommissionPaginatedResponseDto
>;

export enum AccountingEntity {
  COMPANY = 'COMPANY',
  CONTACT = 'CONTACT',
  INVOICE = 'INVOICE',
  PAYMENT = 'PAYMENT',
  DEPOSIT = 'DEPOSIT',
  CUSTOMER = 'CUSTOMER',
  SUB_CUSTOMER = 'SUB_CUSTOMER',
  BILL = 'BILL',
  BILL_PAYMENT = 'BILL_PAYMENT',
  RECOGNIZED_INVOICE = 'RECOGNIZED_INVOICE',
  RECOGNIZED_BILL = 'RECOGNIZED_BILL',
  CREDIT_MEMO = 'CREDIT_MEMO',
  SUPPLIER_OVERPAYMENT_INVOICE = 'SUPPLIER_OVERPAYMENT_INVOICE',
  SUBSEQUENT_COMMISSION_INVOICE = 'SUBSEQUENT_COMMISSION_INVOICE',
  REFUND_RECEIPT = 'REFUND_RECEIPT',
  VENDOR_CREDIT = 'VENDOR_CREDIT',
  PURCHASE = 'PURCHASE',
  BOOKS_CLOSE_CREDIT_MEMO = 'BOOKS_CLOSE_CREDIT_MEMO',
  BOOKS_CLOSE_PAYMENT = 'BOOKS_CLOSE_PAYMENT',
  BOOKS_CLOSE_JOURNAL_ENTRY = 'BOOKS_CLOSE_JOURNAL_ENTRY',
  BOOKS_CLOSE_VENDOR_CREDIT = 'BOOKS_CLOSE_VENDOR_CREDIT',
  BOOKS_CLOSE_BILL_PAYMENT = 'BOOKS_CLOSE_BILL_PAYMENT',
  GIFT_CARD_TRANSFER_JOURNAL_ENTRY = 'GIFT_CARD_TRANSFER_JOURNAL_ENTRY',
}

export const AccountingEntityInvoiceTypes = [
  AccountingEntity.INVOICE,
  AccountingEntity.RECOGNIZED_INVOICE,
  AccountingEntity.SUBSEQUENT_COMMISSION_INVOICE,
  AccountingEntity.SUPPLIER_OVERPAYMENT_INVOICE,
];

export const AccountingEntityPaymentTypes = [
  AccountingEntity.PAYMENT,
  AccountingEntity.BOOKS_CLOSE_PAYMENT,
];

export const AccountingEntityCustomerTypes = [
  AccountingEntity.CUSTOMER,
  AccountingEntity.SUB_CUSTOMER,
  AccountingEntity.CONTACT,
];

export enum AccountingAccountType {
  UNDEPOSITED_FUNDS = 'UNDEPOSITED_FUNDS',
  CHECKING = 'CHECKING',
  CREDIT_CARD = 'CREDIT_CARD',
  PREPAID_EXPENSE = 'PREPAID_EXPENSE',
  AIRLINE_COGS = 'AIRLINE_COGS',
  CRUISE_LINE_COGS = 'CRUISE_LINE_COGS',
  HOTEL_COGS = 'HOTEL_COGS',
  INSURANCE_COGS = 'INSURANCE_COGS',
  RAIL_COGS = 'RAIL_COGS',
  TOUR_DMC_COGS = 'TOUR_DMC_COGS',
  TRANSPORTATION_COGS = 'TRANSPORTATION_COGS',
  OTHER_COGS = 'OTHER_COGS',
  PAYOUTS = 'PAYOUTS',
  EMPLOYEE_PAYOUTS = 'EMPLOYEE_PAYOUTS',
  ACCOUNTS_PAYABLE = 'ACCOUNTS_PAYABLE',
  DUE_FROM_ADVISORS = 'DUE_FROM_ADVISORS',
  CLIENT_FUNDS_ON_ACCOUNT = 'CLIENT_FUNDS_ON_ACCOUNT',
}

export enum UserEmploymentType {
  EMPLOYEE = 'EMPLOYEE',
  IC = 'IC',
}

export const UserEmploymentTypeToAccountingAccountTypeMap: Record<
  UserEmploymentType,
  AccountingAccountType
> = {
  [UserEmploymentType.EMPLOYEE]: AccountingAccountType.EMPLOYEE_PAYOUTS,
  [UserEmploymentType.IC]: AccountingAccountType.PAYOUTS,
};

export type QboAccountingEntity = {
  entityId: string;
  mergeId: string | null;
  remoteId: string;
};

export enum AccountingPlatform {
  INTUIT = 'INTUIT',
  LAYER = 'LAYER',
}

export const AdminAccountingAccountCreateRequestDto = z.object({
  accountType: z.nativeEnum(AccountingAccountType),
  mergeId: trimmedString(),
  remoteId: trimmedString(),
  accountingPlatform: z
    .nativeEnum(AccountingPlatform)
    .default(AccountingPlatform.INTUIT),
});
export type AdminAccountingAccountCreateRequestDto = z.infer<
  typeof AdminAccountingAccountCreateRequestDto
>;

export enum AccountingItemType {
  AIRLINE = 'AIRLINE',
  AIRLINE_COGS = 'AIRLINE_COGS',
  CRUISE_LINE = 'CRUISE_LINE',
  CRUISE_LINE_COGS = 'CRUISE_LINE_COGS',
  HOTEL = 'HOTEL',
  HOTEL_COGS = 'HOTEL_COGS',
  INSURANCE = 'INSURANCE',
  INSURANCE_COGS = 'INSURANCE_COGS',
  RAIL = 'RAIL',
  RAIL_COGS = 'RAIL_COGS',
  TOUR_DMC = 'TOUR_DMC',
  TOUR_DMC_COGS = 'TOUR_DMC_COGS',
  TRANSPORTATION = 'TRANSPORTATION',
  TRANSPORTATION_COGS = 'TRANSPORTATION_COGS',
  OTHER = 'OTHER',
  OTHER_COGS = 'OTHER_COGS',
  TRIP_PAYMENT = 'TRIP_PAYMENT',
  TRIP_PREPAYMENT = 'TRIP_PREPAYMENT',
  DEFERRED_COMMISSION = 'DEFERRED_COMMISSION',
  PREPAID_EXPENSE = 'PREPAID_EXPENSE',
  CLIENT_FEE = 'CLIENT_FEE',
  CLEARINGHOUSE_FEE = 'CLEARINGHOUSE_FEE',
  PAYMENT_FEE = 'PAYMENT_FEE',
  ARC_FEE = 'ARC_FEE',
  CLIENT_FUNDS_ON_ACCOUNT = 'CLIENT_FUNDS_ON_ACCOUNT',
  ANCILLARIES = 'ANCILLARIES',
  ANCILLARIES_COGS = 'ANCILLARIES_COGS',
  ARC_ADJUSTMENTS = 'ARC_ADJUSTMENTS',
}

export const AdminAccountingItemCreateRequestDto = z.object({
  accountingItemType: z.nativeEnum(AccountingItemType),
  mergeId: trimmedString().optional(),
  remoteId: trimmedString(),
  accountingPlatform: z
    .nativeEnum(AccountingPlatform)
    .default(AccountingPlatform.INTUIT),
});
export type AdminAccountingItemCreateRequestDto = z.infer<
  typeof AdminAccountingItemCreateRequestDto
>;

export const AdminAccountingPaymentMethodCreateRequestDto = z.object({
  paymentMethod: z.nativeEnum(PaymentMethod),
  mergeId: trimmedString().optional(),
  remoteId: trimmedString().optional(),
  accountingPlatform: z
    .nativeEnum(AccountingPlatform)
    .default(AccountingPlatform.INTUIT),
});
export type AdminAccountingPaymentMethodCreateRequestDto = z.infer<
  typeof AdminAccountingPaymentMethodCreateRequestDto
>;

export const PublicTokenPostRequestDto = z.object({
  publicToken: trimmedString(),
});
export type PublicTokenPostRequestDto = z.infer<
  typeof PublicTokenPostRequestDto
>;

export enum AccountingProvider {
  MERGE = 'merge',
  INTUIT = 'intuit',
  LAYER = 'layer',
}

export const AccountingConnectionStatus = z.object({
  connected: z.boolean(),
  linkToken: trimmedString().optional(),
  companyName: trimmedString().optional(),
  provider: z.nativeEnum(AccountingProvider).optional(),
});
export type AccountingConnectionStatus = z.infer<
  typeof AccountingConnectionStatus
>;

export const AdvisorResponseDto = AgencyUserResponseDto.extend({});
export type AdvisorResponseDto = z.infer<typeof AdvisorResponseDto>;
export const AdvisorResponseListDto =
  createPaginatedResponseDto<AdvisorResponseDto>(AdvisorResponseDto);
export type AdvisorResponseListDto = z.infer<typeof AdvisorResponseListDto>;

export const ImportParams = z.object({
  dryRun: z.enum(['true', 'false']).optional(),
  batchId: trimmedString(),
  csvFormatType: trimmedString().optional(),
  bucketName: trimmedString().optional(),
  fileName: trimmedString().optional(),
  advisorAutoCreateAgencyId: trimmedString().optional(),
  clientDefaultAgencyId: trimmedString().optional(),
});
export type ImportParams = z.infer<typeof ImportParams>;

export const ClientNotesDto = z.object({
  notes: trimmedString().optional(),
});
export type ClientNotesDto = z.infer<typeof ClientNotesDto>;

export const ClientTagDto = z.object({
  tag: trimmedString().optional(),
});
export type ClientTagDto = z.infer<typeof ClientTagDto>;

export const ClientActiveDto = z.object({
  isActive: z.boolean().optional(),
});
export type ClientActiveDto = z.infer<typeof ClientActiveDto>;

export const ClientAssigneeDto = z.object({
  agencyUserId: z.string().uuid().optional(),
});
export type ClientAssigneeDto = z.infer<typeof ClientAssigneeDto>;

export const ClientProfileBasicInfoDto = z.object({
  firstName: trimmedString(),
  middleName: trimmedString().optional(),
  lastName: trimmedString(),
  prefix: trimmedString().optional(),
  suffix: trimmedString().optional(),
  preferredName: trimmedString().optional(),
  email: trimmedString().optional(),
  secondaryEmail: trimmedString().optional(),
  secondaryEmailNickname: trimmedString().optional(),
  phone: trimmedString().optional(),
  secondaryPhone: trimmedString().optional(),
  secondaryPhoneNickname: trimmedString().optional(),
  pronouns: trimmedString().optional(),
  emergencyContactName: trimmedString().optional(),
  emergencyContactPhone: trimmedString().optional(),
  emergencyContactEmail: trimmedString().optional(),
  emergencyContactRelationship: trimmedString().optional(),
  gender: trimmedString().optional(),
  externalClientId: trimmedString().optional(),
  referralSource: trimmedString().optional(),
});
export type ClientProfileBasicInfoDto = z.infer<
  typeof ClientProfileBasicInfoDto
>;

export enum Months {
  JAN = 'JAN',
  FEB = 'FEB',
  MAR = 'MAR',
  APR = 'APR',
  MAY = 'MAY',
  JUN = 'JUN',
  JUL = 'JUL',
  AUG = 'AUG',
  SEP = 'SEP',
  OCT = 'OCT',
  NOV = 'NOV',
  DEC = 'DEC',
}

export const MonthToNum = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
} as const;

const AddressDetailsDto = z.object({
  id: trimmedString().optional(),
  type: trimmedString().optional(),
  address1: trimmedString().optional(),
  address2: trimmedString().optional(),

  city: trimmedString().optional(),
  state: trimmedString().optional(),
  zip: trimmedString().optional(),

  notes: trimmedString().optional(),

  poBox: trimmedString().optional(),
  effectiveMonths: z.array(z.nativeEnum(Months)).optional(),

  placeId: trimmedString().optional(), // TODO: remove field
  description: trimmedString().optional(), // TODO: remove field
  latitude: z.number().optional(), // TODO: remove field
  longitude: z.number().optional(), // TODO: remove field
  country: trimmedString().optional(), // TODO: remove field
});
export type AddressDetailsDto = z.infer<typeof AddressDetailsDto>;

export const ClientProfileAddressesDto = z.object({
  primary: trimmedString().optional(), // TODO: remove field
  primaryDetails: AddressDetailsDto.optional(),
  secondary: trimmedString().optional(), // TODO: remove field
  secondaryDetails: AddressDetailsDto.optional(),
  billing: trimmedString().optional(), // TODO: remove field
  billingDetails: AddressDetailsDto.optional(),
  addresses: z.array(AddressDetailsDto).optional(),
  business: trimmedString().optional(), // TODO: remove field
  businessDetails: AddressDetailsDto.optional(),
});
export type ClientProfileAddressesDto = z.infer<
  typeof ClientProfileAddressesDto
>;

const AddressDetailsDto2 = z.object({
  poBox: trimmedString().optional(),

  address1: trimmedString().optional(),
  address2: trimmedString().optional(),
  city: trimmedString().optional(),
  state: trimmedString().optional(),
  zip: trimmedString().optional(),
  country: trimmedString().optional(),
  notes: trimmedString().optional(),

  effectiveMonths: z.array(z.nativeEnum(Months)).optional(),
});
export type AddressDetailsDto2 = z.infer<typeof AddressDetailsDto2>;

export const ClientProfileAddressesDto2 = z.object({
  primaryDetails: AddressDetailsDto2.optional(),
  secondaryDetails: AddressDetailsDto2.optional(),
  billingDetails: AddressDetailsDto2.optional(),
  businessDetails: AddressDetailsDto2.optional(),
  sameAsPrimary: z.boolean().optional(),
});
export type ClientProfileAddressesDto2 = z.infer<
  typeof ClientProfileAddressesDto2
>;

export const ClientProfileDatesDto = z.object({
  birthday: trimmedString().optional(),
  anniversary: trimmedString().optional(),
  notes: trimmedString().optional(),
});
export type ClientProfileDatesDto = z.infer<typeof ClientProfileDatesDto>;

export const ClientProfileHealthInfoDto = z.object({
  allergies: z.array(z.string()).optional(),
  dietaryRestrictions: z.array(z.string()).optional(),
  mobilityRestrictions: z.array(z.string()).optional(),
  notes: trimmedString().optional(),
});
export type ClientProfileHealthInfoDto = z.infer<
  typeof ClientProfileHealthInfoDto
>;

export const ClientProfilePassportInfoDto = z.object({
  id: trimmedString().optional(),
  givenName: trimmedString().optional(),
  surname: trimmedString().optional(),
  passportNumber: trimmedString().optional(),
  issuingCountry: trimmedString().optional(),
  issueDate: trimmedString().optional(),
  expirationDate: trimmedString().optional(),
  passportImage: trimmedString().optional(),
  notes: trimmedString().optional(),
});
export type ClientProfilePassportInfoDto = z.infer<
  typeof ClientProfilePassportInfoDto
>;

export const ClientProfilePreferenceInfoDto = z.object({
  airlines: z.array(z.string()).optional(),
  airlineClass: trimmedString().optional(),
  airlineSeat: trimmedString().optional(),
  hotels: z.array(z.string()).optional(),
  bedSize: trimmedString().optional(),
  notes: trimmedString().optional(),
});
export type ClientProfilePreferenceInfoDto = z.infer<
  typeof ClientProfilePreferenceInfoDto
>;

export const ClientProfileMembershipDto = z.object({
  id: trimmedString().optional(),
  name: trimmedString(),
  number: trimmedString(),
});
export type ClientProfileMembershipDto = z.infer<
  typeof ClientProfileMembershipDto
>;

export const ClientProfileProgramsDto = z.object({
  memberships: z.array(ClientProfileMembershipDto).optional(),
  notes: trimmedString().optional(),
});
export type ClientProfileProgramsDto = z.infer<typeof ClientProfileProgramsDto>;

export const ClientProfileRelationshipDto = z.object({
  id: trimmedString().optional(),
  name: trimmedString().optional(),
  relationship: trimmedString(),
  relatedClientId: z.string().uuid(),
});
export type ClientProfileRelationshipDto = z.infer<
  typeof ClientProfileRelationshipDto
>;

export const ClientProfileRelationshipsDto = z.object({
  people: z.array(ClientProfileRelationshipDto).optional(),
  tags: z.array(z.string()).optional(),
  notes: trimmedString().optional(),
});
export type ClientProfileRelationshipsDto = z.infer<
  typeof ClientProfileRelationshipsDto
>;

export const ClientProfileSocialInfoDto = z.object({
  facebook: trimmedString().optional(),
  instagram: trimmedString().optional(),
  linkedin: trimmedString().optional(),
  twitter: trimmedString().optional(),
  whatsapp: trimmedString().optional(),
});
export type ClientProfileSocialInfoDto = z.infer<
  typeof ClientProfileSocialInfoDto
>;

export const CreditCardDto = z.object({
  name: trimmedString().optional(),
  numberToken: trimmedString(),
  cvcToken: trimmedString(),
  lastFour: trimmedString(),
  expirationMonth: z.number(),
  expirationYear: z.number(),
  clientAddressId: z.string().uuid().optional(),
  street1: trimmedString().optional(),
  street2: trimmedString().optional(),
  city: trimmedString().optional(),
  state: trimmedString().optional(),
  zip: trimmedString().optional(),
  country: trimmedString().optional(),
  type: trimmedString(),
  notes: trimmedString().optional(),
});
export type CreditCardDto = z.infer<typeof CreditCardDto>;

export const PublicCreditCardCreateRequestDto = CreditCardDto;
export type PublicCreditCardCreateRequestDto = z.infer<
  typeof PublicCreditCardCreateRequestDto
>;

export const ClientProfileCreditCardDto = CreditCardDto.extend({
  nickname: trimmedString().optional(),
});
export type ClientProfileCreditCardDto = z.infer<
  typeof ClientProfileCreditCardDto
>;

export const ClientProfileCreditCardCreateRequestDto =
  ClientProfileCreditCardDto;
export type ClientProfileCreditCardCreateRequestDto = z.infer<
  typeof ClientProfileCreditCardCreateRequestDto
>;
export const ClientProfileCreditCardUpdateRequestDto =
  ClientProfileCreditCardCreateRequestDto.omit({
    numberToken: true,
    cvcToken: true,
  }).extend({
    clientProfileCreditCardId: z.string().uuid(),
  });

export type ClientProfileCreditCardUpdateRequestDto = z.infer<
  typeof ClientProfileCreditCardUpdateRequestDto
>;

export const ClientProfileCreditCardResponseDto =
  ClientProfileCreditCardDto.extend({
    id: z.string().uuid(),
    authorized: z.boolean(),
    createdAt: z.string().regex(isoDateRegex),
  });
export type ClientProfileCreditCardResponseDto = z.infer<
  typeof ClientProfileCreditCardResponseDto
>;

export const ClientProfileCreditCardsDto = z.object({
  cards: z.array(ClientProfileCreditCardResponseDto),
  notes: trimmedString().optional(),
});
export type ClientProfileCreditCardsDto = z.infer<
  typeof ClientProfileCreditCardsDto
>;

export const ClientProfileCreditCardsNotesDto = z.object({
  notes: trimmedString().optional(),
});
export type ClientProfileCreditCardsNotesDto = z.infer<
  typeof ClientProfileCreditCardsNotesDto
>;

export const clientProfileCreditCardToDto = (
  clientProfileCreditCard: ClientProfileCreditCard & {
    tripInvoiceAuthorization: TripInvoiceAuthorization | null;
  },
): ClientProfileCreditCardResponseDto => {
  return {
    id: clientProfileCreditCard.id,
    name: clientProfileCreditCard.cardholderName ?? undefined,
    numberToken: clientProfileCreditCard.panTk,
    lastFour: clientProfileCreditCard.last4,
    expirationMonth: clientProfileCreditCard.expMonth,
    expirationYear: clientProfileCreditCard.expYear,
    cvcToken: clientProfileCreditCard.cvvTk,
    street1: clientProfileCreditCard.address1 ?? undefined,
    street2: clientProfileCreditCard.address2 ?? undefined,
    city: clientProfileCreditCard.city ?? undefined,
    state: clientProfileCreditCard.state ?? undefined,
    zip: clientProfileCreditCard.zip ?? undefined,
    country: clientProfileCreditCard.country ?? undefined,
    nickname: clientProfileCreditCard.nickname ?? undefined,
    type: clientProfileCreditCard.type,
    notes: clientProfileCreditCard.notes ?? undefined,
    authorized: !!clientProfileCreditCard.tripInvoiceAuthorization,
    createdAt: clientProfileCreditCard.createdAt.toISOString(),
  };
};

export const ClientProfileCreditCardRequestDto = z.object({
  clientProfileCreditCardId: z.string().uuid(),
});
export type ClientProfileCreditCardRequestDto = z.infer<
  typeof ClientProfileCreditCardRequestDto
>;

export const ClientProfileDto = ClientDto.merge(
  z.object({
    basicInfo: ClientProfileBasicInfoDto,
    addresses: ClientProfileAddressesDto,
    corporate: ClientProfileCorporateInfoDto.optional(),
    dates: ClientProfileDatesDto,
    health: ClientProfileHealthInfoDto,
    preferences: ClientProfilePreferenceInfoDto,
    programs: ClientProfileProgramsDto,
    relationships: ClientProfileRelationshipsDto,
    socials: ClientProfileSocialInfoDto,
    formSubmits: z.array(FormSubmitResponseDto).optional(),
    creditCards: ClientProfileCreditCardsDto,
    passport: ClientProfilePassportInfoDto,
    giftCards: GiftCardListResponseDto,
  }),
);
export type ClientProfileDto = z.infer<typeof ClientProfileDto>;

export const ClientProfileResponseDto =
  ClientProfileDto.merge(ClientResponseDto);
export type ClientProfileResponseDto = z.infer<typeof ClientProfileResponseDto>;

export const AdminLeadStageUpdateRequestDto = z.object({
  type: z.nativeEnum(ConcreteLeadStageType),
  name: trimmedString(),
  color: trimmedString(),
  order: z.number(),
});
export type AdminLeadStageUpdateRequestDto = z.infer<
  typeof AdminLeadStageUpdateRequestDto
>;

export const LeadStageRequestDto = z.object({
  leadStageId: z.string().uuid(),
});
export type LeadStageRequestDto = z.infer<typeof LeadStageRequestDto>;

export enum ImportBatchType {
  BOOKING = 'BOOKING',
  CLIENT = 'CLIENT',
}

export const ImportBatchRequestDto = z.object({
  batchId: trimmedString(),
  type: z.nativeEnum(ImportBatchType).optional(),
});
export type ImportBatchRequestDto = z.infer<typeof ImportBatchRequestDto>;

export const ImportBatchProcessRequestDto = z.object({
  take: z.number(),
});
export type ImportBatchProcessRequestDto = z.infer<
  typeof ImportBatchProcessRequestDto
>;

export const ImportBatchProcessResponseDto = z.object({
  processed: z.number(),
  remaining: z.number(),
});
export type ImportBatchProcessResponseDto = z.infer<
  typeof ImportBatchProcessResponseDto
>;

export const TripReportRequestDto = z.object({
  tripId: z.string().uuid(),
  excludeAirfare: z.enum(['true', 'false']).optional(),
});

export const TripReportResponseDto = z.object({
  estimatedRevenue: z.number(),
  estimatedProfit: z.number(),
  estimatedMargin: z.number(),
  profit: z.number(),
  margin: z.number(),
});
export type TripReportResponseDto = z.infer<typeof TripReportResponseDto>;

export enum TripSummaryListItemType {
  CLIENT_PAYMENT = 'Client Payment',
  AGENCY_PAID_BOOKING = 'Booking - Agency',
  CLIENT_PAID_BOOKING = 'Booking - Client',
}

export const TripSummaryListItemResponseDto = z.object({
  id: z.string().uuid(),
  type: z.nativeEnum(TripSummaryListItemType),
  name: trimmedString(),
  revenue: z.number(),
  profit: z.number(),
  dueDate: z.string().regex(isoDateRegex).optional(),
  paidAt: z.string().regex(isoDateRegex).optional(),
  createdAt: z.string().regex(isoDateRegex),
  category: trimmedString(),
  paymentSchedule: z.array(BookingExpenseResponseDto).optional(),
  hideCommissionSummary: z.boolean().optional(),
  isProposed: z.boolean().optional(),
  commissionSummary: z
    .array(
      z.object({
        id: z.string().uuid(),
        type: trimmedString(),
        amount: z.number(),
        referenceNumber: trimmedString(),
        date: z.string().regex(isoDateRegex),
      }),
    )
    .optional(),
  canceledAt: z.string().regex(isoDateRegex).optional(),
  voidedAt: z.string().regex(isoDateRegex).optional(),
  refundedAmount: z.number().optional(),
  maxRefundAmount: z.number(),
  isExchange: z.boolean().optional(),
  wasExchanged: z.boolean().optional(),
  isFareDifference: z.boolean().optional(),
  fareDifferenceDocument: FareDifferenceDocumentResponseDto.optional(),
  originalBookingId: z.string().uuid().optional(),
  invoiceStatus: z.nativeEnum(InvoiceStatus).optional(),
});
export type TripSummaryListItemResponseDto = z.infer<
  typeof TripSummaryListItemResponseDto
>;

export const TripSummaryPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['createdAt']),
  z.enum(['name']),
);
export type TripSummaryPaginatedRequestDto = z.infer<
  typeof TripSummaryPaginatedRequestDto
>;

export const TripSummaryPaginatedResponseDto = createPaginatedResponseDto(
  TripSummaryListItemResponseDto,
);
export type TripSummaryPaginatedResponseDto = z.infer<
  typeof TripSummaryPaginatedResponseDto
>;

export enum ProcessStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
}

export const SyncStatusResponseDto = z.object({
  id: z.number(),
  updatedAt: z.string().regex(isoDateRegex),
  commissionGroupId: z.string().uuid().optional(),
  tripId: z.string().uuid().optional(),
  statementId: z.string().uuid().optional(),
  giftCardTransferId: z.string().uuid().optional(),
  agencyStatementAgencyId: z.string().uuid().optional(),
  date: z.string().regex(isoDateRegex).optional(),
  status: z.nativeEnum(ProcessStatus),
  error: trimmedString().optional(),
  description: trimmedString().optional(),
});
export type SyncStatusResponseDto = z.infer<typeof SyncStatusResponseDto>;

export enum ClientFlagType {
  VIRTUOSO_MARKETING = 'Virtuoso Marketing',
  TRAVEL_LEADERS_MARKETING = 'Travel Leaders Marketing',
  PERMIT_MARKET = 'Permit Market',
  SIGNATURE_MARKETING = 'Signature Marketing',
}

export enum ClientType {
  MARKETING = 'MARKETING',
}

export enum ConsortiumType {
  VIRTUOSO_MARKETING = 'Virtuoso Marketing',
  TRAVEL_LEADERS_MARKETING = 'Travel Leaders Marketing',
}

export const ClientFlagUpdateRequestDto = z.object({
  type: z.nativeEnum(ClientFlagType),
});
export type ClientFlagUpdateRequestDto = z.infer<
  typeof ClientFlagUpdateRequestDto
>;

export const ClientFlagRequestDto = z.object({
  key: trimmedString(),
});
export type ClientFlagRequestDto = z.infer<typeof ClientFlagRequestDto>;

export const ClientFlagListRequestDto = z.object({
  type: z.nativeEnum(ClientFlagType),
});
export type ClientFlagListRequestDto = z.infer<typeof ClientFlagListRequestDto>;

export const ClientFlagResponseDto = z.object({
  key: trimmedString(),
  type: trimmedString(),
  // type: z.nativeEnum(ClientFlagType),
});
export type ClientFlagResponseDto = z.infer<typeof ClientFlagResponseDto>;

export const ClientFlagListResponseDto = z.object({
  data: z.array(ClientFlagResponseDto),
});
export type ClientFlagListResponseDto = z.infer<
  typeof ClientFlagListResponseDto
>;

export const clientFlagToDto = (
  clientFlag: ClientFlag,
): ClientFlagResponseDto => ({
  key: clientFlag.key,
  type: clientFlag.type as ClientFlagType,
});

export const TripInvoiceInstallmentDto = z.object({
  tripInvoiceInstallmentId: z.string().uuid(),
});
export type TripInvoiceInstallmentDto = z.infer<
  typeof TripInvoiceInstallmentDto
>;

export enum TripInvoiceInstallmentType {
  CREDIT_CARD_AUTH = 'CREDIT_CARD_AUTH',
  CREDIT_CARD_PAYMENT = 'CREDIT_CARD_PAYMENT',
}

export const TripInvoiceInstallmentRequestDto = z.object({
  installmentId: z.string().uuid(),
});
export type TripInvoiceInstallmentRequestDto = z.infer<
  typeof TripInvoiceInstallmentRequestDto
>;

export const TripInvoiceInstallmentCreateRequestDto = z.object({
  tripInvoiceId: z.string().uuid(),
  subject: trimmedString(),
  amount: z.number(),
  dueDate: z.string().regex(dateRegex),
  type: z.nativeEnum(TripInvoiceInstallmentType),
});
export type TripInvoiceInstallmentCreateRequestDto = z.infer<
  typeof TripInvoiceInstallmentCreateRequestDto
>;

export const TripInvoiceInstallmentUpdateRequestDto = z.object({
  subject: trimmedString(),
  amount: z.number(),
  dueDate: z.string().regex(dateRegex),
  type: z.nativeEnum(TripInvoiceInstallmentType),
});
export type TripInvoiceInstallmentUpdateRequestDto = z.infer<
  typeof TripInvoiceInstallmentUpdateRequestDto
>;

export const TripInvoiceInstallmentResponseDto = z.object({
  id: z.string().uuid(),
  subject: trimmedString(),
  amount: z.number(),
  dueDate: z.string().regex(dateRegex),
  type: z.nativeEnum(TripInvoiceInstallmentType),
  sentAt: z.string().regex(isoDateRegex).optional(),
  completedAt: z.string().regex(isoDateRegex).optional(),
  termsAndConditions: trimmedString().optional(),
  cardId: z.string().uuid().optional(),
  paymentTransactions: z.array(PaymentTransactionResponseDto).optional(),
  totalPaidAmount: z.number(),
  paymentStatus: z.nativeEnum(TripInvoiceInstallmentPaymentStatus),
});
export type TripInvoiceInstallmentResponseDto = z.infer<
  typeof TripInvoiceInstallmentResponseDto
>;

export const TripInvoiceInstallmentListResponseDto = z.object({
  data: z.array(TripInvoiceInstallmentResponseDto),
});
export type InstallmentListResponseDto = z.infer<
  typeof TripInvoiceInstallmentListResponseDto
>;

export const TripInvoiceInstallmentPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['createdAt', 'subject', 'dueDate', 'amount']),
  z.enum(['subject']),
).extend({
  tripInvoiceId: z.string().uuid().optional(),
});
export type TripInvoiceInstallmentPaginatedRequestDto = z.infer<
  typeof TripInvoiceInstallmentPaginatedRequestDto
>;

export const TripInvoiceInstallmentPaginatedResponseDto =
  createPaginatedResponseDto(TripInvoiceInstallmentResponseDto);
export type TripInvoiceInstallmentPaginatedResponseDto = z.infer<
  typeof TripInvoiceInstallmentPaginatedResponseDto
>;

export function tripInvoiceInstallmentToDto(
  tripInvoiceInstallment: TripInvoiceInstallment & {
    submit:
      | (TripInvoiceInstallmentSubmit & {
          authorization: TripInvoiceAuthorization | null;
        })
      | null;
    paymentTransactions:
      | (PaymentTransaction & {
          paymentReversals: PaymentReversal[];
          createdBy: User | null;
        })[]
      | null;
  },
): TripInvoiceInstallmentResponseDto {
  const totalPaidAmount = tripInvoiceInstallment.completedAt
    ? tripInvoiceInstallment.amount
    : calculateTotalPaidOnTripInvoiceInstallment(
        tripInvoiceInstallment.paymentTransactions || [],
      );

  // TODO: this is a guard. We need to make sure that completedAt is set on installment when CC and ACH payments settle/clear
  let completedAt = tripInvoiceInstallment.completedAt?.toISOString();
  if (
    totalPaidAmount.greaterThanOrEqualTo(tripInvoiceInstallment.amount) &&
    !completedAt
  ) {
    const latestPaymentTransactionDate = getLastPaymentDate(
      tripInvoiceInstallment.paymentTransactions,
    );
    completedAt = latestPaymentTransactionDate
      ? latestPaymentTransactionDate.toISOString()
      : undefined;
  }

  return {
    id: tripInvoiceInstallment.id,
    subject: tripInvoiceInstallment.subject,
    amount: tripInvoiceInstallment.amount.toNumber(),
    totalPaidAmount: totalPaidAmount.toNumber(),
    dueDate: tripInvoiceInstallment.dueDate?.toISOString().substring(0, 10),
    sentAt: tripInvoiceInstallment.sentAt?.toISOString(),
    type: ensureCorrectInstallmentType(tripInvoiceInstallment.type),
    completedAt,
    cardId:
      tripInvoiceInstallment.submit?.authorization?.clientProfileCreditCardId ??
      undefined,
    paymentTransactions: tripInvoiceInstallment.paymentTransactions?.map(
      (t) =>
        ({
          paymentTransactionId: t.id,
          transferId: t.partnerTransactionID,
          amount: {
            value: t.amount ? t.amount.toNumber() : 0,
            currency: t.currency || '',
          },
          status: t.overallStatus,
          createdOn: t.createdAt.toISOString(),
          sourceStatus: t.sourceStatus,
          message: '',
          paymentReversals: t.paymentReversals?.map((pr) =>
            paymentReversalToDto(pr),
          ),
          paymentProcessedByUser: t?.createdBy
            ? userToDto(t?.createdBy)
            : undefined,
        }) as PaymentTransactionResponseDto,
    ),
    paymentStatus: getTripInvoiceInstallmentPaymentStatus({
      dueDate: tripInvoiceInstallment.dueDate,
      sentAt: tripInvoiceInstallment.sentAt,
      completedAt: tripInvoiceInstallment.completedAt,
      amount: tripInvoiceInstallment.amount,
      totalPaidAmount: totalPaidAmount,
      paymentTransactions: tripInvoiceInstallment.paymentTransactions,
    }),
  };
}

function getTransactionStatus(
  paymentTransactions: (PaymentTransaction & {
    paymentReversals: PaymentReversal[];
  })[],
): TripInvoiceInstallmentPaymentStatus | null {
  const partiallyRefunded = paymentTransactions.some(paymentHasPartialRefund);
  const hasCompletedRefund = paymentTransactions.some(
    paymentHasCompletedRefund,
  );
  const hasPendingRefund = paymentTransactions.some(paymentHasPendingRefund);
  const hasPendingCancellation = paymentTransactions.some(
    paymentHasPendingCancellation,
  );
  const allFailed = paymentTransactions.every((transaction) =>
    isFailedPayment(transaction.overallStatus),
  );
  const allCancelledOrReversed = paymentTransactions.every(
    (transaction) =>
      isCancelledOrReversed(transaction.overallStatus ?? undefined) ||
      isFailedPayment(transaction.overallStatus ?? undefined),
  );
  const hasLikePendingStatus = paymentTransactions.some((transaction) =>
    isLikePendingStatus(transaction.overallStatus ?? undefined),
  );

  if (hasPendingRefund)
    return TripInvoiceInstallmentPaymentStatus.PENDING_REFUND;
  if (partiallyRefunded)
    return TripInvoiceInstallmentPaymentStatus.PARTIALLY_REFUNDED;
  if (hasCompletedRefund) return TripInvoiceInstallmentPaymentStatus.REFUNDED;
  if (hasPendingCancellation)
    return TripInvoiceInstallmentPaymentStatus.PENDING_CANCELED;
  if (allFailed) return TripInvoiceInstallmentPaymentStatus.FAILED;
  if (allCancelledOrReversed)
    return TripInvoiceInstallmentPaymentStatus.CANCELED;
  if (hasLikePendingStatus)
    return TripInvoiceInstallmentPaymentStatus.PENDING_PAID;

  return null; // No specific transaction status found
}

interface InvoiceInstallmentPaymentStatusInput {
  dueDate: Date;
  sentAt: Date | null;
  completedAt: Date | null;
  amount: Decimal; // Assuming Decimal type is defined or imported from a library
  totalPaidAmount: Decimal;
  paymentTransactions?:
    | (PaymentTransaction & { paymentReversals: PaymentReversal[] })[]
    | null;
}

export function getTripInvoiceInstallmentPaymentStatus({
  dueDate,
  sentAt,
  completedAt,
  amount,
  totalPaidAmount,
  paymentTransactions,
}: InvoiceInstallmentPaymentStatusInput): TripInvoiceInstallmentPaymentStatus {
  if (totalPaidAmount.greaterThanOrEqualTo(amount))
    return TripInvoiceInstallmentPaymentStatus.PAID;
  if (completedAt && !paymentTransactions?.length)
    return TripInvoiceInstallmentPaymentStatus.AUTHORIZED;

  // Handle transaction-related statuses in a separate function for clarity
  const txStatus = paymentTransactions?.length
    ? getTransactionStatus(paymentTransactions)
    : null;
  if (txStatus) return txStatus;

  if (moment(dueDate).isBefore(moment()))
    return TripInvoiceInstallmentPaymentStatus.LATE;
  if (sentAt) return TripInvoiceInstallmentPaymentStatus.SENT;

  return TripInvoiceInstallmentPaymentStatus.NOT_SENT;
}

export function calculateTotalPaidOnTripInvoiceInstallment(
  paymentTransactions?: (PaymentTransaction & {
    paymentReversals: PaymentReversal[];
  })[],
): Decimal {
  if (!paymentTransactions) {
    return new Decimal(0);
  }

  return (
    paymentTransactions.reduce(
      (accumulator, currentTransaction) =>
        isCompletedTransferAndFullyPaid(currentTransaction)
          ? accumulator.plus(currentTransaction?.amount ?? new Decimal(0))
          : accumulator,
      new Decimal(0),
    ) ?? new Decimal(0)
  );
}

export function tripInvoiceInstallmentToPublicDto(
  tripInvoiceInstallment: TripInvoiceInstallment & {
    tripInvoice: TripInvoice & { trip: Trip & { organization: Organization } };
    paymentTransactions:
      | (PaymentTransaction & {
          paymentReversals: PaymentReversal[];
          createdBy: User | null;
        })[]
      | null;
  },
  agency: Agency,
): PublicTripInvoiceInstallmentResponseDto {
  const currency = getCurrencyByCode(
    tripInvoiceInstallment.tripInvoice.trip.organization.homeCurrency,
  );

  const totalPaidAmount = tripInvoiceInstallment.completedAt
    ? tripInvoiceInstallment.amount
    : calculateTotalPaidOnTripInvoiceInstallment(
        tripInvoiceInstallment.paymentTransactions || [],
      );

  // TODO: this is a guard. We need to make sure that completedAt is set on installment when CC and ACH payments settle/clear
  let completedAt = tripInvoiceInstallment.completedAt?.toISOString();
  if (
    totalPaidAmount.greaterThanOrEqualTo(tripInvoiceInstallment.amount) &&
    !completedAt
  ) {
    const latestPaymentTransactionDate = getLastPaymentDate(
      tripInvoiceInstallment.paymentTransactions,
    );
    completedAt = latestPaymentTransactionDate
      ? latestPaymentTransactionDate.toISOString()
      : undefined;
  }

  return {
    id: tripInvoiceInstallment.id,
    subject: tripInvoiceInstallment.subject,
    amount: tripInvoiceInstallment.amount.toNumber(),
    totalPaidAmount: totalPaidAmount.toNumber(),
    dueDate: tripInvoiceInstallment.dueDate?.toISOString().substring(0, 10),
    type: ensureCorrectInstallmentType(tripInvoiceInstallment.type),
    completedAt,
    data: JSON.parse(
      JSON.stringify(tripInvoiceInstallment.tripInvoice.structure),
    ),
    agencyName: agency.name,
    invoiceNumber: tripInvoiceInstallment.tripInvoice.invoiceNumber,
    termsAndConditions: tripInvoiceInstallment.tripInvoice.terms ?? undefined,
    orgHomeCurrencySymbol: currency?.symbol ?? '$',
    paymentTransactions: tripInvoiceInstallment.paymentTransactions?.map(
      (t) =>
        ({
          paymentTransactionId: t.id,
          transferId: t.partnerTransactionID,
          amount: {
            value: t.amount ? t.amount.toNumber() : 0,
            currency: t.currency || '',
          },
          status: t.overallStatus,
          createdOn: t.createdAt.toISOString(),
          sourceStatus: t.sourceStatus,
          message: '',
          paymentProcessedByUser: t?.createdBy
            ? userToDto(t?.createdBy)
            : undefined,
        }) as PaymentTransactionResponseDto,
    ),
    paymentStatus: getTripInvoiceInstallmentPaymentStatus({
      dueDate: tripInvoiceInstallment.dueDate,
      sentAt: tripInvoiceInstallment.sentAt,
      completedAt: tripInvoiceInstallment.completedAt,
      amount: tripInvoiceInstallment.amount,
      totalPaidAmount: totalPaidAmount,
      paymentTransactions: tripInvoiceInstallment.paymentTransactions,
    }),
  };
}

export const InstallmentSentStatusRequestDto = z.object({
  sent: z.boolean(),
});
export type InstallmentSentStatusRequestDto = z.infer<
  typeof InstallmentSentStatusRequestDto
>;

export const TripInvoiceAuthorizationCreateRequestDto = CreditCardDto.extend({
  email: z.string().email(),
});
export type TripInvoiceAuthorizationCreateRequestDto = z.infer<
  typeof TripInvoiceAuthorizationCreateRequestDto
>;

export const TripInvoiceStructureData = z.record(z.any());
export type TripInvoiceStructureData = z.infer<typeof TripInvoiceStructureData>;

export const PublicTripInvoiceInstallmentResponseDto = z.object({
  id: z.string().uuid(),
  subject: trimmedString(),
  amount: z.number(),
  dueDate: z.string().regex(dateRegex),
  type: z.nativeEnum(TripInvoiceInstallmentType),
  completedAt: z.string().regex(isoDateRegex).optional(),
  invoiceNumber: trimmedString(),
  data: TripInvoiceStructureData,
  agencyName: trimmedString(),
  termsAndConditions: trimmedString().optional(),
  orgHomeCurrencySymbol: trimmedString(),
  paymentTransactions: z.array(PaymentTransactionResponseDto).optional(),
  totalPaidAmount: z.number(),
  paymentStatus: z.nativeEnum(TripInvoiceInstallmentPaymentStatus),
});
export type PublicTripInvoiceInstallmentResponseDto = z.infer<
  typeof PublicTripInvoiceInstallmentResponseDto
>;

export const TripInvoiceResponseDto = z.object({
  id: z.string().uuid(),
  tripId: z.string().uuid(),
  invoiceNumber: trimmedString(),
  subject: trimmedString(),
  dueDate: z.string().regex(dateRegex).optional(),
  sentAt: z.string().regex(isoDateRegex).optional(),
  amount: z.number().optional(),
  structure: TripInvoiceStructureData.optional(),
  installments: z.array(TripInvoiceInstallmentResponseDto),
  showTermsAndConditions: z.boolean(),
  termsAndConditions: trimmedString().optional(),
  recipientClient: ClientResponseDto,
  totalInstallmentsAmount: z.number(),
  totalInstallmentsPaidAmount: z.number(),
});
export type TripInvoiceResponseDto = z.infer<typeof TripInvoiceResponseDto>;

export const TripInvoicePaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['createdAt', 'subject', 'amount', 'invoiceNumber', 'installments']),
  z.enum(['subject']),
).extend({
  tripId: z.string().uuid().optional(),
});
export type TripInvoicePaginatedRequestDto = z.infer<
  typeof TripInvoicePaginatedRequestDto
>;

export const TripInvoicePaginatedResponseDto = createPaginatedResponseDto(
  TripInvoiceResponseDto,
);
export type TripInvoicePaginatedResponseDto = z.infer<
  typeof TripInvoicePaginatedResponseDto
>;

export const TripInvoiceRequestDto = z.object({
  tripInvoiceId: z.string().uuid(),
});
export type TripInvoiceRequestDto = z.infer<typeof TripInvoiceRequestDto>;

export const TripInvoiceUpdateRequestDto = z.object({
  subject: trimmedString(),
  amount: z.number().optional(),
  recipientClientId: z.string().uuid().optional(),
  showTermsAndConditions: z.boolean().optional(),
  termsAndConditions: trimmedString().optional(),
});
export type TripInvoiceUpdateRequestDto = z.infer<
  typeof TripInvoiceUpdateRequestDto
>;

export const TripInvoiceCreateRequestDto = TripInvoiceUpdateRequestDto.extend({
  invoiceNumber: trimmedString().optional(),
  tripId: z.string().uuid(),
});
export type TripInvoiceCreateRequestDto = z.infer<
  typeof TripInvoiceCreateRequestDto
>;

export function tripInvoiceToDto(
  tripInvoice: TripInvoice & {
    installments: (TripInvoiceInstallment & {
      submit:
        | (TripInvoiceInstallmentSubmit & {
            authorization: TripInvoiceAuthorization | null;
          })
        | null;
      paymentTransactions:
        | (PaymentTransaction & {
            paymentReversals: PaymentReversal[];
            createdBy: User | null;
          })[]
        | null;
    })[];
    recipientClient: Client;
  },
  defaultTerms?: string, // will only be set when fetching a single invoice
): TripInvoiceResponseDto {
  const { totalAmount, totalPaidAmount } = tripInvoice.installments.reduce(
    (totalAmount, installment) => {
      const totalPaidAmount = installment.completedAt
        ? installment.amount
        : calculateTotalPaidOnTripInvoiceInstallment(
            installment.paymentTransactions || [],
          );

      return {
        totalAmount: totalAmount.totalAmount.plus(installment.amount),
        totalPaidAmount,
      };
    },
    { totalAmount: new Decimal(0), totalPaidAmount: new Decimal(0) },
  );

  return {
    id: tripInvoice.id,
    invoiceNumber: tripInvoice.invoiceNumber,
    subject: tripInvoice.subject,
    amount: tripInvoice.amount?.toNumber(),
    tripId: tripInvoice.tripId,
    installments: tripInvoice.installments.map(tripInvoiceInstallmentToDto),
    showTermsAndConditions: tripInvoice.showTerms ?? false,
    termsAndConditions: tripInvoice.terms ?? defaultTerms ?? undefined,
    recipientClient: clientToDto(tripInvoice.recipientClient),
    totalInstallmentsAmount: totalAmount.toNumber(),
    totalInstallmentsPaidAmount: totalPaidAmount.toNumber(),
  };
}

export const TripInvoiceStructureUpdateRequestDto = z.object({
  data: TripInvoiceStructureData,
});
export type TripInvoiceStructureUpdateRequestDto = z.infer<
  typeof TripInvoiceStructureUpdateRequestDto
>;

export const TripInvoiceStructureResponseDto = z.object({
  data: TripInvoiceStructureData.optional(),
});
export type TripInvoiceStructureResponseDto = z.infer<
  typeof TripInvoiceStructureResponseDto
>;

export enum Vendor {
  LITHIC = 'LITHIC',
  CLERK = 'CLERK',
  ZAPIER_FORM = 'ZAPIER_FORM',
  MOOV = 'MOOV',
  CONNEX_PAY = 'CONNEX_PAY',
}

export enum ImportSource {
  SION = 'SION',
  PROTRAVEL = 'PROTRAVEL',
  PIQUE = 'PIQUE',
  ALLPOINTSTRAVEL = 'ALLPOINTSTRAVEL',
}

export enum MergeTaskType {
  SYNC_COMMISSION_GROUP = 'SYNC_COMMISSION_GROUP',
  FORCE_REFRESH_COMMISSION_GROUP = 'FORCE_REFRESH_COMMISSION_GROUP',
  SYNC_TRIP = 'SYNC_TRIP',
  FORCE_REFRESH_TRIP = 'FORCE_REFRESH_TRIP',
  SYNC_STATEMENT = 'SYNC_STATEMENT',
  FORCE_REFRESH_STATEMENT = 'FORCE_REFRESH_STATEMENT',
  SYNC_AGENCY_STATEMENT = 'SYNC_AGENCY_STATEMENT',
  FORCE_REFRESH_AGENCY_STATEMENT = 'FORCE_REFRESH_AGENCY_STATEMENT',
  SYNC_GIFT_CARD_TRANSFER = 'SYNC_GIFT_CARD_TRANSFER',
  FORCE_REFRESH_GIFT_CARD_TRANSFER = 'FORCE_REFRESH_GIFT_CARD_TRANSFER',
}

export const ReportExportRequestDto = z.object({
  format: z.enum(['json', 'csv', 'csv-preview']).optional(),
  showTotalFare: z
    .string()
    .transform((value) => value === 'true')
    .optional(),
});
export type ReportExportRequestDto = z.infer<typeof ReportExportRequestDto>;

export const ArcDepositRequestDto = ReportExportRequestDto.extend({
  searchTerm: trimmedString().optional(),
  sampleDate: trimmedString().optional(),
  pccs: trimmedString().optional(),
  excludeToday: trimmedString().optional(),
});
export type ArcDepositRequestDto = z.infer<typeof ArcDepositRequestDto>;

export const ArcWeekUpdateRequestDto = z.object({
  arcWeekId: z.string().uuid(),
  lockingAction: z.enum(['lock', 'unlock']),
});
export type ArcWeekUpdateRequestDto = z.infer<typeof ArcWeekUpdateRequestDto>;

export const DateRangeRequestDto = z.object({
  startDate: z.string().regex(dateRegex).optional(),
  endDate: z.string().regex(dateRegex).optional(),
});
export type DateRangeRequestDto = z.infer<typeof DateRangeRequestDto>;

export const ArcReconciliationRequestDto = DateRangeRequestDto.extend({
  isDebug: z.enum(['true', 'false']).optional(),
  isDecode: z.enum(['true', 'false']).optional(),
  pccs: z.array(z.string()).optional(),
});
export type ArcReconciliationRequestDto = z.infer<
  typeof ArcReconciliationRequestDto
>;

export const DateRequestDto = z.object({
  sampleDate: z.string().regex(dateRegex),
});
export type DateRequestDto = z.infer<typeof DateRequestDto>;

export const TokenExHmacTokenizeRequestDto = z.object({
  tokenScheme: z.enum(['PCI', 'GUID']),
});
export type TokenExHmacTokenizeRequestDto = z.infer<
  typeof TokenExHmacTokenizeRequestDto
>;

export const TokenExHmacDetokenizeRequestDto = z.object({
  token: trimmedString(),
});
export type TokenExHmacDetokenizeRequestDto = z.infer<
  typeof TokenExHmacDetokenizeRequestDto
>;

export const HmacResponseDto = z.object({
  timestamp: trimmedString(),
  hmac: trimmedString(),
});
export type HmacResponseDto = z.infer<typeof HmacResponseDto>;

export const AgencySplitDto = z.object({
  agencyId: z.string().uuid(),
  takePct: z.number().min(0).max(100),
});
export type AgencySplitDto = z.infer<typeof AgencySplitDto>;

export enum TripSplitType {
  COMMISSION = 'COMMISSION',
  FEE = 'FEE',
}

export const TripSplitRequestDto = z.object({
  type: z.nativeEnum(TripSplitType),
});
export type TripSplitRequestDto = z.infer<typeof TripSplitRequestDto>;

export const TripSplitUpdateRequestDto = z.object({
  agencySplits: z.array(AgencySplitDto),
});
export type TripSplitUpdateRequestDto = z.infer<
  typeof TripSplitUpdateRequestDto
>;

export const AgencySplitResponseDto = AgencySplitDto.extend({
  agencyName: trimmedString(),
  color: trimmedString(),
});
export type AgencySplitResponseDto = z.infer<typeof AgencySplitResponseDto>;

export const TripSplitResponseDto = z.object({
  agencySplits: z.array(AgencySplitResponseDto),
});
export type TripSplitResponseDto = z.infer<typeof TripSplitResponseDto>;

export const tripSplitsToDto = (
  tripSplits: (TripSplit & {
    agency: Agency;
  })[],
): TripSplitResponseDto => {
  return {
    agencySplits: tripSplits.map((tripSplit) => ({
      agencyId: tripSplit.agencyId,
      agencyName: tripSplit.agency.name,
      takePct: tripSplit.takePct,
      color: generateColorForId(tripSplit.agencyId),
    })),
  };
};
export const AdminOrganizationApiKeyRequestDto = z.object({
  organizationId: z.string().uuid(),
  type: z.nativeEnum(ApiKeyExternalType).optional(),
});
export type AdminOrganizationApiKeyRequestDto = z.infer<
  typeof AdminOrganizationApiKeyRequestDto
>;

export const AdminOrganizationApiKeyCreateRequestDto = z.object({
  type: z.nativeEnum(ApiKeyExternalType),
  apiKey: trimmedString().optional(),
});
export type AdminOrganizationApiKeyCreateRequestDto = z.infer<
  typeof AdminOrganizationApiKeyCreateRequestDto
>;

export const AdminOrganizationApiKeyDeleteRequestDto = z.object({
  type: z.nativeEnum(ApiKeyExternalType),
  apiKey: trimmedString(),
});
export type AdminOrganizationApiKeyDeleteRequestDto = z.infer<
  typeof AdminOrganizationApiKeyDeleteRequestDto
>;

export const AdminOrganizationApiKeyResponseDto = z.object({
  type: z.nativeEnum(ApiKeyExternalType),
  apiKey: trimmedString(),
});
export type AdminOrganizationApiKeyResponseDto = z.infer<
  typeof AdminOrganizationApiKeyResponseDto
>;

export const apiKeyOrganizationMapToAdminOrganizationApiKeyResponseDto = (
  apiKey: ApiKeyOrganizationMap,
): AdminOrganizationApiKeyResponseDto => {
  return {
    type: apiKey.externalType as ApiKeyExternalType,
    apiKey: apiKey.apiKey,
  };
};

export const AdminOrganizationApiKeyListResponseDto = z.object({
  data: z.array(AdminOrganizationApiKeyResponseDto),
});
export type AdminOrganizationApiKeyListResponseDto = z.infer<
  typeof AdminOrganizationApiKeyListResponseDto
>;

export const OneSchemaTokenResponseDto = z.object({
  token: trimmedString(),
});
export type OneSchemaTokenResponseDto = z.infer<
  typeof OneSchemaTokenResponseDto
>;

export const PnrSyncRequestQueryDto = z.object({
  pnr: trimmedString(),
  useCache: z
    .union([z.literal('true'), z.literal('false')])
    .default('false')
    .transform((v) => v === 'true')
    .optional(),
  syncTripPayouts: z
    .union([z.literal('true'), z.literal('false')])
    .transform((v) => v === 'true')
    .optional(),
  overrideLocking: z
    .union([z.literal('true'), z.literal('false')])
    .transform((v) => v === 'true')
    .optional(),
  overrideSplits: z
    .union([z.literal('true'), z.literal('false')])
    .transform((v) => v === 'true')
    .optional(),
});
export type PnrSyncRequestQueryDto = z.infer<typeof PnrSyncRequestQueryDto>;

export const UploadRequestDto = z.object({
  fileName: trimmedString(),
  mimeType: trimmedString(),
  isPublic: z.union([z.literal('true'), z.literal('false')]).optional(),
});
export type UploadRequestDto = z.infer<typeof UploadRequestDto>;

export const UploadResponseDto = z.object({
  writeUrl: trimmedString(),
  newFileName: trimmedString(),
});
export type UploadResponseDto = z.infer<typeof UploadResponseDto>;

export const MAXIMUM_GROUP_MEMBERS_RETURNED = 3;
const sortGroupMembers = (a: ClientGroup, b: ClientGroup) => {
  return a.createdAt.getTime() - b.createdAt.getTime();
};

export const groupToDto = (
  group: Group & {
    assignedToAgencyUser: (AgencyUser & { agency: Agency; user: User }) | null;
  } & {
    clients?: (ClientGroup & { client: Client })[];
  },
  options: {
    includeAllMembers?: boolean;
    canUseMergeToPnr?: boolean;
  } = { includeAllMembers: false, canUseMergeToPnr: false },
): GroupResponseDto => {
  const primaryMember = group.clients?.find(({ isPrimary }) => isPrimary);
  const members = primaryMember
    ? [
        primaryMember,
        ...(group.clients
          ?.filter(({ isPrimary }) => !isPrimary)
          .sort(sortGroupMembers)
          .slice(
            0,
            options.includeAllMembers
              ? undefined
              : MAXIMUM_GROUP_MEMBERS_RETURNED - 1,
          ) ?? []),
      ]
    : group.clients
        ?.sort(sortGroupMembers)
        .slice(
          0,
          options.includeAllMembers
            ? undefined
            : MAXIMUM_GROUP_MEMBERS_RETURNED,
        ) ?? [];

  return {
    id: group.id,
    type: group.type as GroupType,
    sourcedBy: (group.sourcedBy as SourcedBy) ?? undefined,
    name: group.name,
    industry: group.industry ?? undefined,
    dkNumber: group.dkNumber ?? undefined,
    email: group.email ?? undefined,
    phone: group.phone ?? undefined,
    address1: group.address1 ?? undefined,
    address2: group.address2 ?? undefined,
    city: group.city ?? undefined,
    state: group.state ?? undefined,
    zip: group.zip ?? undefined,
    createdAt: group.createdAt.toISOString(),
    updatedAt: group.updatedAt.toISOString(),
    color: generateColorForId(primaryMember?.clientId ?? group.id),
    primaryMemberId: primaryMember?.clientId ?? undefined,
    primaryMemberName: primaryMember
      ? clientToDto(primaryMember.client).name
      : undefined,
    memberCount: group.clients?.length ?? 0,
    members: members.map(({ client, isPrimary, relationship }) => ({
      ...clientToDto(client),
      isPrimary,
      relationship: relationship ?? undefined,
    })),
    assignedToAgencyUserId: group.assignedToAgencyUserId ?? undefined,
    assignedToAgencyUser: group.assignedToAgencyUser
      ? agencyUserToDto(group.assignedToAgencyUser)
      : undefined,
    notes: group.notes ?? undefined,
    internalClientId: group.internalClientId ?? undefined,
    canUseMergeToPnr: options.canUseMergeToPnr,
  };
};

export enum GroupMappingType {
  PROFILENO = 'PROFILENO',
}

export const ClientGroupCreateRequestDto = ClientGroupDto.extend({
  id: z.string().uuid(),
});
export type ClientGroupCreateRequestDto = z.infer<
  typeof ClientGroupCreateRequestDto
>;

export const GroupCreateRequestDto = GroupDto.extend({
  members: z.array(ClientGroupCreateRequestDto).optional(),
}).refine(
  ({ type, sourcedBy }) => type !== GroupType.CORPORATE || Boolean(sourcedBy),
  {
    message: 'sourcedBy is required for corporate groups',
  },
);
export type GroupCreateRequestDto = z.infer<typeof GroupCreateRequestDto>;

export const GroupUpdateRequestDto = GroupCreateRequestDto;
export type GroupUpdateRequestDto = z.infer<typeof GroupUpdateRequestDto>;

export const GroupPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['id', 'name', 'createdAt', 'industry', 'dkNumber', 'email']),
  z.enum(['name', 'industry', 'dkNumber', 'email']),
).extend({
  type: GroupTypeEnum,
  clientId: z.string().uuid().optional(),
  includeAllMembers: z.preprocess(
    (val) => val === 'true' || val === true,
    z.boolean().optional(),
  ),
});
export type GroupPaginatedRequestDto = z.infer<typeof GroupPaginatedRequestDto>;

export const GroupPaginatedResponseDto =
  createPaginatedResponseDto(GroupResponseDto);
export type GroupPaginatedResponseDto = z.infer<
  typeof GroupPaginatedResponseDto
>;

export const GroupRequestDto = z.object({
  groupId: z.string().uuid(),
  includeAllMembers: z.preprocess(
    (val) => val === 'true' || val === true,
    z.boolean().optional(),
  ),
});
export type GroupRequestDto = z.infer<typeof GroupRequestDto>;

export const GroupContactDto = z.object({
  name: trimmedString().optional(),
  email: trimmedString().optional(),
  phone: trimmedString().optional(),
  title: trimmedString().optional(),
  role: trimmedString().optional(),
});
export type GroupContactDto = z.infer<typeof GroupContactDto>;

export const GroupContactResponseDto = GroupContactDto.extend({
  id: z.string().uuid(),
  createdAt: z.string().regex(isoDateRegex),
  updatedAt: z.string().regex(isoDateRegex),
});
export type GroupContactResponseDto = z.infer<typeof GroupContactResponseDto>;

export const groupContactToDto = (
  groupContact: GroupContact,
): GroupContactResponseDto => {
  return {
    id: groupContact.id,
    name: groupContact.name ?? undefined,
    email: groupContact.email ?? undefined,
    phone: groupContact.phone ?? undefined,
    title: groupContact.title ?? undefined,
    role: groupContact.role ?? undefined,
    createdAt: groupContact.createdAt.toISOString(),
    updatedAt: groupContact.updatedAt.toISOString(),
  };
};

export const GroupContactCreateRequestDto = GroupContactDto;
export type GroupContactCreateRequestDto = z.infer<
  typeof GroupContactCreateRequestDto
>;

export const GroupContactUpdateRequestDto = GroupContactDto;
export type GroupContactUpdateRequestDto = z.infer<
  typeof GroupContactUpdateRequestDto
>;

export const GroupContactPaginatedRequestDto = getPaginatedRequestDto(
  z.enum(['id', 'name', 'email', 'title', 'phone', 'role', 'createdAt']),
  z.enum(['name', 'email', 'phone', 'title', 'role']),
);
export type GroupContactPaginatedRequestDto = z.infer<
  typeof GroupContactPaginatedRequestDto
>;

export const GroupContactPaginatedResponseDto = createPaginatedResponseDto(
  GroupContactResponseDto,
);
export type GroupContactPaginatedResponseDto = z.infer<
  typeof GroupContactPaginatedResponseDto
>;

export const GroupContactRequestDto = z.object({
  groupContactId: z.string().uuid(),
});
export type GroupContactRequestDto = z.infer<typeof GroupContactRequestDto>;

export const GroupNotesDto = z.object({
  notes: trimmedString().optional(),
});
export type GroupNotesDto = z.infer<typeof GroupNotesDto>;

export const BulkMatchSupplierPaymentsRequestDto = z.array(
  z.object({
    confirmationNumber: trimmedString().optional(),
    lastName: trimmedString().optional(),
    checkInDate: z.string().regex(isoDateRegex).optional(),
  }),
);
export type BulkMatchSupplierPaymentsRequestDto = z.infer<
  typeof BulkMatchSupplierPaymentsRequestDto
>;

export const BulkMatchSupplierPaymentsResponseDto = z.record(
  trimmedString(),
  z.array(z.string()),
);
export type BulkMatchSupplierPaymentsResponseDto = z.infer<
  typeof BulkMatchSupplierPaymentsResponseDto
>;

export const StatementExpenseDto = z.object({
  userId: z.string().uuid().optional(),
  frequency: ExpenseRecurrenceTypeEnum,
  categoryId: z.string().uuid().optional(),
  amount: z.number(),
  notes: trimmedString().optional(),
  isWaived: z.boolean().optional(),
  createAfterDate: z.string().regex(isoDateRegex).optional(),
  pnr: trimmedString().optional(),
});
export type StatementExpenseDto = z.infer<typeof StatementExpenseDto>;

export const StatementExpenseCreateRequestDto = StatementExpenseDto.merge(
  z.object({
    userId: z.string().uuid(),
  }),
)
  .refine(({ frequency }) => frequency !== ExpenseRecurrenceType.ROLLOVER, {
    message: 'Rollover expenses cannot be created',
  })
  .refine(({ categoryId }) => Boolean(categoryId), {
    message: 'categoryId is required when creating an expense',
  });
export type StatementExpenseCreateRequestDto = z.infer<
  typeof StatementExpenseCreateRequestDto
>;

export const StatementExpenseUpdateRequestDto = StatementExpenseDto.extend({
  userId: z.string().uuid(),
  updateAll: z.boolean().optional(),
})
  .refine(({ frequency }) => frequency !== ExpenseRecurrenceType.ROLLOVER, {
    message: 'Rollover expenses cannot be updated',
  })
  .refine(({ categoryId }) => Boolean(categoryId), {
    message: 'categoryId is required when updating an expense',
  })
  .refine(
    ({ frequency, updateAll }) =>
      !updateAll || frequency === ExpenseRecurrenceType.RECURRING,
    {
      message: 'updateAll cannot be true for one-time expenses',
    },
  );
export type StatementExpenseUpdateRequestDto = z.infer<
  typeof StatementExpenseUpdateRequestDto
>;

export const StatementExpenseCategoryResponseDto = z.object({
  id: z.string().uuid(),
  name: trimmedString(),
});
export type StatementExpenseCategoryResponseDto = z.infer<
  typeof StatementExpenseCategoryResponseDto
>;

export const StatementExpenseUserResponseDto = z.object({
  id: z.string().uuid(),
  name: trimmedString(),
});
export type StatementExpenseUserResponseDto = z.infer<
  typeof StatementExpenseUserResponseDto
>;

export const StatementExpenseResponseDto = StatementExpenseDto.extend({
  id: z.string().uuid(),
  createdAt: z.string().regex(isoDateRegex),
  user: StatementExpenseUserResponseDto.optional(),
  category: StatementExpenseCategoryResponseDto.optional(),
  endDate: z.string().regex(isoDateRegex).optional(),
  isLocked: z.boolean(),
  statementStartDate: z.string().regex(isoDateRegex),
});
export type StatementExpenseResponseDto = z.infer<
  typeof StatementExpenseResponseDto
>;

export const StatementExpensePaginatedResponse = createPaginatedResponseDto(
  StatementExpenseResponseDto,
);

export const StatementExpensePaginatedResponseDto =
  StatementExpensePaginatedResponse.extend({
    meta: StatementExpensePaginatedResponse.shape.meta.extend({
      payoutsTotal: z.number(),
    }),
  });
export type StatementExpensePaginatedResponseDto = z.infer<
  typeof StatementExpensePaginatedResponseDto
>;

export const statementExpenseToDto = (
  statementExpense: StatementExpense & {
    category: ExpenseCategory | null;
    statement:
      | (Statement & {
          user: User;
        })
      | null;
    advisorExpense: AdvisorExpense | null;
    agency: Agency & { organization: Organization };
  },
  statementStartDate: Date,
): StatementExpenseResponseDto => {
  return {
    id: statementExpense.id,
    userId: statementExpense.statement?.userId ?? undefined,
    frequency: statementExpense.advisorExpense
      ? (statementExpense.advisorExpense.type as ExpenseRecurrenceType)
      : ExpenseRecurrenceType.ONE_TIME,
    categoryId: statementExpense.categoryId ?? undefined,
    amount: statementExpense.amount.toNumber(),
    notes: statementExpense.notes ?? undefined,
    isWaived: statementExpense.isWaived,
    createdAt: statementExpense.advisorExpense
      ? new Date(
          Math.max(
            statementExpense.advisorExpense.createdAt.getTime(),
            statementStartDate.getTime(),
          ),
        ).toISOString()
      : statementExpense.createdAt.toISOString(),
    category: statementExpense.category
      ? {
          id: statementExpense.category.id,
          name: statementExpense.category.name,
        }
      : undefined,
    user: statementExpense.statement
      ? {
          id: statementExpense.statement.user.id,
          name: `${statementExpense.statement.user.firstName} ${statementExpense.statement.user.lastName}`,
        }
      : undefined,
    endDate: statementExpense.advisorExpense?.endDate?.toISOString(),
    isLocked: Boolean(
      !statementExpense.statement ||
        (statementExpense.agency.organization.statementsClosedAt &&
          statementExpense.statement.endDate.getTime() <=
            statementExpense.agency.organization.statementsClosedAt.getTime()),
    ),
    statementStartDate: statementStartDate.toISOString(),
  };
};

export const StatementExpenseRequestDto = z.object({
  userId: z.string().uuid(),
  statementExpenseId: z.string().uuid(),
});
export type StatementExpenseRequestDto = z.infer<
  typeof StatementExpenseRequestDto
>;

export const StatementExpenseDeleteRequestDto = z.object({
  deleteAll: z.enum(['true', 'false']).optional(),
});
export type StatementExpenseDeleteRequestDto = z.infer<
  typeof StatementExpenseDeleteRequestDto
>;

export const clientGroupToDto = (
  clientGroup: ClientGroup & {
    group: Group & {
      assignedToAgencyUser:
        | (AgencyUser & {
            agency: Agency;
            user: User;
          })
        | null;
    };
  },
): ClientGroupResponseDto => {
  return {
    groupId: clientGroup.groupId,
    clientId: clientGroup.clientId,
    isPrimary: clientGroup.isPrimary,
    relationship: clientGroup.relationship ?? undefined,
    group: groupToDto(clientGroup.group),
  };
};

export const XlsImportResponseDto = z.object({
  message: trimmedString(),
  agenciesCreated: z.number(),
  agenciesSkipped: z.number(),
  usersCreated: z.number(),
  usersSkipped: z.number(),
  agencyUsersCreated: z.number(),
  organizationId: z.string().uuid(),
});
export type XlsImportResponseDto = z.infer<typeof XlsImportResponseDto>;

export const DryRunnable = z.object({
  dryRun: z
    .enum(['true', 'false'])
    .transform((v) => v !== 'false') // require exactly 'false' to be false
    .optional(),
});
export type DryRunnable = z.infer<typeof DryRunnable>;

export const Limitable = z.object({
  limit: z.string().regex(numberRegex).transform(Number).optional(),
});
export type Limitable = z.infer<typeof Limitable>;

export const Offsetable = z.object({
  offset: z.string().regex(numberRegex).transform(Number).optional(),
});
export type Offsetable = z.infer<typeof Offsetable>;

export const NowOverridable = z.object({
  now: z.string().regex(isoDateRegex).optional(),
});
export type NowOverridable = z.infer<typeof NowOverridable>;

export const IsoDatable = z.object({
  date: z.string().regex(isoDateRegex).optional(),
});
export type IsoDatable = z.infer<typeof IsoDatable>;

export const Datable = z.object({
  date: z.string().regex(dateRegex).optional(),
});
export type Datable = z.infer<typeof Datable>;

export const bookingSplitToDto = (
  bookingSplit: EntitySplitConfig & {
    agency: Agency | null;
    agencyUser: AgencyUser & { user: User };
    booking: Booking | null;
  },
  isOrgUser: boolean,
): SplitResponseDto => {
  return {
    agencyUserId: bookingSplit.agencyUserId,
    agencyId: bookingSplit.agencyId ?? undefined,
    name:
      bookingSplit.agency?.name ??
      `${bookingSplit.agencyUser?.user.firstName} ${bookingSplit.agencyUser?.user.lastName}`,
    canEdit: isOrgUser,
    color: generateColorForId(
      bookingSplit.agencyId ?? bookingSplit.agencyUserId ?? bookingSplit.id,
    ),
    type: bookingSplit?.agencyId ? SplitType.AGENCY : SplitType.ADVISOR,
    takePercent: bookingSplit.takePercent.toNumber(),
    isOverride: false,
  };
};

export const BookingSplitCreateRequestDto = SplitDto.extend({
  agencyUserId: trimmedString(),
});
export type BookingSplitCreateRequestDto = z.infer<
  typeof BookingSplitCreateRequestDto
>;

export const BookingSplitUpdateRequestDto = BookingSplitCreateRequestDto.extend(
  {
    agencyId: z.string().uuid().optional(),
  },
).refine(({ agencyId, agencyUserId }) => agencyId || agencyUserId, {
  message: 'agencyId or agencyUserId is required',
});
export type BookingSplitUpdateRequestDto = z.infer<
  typeof BookingSplitUpdateRequestDto
>;

export const BookingSplitRequestDto = z.object({
  bookingSplitId: z.string().uuid(),
});
export type BookingSplitRequestDto = z.infer<typeof BookingSplitRequestDto>;

export const DefaultTripSplitsRequestDto = z.object({
  agencyUserId: z.string().uuid(),
  primaryClientId: z.string().uuid().optional(),
  corporateGroupId: z.string().uuid().optional(),
});
export type DefaultTripSplitsRequestDto = z.infer<
  typeof DefaultTripSplitsRequestDto
>;

export type HydratedBaseSplit = {
  id?: string;
  agencyUser: AgencyUser & { user: User };
  agency: Agency | null;
  supplierType: string;
  takePercent: number;
  isOverride: boolean;
};

export const hydratedBaseSplitToSplitResponseDto = (
  hydratedBaseSplit: HydratedBaseSplit,
  canEdit: boolean,
): SplitResponseDto => {
  return {
    id: hydratedBaseSplit.id,
    agencyUserId: hydratedBaseSplit.agencyUser.id,
    agencyId: hydratedBaseSplit.agency?.id ?? undefined,
    name:
      hydratedBaseSplit.agency?.name ??
      `${hydratedBaseSplit.agencyUser?.user.firstName} ${hydratedBaseSplit.agencyUser?.user.lastName}`,
    canEdit,
    color: hydratedBaseSplit.agency?.id
      ? generateColorForId(hydratedBaseSplit.agency.id)
      : hydratedBaseSplit.agencyUser?.id
        ? generateColorForId(hydratedBaseSplit.agencyUser.id)
        : '#000',
    type: hydratedBaseSplit?.agency?.id ? SplitType.AGENCY : SplitType.ADVISOR,
    takePercent: hydratedBaseSplit.takePercent,
    isOverride: hydratedBaseSplit.isOverride,
  };
};

export const PnrSyncResponseDto = z.object({
  tripId: z.string().uuid(),
  status: trimmedString(),
});
export type PnrSyncResponseDto = z.infer<typeof PnrSyncResponseDto>;

export const DefaultGroupSplitsRequestDto = z.object({
  agencyUserId: z.string().uuid(),
});
export type DefaultGroupSplitsRequestDto = z.infer<
  typeof DefaultGroupSplitsRequestDto
>;

export const TaskDto = z.object({
  title: trimmedString(),
  description: trimmedString(),
  agencyUserId: z.string().uuid().optional(),
  agencyUserIds: z.array(z.string().uuid()).optional(),
  remindAt: z.string().regex(isoDateRegex).optional(),
  dueAt: z.string().regex(isoDateRegex).optional(),
  completedAt: z.string().regex(isoDateRegex).optional(),
  isCompleted: z.boolean(),
  trigger: z.nativeEnum(TaskTriggerType),
  triggerTiming: z.nativeEnum(TaskTriggerTiming),
  triggerTimingQualifier: z.nativeEnum(TaskTriggerTimingQualifier),
  triggerTimingDuration: z.number(),
  triggerDate: z.string().regex(isoDateRegex).optional(),
  sendEmail: z.boolean().optional(),
});
export type TaskDto = z.infer<typeof TaskDto>;

export const TaskPaginatedRequestDto = getPaginatedRequestDto(
  z.enum([
    'title',
    'description',
    'trip',
    'remindAt',
    'agencyUser',
    'createdBy',
    'createdAt',
    'dueAt',
    'client',
  ]),
  z.enum(['title']),
).merge(ReportingTimeboundRequestParams);
export type TaskPaginatedRequestDto = z.infer<typeof TaskPaginatedRequestDto>;

export const TaskRequestDto = z.object({
  taskId: z.string().uuid(),
});
export type TaskRequestDto = z.infer<typeof TaskRequestDto>;

export const TaskCreateRequestDto = TaskDto;
export type TaskCreateRequestDto = z.infer<typeof TaskCreateRequestDto>;

export const TaskUpdateRequestDto = TaskDto;
export type TaskUpdateRequestDto = z.infer<typeof TaskUpdateRequestDto>;

export const TaskResponseDto = TaskDto.extend({
  id: z.string().uuid(),
  trip: z
    .object({
      id: z.string().uuid(),
      name: trimmedString(),
      agencyUser: AgencyUserResponseDto,
    })
    .optional(),
  agencyUser: AgencyUserResponseDto.optional(),
  agencyUsers: z.array(AgencyUserResponseDto),
  createdBy: UserResponseDto,
  createdAt: z.string().regex(isoDateRegex),
  client: ClientResponseDto.optional(),
});
export type TaskResponseDto = z.infer<typeof TaskResponseDto>;

export const TaskPaginatedResponseDto =
  createPaginatedResponseDto(TaskResponseDto);
export type TaskPaginatedResponseDto = z.infer<typeof TaskPaginatedResponseDto>;

export const taskToDto = (task: TaskWithInclude): TaskResponseDto => {
  const trigger = task.triggers[0];

  const triggerType = trigger?.triggerType ?? TaskTriggerType.NONE;

  const agencyUsers =
    task.agencyUsers.length > 0
      ? task.agencyUsers.map((taskAgencyUser) => taskAgencyUser.agencyUser)
      : task.agencyUser
        ? [task.agencyUser]
        : task.trip?.agencyUser
          ? [task.trip.agencyUser]
          : [];

  return {
    id: task.id,
    isCompleted: Boolean(task.completedAt),
    title: task.title,
    description: task.description,
    agencyUserId:
      task.agencyUserId ??
      task.agencyUsers[0]?.agencyUserId ??
      task.trip?.agencyUserId,
    remindAt: task.remindAt?.toISOString(),
    completedAt: task.completedAt?.toISOString(),
    dueAt: trigger?.scheduledAt?.toISOString() ?? task.dueAt?.toISOString(),
    ...(task.trip
      ? {
          trip: {
            id: task.trip.id,
            name: task.trip.name,
            agencyUser: agencyUserToDto(task.trip.agencyUser),
          },
        }
      : {}),
    agencyUser: task.agencyUser
      ? agencyUserToDto(task.agencyUser)
      : task.agencyUsers[0]?.agencyUser
        ? agencyUserToDto(task.agencyUsers[0].agencyUser)
        : task.trip?.agencyUser
          ? agencyUserToDto(task.trip.agencyUser)
          : undefined,
    agencyUsers: agencyUsers.map(agencyUserToDto),
    createdBy: userToDto(task.createdBy),
    createdAt: task.createdAt.toISOString(),
    client: task.trip?.primaryClient
      ? clientToMinimalDto(task.trip?.primaryClient)
      : undefined,
    trigger: triggerType as TaskTriggerType,
    triggerTiming:
      trigger?.offset === 0
        ? TaskTriggerTiming.DAY_OF
        : TaskTriggerTiming.BEFORE_AFTER,
    triggerTimingQualifier:
      trigger?.offset > 0
        ? TaskTriggerTimingQualifier.AFTER
        : TaskTriggerTimingQualifier.BEFORE,
    triggerTimingDuration: Math.abs(trigger?.offset ?? 0),
    triggerDate: trigger?.scheduledAt?.toISOString(),
  };
};

export enum OrganizationHostConfigType {
  NONE = 'NONE',
  ARC = 'ARC',
}

export const OrganizationHostConfigDto = z.object({
  type: z.nativeEnum(OrganizationHostConfigType).optional(),
  takePercent: z.number(),
});
export type OrganizationHostConfigDto = z.infer<
  typeof OrganizationHostConfigDto
>;

export const OrganizationHostConfigRequestDto = z.object({
  organizationHostConfigId: z.string().uuid(),
});
export type OrganizationHostConfigRequestDto = z.infer<
  typeof OrganizationHostConfigRequestDto
>;

export const OrganizationHostConfigCreateRequestDto = OrganizationHostConfigDto;
export type OrganizationHostConfigCreateRequestDto = z.infer<
  typeof OrganizationHostConfigCreateRequestDto
>;

export const OrganizationHostConfigUpdateRequestDto =
  OrganizationHostConfigDto.omit({
    type: true,
  });
export type OrganizationHostConfigUpdateRequestDto = z.infer<
  typeof OrganizationHostConfigUpdateRequestDto
>;

export const OrganizationHostConfigResponseDto =
  OrganizationHostConfigDto.extend({
    id: z.string().uuid(),
  });
export type OrganizationHostConfigResponseDto = z.infer<
  typeof OrganizationHostConfigResponseDto
>;

export const OrganizationHostConfigListResponseDto = z.object({
  data: z.array(OrganizationHostConfigResponseDto),
});
export type OrganizationHostConfigListResponseDto = z.infer<
  typeof OrganizationHostConfigListResponseDto
>;

export const organizationHostConfigToDto = (
  organizationHostConfig: OrganizationHostConfig,
): OrganizationHostConfigResponseDto => ({
  id: organizationHostConfig.id,
  type:
    (organizationHostConfig?.type as OrganizationHostConfigType) ??
    OrganizationHostConfigType.NONE,
  takePercent: organizationHostConfig.takePercent.toNumber(),
});

export const ProcessPaymentRequestDto = CreditCardDto.omit({
  email: true,
  name: true,
  zip: true,
}).extend({
  email: z.string().email(),
  tripInvoiceInstallmentId: z.string().uuid().optional(),
  amount: MoneySchema.optional(),
  idempotentKey: trimmedString().optional(),
  zip: trimmedString(),
  name: trimmedString(),
});
export type ProcessPaymentRequestDto = z.infer<typeof ProcessPaymentRequestDto>;

export const PaylocityExportRequestDto = z.object({
  date: z.string().regex(isoDateRegex).optional(),
});
export type PaylocityExportRequestDto = z.infer<
  typeof PaylocityExportRequestDto
>;

export enum TripSource {
  SABRE = 'SABRE',
  IMPORT = 'IMPORT',
}

// this was moved here to avoid circular references
export enum TripInvoiceV2InstallmentType {
  APPROVAL = 'approval',
  PAYMENT = 'payment',
}

// While we migrate to Invoices V2, we have some overlapping enums that need to be handled
function ensureCorrectInstallmentType(
  type: string,
): TripInvoiceInstallmentType {
  if (type === TripInvoiceV2InstallmentType.APPROVAL) {
    return TripInvoiceInstallmentType.CREDIT_CARD_AUTH;
  }
  if (type === TripInvoiceV2InstallmentType.PAYMENT) {
    return TripInvoiceInstallmentType.CREDIT_CARD_PAYMENT;
  }

  return type as TripInvoiceInstallmentType;
}

export const BatchIdsRequestDto = z.object({
  ids: z.array(z.string().uuid()),
});
export type BatchIdsRequestDto = z.infer<typeof BatchIdsRequestDto>;

export const OrganizationStatementsClosedTimeRequestDto = z.object({
  closeStatementContainingDate: z.string().regex(isoDateRegex),
});
export type OrganizationStatementsClosedTimeRequestDto = z.infer<
  typeof OrganizationStatementsClosedTimeRequestDto
>;

export const OrganizationStatementsClosedTimeResponseDto = z.object({
  statementsClosedAt: z.union([z.string().regex(isoDateRegex), z.null()]),
});
export type OrganizationStatementsClosedTimeResponseDto = z.infer<
  typeof OrganizationStatementsClosedTimeResponseDto
>;

export const SlackCommandBody = z.object({
  token: trimmedString(),
  team_id: trimmedString(),
  team_domain: trimmedString(),
  channel_id: trimmedString(),
  channel_name: trimmedString(),
  user_id: trimmedString(),
  user_name: trimmedString(),
  command: trimmedString(),
  text: trimmedString(),
  api_app_id: trimmedString(),
  is_enterprise_install: trimmedString(),
  response_url: trimmedString(),
  trigger_id: trimmedString(),
});
export type SlackCommandBody = z.infer<typeof SlackCommandBody>;

export const StatementBucketsListRequestDto = z.object({
  includeClosedStatements: z
    .enum(['true', 'false'])
    .transform((v) => v === 'true')
    .optional(),
});
export type StatementBucketsListRequestDto = z.infer<
  typeof StatementBucketsListRequestDto
>;

export const StatementBucketDto = z.object({
  startDate: z.string().regex(isoDateRegex),
  endDate: z.string().regex(isoDateRegex),
  isOpen: z.boolean(),
});
export type StatementBucketDto = z.infer<typeof StatementBucketDto>;

export const statementBucketToDto = (
  { startDate, endDate }: StatementBucket,
  statementsClosedAt: Date | null,
): StatementBucketDto => ({
  startDate: startDate.toISOString(),
  endDate: endDate.toISOString(),
  isOpen: !statementsClosedAt || startDate >= statementsClosedAt,
});

export const StatementBucketsListResponseDto = z.object({
  data: z.array(StatementBucketDto),
});
export type StatementBucketsListResponseDto = z.infer<
  typeof StatementBucketsListResponseDto
>;

export const StatementReportRequestDto = z
  .object({
    agencyId: z.string().uuid().optional(),
    userId: z.string().uuid().optional(),
    startDate: z.string().regex(isoDateRegex),
  })
  .refine(({ agencyId, userId }) => agencyId || userId, {
    message: 'agencyId or userId is required',
  });
export type StatementReportRequestDto = z.infer<
  typeof StatementReportRequestDto
>;

export const AllStatementsReportRequestDto = z.object({
  startDate: z.string().regex(isoDateRegex),
  agencyId: trimmedString().optional(),
});
export type AllStatementsReportRequestDto = z.infer<
  typeof AllStatementsReportRequestDto
>;

export enum Gender {
  Male = 'M',
  Female = 'F',
  Undisclosed = 'U',
  NonBinary = 'X',
}

export const genderToString = (gender?: Gender) => {
  switch (gender) {
    case Gender.Male:
      return 'Male';
    case Gender.Female:
      return 'Female';
    case Gender.NonBinary:
      return 'Non-Binary';
    case Gender.Undisclosed:
      return 'Undisclosed';
    default:
      return '';
  }
};

export const PccGroupDto = z.object({
  name: trimmedString(),
  pccs: z.array(z.string()),
  arcSupplierPaymentMethodName: trimmedString().optional(),
  accountRemoteId: trimmedString().optional(),
});
export type PccGroupDto = z.infer<typeof PccGroupDto>;

export const PccGroupCreateRequestDto = PccGroupDto;
export type PccGroupCreateRequestDto = z.infer<typeof PccGroupCreateRequestDto>;

export const PccGroupUpdateRequestDto = PccGroupDto;
export type PccGroupUpdateRequestDto = z.infer<typeof PccGroupUpdateRequestDto>;

export const PccGroupRequestDto = z.object({
  pccGroupId: z.string().uuid(),
});
export type PccGroupRequestDto = z.infer<typeof PccGroupRequestDto>;

export const PccGroupResponseDto = PccGroupDto.extend({
  id: z.string().uuid(),
});
export type PccGroupResponseDto = z.infer<typeof PccGroupResponseDto>;

export function PccGroupToDto(
  pccGroup: PccGroup & { pccConfigs?: PccConfig[] },
): PccGroupResponseDto {
  return {
    id: pccGroup.id,
    name: pccGroup.name,
    pccs: pccGroup.pccConfigs?.map((pccConfig) => pccConfig.pcc) ?? [],
    arcSupplierPaymentMethodName:
      pccGroup.arcSupplierPaymentMethodName ?? undefined,
    accountRemoteId: pccGroup.accountRemoteId ?? undefined,
  };
}

export const PccGroupPaginatedResponseDto =
  createPaginatedResponseDto(PccGroupResponseDto);
export type PccGroupPaginatedResponseDto = z.infer<
  typeof PccGroupPaginatedResponseDto
>;

export const PccConfigDto = z.object({
  pcc: trimmedString(),
  acceptArc: z.boolean(),
  address1: trimmedString().optional(),
  address2: trimmedString().optional(),
  city: trimmedString().optional(),
  state: trimmedString().optional(),
  zip: trimmedString().optional(),
  country: trimmedString().optional(),
  pccGroupId: z.string().uuid().optional(),
  arcClassNameOverride: trimmedString().optional(),
});
export type PccConfigDto = z.infer<typeof PccConfigDto>;

export const PccConfigCreateRequestDto = PccConfigDto;
export type PccConfigCreateRequestDto = z.infer<
  typeof PccConfigCreateRequestDto
>;

export const PccConfigUpdateRequestDto = PccConfigDto.omit({
  pcc: true,
});
export type PccConfigUpdateRequestDto = z.infer<
  typeof PccConfigUpdateRequestDto
>;

export const PccConfigRequestDto = z.object({
  pccConfigId: z.string().uuid(),
});
export type PccConfigRequestDto = z.infer<typeof PccConfigRequestDto>;

export const PccConfigResponseDto = PccConfigDto.extend({
  id: z.string().uuid(),
  pccGroup: PccGroupResponseDto.optional(),
});
export type PccConfigResponseDto = z.infer<typeof PccConfigResponseDto>;

export function PccConfigToDto(
  pccConfig: PccConfig & {
    pccGroup: PccGroup | null;
  },
): PccConfigResponseDto {
  return {
    id: pccConfig.id,
    pcc: pccConfig.pcc,
    acceptArc: pccConfig.acceptArc,
    address1: pccConfig.address1 ?? undefined,
    address2: pccConfig.address2 ?? undefined,
    city: pccConfig.city ?? undefined,
    state: pccConfig.state ?? undefined,
    zip: pccConfig.zip ?? undefined,
    country: pccConfig.country ?? undefined,
    pccGroupId: pccConfig.pccGroupId ?? undefined,
    pccGroup: pccConfig.pccGroup
      ? PccGroupToDto(pccConfig.pccGroup)
      : undefined,
    arcClassNameOverride: pccConfig.arcClassNameOverride ?? undefined,
  };
}

export const PccConfigPaginatedResponseDto =
  createPaginatedResponseDto(PccConfigResponseDto);
export type PccConfigPaginatedResponseDto = z.infer<
  typeof PccConfigPaginatedResponseDto
>;

export const ClientPaymentMethodsRequestDto = z.object({
  clientId: z.string().uuid().optional(),
  clientInvoiceId: z.string().uuid().optional(),
});
export type ClientPaymentMethodsRequestDto = z.infer<
  typeof ClientPaymentMethodsRequestDto
>;

export const TripWalletClientPayment = z.object({
  id: z.string().uuid(),
  subject: trimmedString(),
  amount: z.number(),
  from: trimmedString(),
  paymentMethod: trimmedString().optional(),
  createdAt: z.string().regex(isoDateRegex),
  dueAt: z.string().regex(isoDateRegex).optional(),
  paidAt: z.string().regex(isoDateRegex).optional(),
  entity: ClientInvoiceResponseDto,
});
export type TripWalletClientPayment = z.infer<typeof TripWalletClientPayment>;

export enum SupplierPaymentType {
  AGENCY_PAID = 'Agency Paid',
  REFUNDED = 'Refunded',
}

export const TripWalletSupplierPayment = z.object({
  id: z.string().uuid(),
  entityId: z.string().uuid(),
  subject: trimmedString(),
  amount: z.number(),
  type: z.nativeEnum(SupplierPaymentType),
  createdAt: z.string().regex(isoDateRegex),
  dueAt: z.string().regex(isoDateRegex).optional(),
  paidAt: z.string().regex(isoDateRegex).optional(),
  entity: BookingExpenseResponseDto,
});
export type TripWalletSupplierPayment = z.infer<
  typeof TripWalletSupplierPayment
>;

export const TripWalletMarkupWithdrawal = z.object({
  id: z.string().uuid(),
  amount: z.number(),
  createdAt: z.string().regex(isoDateRegex),
  createdBy: trimmedString(),
  fromClientAmount: z.number(),
  toSupplierAmount: z.number(),
});
export type TripWalletMarkupWithdrawal = z.infer<
  typeof TripWalletMarkupWithdrawal
>;

export const TripWalletMeta = z.object({
  collectedAmount: z.number(),
  collectedTotal: z.number(),
  collectedPercentage: z.number(),
  paidAmount: z.number(),
  paidTotal: z.number(),
  paidPercentage: z.number(),
  markupWithdrawnAmount: z.number(),
  markupAvailable: z.number(),
  markupTotal: z.number(),
  markupPercentage: z.number(),
});
export type TripWalletMeta = z.infer<typeof TripWalletMeta>;

export const TripWalletResponseDto = z.object({
  meta: TripWalletMeta,
  markupWithdrawals: z.array(TripWalletMarkupWithdrawal),
  clientPayments: z.array(TripWalletClientPayment),
  supplierPayments: z.array(TripWalletSupplierPayment),
});
export type TripWalletResponseDto = z.infer<typeof TripWalletResponseDto>;

export const PublicUploadRequestDto = z.object({
  clientId: trimmedString(),
});
export type PublicUploadRequestDto = z.infer<typeof PublicUploadRequestDto>;

export const PublicUploadResponseDto = z.object({
  client: z.object({
    id: z.string().uuid(),
    name: trimmedString().optional(),
    email: trimmedString().optional(),
  }),
  agency: z.object({
    name: trimmedString(),
    imageUrl: trimmedString().optional(),
  }),
});
export type PublicUploadResponseDto = z.infer<typeof PublicUploadResponseDto>;

export const AgencyUserMappingRequestDto = z.object({
  agentSine: trimmedString(),
  agentInterfaceId: trimmedString(),
});
export type AgencyUserMappingRequestDto = z.infer<
  typeof AgencyUserMappingRequestDto
>;

export const AgencyUsersExtendedRequestDto = z.object({
  agencyId: z.string().uuid().optional(),
  pcc: trimmedString().optional(),
});
export type AgencyUsersExtendedRequestDto = z.infer<
  typeof AgencyUsersExtendedRequestDto
>;

export const AgencyUserExtendedResponseDto = AgencyUserResponseDto.extend({
  sabreSine: trimmedString().optional(),
  sabreInterfaceId: trimmedString().optional(),
  pccConfigId: z.string().optional(),
});
export type AgencyUserExtendedResponseDto = z.infer<
  typeof AgencyUserExtendedResponseDto
>;

export const agencyUserToExtendedDto = (
  agencyUser: AgencyUser & {
    agency: Agency;
    user: User;
    mappings: (AgencyUserMapping & { pccConfig: PccConfig | null })[];
  },
): AgencyUserExtendedResponseDto => {
  const dto = agencyUserToDto(agencyUser) as AgencyUserExtendedResponseDto;
  const sabreSineMapping = agencyUser.mappings.find(
    (mapping) => mapping.type === AgencyUserMappingType.SABRE_SINE,
  );
  const sabreInterfaceIdMapping = agencyUser.mappings.find(
    (mapping) => mapping.type === AgencyUserMappingType.SABRE_INTERFACE_ID,
  );

  return {
    ...dto,
    sabreSine: sabreSineMapping?.externalId,
    sabreInterfaceId: sabreInterfaceIdMapping?.externalId,
    pccConfigId:
      sabreSineMapping?.pccConfigId ??
      sabreInterfaceIdMapping?.pccConfigId ??
      undefined,
  };
};

export const SupplierHasDownloadableInvoiceDto = z.object({
  hasDownloadableInvoice: z.boolean(),
});
export type SupplierHasDownloadableInvoiceDto = z.infer<
  typeof SupplierHasDownloadableInvoiceDto
>;

export function checkUserSupportsDirectPayment(
  clientInvoice: ClientInvoice & {
    recipientClient: Client & {
      creditCards: ClientProfileCreditCard[];
      addresses: ClientAddress[];
    };
    clientPaymentMethod: ClientPaymentMethod | null;
  },
  exchangeRateToUsd: number,
  isOrgUser: boolean | undefined,
) {
  const activeCCs = filterExpiredCreditCards(
    clientInvoice.recipientClient.creditCards,
  );

  const paymentMethodSupportsDirectPayment =
    clientInvoice.clientPaymentMethod &&
    DIRECT_PAYMENT_METHOD_NAMES.includes(
      clientInvoice.clientPaymentMethod.name,
    );

  const ccAddresses = clientInvoice.recipientClient.creditCards.filter(
    (a) => a.address1 && a.city && a.state && a.zip,
  );
  const addresses = clientInvoice.recipientClient.addresses.filter(
    (address) => {
      return address.address1 && address.city && address.state && address.zip;
    },
  );

  // the processingCost should already be part of the invoice amount b/c the amount is the total
  // including any processing fees
  const totalUsd = clientInvoice.amount.times(exchangeRateToUsd);

  const supportsDirectPayment =
    // invoice cannot be paid
    clientInvoice.paidAt === null &&
    // our payment processor ConnexPay only supports USD payments
    clientInvoice.currency === USD &&
    // client must have at least one non-expired credit card
    activeCCs.length > 0 &&
    // client invoice must have a compatible payment method
    paymentMethodSupportsDirectPayment &&
    // invoice amount must be <= $2000 USD equivalent (converted from whatever currency the invoice is in)
    (totalUsd.lessThanOrEqualTo(
      new Decimal(CLIENT_INVOICE_CC_MAX_AMOUNT_FOR_NON_ORG_USER),
    ) ||
      isOrgUser) &&
    // there is an address on the client
    (addresses.length > 0 || ccAddresses.length > 0);

  const unprocessableReasons = [];
  if (!supportsDirectPayment) {
    if (clientInvoice.paidAt !== null) {
      unprocessableReasons.push('Invoice is already paid');
    }
    if (clientInvoice.currency !== USD) {
      unprocessableReasons.push('Currency is not USD');
    }
    if (activeCCs.length === 0) {
      unprocessableReasons.push('No active credit cards');
    }
    if (!paymentMethodSupportsDirectPayment) {
      unprocessableReasons.push(
        'Payment method does not support direct payment',
      );
    }
    if (
      totalUsd.greaterThan(
        new Decimal(CLIENT_INVOICE_CC_MAX_AMOUNT_FOR_NON_ORG_USER),
      ) &&
      !isOrgUser
    ) {
      unprocessableReasons.push(
        `Amount exceeds $${CLIENT_INVOICE_CC_MAX_AMOUNT_FOR_NON_ORG_USER} USD equivalent`,
      );
    }
    if (addresses.length === 0 && ccAddresses.length === 0) {
      unprocessableReasons.push('No addresses on file');
    }
  }
  return { supportsDirectPayment, unprocessableReasons };
}

export enum EmailRecipientType {
  CC = 'CC',
  BCC = 'BCC',
}

export const AdminGetPayoutsRequestDto = z.object({
  entityId: z.string().uuid(),
});
export type AdminGetPayoutsRequestDto = z.infer<
  typeof AdminGetPayoutsRequestDto
>;
export const AdminPayoutResponseDto = z.object({
  data: z.array(
    z.object({
      id: z.string().uuid(),
      createdAt: z.string().regex(isoDateRegex),
      deletedAt: z.string().regex(isoDateRegex).optional(),
      receivedAt: z.string().regex(isoDateRegex),
      recipientName: trimmedString(),
      payoutToName: trimmedString().optional(),
      entityId: z.string().uuid(),
      receivedAmount: z.number(),
      takeAmount: z.number(),
      payoutAmount: z.number(),
      agencyUserName: trimmedString().optional(),
    }),
  ),
});
export type AdminPayoutResponseDto = z.infer<typeof AdminPayoutResponseDto>;

export const AdminBackdatePayoutsRequestDto = z.object({
  backdateToDate: z.string().regex(dateRegex),
  payoutIds: z.array(z.string().uuid()),
});
export type AdminBackdatePayoutsRequestDto = z.infer<
  typeof AdminBackdatePayoutsRequestDto
>;

export const adminPayoutsInclude = {
  organization: true,
  agency: true,
  user: true,
  payoutToAgency: true,
  payoutToUser: true,
  agencyUser: { include: { user: true, agency: true } },
} satisfies Prisma.PayoutInclude;

export type AdminPayoutsWithInclude = Prisma.PayoutGetPayload<{
  include: typeof adminPayoutsInclude;
}>;

export const payoutsToAdminDto = (
  payouts: AdminPayoutsWithInclude[],
): AdminPayoutResponseDto => {
  return {
    data: payouts.map((payout) => ({
      id: payout.id,
      createdAt: payout.createdAt.toISOString(),
      deletedAt: payout.deletedAt?.toISOString(),
      receivedAt: payout.receivedAt.toISOString(),
      recipientName:
        payout.organization?.name ??
        payout.agency?.name ??
        `${payout.user?.firstName} ${payout.user?.lastName}`,
      payoutToName:
        payout.payoutToAgency?.name ??
        (payout.payoutToUser
          ? `${payout.payoutToUser.firstName} ${payout.payoutToUser.lastName}`
          : undefined),
      entityId:
        payout.commissionId ??
        payout.feePaymentId ??
        payout.tripMarkupWithdrawalId ??
        '',
      receivedAmount: payout.receivedAmountUsd?.toNumber() ?? 0,
      takeAmount: payout.takeAmountUsd.toNumber(),
      payoutAmount: payout.payoutAmountUsd.toNumber(),
      agencyUserName: payout.agencyUser
        ? `${payout.agencyUser.user.firstName} ${payout.agencyUser.user.lastName} / ${payout.agencyUser.agency.name}`
        : undefined,
    })),
  };
};

export const SpotnanaNotificationRequestDto = z.object({
  notificationId: z.string().transform((v) => Number(v)),
});
export type SpotnanaNotificationRequestDto = z.infer<
  typeof SpotnanaNotificationRequestDto
>;

export const OpenAiMessageDto = z.object({
  id: z.number().default(0),
  role: z.string(),
  shouldDisplay: z.boolean(),
  shouldSendToOpenAi: z.boolean(),
  content: z.string(),
});
export type OpenAiMessageDto = z.infer<typeof OpenAiMessageDto>;

export const OpenAiConversationDto = z.object({
  data: z.array(OpenAiMessageDto),
});
export type OpenAiConversationDto = z.infer<typeof OpenAiConversationDto>;

export const LayerBusinessCredentialsDto = z
  .object({
    businessId: z.string(),
  })
  .and(
    z.union([
      z.object({
        businessAccessToken: z.string(),
      }),
      z.object({
        appId: z.string(),
        appSecret: z.string(),
      }),
    ]),
  );
export type LayerBusinessCredentialsDto = z.infer<
  typeof LayerBusinessCredentialsDto
>;
