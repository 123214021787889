export enum DocumentType {
  // Sabre ARC (AIR) that should be able to be viewed in the ARC Dashboard
  ARC_DOCUMENT_TYPE = 'A',
  SUBMIT_TO_SUPPLIER = 'S',
  // Sabre AIR bookings that should NOT show up in the ARC Dashboard.
  // They are basically the same as ARC_DOCUMENT_TYPE but the organization has specified that they don't want these to be treated as ARC
  SABRE_AIR = 'SABRE_AIR',
}

export const ARC_SUBMIT_TO = 'ARC';
export const CADENCE = 'Cadence';
export const MWT = 'McCabe World Travel';
export const A2T = 'Avenue Two Travel';
export const BROWNELL = 'Brownell';
export const ORIGINAL_DOCUMENT_NUMBER = 'ORIGINAL_DOCUMENT_NUMBER';
export const TICKET_NUMBER = 'TICKET_NUMBER';
export const EXCHANGED_TICKET_NUMBER = 'EXCHANGED_TICKET_NUMBER';
// this is a PCC that is used for Virtuoso bookings created by agents
// This PCC is used by many of our customer organizations to track Virtuoso bookings
export const VIRTUOSO_PCC = 'B7ZB';

// Error strings
export const PccAssociatedWithMoreThanOneOrgErr =
  'PCC is associated with more than one organization. Cannot create default agency user.';
export const PccDoesNotExistInAnyOrganizationErr = `PCC doesn't exist in any organization. Cannot create default agency user.`;

export const SABRE_STATEMENT_EXPENSE_CATEGORY = 'Air Dept Fee';
export const FARE_DIFF_DOC_NOTE =
  'This is a residual/fare difference document.';

// TODO: this makes most sense to do in a separate table related to PccConfig but there was a migration freeze when this was developed
//       so this is an interim solution
// Map of PCCs and which IATA numbers they are allowed to use for ticketing
// e.g. PCC Q0CA is allowed to use 05515171
export const MapOfPccsToAllowedIataNumbers = new Map([
  ['Q0CA', '05515171'],
  ['45CH', '05515171'],
  ['P45I', '05515171'],
  ['8I2I', '05515171'],
]);

export const A2T_PCC = 'C1BI';
export const WALLACE_PIERSON_PCC = 'TP80';
