import type {
  Agency,
  AgencyUser,
  Booking,
  BookingRefund,
  ClientInvoice,
  Commission,
  Group,
  Organization,
  Prisma,
  Supplier,
  Trip,
  TripMapping,
  TripRemark,
  User,
} from '@prisma/client';
import type * as sabreTypes from 'clients/sabre/soap/xsd/xmlns/webservices.sabre.com/pnrbuilder/v1_19';
import type * as stl from 'clients/sabre/soap/xsd/xmlns/webservices.sabre.com/sabreXML/2011/10';
import type Decimal from 'decimal.js';
import type {
  InvoiceFor,
  ItineraryType,
  PaymentMethod,
  SupplierType,
} from 'dtos';
import type { ArcWeekSansSnapshot } from 'services/arc/types';
import type { Timing } from 'utils/server/timing';
import type { InterfaceType } from 'utils/shared/types';

export enum EnsClientInvoiceSubject {
  SALE = 'Sale',
  MISC = 'Misc',
  SEAT = 'Seat',
}

export enum EnsClientInvoiceFeeType {
  SERVICE_CHARGE = 'Service Charge',
  ANCILLARY_FEES = 'Ancillary Fees',
}
export type ClientInvoiceInput = {
  pcc: string;
  feeTypeId?: string;
  recipientClientId: string;
  invoiceFor: InvoiceFor;
  subject: string;
  paymentMethods?: string[];
  lastFour?: string;
  currency: string;
  amount: number;
  processingCostPct?: number;
  processingCost?: Decimal;
  dueDate?: Date;
  paidAt?: Date;
  documentNumber?: string;
  documentType?: string;
  invoiceNumber?: string;
  invoiced?: ItineraryInfo;
  canceled?: ItineraryInfo | undefined;
  areSplitsNetOfProcessingCosts: boolean;
};
export type SupplierInput = {
  type: SupplierType;
  name?: string;
  code?: string;
  address?: string;
  phone?: string;
  email?: string;
  fax?: string;
  address1?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
};

export type FareDifferenceDocument = {
  documentNumber: string;
  baseFare: number;
  taxesAndFees: number;
  estCommission: number;
  issuedAt: Date;
  voidedAt?: Date;
};

export type BookingInput = {
  // PassengerReservation.Segments.Segment XML sequence number
  // TODO: make this not optional and find Segment sequence number for all SupplierTypes
  sequenceNumber?: number;
  supplier: SupplierInput;
  confirmationNumber?: string;
  invoiceNumber?: string;
  invoiced?: ItineraryInfo;
  documentType?: string;
  bookingType?: InterfaceType | undefined;
  originalConfirmationNumber?: string;
  fareDifferences?: FareDifferenceDocument[];
  total?: number;
  faceValueTotal?: number;
  commissionableValue?: number;
  faceValueCommissionableValue?: number;
  estCommission?: number;
  commissionDue?: Date | null;
  changeFee?: number;
  residualTotal?: number | undefined;
  residualForfeited?: number | undefined;
  numberOfUnits?: number | undefined;
  unitClass?: string | undefined;
  cityCode?: string | undefined;
  ticketDesignator?: string | undefined;
  itineraryType?: ItineraryType | undefined;
  tourCode?: string | undefined;
  notes?: string;
  checkIn: Date;
  checkOut: Date;
  clientNameNumber?: string;
  cancelled?: ItineraryInfo;
  issued?: ItineraryInfo;
  payments?: BookingPayment[];
  fareInfo?: FareInfo | undefined;
  segments?: FullTicketSegment[];
  ancillaryServicesCategory?: string | undefined;
  supplierType?: BookingSupplierType | undefined;
  currency?: string;
  exchangeRate?: number;
  penalty: Decimal | undefined;
  iataNumber?: string;
  wasUncanceled?: boolean;
  checkSupplierDefaultCommission?: boolean;
  segmentText?: string;
  pcc?: string;
};

export type FareInfo = {
  tax1Amount: number | undefined;
  tax2Amount: number | undefined;
  tax3Amount: number | undefined;
  tax4Amount: number | undefined;
  tax5Amount: number | undefined;
  gstAmount: number | undefined;
  qstAmount: number | undefined;
  penaltyAmount: number | undefined;
};

export type BookingPayment = {
  paymentMethod: string;
  dueDate: Date;
  paidAt?: Date;
  amount: number;
  invoiceNumber: string;
  lastFour?: string | undefined;
  cardCode?: string | undefined;
  paymentType?: string | undefined;
};

export type BookingFee = {
  feeType: string;
  paymentMethod: string;
  lastFour: string | undefined;
  dueDate?: Date;
  paidAt?: Date;
  amount: number;
  processingCostPct?: number;
  processingCost?: Decimal;
  documentNumber?: string;
  invoiceNumber?: string;
  invoiced?: ItineraryInfo;
  subject: string;
  canceled?: ItineraryInfo | undefined;
  documentType: string | undefined;
  pcc?: string;
};

export enum CancelationType {
  CANCEL = 'CANCEL',
  VOID = 'VOID',
}

export type ItineraryInfo = {
  at: Date;
  userSign: string;
  pcc: string;
  canceledOrVoided?: CancelationType;
};

export type QuoteTicketSegment = {
  airlineCode: string;
  departureDate: Date;
  arrivalDate: Date;
  flightNum: string;
};

export type FullTicketSegment = {
  airlineCode: string;
  flightNum: string;
  segmentId: string;
  leg: number | undefined;
  departAtLocal: string;
  departureCityCode: string | undefined;
  arrivalAtLocal: string;
  arrivalCityCode: string | undefined;
  connectionCode: string | undefined;
  fareBasis: string | undefined;
  segmentFare: number | undefined;
  classOfService: string | undefined;
  ticketDesignator: string | undefined;
  seatAssignment: string | undefined;
  equipmentType: string | undefined;
};

export type ArcWeekLookupCache = {
  lookup: Record<string, ArcWeekSansSnapshot>;
  findOrCreateArcWeekByDate: (
    date: Date,
    pcc: string,
  ) => Promise<ArcWeekSansSnapshot>;
};

export const ensTripInclude = {
  primaryClient: true,
  corporateGroup: true,
  remarks: true,
  mappings: { where: { deletedAt: null } },
  agencyUser: {
    include: {
      agency: { include: { organization: true } },
      user: true,
    },
  },
  tripPnrs: {
    where: {
      deletedAt: null,
    },
  },
  bookings: {
    where: { deletedAt: null },
  },
  clientInvoices: { where: { deletedAt: null } },
} satisfies Prisma.TripInclude;

export type EnsTrip = Prisma.TripGetPayload<{
  include: typeof ensTripInclude;
}>;

// context object for easily passing around or down from function to function call the important data used in the ENS service
export type EnsContext = {
  globalPnrPcc: string;
  orgPcc: string;
  pnr: string;
  arcWeekCache: ArcWeekLookupCache;
  organizationId: string;
  organization: Organization;
  dkNumber?: string;
  group?: Group;
  userId: string;
  agentSabreSine?: string;
  reservation: sabreTypes.ReservationPNRB | undefined;
  historyInfos: stl.TravelItineraryHistoryRSTypeHistoricalInfoType[];
  t: Timing;
  invoiceFees: BookingFee[];
  flights: BookingInput[];
  arcEnabled: boolean;
  trip: EnsTrip | null | undefined;
  tripIsAProgram: boolean;
  isExistingTrip: boolean;
};

export type FlightQuote = {
  docNum: string;
  invoiceNumber?: string;
  invoicedItineraryInfo: ItineraryInfo | undefined;
  fareDifferences?: FareDifferenceDocument[];
  documentType: string | undefined;
  bookingType?: InterfaceType | undefined;
  ancillaryServicesCategory?: string | undefined;
  originalTicketNum: string | undefined;
  segments: QuoteTicketSegment[];
  clientNameNumber: string | undefined;
  total: string;
  faceValueTotal?: string;
  baseFare: string;
  faceValueBaseFare?: string;
  changeFee?: string;
  residualTotal?: number | undefined;
  residualForfeited?: number | undefined;
  unitClass?: string | undefined;
  ticketDesignator?: string | undefined;
  itineraryType?: ItineraryType | undefined;
  tourCode?: string | undefined;
  payments: BookingPayment[];
  commission?: { amount: number; currency: string };
  issuedItineraryInfo: ItineraryInfo;
  fareInfo?: FareInfo | undefined;
  notes?: string;
  supplierType?: BookingSupplierType | undefined;
  penalty: Decimal | undefined;
};

export enum BookingSupplierType {
  Ancillaries = 'ANCILLARIES', // same as SupplierType.ANCILLARIES but TS doesn't like using a ref here
}

// cspell:disable
// From ChatGPT. To be verified
// SSR stand for Special Services Request
export enum AncillaryServiceSSR {
  BAGX = 'BAGX', // Extra Baggage
  BIKE = 'BIKE', // Bicycle Handling
  CBBG = 'CBBG', // Cabin Baggage (baggage occupying a seat)
  COTS = 'COTS', // Child Restraint System
  ESAN = 'ESAN', // Emotional Support Animal
  FQTU = 'FQTU', // Frequent Flyer Number Update
  FQTS = 'FQTS', // Frequent Flyer Tier Status
  MEAL = 'MEAL', // General Special Meal Request
  AVML = 'AVML', // Asian Vegetarian Meal
  BBML = 'BBML', // Baby Meal
  BLML = 'BLML', // Bland Meal
  CHML = 'CHML', // Child Meal
  DBML = 'DBML', // Diabetic Meal
  FPML = 'FPML', // Fruit Platter Meal
  GFML = 'GFML', // Gluten-Free Meal
  HNML = 'HNML', // Hindu Meal
  KSML = 'KSML', // Kosher Meal
  LCML = 'LCML', // Low Calorie Meal
  LFML = 'LFML', // Low Fat Meal
  LSML = 'LSML', // Low Sodium Meal
  MOML = 'MOML', // Muslim Meal
  NLML = 'NLML', // Non-Lactose Meal
  RVML = 'RVML', // Raw Vegetarian Meal
  SFML = 'SFML', // Seafood Meal
  VGML = 'VGML', // Vegetarian Meal
  VLML = 'VLML', // Vegetarian Lacto-Ovo Meal
  PETC = 'PETC', // Pet in Cabin
  SPORT = 'SPORT', // Sporting Equipment
  UMNR = 'UMNR', // Unaccompanied Minor
  WCHR = 'WCHR', // Wheelchair Request (able to walk short distances)
  WCHS = 'WCHS', // Wheelchair Request (unable to walk short distances)
  WCHC = 'WCHC', // Wheelchair Request (completely immobile)
  SEAT = 'SEAT', // General Seat Selection
  EXST = 'EXST', // Extra Seat
  UPGR = 'UPGR', // Upgrade
}
// cspell:enable

// SourceType is used to determine the source of the update to specific entities,
// whether via ENS notification or manual update
export enum SourceType {
  IMPORT = 'IMPORT',
  ENS = 'ENS',
  MANUAL = 'MANUAL',
  MANUAL_ARC_DASHBOARD = 'MANUAL_ARC_DASHBOARD',
}

export type UpsertBookingReturnType =
  | (Booking & {
      refunds: BookingRefund[];
      commissionBookings: {
        commission: Commission;
      }[];
      supplier: Supplier;
      arcWeek: ArcWeekSansSnapshot | null;
    })
  | undefined
  | null;

export const ensBookingInclude = {
  expenses: {
    include: { bookingExpenseMapping: true },
  },
  mapping: true,
  airSegments: true,
  refunds: true,
  commissionBookings: {
    include: { commission: true },
  },
  fareDifferenceDocument: {
    where: { deletedAt: null },
  },
  supplier: true,
  arcWeek: { omit: { snapshot: true } },
} satisfies Prisma.BookingInclude;

export type ConfirmationNumber = string;
export type SupplierTypeStr = string;

export type BookingInputMap = Record<ConfirmationNumber, BookingInput[]>;

export type PaymentDetails = {
  paymentAmt: number;
  paymentMethod: PaymentMethod;
  lastFour: string | undefined;
  cardCode: string | undefined;
  paymentType: string | undefined;
};

export type TicketSegment = {
  airlineCode: string;
  departureDate: Date;
  arrivalDate?: Date;
  flightNum: string;
  ticketNum: string;
  departureCity: string;
  arrivalCity: string;
  canceled: boolean;
  isInfant: boolean;
};

export type CliqbookPriceQuote = {
  quote: {
    total: number;
    base: number;
    taxes: number;
  };
  ticketInfo: {
    locator: string;
    ticketNumber: string;
  };
};

export type SupplierCommissionPctAndCommDueOffset = {
  supplierCommissionRate: number | undefined;
  commissionDueOffset: number | undefined;
};

export type RefundsByDocNum = Record<string, Refund>;

export type Refund = {
  paymentMethod?: string;
  at: Date;
  amount: number;
  tax: number;
  commissionableValue: number;
  estCommission: number;
  invoiceNumber?: string;
  documentNumber: string;
  canceled: ItineraryInfo | undefined;
  penalty: Decimal | undefined;
  pcc?: string;
};
