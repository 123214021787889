import type { LeadStage, Prisma } from '@prisma/client';
import type { ContextToken } from 'dtos';
import type {
  DashboardDateRange,
  DashboardDueTodaySortKey,
  DashboardPaymentType,
  DashboardPaymentsSortKey,
  DashboardTasksSortKey,
  DashboardTripsSortKey,
} from 'dtos/dashboard.dtos';

export type SqlTripStatus = {
  trip_status: string;
  status_type: string;
  status_order: number;
  status_count: bigint;
};

export type SqlTrip = {
  id: string;
  traveler_name: string;
  name: string;
  destinations: string;
  advisor_name: string;
  start_date: Date | null;
  end_date: Date | null;
  status: string;
  'stage.id'?: string;
  'stage.createdAt': Date;
  'stage.createdById': string;
  'stage.updatedAt': Date;
  'stage.deletedAt': Date | null;
  'stage.deletedById': string | null;
  'stage.organizationId': string;
  'stage.type': string;
  'stage.name': string;
  'stage.color': string;
  'stage.order': number;
  stage?: LeadStage;
};

export type GetTripsByStatusParams = {
  organizationId: string;
  status?: string;
  sort: DashboardTripsSortKey;
  sortDirection: 'asc' | 'desc';
  page: number;
  pageSize: number;
  userTripsWhereRaw: Prisma.Sql;
};

export const tripSortKeys: Record<DashboardTripsSortKey, keyof SqlTrip> = {
  travelerName: 'traveler_name',
  name: 'name',
  destinations: 'destinations',
  advisorName: 'advisor_name',
  startDate: 'start_date',
  endDate: 'end_date',
  stage: 'status',
};

export enum FromEntityType {
  CLIENT = 'CLIENT',
  AGENCY = 'AGENCY',
  GROUP = 'GROUP',
  ORGANIZATION = 'ORGANIZATION',
}

export enum PaymentTypeFilter {
  ALL = 'ALL',
  BOOKING = 'BOOKING',
  CLIENT = 'CLIENT',
}

export enum DateRangeFilter {
  ALL = 'ALL',
  TODAY = 'TODAY',
  NEXT_7_DAYS = 'NEXT_7_DAYS',
  THIS_MONTH = 'THIS_MONTH',
  OVERDUE = 'OVERDUE',
  UPCOMING = 'UPCOMING',
}

export type SqlPayment = {
  payment_type: DashboardPaymentType;
  payment_id: string;
  booking_id: string | null;
  trip_id: string;
  trip_name: string | null;
  subject: string;
  from_entity_type: FromEntityType;
  from_entity_id: string;
  from_entity_name: string;
  to_entity_name: string;
  amount_home: number;
  due_date: Date;
  client_id: string;
  client_first_name: string;
  client_last_name: string | null;
  client_type: 'CLIENT' | 'GROUP';
  agency_user_id: string;
  agency_user_first_name: string;
  agency_user_last_name: string;
  agency_user_profile_image_url: string | null;
};

export type GetPaymentsByTypeParams = {
  userId: string;
  context: ContextToken | undefined;
  type: PaymentTypeFilter;
  dateRange: DateRangeFilter;
  today: string;
  sort: DashboardPaymentsSortKey;
  sortDirection: 'asc' | 'desc';
  page: number;
  pageSize: number;
};

export type SqlTask = {
  id: string;
  task_type: string;
  title: string;
  due_at: Date | null;
  trip_id: string;
  trip_name: string;
  entity_id: string;
  entity_type: string;
  client_id: string;
  client_first_name: string;
  client_last_name: string;
  agency_user_id: string;
  agency_user_first_name: string;
  agency_user_last_name: string;
  agency_user_profile_image_url: string | null;
};

export type GetTasksByTypeParams = {
  userId: string;
  context: ContextToken | undefined;
  basis: TaskBasis;
  filter: TaskFilterType;
  today: string;
  sort: DashboardTasksSortKey;
  sortDirection: 'asc' | 'desc';
  page: number;
  pageSize: number;
};

export enum TaskBasis {
  TRIP = 'TRIP',
  DATE = 'DATE',
}

enum NonDateRangeFilters {
  TBD = 'TBD',
  COMPLETED = 'COMPLETED',
}

export const TaskFilter = {
  ...DateRangeFilter,
  ...NonDateRangeFilters,
} as const;

export type TaskFilterType = (typeof TaskFilter)[keyof typeof TaskFilter];

// TODO: how can we use the ClientTaskDateType enum here?
export enum DateType {
  ALL = 'ALL',
  BIRTHDAYS = 'BIRTHDAY',
  PASSPORTS = 'PASSPORT_EXPIRATION',
  ANNIVERSARIES = 'ANNIVERSARY',
}

export type SqlDateTask = {
  id: string;
  type: string;
  remind_at: Date;
  due_at: Date;
  client_id: string;
  client_first_name: string;
  client_last_name: string;
  group_name: string | null;
  groups: { id: string; name: string }[];
  agency_user_id: string;
  agency_user_first_name: string;
  agency_user_last_name: string;
  agency_user_profile_image_url: string | null;
};

export type GetDateTasksParams = {
  userId: string;
  context?: ContextToken;
  type: DateType | string;
  today: string;
  dateRange: DashboardDateRange;
  sort?: string;
  sortDirection?: string;
  page?: number;
  pageSize?: number;
};

export enum DueTodayFilter {
  ALL = 'ALL',
  TASK = 'TASK',
  PAYMENT = 'PAYMENT',
  DATE = 'DATE',
  COMPLETED = 'COMPLETED',
}

export type GetDueTodayParams = {
  userId: string;
  context: ContextToken | undefined;
  today: string;
  type: DueTodayFilter;
  sort: DashboardDueTodaySortKey;
  sortDirection: 'asc' | 'desc';
  page: number;
  pageSize: number;
};

export type SqlDueToday = {
  id: string;
  due_today_type: string;
  title: string;
  trip_id: string | null;
  trip_name: string | null;
  booking_id: string | null;
  client_id: string;
  client_type: 'CLIENT' | 'GROUP';
  client_first_name: string;
  client_last_name: string | null;
  agency_user_id: string | null;
  agency_user_first_name: string | null;
  agency_user_last_name: string | null;
  agency_user_profile_image_url: string | null;
};
